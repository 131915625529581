import actionCreatorFactory from 'typescript-fsa'
import { RouterState } from './router.reducer'

const actionCreator = actionCreatorFactory('router')

export const navigate = actionCreator<{
  viewId: string
  params?: { [x: string]: any }
}>('NAVIGATE')

export const goBack = actionCreator<{}>('GO_BACK')

export const rehydrateRouterState = actionCreator<{ state: RouterState }>(
  'REHYDRATE'
)
