import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Actions } from '@andyet/simplewebrtc'

import {
  leaveVideoCall,
  expandedModeSelector,
  mobileShrinkSelector,
  toggleMobileShrink
} from 'state/video'
import { videoDisplayNameSelector } from 'state/lead/lead.selectors'
import { webdeskPositionSelector, windowSelector } from 'state/ui'
import { RootState } from 'types'
import { VideoRoomDisplay, Props } from './VideoRoom.display'

type StateProps = Pick<
  Props,
  | 'expandedVideoMode'
  | 'webdeskPosition'
  | 'isWindowOpen'
  | 'displayName'
  | 'mobileShrink'
>
const mapStateToProps = (state: RootState): StateProps => {
  return {
    expandedVideoMode: expandedModeSelector(state),
    webdeskPosition: webdeskPositionSelector(state),
    isWindowOpen: windowSelector(state),
    displayName: videoDisplayNameSelector(state),
    mobileShrink: mobileShrinkSelector(state)
  }
}

type DispatchProps = Pick<
  Props,
  'leaveCall' | 'setDisplayName' | 'toggleMobileShrink'
>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  leaveCall: () => {
    dispatch(leaveVideoCall())
  },
  setDisplayName: (name: string) => {
    dispatch(Actions.setDisplayName(name) as any)
  },
  toggleMobileShrink: () => {
    dispatch(toggleMobileShrink())
  }
})

export const VideoRoomContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoRoomDisplay)
