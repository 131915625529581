import * as React from 'react'

import { lighten } from 'polished'
import { helpers } from 'style/mixins'
import styled, { css, withTheme } from 'styledComponents'
import { HelpersProps, InjectedFieldProps, ThemeInterface } from 'types'

import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Flex } from 'components/_utility'

export interface Props extends InjectedFieldProps, HelpersProps {
  className?: string
  theme: ThemeInterface
  name: string
  placeholder?: string
  disabled?: boolean
}

const Component = ({
  className,
  theme,
  name,
  placeholder,
  value,
  status,
  updateField,
  disabled
}: Props) => {
  const onChange = (e: React.SyntheticEvent) => {
    updateField({
      [name]: {
        value: (e.target as HTMLTextAreaElement).value
      }
    })
  }

  const onBlur = () => {
    updateField({
      [name]: {
        dirty: true
      }
    })
  }

  return (
    <Flex className={className} row alignItems="center" relative>
      <StyledTextarea
        name={name}
        value={value as string}
        status={status}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
      {status === 'invalid' && <Icon icon={faTimes} color={theme.red} />}
      {status === 'valid' && <Icon icon={faCheck} color={theme.green} />}
    </Flex>
  )
}

const StyledTextarea = styled('textarea')<{ status?: string }>`
  ${helpers}
  padding: 8px 12px;
  border-radius: 6px;

  font-size: 14px;

  transition: border-color 0.25s;

  ${p => {
    return css`
      background-color: ${p.theme.colors.lightest}
      color: ${p.theme.gray.dark}
      border: solid 2px ${p.theme.colors.lighter}
      ${p.status === 'invalid' && `border-color: ${lighten(0.25, p.theme.red)}`}
      ${p.status === 'valid' && `border-color: ${lighten(0.25, p.theme.green)}`}
    `
  }}

  &:focus {
    outline: none;

    ${p => !p.status && `border-color: ${p.theme.colors.light};`}
  }

  &::placeholder {
    color: ${p => p.theme.gray.lighter};
  }
`

const StyledComponent = styled(Component)`
  textarea {
    width: 100%;
    min-width: 100%;
    min-height: 36px;
    ${p =>
      p.status === 'valid' || p.status === 'invalid'
        ? `padding-right: 33px;`
        : ``};
  }

  svg {
    position: absolute;
    right: 12px;
    transition: all 0.25s;
  }
`

export const Textarea = withTheme(StyledComponent)
