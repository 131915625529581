import * as React from 'react'
import styled from 'styledComponents'

import { Card, Flex, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  HelpersProps
} from 'types'

export interface Props extends HelpersProps {
  className?: string
  _id: string
  body: string
  collapsed?: boolean
  header?: CardHeaderProps
  place: string
  actionButtons: ActionButtonActionInterface[]
}

const Component = ({
  className,
  body,
  collapsed = false,
  header,
  place,
  actionButtons
}: Props) => (
  <Card
    className={className}
    padding="none"
    header={header}
    collapsed={collapsed}
    actionButtons={actionButtons}
    actionButtonsDisplay={{ padding: 'full' }}
    customAttributes={{ 'data-test': 'location-card' }}
  >
    <iframe
      src={`https://www.google.com/maps/embed/v1/place?q=${place}&key=AIzaSyBhGJGUxMsSTI-wpRBEfj3P6JEEg19mvfY&maptype=satellite`}
      frameBorder="0"
      allowFullScreen
    />
    <Flex column padding="standard">
      <Flex column>
        <Text isTitle>Address</Text>
        <Body isBody>{body}</Body>
      </Flex>
    </Flex>
  </Card>
)

export const LocationCardDisplay = styled(Component)`
  ${helpers}

  iframe {
    width: 100%;
    height: 240px;
  }
`

const Body = styled(Text)``
