import { call, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import settings from 'settings'
import customWindow from 'utility/storage/customWindow'
import { monitoringService } from 'services/monitoring'
import { logAction } from './_actions'

function* saga(action: Action<{ error: Error }>) {
  const logData = {
    href: window.location.href,
    userAgent: navigator.userAgent,
    roomId: customWindow.localStorage.getItem(settings.leadRoomIdStorageKey),
    companyId: settings.companyId,
    webdeskId: settings.webdeskId
  }

  yield call(() =>
    monitoringService.trackException(action.payload.error, logData)
  )
}

export function* logSaga() {
  yield takeEvery(logAction, saga)
}
