import { delay } from 'redux-saga'
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { Action, isType } from 'typescript-fsa'

import {
  BOT_STOP_TYPING_TIMEOUT,
  FORM_CARD_ID_SEPARATOR,
  NAVIGATION,
  STOP_TYPING_TIMEOUT
} from 'common/constants'
import iframeCommunication from 'services/iframe'
import rpApiService from 'services/rp.api'
import socket from 'services/rp.socket'
import { logAction, showToast, trackEvent } from 'state/_sagas/_actions'
import { updateAuthors } from 'state/entities/authors'
import { cardByIdSelector, setCard, updateCard } from 'state/entities/cards'
import { formSelector, runConversionCode, setFormSubmitted } from 'state/forms'
import {
  leadSelector,
  pingLead,
  pingLeadFail,
  pingLeadSuccess
} from 'state/lead'
import { currentViewIdSelector, navigate } from 'state/router'
import { init as initSocket } from 'state/socket'
import { windowSelector } from 'state/ui'
import {
  ConversationInterface,
  ConversationSession,
  Message,
  ProviderTypingInterface,
  SocketMessage,
  TrackedEventDestinations,
  UnreadMessageTypes
} from 'types'
import { getFormCardRows } from 'utility/getFormCardRows'
import { getFormDataToSubmit } from 'utility/getFormDataToSubmit'
import { mapInventoryElement } from 'utility/mapInventoryElement'
import {
  botTyping,
  cancelProviderTyping,
  checkIfMarkRead,
  fetchConversation,
  FetchConversationPayload,
  fetchConversationSuccess,
  markRead,
  markReadInState,
  messageSent,
  pingConversation,
  PingConversationPayload,
  providerTyping,
  replyAction,
  ReplyActionPayload,
  replyText,
  ReplyTextPayload,
  submitConversationCard,
  submitForm,
  SubmitFormPayload,
  typeKey,
  updateConversationSession
} from './conversations.actions'
import {
  companyIdsSelector,
  conversationSelector,
  lastConversationIdSelector
} from './conversations.selectors'

function* extractCardsFromMessages(messages: Message[] | SocketMessage[]) {
  for (const message of messages) {
    if (!message.card) continue

    if (message.card.template === 'carousel') {
      message.card.props.products = (message.card.props.products || []).map(
        mapInventoryElement
      )
    }
    if (message.card.template === 'form') {
      message.card.props.formData.rows = getFormCardRows(
        message.card.props.formData.rows
      )
    }
    yield put(setCard({ card: message.card }))
  }
}

function* fetchConversationSaga(action: Action<FetchConversationPayload>) {
  try {
    const { conversationId } = action.payload

    const {
      participants,
      messages,
      companyId,
      sourceCompanyId
    }: ConversationSession = yield call(
      rpApiService.getConversation,
      conversationId
    )
    yield put(updateAuthors({ participants }))
    yield extractCardsFromMessages(messages)
    yield put(
      updateConversationSession({
        conversationId,
        messages,
        companyId,
        sourceCompanyId
      })
    )
    yield put(fetchConversationSuccess({ conversationId }))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

function* pingConversationSaga(action: Action<PingConversationPayload>) {
  try {
    if (!socket.isInitialized()) {
      yield put(initSocket())
      while (!socket.isInitialized()) {
        yield delay(1000)
      }
    }
    const { chatbotId, pathId, autoReplyMessage } = action.payload

    yield put(pingLead({ performUpdate: true, withoutDelay: true }))
    const pingLeadResultAction = yield take([pingLeadSuccess, pingLeadFail])
    if (isType(pingLeadResultAction, pingLeadFail)) {
      throw new Error('Unable to ping lead')
    }

    const {
      conversationId,
      participants,
      messages,
      companyId,
      sourceCompanyId
    }: ConversationSession = yield call(rpApiService.pingConversation, {
      chatbotId,
      pathId,
      autoReplyMessage
    })
    yield put(updateAuthors({ participants }))
    yield extractCardsFromMessages(messages)
    yield put(
      updateConversationSession({
        conversationId,
        messages,
        companyId,
        sourceCompanyId
      })
    )
    yield put(
      navigate({ viewId: NAVIGATION.CONVERSATIONS, params: { conversationId } })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

function* replyActionSaga(action: Action<ReplyActionPayload>) {
  try {
    const { conversationId, conversationAction } = action.payload

    switch (conversationAction.type) {
      case 'booking':
        yield put(
          navigate({
            viewId: NAVIGATION.BOOKING,
            params: {
              showAll: conversationAction.showAll,
              service: conversationAction.service
            }
          })
        )
        break
      case 'chat':
        yield call(rpApiService.replyAction, conversationId, {
          text: conversationAction.cta,
          elements: conversationAction.elements
        })
        break
      case 'faq':
        yield put(
          navigate({
            viewId: NAVIGATION.FAQS,
            params: {
              actionId: conversationAction._id,
              faqsOrder: conversationAction.order
            }
          })
        )
        break
      default:
        return null
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(showToast({ message: 'Failed to reply', variant: 'error' }))
  }
}

function* replyTextSaga(action: Action<ReplyTextPayload>) {
  try {
    const { text, conversationId } = action.payload
    yield call(rpApiService.replyText, conversationId, {
      text
    })
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(
      showToast({ message: 'Failed to send message', variant: 'error' })
    )
  }
}

function* submitFormSaga(action: Action<SubmitFormPayload>) {
  const { formId, conversationId, conversionCode } = action.payload
  try {
    yield put(setFormSubmitted({ formId, submitted: true }))

    const cardId = formId.split(FORM_CARD_ID_SEPARATOR)[1]
    yield put(
      updateCard({
        _id: cardId,
        data: {
          props: { submitted: true }
        }
      })
    )

    const form = yield select(formSelector, formId)
    const card = yield select(cardByIdSelector, cardId)
    const dataToSubmit = getFormDataToSubmit(form, card)
    const SD_SESSION_ID = localStorage.getItem("SD_SESSION_ID")
    dataToSubmit.SD_SESSION_ID = localStorage.getItem("SD_SESSION_ID")

    yield call(rpApiService.replyChatbotForm, conversationId, dataToSubmit, SD_SESSION_ID)
    yield put(runConversionCode({ conversionCode }))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    let message = 'Something went wrong. Please try again later.'
    try {
      const { errors = [] } = JSON.parse(e.message)
      if (errors.length) {
        message = Object.values(errors[0])[0] as string
      }
    } catch (e) {
      yield put(logAction({ error: e }))
      console.error('Error:', e)
    }
    yield put(setFormSubmitted({ formId, submitted: false }))
    yield put(showToast({ message, variant: 'error' }))
  }
}

function* runConversionCodeSaga(action: Action<{ conversionCode: string }>) {
  try {
    let code = action.payload.conversionCode
    if (!code) return

    const lead = yield select(leadSelector)
    const leadFields = ['firstName', 'lastName', 'email', 'phone']
    // Merge variables
    leadFields.forEach(field => {
      code = (code || '').replace(`--${field}--`, lead[field])
    })

    iframeCommunication.conversionCode({ code })
  } catch (err) {
    yield put(logAction({ error: err }))
    console.log('Conversion script has errors:', `${err.message} ${err.stack}`)
  }
}

function* typeKeySaga(action: Action<{ conversationId: string }>) {
  try {
    const { companyId, sourceCompanyId } = yield select((state: any) =>
      companyIdsSelector(state, action.payload.conversationId)
    )
    socket.emit('user:typing', { companyId, sourceCompanyId })
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

function* messageSentSaga(action: Action<SocketMessage>) {
  const message = action.payload

  // send to shift digital
  const messageType = message.authorId === 'client' ? 'user' : 'agent'
  let content = message.text
  if (content === '' && message.actions) {
    // could use json tostring here to show all the inventory or form data later
    content = (message.actions as any).reduce(
      (text: string, act: any) => (text += `${act.cta}\n`)
    )
  }

  if (content !== '') {
    const { inquiryTypeId } = yield select(leadSelector)
    yield put(
      trackEvent({
        trackingName: 'CHAT_MESSAGE',
        properties: {
          chatMessageType: messageType,
          chatMessageContent: content,
          inquiryTypeId
        },
        destinations: [TrackedEventDestinations.SHIFT]
      })
    )
  }

  const providerData = {
    _id: message.authorId,
    conversationId: message.conversationId
  }

  yield put(cancelProviderTyping(providerData))
  yield extractCardsFromMessages([message])
}

function* markReadSaga(action: Action<{ conversationId: string }>) {
  const { conversationId } = action.payload
  const conversation: ConversationInterface = yield select(
    conversationSelector,
    conversationId
  )
  if (
    conversation.messages.filter(
      m => UnreadMessageTypes.includes(m.type) && m.status === 'unread'
    ).length === 0
  )
    return
  yield put(markReadInState({ conversationId }))
  yield call(rpApiService.markRead, conversationId)
}

function* providerTypingSaga(action: Action<ProviderTypingInterface>) {
  const providerData = action.payload

  yield delay(STOP_TYPING_TIMEOUT)
  yield put(cancelProviderTyping(providerData))
}

function* botTypingSaga(action: Action<ProviderTypingInterface>) {
  const providerData = action.payload

  yield delay(BOT_STOP_TYPING_TIMEOUT)
  yield put(cancelProviderTyping(providerData))
}

function* checkIfMarkReadSaga(action: Action<{ conversationId?: string }>) {
  let { conversationId } = action.payload

  if (!conversationId) {
    conversationId = yield select(lastConversationIdSelector)
  }
  const viewId = yield select(currentViewIdSelector)
  const isWindowOpen = yield select(windowSelector)
  const isFocused = document.hasFocus()
  if (
    conversationId &&
    viewId === NAVIGATION.CONVERSATIONS &&
    isFocused &&
    isWindowOpen
  ) {
    yield put(markRead({ conversationId }))
  }
}

function* submitConversationCardSaga(action: Action<any>) {
  const { conversationId, cardId, data } = action.payload
  try {
    yield put(
      updateCard({
        _id: cardId,
        data: {
          props: { submitted: true }
        }
      })
    )
    yield call(
      rpApiService.submitConversationCard,
      conversationId,
      cardId,
      data
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    let message = 'Something went wrong. Please try again later.'
    try {
      const { errors = [] } = JSON.parse(e.message)
      if (errors.length) {
        message = Object.values(errors[0])[0] as string
      }
    } catch (e) {
      yield put(logAction({ error: e }))
      console.error('Error:', e)
    }
    yield put(
      updateCard({
        _id: cardId,
        data: {
          props: { submitted: false }
        }
      })
    )
    yield put(showToast({ message, variant: 'error' }))
  }
}

export default function* conversationsSaga() {
  yield all([
    takeEvery(fetchConversation, fetchConversationSaga),
    takeEvery(pingConversation, pingConversationSaga),
    takeEvery(replyAction, replyActionSaga),
    takeEvery(replyText, replyTextSaga),
    takeEvery(submitForm, submitFormSaga),
    takeEvery(typeKey, typeKeySaga),
    takeEvery(messageSent, messageSentSaga),
    takeEvery(runConversionCode, runConversionCodeSaga),
    takeEvery(fetchConversationSuccess, checkIfMarkReadSaga),
    takeEvery(checkIfMarkRead, checkIfMarkReadSaga),
    takeEvery(navigate, checkIfMarkReadSaga),
    takeLatest(markRead, markReadSaga),
    takeLatest(providerTyping, providerTypingSaga),
    takeLatest(botTyping, botTypingSaga),
    takeEvery(submitConversationCard, submitConversationCardSaga)
  ])
}
