import * as React from 'react'
import styled, { css } from 'styledComponents'

import { helpers, shadow } from 'style/mixins/'
import { HelpersProps } from 'types'

export interface Props extends HelpersProps {
  className?: string
  children: React.ReactNode
  type: string
  fullWidth?: boolean
}

const Component = ({ className, children }: Props) => (
  <div className={className} data-test="message-bubble">
    {children}
  </div>
)

export const Bubble = styled(Component)<Props>`
  ${helpers}
  position: relative;
  z-index: 0;
  max-width: 100%;
  padding: 15px;
  margin-bottom: 6px;
  border-radius: 6px;

  font-size: 14px;
  line-height: 1.4;
  ${shadow('light')}
  word-break: break-word;

  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-position: inside;
    list-style-type: inherit;
  }

  p {
    margin: 0;
  }

  ${p => {
    return css`
      ${p.type === 'user' &&
        `
        background-color: ${p.theme.gray.lightest};
        color: ${p.theme.gray.dark};
        a {
          color: ${p.theme.gray.dark};
        }
      `}

      ${p.type === 'client' &&
        `
        background-color: ${p.theme.isCadillacTheme?p.theme.cadillacBubble:p.theme.colors.original};
        color: ${p.theme.isCadillacTheme?'black':"white"};
        a {
          color:${p.theme.isCadillacTheme?'black':"white"};
        }
      `}

      ${p.fullWidth === true &&
        `
        width: 100%;  
      `}
    `
  }}
`
