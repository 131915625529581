interface SizeInterface {
  utilityHeight: number
  toolbarHeight: number
  mobileWidth: number
  isMobile: boolean
}

export const sizes: SizeInterface = {
  utilityHeight: 72,
  toolbarHeight: 50,
  mobileWidth: 480,
  isMobile: window.top.innerWidth < 480
}
