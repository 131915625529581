/* eslint-disable react/prop-types */
import { darken } from 'polished'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { colorShades, sizes } from 'style/variables'
import styled, { css, withTheme } from 'styledComponents'

import iframeCommunication from 'services/iframe'

import { NavButtonContainer } from 'components/'
import { Cirbutt, Flex } from 'components/_utility'
import { CirbuttProps } from 'components/_utility/Cirbutt'
import { TranslateButton } from 'components/TranslateButton'
import { ControlToolbarButton } from 'components/ControlToolbarButton'

import { faArrowsH } from '@fortawesome/pro-light-svg-icons/faArrowsH'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableStateSnapshot,
  DropResult
} from 'react-beautiful-dnd'
import {
  CardStackViewInterface,
  NavButtonInterface,
  ThemeInterface
} from 'types'
import { calculateToolbarWidth, deepCopyObject } from 'utility'

export interface Props {
  className?: string
  theme: ThemeInterface
  show: boolean
  currentView: CardStackViewInterface
  homeViewId: string
  navButtons: NavButtonInterface[]
  previewMode: string
  isTranslatorDisabled: boolean
  window: boolean
  isToolbarEnabled: boolean
  webdeskPosition: string
  updateNavButtons: (newNavButtons: NavButtonInterface[]) => void
  addNavButton: (navButtons: NavButtonInterface[]) => void
  editNavButton: (index: number) => void
  editHome: () => void
}

const Component = ({
  className,
  window,
  show,
  theme,
  currentView,
  homeViewId,
  navButtons,
  previewMode,
  updateNavButtons,
  editNavButton,
  addNavButton,
  editHome,
  isToolbarEnabled,
  isTranslatorDisabled,
  webdeskPosition
}: Props) => {
  const currentViewCardsLength = React.useMemo(
    () => (currentView.cardIds || []).length,
    [currentView.cardIds]
  )
  const [isToolbarExpanded, setIsToolbarExpanded] = React.useState(false)
  const prevIsToolbarExpanded = React.useRef(isToolbarExpanded)
  const prevCurrentViewCardsLength = React.useRef(currentViewCardsLength)
  const prevWindow = React.useRef(window)

  const portal = document.createElement('div')
  if (previewMode === 'viewsview') {
    document.body.appendChild(portal)
  }

  const dragEnd = (result: DropResult) => {
    const { destination, source } = result
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    const newNavButtons = deepCopyObject(navButtons)
    newNavButtons.splice(source.index, 1)
    newNavButtons.splice(destination.index, 0, navButtons[source.index])
    updateNavButtons(newNavButtons)
  }

  const renderButtons = (
    navButtonsData: NavButtonInterface[],
    reducingForHelpButtons: number
  ) => {
    return navButtonsData.map((props, index) => {
      const selected = window && currentView._id === props.viewId
      return (
        <NavButtonContainer
          key={index}
          selected={selected}
          reducingForHelpButtons={reducingForHelpButtons}
          {...props}
        />
      )
    })
  }

  const renderDndButtons = (
    navButtonsData: NavButtonInterface[],
    reducingForHelpButtons: number
  ) => {
    return (
      <React.Fragment>
        <DragDropContext onDragEnd={dragEnd}>
          <Droppable droppableId={`navButtonDroppable`} direction="horizontal">
            {(droppableProvided, droppableSnapshot) => {
              return (
                <StyledDroppable
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  {...droppableSnapshot}
                >
                  {!isToolbarEnabled && (
                    <StyledDiv>
                      <NavOption
                        id="navOption"
                        top="3px"
                        left="3px"
                        onClick={editHome}
                      >
                        <Icon icon={faHome} color={'white'} size="xs" />
                      </NavOption>
                      <NavButtonContainer
                        label="Messages"
                        viewId={homeViewId}
                        selected={currentView._id === homeViewId}
                        reducingForHelpButtons={reducingForHelpButtons}
                      />
                    </StyledDiv>
                  )}
                  {navButtonsData.map((navButton, index) => {
                    const selected =
                      window && currentView._id === navButton.viewId

                    return (
                      <React.Fragment key={`draggableNavButton_${index}`}>
                        <Draggable draggableId={`${index}`} index={index}>
                          {(draggableProvided, draggableSnapshot) => {
                            const dragChildren: React.ReactNode = (
                              <StyledDiv
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                {...draggableSnapshot}
                              >
                                <NavOption
                                  id="navOption"
                                  top="3px"
                                  left="3px"
                                  onClick={() => editNavButton(index)}
                                >
                                  <Icon
                                    icon={faPencil}
                                    color={'white'}
                                    size="xs"
                                  />
                                </NavOption>
                                {isToolbarEnabled &&
                                  navButton.hiddenInToolbarMode && (
                                    <NavOption
                                      id="navVisibleOption"
                                      top="3px"
                                      left="3px"
                                      visible
                                      opacity={0.4}
                                      selected={selected}
                                    >
                                      <Icon
                                        icon={faEyeSlash}
                                        color={
                                          selected
                                            ? 'white'
                                            : // eslint-disable-next-line react/prop-types
                                              theme.colors.original
                                        }
                                        size="xs"
                                      />
                                    </NavOption>
                                  )}

                                <NavOption
                                  id="navOption"
                                  {...draggableProvided.dragHandleProps}
                                  top="3px"
                                  right="3px"
                                >
                                  <Icon
                                    icon={faArrowsH}
                                    color={'white'}
                                    size="xs"
                                  />
                                </NavOption>
                                <NavButtonContainer
                                  key={index}
                                  selected={selected}
                                  reducingForHelpButtons={
                                    reducingForHelpButtons
                                  }
                                  {...navButton}
                                />
                              </StyledDiv>
                            )

                            if (!draggableSnapshot.isDragging) {
                              return dragChildren as React.ReactElement<any>
                            }

                            return ReactDOM.createPortal(dragChildren, portal)
                          }}
                        </Draggable>
                      </React.Fragment>
                    )
                  })}
                </StyledDroppable>
              )
            }}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    )
  }

  const navButtonsToRender = React.useMemo(() => {
    if (previewMode === 'viewsview') {
      return navButtons
    }

    if (isToolbarEnabled) {
      return isToolbarExpanded
        ? navButtons
        : navButtons.filter(props => !props.hiddenInToolbarMode)
    }

    return [
      {
        label: 'Messages',
        viewId: homeViewId
      },
      ...navButtons
    ]
  }, [navButtons, isToolbarEnabled, isToolbarExpanded, previewMode])

  const renderNavButtons = (numberOfHelpButtons: number) => {
    /* Beautiful dnd only in preview mode */
    if (previewMode === 'viewsview') {
      return renderDndButtons(
        navButtonsToRender,
        navButtonsToRender.length > 4 ? numberOfHelpButtons : 0
      )
    }

    return renderButtons(
      navButtonsToRender,
      navButtonsToRender.length > 4 ? numberOfHelpButtons : 0
    )
  }

  const maxButtonsCount = React.useMemo(() => (isToolbarEnabled ? 5 : 4), [
    isToolbarEnabled
  ])

  const isControlToolbarButtonDisplayed = React.useMemo(
    () =>
      isToolbarEnabled && navButtons.some(props => !!props.hiddenInToolbarMode),
    [isToolbarEnabled, navButtons]
  )

  const isTranslatorButtonDisplayed = React.useMemo(
    () => isToolbarEnabled && !isTranslatorDisabled,
    [isToolbarEnabled, isTranslatorDisabled]
  )
  const isAddButtonDisplayed = React.useMemo(
    () => previewMode === 'viewsview' && maxButtonsCount > navButtons.length,
    [previewMode, maxButtonsCount, navButtons]
  )

  const isNavControlTooltipShort = React.useMemo(
    () =>
      !(isTranslatorButtonDisplayed || isAddButtonDisplayed) &&
      navButtonsToRender.length === 1,
    [isTranslatorButtonDisplayed, isAddButtonDisplayed, navButtonsToRender]
  )

  let numberOfHelpButtons = 0

  let leftHelpButton = null
  if (isControlToolbarButtonDisplayed) {
    leftHelpButton = (
      <NavControlToolbarButton
        isToolbarExpanded={
          previewMode === 'viewsview' ? true : isToolbarExpanded
        }
        setIsToolbarExpanded={
          previewMode === 'viewsview' ? () => ({}) : setIsToolbarExpanded
        }
        webdeskPosition={webdeskPosition}
        isShortTooltip={isNavControlTooltipShort}
      />
    )
    numberOfHelpButtons += 1
  }

  let rightHelpButton = null
  if (isAddButtonDisplayed) {
    rightHelpButton = (
      <AddNavButton
        webdeskPosition={webdeskPosition}
        isToolbarEnabled={isToolbarEnabled}
        color={theme.colors.original}
        icon={faPlus}
        size={30}
        onClick={() => addNavButton(navButtons)}
        tooltip={
          !isControlToolbarButtonDisplayed && navButtonsToRender.length === 1 // not enough space
            ? 'Add Nav'
            : 'Add Nav Button'
        }
        tooltipRight={webdeskPosition === 'left'}
      />
    )
    numberOfHelpButtons += 1
  } else if (isTranslatorButtonDisplayed) {
    rightHelpButton = <NavTranslateButton webdeskPosition={webdeskPosition} />
    numberOfHelpButtons += 1
  }

  if (webdeskPosition === 'left') {
    const temp = rightHelpButton
    rightHelpButton = leftHelpButton
    leftHelpButton = temp
  }

  // componentDidMount
  React.useEffect(() => {
    if (isToolbarEnabled && !sizes.isMobile) {
      if (!currentViewCardsLength) {
        const width = calculateToolbarWidth(
          navButtons,
          isToolbarExpanded,
          numberOfHelpButtons
        )
        iframeCommunication.changeDimensions({
          width
        })
      }
    }
  }, [])

  React.useEffect(() => {
    if (isToolbarEnabled && !sizes.isMobile) {
      // change width of iframe if expand/collapse button was clicked and no cards in window
      if (prevIsToolbarExpanded.current !== isToolbarExpanded) {
        if (!currentViewCardsLength || !window) {
          const width = calculateToolbarWidth(
            navButtons,
            isToolbarExpanded,
            numberOfHelpButtons
          )
          iframeCommunication.changeDimensions({
            width
          })
        }
        prevIsToolbarExpanded.current = isToolbarExpanded
      }

      // change width of iframe if open/close window
      if (
        (prevCurrentViewCardsLength.current !== currentViewCardsLength &&
          (!prevCurrentViewCardsLength.current || !currentViewCardsLength)) ||
        prevWindow.current !== window
      ) {
        // change state: open <-> close
        const width =
          !currentViewCardsLength || !window
            ? calculateToolbarWidth(
                navButtons,
                isToolbarExpanded,
                numberOfHelpButtons
              )
            : '396px' // Desktop expanded
        iframeCommunication.changeDimensions({
          width
        })
      }

      if (prevCurrentViewCardsLength.current !== currentViewCardsLength) {
        prevCurrentViewCardsLength.current = currentViewCardsLength
      }

      if (prevWindow.current !== window) {
        prevWindow.current = window
      }
    }
  }, [
    isToolbarEnabled,
    isToolbarExpanded,
    window,
    numberOfHelpButtons,
    currentViewCardsLength
  ])

  return (
    <CSSTransition appear in={show} classNames="nav" timeout={500}>
      <Flex
        row
        justifyContent="space-between"
        _width={100}
        _height={100}
        className={className}
      >
        {leftHelpButton}
        <Flex row grow justifyContent="center" alignItems="center">
          {renderNavButtons(numberOfHelpButtons)}
        </Flex>
        {rightHelpButton}
      </Flex>
    </CSSTransition>
  )
}

interface NavHelpInterface {
  webdeskPosition: string
}

// @ts-ignore
const NavControlToolbarButton = styled(ControlToolbarButton)<NavHelpInterface>`
  position: relative;
  align-self: center;
  ${p => {
    return css`
      ${p.webdeskPosition === 'right' &&
        `
        margin-right: 2px;
        margin-left: 8px;
        `}
      ${p.webdeskPosition === 'left' &&
        `
        margin-right: 8px;
        margin-left: 2px;
        `}
    `
  }}
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.colors.original};
  box-shadow: 0px 5px 5px 0 rgba(50, 50, 93, 0.1),
    0px -1px 3px 0 rgba(0, 0, 0, 0.07);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:colorShades(p.theme.colors.original).darker};
  }

  > svg {
    width: 80%;
    height: 80%;
  }
`

const NavTranslateButton = styled(TranslateButton)<NavHelpInterface>`
  align-self: center;
  ${p => {
    return css`
      ${p.webdeskPosition === 'right' &&
        `
        margin-right: 8px;
        margin-left: 2px;
        `}
      ${p.webdeskPosition === 'left' &&
        `
        margin-right: 2px;
        margin-left: 8px;
        `}
    `
  }}
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.colors.original};
  box-shadow: 0px 5px 5px 0 rgba(50, 50, 93, 0.1),
    0px -1px 3px 0 rgba(0, 0, 0, 0.07);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:colorShades(p.theme.colors.original).darker};
  }

  > svg {
    width: 80%;
    height: 80%;
  }
`

interface NavOptionProps {
  top?: string
  right?: string
  bottom?: string
  left?: string
  visible?: boolean
  opacity?: number
  selected?: boolean
}

const NavOption = styled('div')<NavOptionProps>`
  ${p =>
    !p.visible &&
    css`
      visibility: hidden;
    `}
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  ${p =>
    p.opacity &&
    css`
      opacity: ${p.opacity};
    `}
  ${p =>
    p.top &&
    css`
      top: ${p.top};
    `};
  ${p =>
    p.right &&
    css`
      right: ${p.right};
    `};
  ${p =>
    p.bottom &&
    css`
      bottom: ${p.bottom};
    `};
  ${p =>
    p.left &&
    css`
      left: ${p.left};
    `};
  border-radius: 50%;
  background-color: ${p =>
    p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.visible && !p.selected ? 'white' : p.theme.colors.original};
  z-index: 4;
  &:hover {
    background-color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.colors.darker};
  }
  &:active {
    transform: scale(0.9);
  }
`

const StyledDroppable = styled('div')<DroppableStateSnapshot>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  &:hover {
    ${p =>
      p.isDraggingOver &&
      css`
        #navOption {
          visibility: hidden;
        }
      `}
  }
`

const StyledDiv = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  &:hover {
    background-color: ${p => darken(0.03, p.theme.navColors.lighter)};
    & .nav-label {
      color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.navColors.original};
    }
    ${NavOption} {
      visibility: visible;
    }

    #navVisibleOption {
      visibility: hidden;
    }
  }
`

interface AddNavButtonProps {
  isToolbarEnabled?: boolean
}

const AddNavButton = styled(Cirbutt)<
  CirbuttProps & AddNavButtonProps & NavHelpInterface
>`
  align-self: center;
  ${p => {
    return css`
      ${p.webdeskPosition === 'right' &&
        `
        margin-right: 8px;
        margin-left: 2px;
        `}
      ${p.webdeskPosition === 'left' &&
        `
        margin-right: 2px;
        margin-left: 8px;
        `}
    `
  }}
`

export const NavDisplay = withTheme(styled(Component)`
  position: relative;
  display: ${p => (p.show ? 'flex' : 'none')};
  background-color: ${p => p.theme.colors.lightest};
  overflow: hidden;

  &.nav-appear-active {
    opacity: 0;
  }

  &.nav-enter-done {
    opacity: 1;
    transition: opacity 250ms;
  }

  ${p =>
    p.isToolbarEnabled &&
    css`
      background: linear-gradient(
        ${p.theme.colors.original},
        ${p.theme.colors.light}
      );
    `}
`)
