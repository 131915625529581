import * as mixpanel from 'mixpanel-browser'
import { fork, put, select, takeEvery } from 'redux-saga/effects'

import { NAVIGATION } from 'common/constants'
import settings from 'settings'
const { companyId } = settings

import { viewByIdSelector } from 'state/entities/views'
import { Action } from 'typescript-fsa'
import { logAction, trackEvent } from './_actions'
import { rpAnalytics, shiftAnalytics, adobeAnalytics } from './_subgenerators'
import { TrackedEventDestinations, TrackedEventInterface } from 'types'


function* getViewName(viewId: string) {
  if (viewId === NAVIGATION.CONVERSATIONS) {
    return { name: 'Conversations' }
  } else if (viewId === NAVIGATION.BOOKING) {
    return { name: 'Booking' }
  } else if (viewId === NAVIGATION.CHATBOT) {
    return { name: 'Chatbot' }
  } else if (viewId === NAVIGATION.FAQS) {
    return { name: 'FAQ' }
  } else {
    return yield select(viewByIdSelector, viewId)
  }
}

function* saga(action: Action<TrackedEventInterface>) {
  const { trackingName, properties = {}, destinations } = action.payload
  properties.companyId = companyId

  if (trackingName === 'VIEW') {
    properties.viewName = (yield getViewName(properties.viewId)).name
  }

  try {
    if (destinations.includes(TrackedEventDestinations.MIXPANEL)) 
      mixpanel.track(`ep/${trackingName}`, properties)
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }

  try {
    if (destinations.includes(TrackedEventDestinations.RP)) 
      yield fork(rpAnalytics, trackingName, {
        companyId: settings.companyId,
        webdeskId: settings.webdeskId
      })
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }

  try {
    if (destinations.includes(TrackedEventDestinations.SHIFT)) {
      if (settings.shiftRetailerId) 
        properties.dealerCode = settings.shiftRetailerId
      yield fork(shiftAnalytics, trackingName, properties)
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }

  try {
    if (destinations.includes(TrackedEventDestinations.ADOBE)) 
      yield fork(adobeAnalytics, trackingName, properties)
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
  
}

export function* trackEventSaga() {
  mixpanel.init('e52581ce0165103fd31d0b52e6cf51e1', {
    persistence: 'localStorage'
  })
  mixpanel.identify()
  yield takeEvery(trackEvent, saga)
}