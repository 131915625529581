import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { currentViewSelector } from 'state/router'
import {
  addNavButton,
  editHomeNav,
  homeViewIdSelector,
  isToolbarEnabledSelector,
  navButtonsSelector,
  navSelector,
  rpPreviewModeSelector,
  updateNavButtons,
  windowSelector,
  isTranslatorDisabledSelector,
  webdeskPositionSelector
} from 'state/ui'
import { NavButtonInterface, RootState } from 'types'
import { NavDisplay, Props } from './Nav.display'

type StateProps = Pick<
  Props,
  | 'show'
  | 'currentView'
  | 'homeViewId'
  | 'navButtons'
  | 'previewMode'
  | 'window'
  | 'isToolbarEnabled'
  | 'isTranslatorDisabled'
  | 'webdeskPosition'
>
const mapStateToProps = (state: RootState): StateProps => {
  return {
    show: navSelector(state),
    currentView: currentViewSelector(state),
    homeViewId: homeViewIdSelector(state),
    navButtons: navButtonsSelector(state),
    previewMode: rpPreviewModeSelector(state),
    window: windowSelector(state),
    isToolbarEnabled: isToolbarEnabledSelector(state),
    isTranslatorDisabled: isTranslatorDisabledSelector(state),
    webdeskPosition: webdeskPositionSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateNavButtons: (newNavButtons: NavButtonInterface[]) => {
    dispatch(updateNavButtons({ newNavButtons }))
  },
  editNavButton: (index: number) => {
    dispatch(addNavButton({ index }))
  },
  addNavButton: (navButtons: NavButtonInterface[]) => {
    const index = navButtons.length
    dispatch(addNavButton({ index }))
  },
  editHome: () => {
    dispatch(editHomeNav({}))
  }
})

export const NavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavDisplay)
