import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { cardIndexSelector, setCardIndex } from 'state/entities/cards'

import { RootState } from 'types'
import { HoursCardDisplay, Props } from './HoursCard.display'

type StateProps = Pick<Props, 'index'>

const mapStateToProps = (
  state: RootState,
  ownProps: { _id: string }
): StateProps => ({
  index: cardIndexSelector(state, ownProps._id)
})

type DispatchProps = Pick<Props, 'setIndex'>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { _id: string }
): DispatchProps => ({
  setIndex: index => {
    dispatch(setCardIndex({ _id: ownProps._id, index }))
  }
})

export const HoursCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HoursCardDisplay)
