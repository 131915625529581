import * as React from 'react'

import { lighten } from 'polished'
import { helpers } from 'style/mixins'
import styled, { css, withTheme } from 'styledComponents'
import {
  HelpersProps,
  InjectedFieldProps,
  InputMask,
  ThemeInterface
} from 'types'

import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Flex } from 'components/_utility'
import MaskedInputComponent from 'react-text-mask'
import { sizes } from 'style/variables'

export interface Props extends InjectedFieldProps, HelpersProps {
  className?: string
  theme: ThemeInterface
  name: string
  placeholder?: string
  search?: boolean
  mask?: InputMask
  disabled?: boolean
}

const Component = ({
  className,
  theme,
  name,
  search,
  value,
  status,
  updateField,
  mask,
  disabled
}: Props) => {
  const onChange = (e: React.SyntheticEvent) => {
    updateField({
      [name]: {
        value: (e.target as HTMLInputElement).value
      }
    })
  }

  const onBlur = () => {
    updateField({
      [name]: {
        dirty: true
      }
    })
  }

  if (search) {
    status = undefined
  }

  return (
    <Flex className={className} row alignItems="center" relative>
      <StyledMask
        name={name}
        status={status}
        mask={mask}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={value as string}
        disabled={disabled}
      />
      {status === 'invalid' && <Icon icon={faTimes} color={theme.red} />}
      {status === 'valid' && <Icon icon={faCheck} color={theme.green} />}

      {search && (
        <Icon
          icon={faSearch}
          color={
            value && (value as string).length
              ? theme.colors.light
              : theme.colors.lighter
          }
        />
      )}
    </Flex>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledMask = styled(({ status, ...rest }) => (
  <MaskedInputComponent {...rest} />
))<{
  status?: string
  mask?: InputMask
  onChange: (e: React.SyntheticEvent<Element>) => void
  onBlur: (e: React.SyntheticEvent<Element>) => void
  defaultValue: string | string[] | undefined
}>`
  ${helpers}
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.25s;

  ${(p: any) => {
    return css`
      background-color: ${p.theme.colors.lightest}
      color: ${p.theme.gray.dark}
      border: solid 2px ${p.theme.colors.lighter}
      ${p.status === 'invalid' && `border-color: ${lighten(0.25, p.theme.red)}`}
      ${p.status === 'valid' && `border-color: ${lighten(0.25, p.theme.green)}`}
    `
  }}
  &:focus {
    outline: none;
    ${p => !p.status && `border-color: ${p.theme.colors.light};`}
  }
  &::placeholder {
    color: ${(p: any) => p.theme.gray.lighter};
  }
`

const StyledComponent = styled(Component)`
  ${sizes.isMobile
    ? `
    transform: scale(1, 0.875);
    transform-origin: left top;
    overflow: hidden;
  `
    : ''}

  input {
    width: 100%;
    ${sizes.isMobile ? 'font-size: 16px;' : ''}
    ${p =>
      p.search || p.status === 'valid' || p.status === 'invalid'
        ? `padding-right: 33px;`
        : ``};
  }

  svg {
    position: absolute;
    right: 12px;
    transition: all 0.25s;
  }
`

export const MaskedInput = withTheme(StyledComponent)
