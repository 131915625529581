import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { getReviewsAction, initializeReviews } from 'state/_sagas/_actions'
import { cardIndexSelector, updateCard } from 'state/entities/cards'
import { RootState } from 'types'
import { Props, ReviewsCardDisplay } from './ReviewsCard.display'

type StateProps = Pick<Props, 'index'>

const mapStateToProps = (
  state: RootState,
  ownProps: { _id: string }
): StateProps => ({
  index: cardIndexSelector(state, ownProps._id)
})

type DispatchProps = Pick<Props, 'setPage' | 'update' | 'init'>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { _id: string }
): DispatchProps => ({
  setPage: (page: number) =>
    dispatch(getReviewsAction({ index: page, cardId: ownProps._id })),
  update: (data: any) =>
    dispatch(updateCard({ _id: ownProps._id, data: { props: data } })),
  init: () => dispatch(initializeReviews({ cardId: ownProps._id }))
})

export const ReviewsCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewsCardDisplay)
