import { VideoStatus } from 'types'
import { ConnectedComponent } from 'react-redux'

import { RingingContainer } from './Ringing.container'
import { CommonMessageContainer } from './CommonMessage.container'

export const videoMessageMap: {
  [x: string]: ConnectedComponent<any, any>
} = {
  [VideoStatus.calling]: RingingContainer,
  [VideoStatus.declined]: CommonMessageContainer,
  [VideoStatus.active]: CommonMessageContainer,
  [VideoStatus.ended]: CommonMessageContainer
}
