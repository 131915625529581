import * as React from 'react'
import styled from 'styledComponents'

import { Flex, Text } from 'components/_utility'

import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { ProductInterface, ThemeInterface } from 'types'

export interface Props {
  className?: string
  theme?: ThemeInterface
  product: ProductInterface
}

const Component = ({ className, product }: Props) => {
  const [imageIndex, setImageIndex] = React.useState(0)

  // In order not to load all the images for the vehicle at once,
  // we remember the index of the last image and then cut the array with images at this index
  const [lastImageIndex, setLastImageIndex] = React.useState(0)
  React.useEffect(() => {
    if (imageIndex > lastImageIndex) {
      setLastImageIndex(imageIndex)
    }
  }, [imageIndex])

  return (
    <div className={className} data-test="carousel-product">
      <ImagesContainer index={imageIndex}>
        {product.images.slice(0, lastImageIndex + 1).map((image, i) => (
          <Flex key={i} _width={100} shrink={false}>
            <Image url={image} />
          </Flex>
        ))}
      </ImagesContainer>
      <ImageControls row justifyContent="space-between">
        <Flex
          column
          justifyContent="center"
          alignItems="center"
          onClick={() => setImageIndex(imageIndex - 1)}
          hidden={imageIndex === 0}
        >
          <Icon icon={faChevronLeft} size="3x" />
        </Flex>
        <Flex
          column
          justifyContent="center"
          alignItems="center"
          onClick={() => setImageIndex(imageIndex + 1)}
          hidden={imageIndex === product.images.length - 1}
        >
          <Icon icon={faChevronRight} size="3x" />
        </Flex>
      </ImageControls>
      <InfoContainer column padding="standard">
        <Text isTitle mb={product.subtitle ? 0 : 8}>
          {product.title}
        </Text>
        {product.subtitle && (
          <Text isSubtitle mb={8}>
            {product.subtitle}
          </Text>
        )}
        {product.attributes.map((attribute, i) => {
          return (
            <Flex
              row
              justifyContent={attribute.label ? 'space-between' : 'flex-end'}
              key={i}
              data-test="product-attribute"
            >
              {attribute.label && (
                <Text isBody truncated>
                  {attribute.label}:
                </Text>
              )}
              <Text isBody truncated italic align="right">
                {attribute.value}
              </Text>
            </Flex>
          )
        })}
      </InfoContainer>
    </div>
  )
}

export const CarouselProduct = styled(Component)`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
`

const Image = styled('div')<{ url: string }>`
  height: 200px;
  width: 100%;
  background-image: url(${p => p.url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const InfoContainer = styled(Flex)`
  height: calc(100% - 200px);
  justify-content: space-between;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ImagesContainer = styled(({ index, ...rest }) => <Flex {...rest} />)`
  position: relative;
  left: ${p => p.index && p.index * -100}%;

  transition: left 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`

const ImageControls = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 200px;

  > ${Flex} {
    cursor: pointer;
    width: 45px;
    height: 100%;
    user-select: none;

    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    /* background-color: rgba(255, 255, 255, 0.08); */

    transition: background-color 0.25s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`
