import * as moment from 'moment-timezone'
import { path } from 'ramda'
import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styledComponents'

import { Card, Flex, Spinner } from 'components/_utility'
import rpApiService from 'services/rp.api'
import {
  logAction,
  showToast,
  updateAppointment as updateAppointmentAction
} from 'state/_sagas/_actions'
import { companySettingsSelector } from 'state/company'
import {
  cardIndexSelector,
  setCardIndex,
  updateCard
} from 'state/entities/cards'
import { runConversionCode, setFormSubmitted, updateForm } from 'state/forms'
import {
  appointmentSummarySelector,
  leadInfoSelector,
  leadSelector,
  updateLead
} from 'state/lead'
import { currentViewParamsSelector } from 'state/router'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  AppointmentInterface,
  BookingField,
  CardHeaderProps,
  CardInterface,
  DatetimeInterface,
  FieldOptionInterface,
  FormInterface,
  HelpersProps,
  LeadState,
  RootState,
  Service
} from 'types'
import { getDatetimeOptions } from 'utility'
import { Step1, Step2, Step3 } from '.'

export interface Props extends HelpersProps {
  className?: string
  _id: string
  fields?: string[]
  collapsed?: boolean
  header: CardHeaderProps
  index: number
  actionButtons: ActionButtonActionInterface[]
  bookingFields: BookingField[]
  servicesList: Service[]
  typeOptions: FieldOptionInterface[]
  datetimeOptions: DatetimeInterface[]
  datePickerIndex: number
  selectedDate?: string
  selectedTime?: string
  timezoneId: string
  selectedServiceId: string
  appointmentSummary?: AppointmentInterface
  leadInfo?: Pick<
    LeadState,
    'firstName' | 'lastName' | 'email' | 'phone' | 'extraFields' | 'customData'
  >
  lead: LeadState
  defaultService: Service
  showAll: boolean

  setIndex: (index: number) => void
  showToast: (toast: {
    variant: string
    message: string
    duration?: number
  }) => void
  updateThisCard: (data: Partial<CardInterface>) => void
  updateAppointment: (appointment: AppointmentInterface) => void
  updateForm: (formId: string, data: FormInterface) => void
  updateLead: (data: Partial<LeadState>) => void
  runConversionCode: (conversionCode: string) => void
  setFormSubmitted: (formId: string, submitted: boolean) => void
  logException: (x: { error: Error }) => void
}

type StateProps = Pick<
  Props,
  | 'index'
  | 'appointmentSummary'
  | 'showAll'
  | 'defaultService'
  | 'leadInfo'
  | 'timezoneId'
  | 'bookingFields'
  | 'lead'
>
const mapStateToProps = (
  state: RootState,
  ownProps: { _id: string }
): StateProps => {
  const { service: defaultService, showAll } = currentViewParamsSelector(state)
  const { timezoneId, bookingFields } = companySettingsSelector(state)
  return {
    index: cardIndexSelector(state, ownProps._id),
    appointmentSummary: appointmentSummarySelector(state),
    leadInfo: leadInfoSelector(state),
    lead: leadSelector(state),
    timezoneId,
    bookingFields,
    showAll,
    defaultService
  }
}

const LAST_INDEX = 2

type DispatchProps = Pick<
  Props,
  | 'setIndex'
  | 'showToast'
  | 'updateThisCard'
  | 'updateAppointment'
  | 'updateForm'
  | 'updateLead'
  | 'runConversionCode'
  | 'setFormSubmitted'
  | 'logException'
>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { _id: string }
): DispatchProps => ({
  setIndex: index => {
    dispatch(setCardIndex({ _id: ownProps._id, index }))
  },
  showToast: toast => {
    dispatch(showToast(toast))
  },
  updateThisCard: (data: Partial<CardInterface>) => {
    dispatch(updateCard({ _id: ownProps._id, data }))
  },
  updateAppointment: (appointment: Partial<AppointmentInterface>) => {
    dispatch(updateAppointmentAction({ appointment, cardId: ownProps._id }))
  },
  updateForm: (formId: string, data: FormInterface) => {
    dispatch(updateForm({ formId, data }))
  },
  updateLead: (data: Partial<LeadState>) => {
    dispatch(updateLead(data))
  },
  runConversionCode: (conversionCode: string) => {
    dispatch(runConversionCode({ conversionCode }))
  },
  setFormSubmitted: (formId: string, submitted: boolean) => {
    dispatch(setFormSubmitted({ formId, submitted }))
  },
  logException: ({ error }) => {
    dispatch(logAction({ error }))
  }
})

class Component extends React.Component<Props> {
  public createAppointment = () => {
    if (this.props.appointmentSummary && this.props.leadInfo) {
      this.props.setFormSubmitted('appointment_2', true)
      const currentService = this.props.servicesList.find(
        (service: Service) =>
          service._id === this.props.appointmentSummary!.type.value
      )
      const appointment = {
        clientEmail: this.props.leadInfo.email,
        emailConfirm: this.props.leadInfo.email,
        firstName: this.props.leadInfo.firstName,
        lastName: this.props.leadInfo.lastName,
        clientPhone: this.props.leadInfo.phone,
        startTime: this.props.appointmentSummary.time,
        startDate: this.props.appointmentSummary.date,
        serviceId: this.props.appointmentSummary.type.value,
        inquiryTypeId: currentService!.inquiryTypeId,
        customData: this.props.leadInfo.customData
      }
      this.props.leadInfo.extraFields!.forEach(extraField => {
        appointment[extraField._id] = extraField.value
      })
      rpApiService
        .createAppointment(appointment)
        .then(() => {
          this.props.runConversionCode(currentService!.conversionCode)
          this.props.setIndex(this.props.index + 1)
        })
        .catch((e: Error) => {
          this.props.setFormSubmitted('appointment_2', false)
          this.props.logException({ error: e })
          const errorMessages = JSON.parse(e.message).errors[0]
          const errorType = Object.keys(errorMessages)[0]
          this.props.showToast({
            duration: 5000,
            variant: 'error',
            message: errorMessages[errorType]
          })
          this.props.updateThisCard({
            props: { errorsMessage: errorMessages[errorType] }
          })
        })
    }
  }

  public componentDidMount() {
    const defaultServiceId: string =
      path(['defaultService', '_id'], this.props) || ''
    let servicesPromise: Promise<Service[]>
    if (this.props.showAll) {
      servicesPromise = rpApiService.getServicesList()
    } else {
      servicesPromise = rpApiService
        .getServiceById(defaultServiceId)
        .then(s => [s])
    }
    servicesPromise
      .then(services => {
        const defaultService =
          services.find(s => s._id === defaultServiceId) || services[0]
        return Promise.all([
          defaultService,
          services,
          rpApiService.getAvailability(defaultService._id, this.props.lead)
        ])
      })
      .then(([defaultService, services, availability]) => {
        if (availability.activeAppointment) {
          this.props.setIndex(LAST_INDEX)
          const lead = {}
          const appointmentDate = moment
            .utc(availability.activeAppointment.date)
            .tz(this.props.timezoneId)
            .format('YYYY-MM-DD')
          const appointmentTime = moment
            .utc(availability.activeAppointment.date)
            .tz(this.props.timezoneId)
            .format('H:mm')
          lead['appointment.type'] = availability.activeAppointment.type
          lead['appointment.date'] = appointmentDate
          lead['appointment.time'] = appointmentTime
          this.props.updateLead(lead)
          return
        }
        const servicesList = Array.isArray(services) ? services : [services]
        this.props.updateThisCard({
          props: {
            servicesList,
            datetimeOptions: getDatetimeOptions(availability.times),
            typeOptions: servicesList.map((service: Service) => ({
              label: service.name,
              value: service._id
            }))
          }
        })
        const updatedLead = {}
        updatedLead['appointment.type'] = {
          value: defaultService._id,
          label: defaultService.name
        }
        const updatedFormStep1 = {} as FormInterface
        updatedFormStep1['appointment.type'] = {
          ...updatedLead['appointment.type'],
          status: 'valid',
          required: true
        }

        const updatedFormStep2 = {} as FormInterface
        const yesNoBookingFields = this.props.bookingFields.filter(
          bookingField => bookingField.type === 'yesNo'
        )
        yesNoBookingFields.forEach(yesNoBookingField => {
          updatedLead[
            `appointment.fields.${yesNoBookingField._id}`
          ] = yesNoBookingField.consent
            ? { label: 'No', value: 'No' }
            : { label: 'Yes', value: 'Yes' }
          updatedFormStep2[`appointment.fields.${yesNoBookingField._id}`] = {
            value: updatedLead[`appointment.fields.${yesNoBookingField._id}`],
            status: yesNoBookingField.consent ? 'invalid' : 'valid',
            required: yesNoBookingField.consent
          }
        })
        this.props.updateLead(updatedLead)
        this.props.updateForm('appointment_1', updatedFormStep1)
        this.props.updateForm('appointment_2', updatedFormStep1)
      })
  }

  public render() {
    const {
      className,
      collapsed = false,
      header,
      typeOptions,
      datetimeOptions,
      datePickerIndex,
      selectedDate,
      selectedTime,
      bookingFields,
      timezoneId,
      appointmentSummary,
      index,
      setIndex,
      updateThisCard,
      updateAppointment
    }: Props = this.props

    if (!typeOptions && index < LAST_INDEX) {
      return (
        <Card className={className}>
          <Flex justifyContent="center" alignItems="center">
            <Spinner size="2x" />
          </Flex>
        </Card>
      )
    }

    const cards = [
      <Step1
        key={0}
        typeOptions={typeOptions}
        datetimeOptions={datetimeOptions}
        datePickerIndex={datePickerIndex}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        timezoneId={timezoneId}
        updateThisCard={updateThisCard}
        updateAppointment={updateAppointment}
        onNextClick={() => setIndex(index + 1)}
      />,
      <Step2
        key={1}
        createAppointment={this.createAppointment}
        appointmentSummary={appointmentSummary}
        bookingFields={bookingFields}
      />,
      <Step3
        key={2}
        appointmentSummary={appointmentSummary}
        timezoneId={timezoneId}
      />
    ]
    const CurrentCard = cards[index]

    const currentHeader = {
      status: `${index + 1}/${cards.length}`,
      ...header,
      onPrevious:
        index > 0 && index < cards.length - 1
          ? () => setIndex(index - 1)
          : undefined
    }
    return (
      <Card
        className={className}
        header={currentHeader}
        collapsed={collapsed}
        padding="none"
      >
        {CurrentCard}
      </Card>
    )
  }
}

const AppointmentCardDisplay = styled(Component)`
  ${helpers}

  ${Card} {
    background: none;
    box-shadow: none;
    border-radius: none;
  }
`

export const AppointmentCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentCardDisplay)
