import { pathOr } from 'ramda'

import { InventoryElement, ProductInterface } from 'types'

export const mapInventoryElement = (
  inventoryElement: InventoryElement
): ProductInterface => {
  const make = pathOr('', ['make', 'value'], inventoryElement)
  const model = pathOr('', ['model', 'value'], inventoryElement)
  const year = pathOr('', ['year', 'value'], inventoryElement)
  let title = `${make} ${model}`
  if (year) {
    title = `${year} ${title}`
  }
  const priceLabel = pathOr('', ['price', 'label'], inventoryElement)
  const priceValue = pathOr('', ['price', 'value'], inventoryElement)
  let subtitle
  if (priceValue) {
    if (priceLabel) {
      subtitle = `${priceLabel}: ${priceValue}`
    } else {
      subtitle = priceValue
    }
  }
  const attributes: Array<{ label: string; value: string }> = []
  Object.keys(inventoryElement)
    .filter(
      key =>
        inventoryElement[key].value &&
        inventoryElement[key].displayIndex !== undefined
    )
    .sort(
      (aKey, bKey) =>
        inventoryElement[aKey].displayIndex -
        inventoryElement[bKey].displayIndex
    )
    .forEach(key => {
      attributes.push({
        label: inventoryElement[key].label,
        value: inventoryElement[key].value
      })
    })

  return {
    title,
    subtitle,
    images: inventoryElement.images || [],
    attributes,
    original: inventoryElement
  }
}
