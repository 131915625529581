import * as React from 'react'
import styled from 'styledComponents'

import { Button, Card, Flex, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  Day,
  HelpersProps
} from 'types'

export interface HoursTime {
  startTime: string
  endTime: string
}

export interface Props extends HelpersProps {
  className?: string
  _id: string
  url: string
  collapsed?: boolean
  header?: CardHeaderProps
  hours: Array<{ label: string; data: { [day: string]: HoursTime } }>
  actionButtons?: ActionButtonActionInterface[]
  index: number
  setIndex: (index: number) => void
}

const Component = ({
  className,
  collapsed = false,
  header,
  hours,
  actionButtons,
  index,
  setIndex
}: Props) => {
  let hoursTypes = [] as string[]
  let hoursByDay = {} as { [index: string]: HoursTime }
  if (hours && hours.length !== 0) {
    hoursTypes = hours.map(h => h.label)
    hoursByDay = hours[index].data || {}
  }

  return (
    <Card
      className={className}
      header={header}
      collapsed={collapsed}
      actionButtons={actionButtons}
      actionButtonsDisplay={{ padding: 'top' }}
      customAttributes={{ 'data-test': 'hours-card' }}
    >
      <Flex row>
        <Flex column _width={40} pr={14}>
          {hoursTypes.map((hoursType, i) => (
            <Button
              customAttributes={{ title: hoursType }}
              _width={100}
              outline={index === i}
              textonly={index !== i}
              key={i}
              capitalize
              onClick={() => setIndex(i)}
            >
              {hoursType}
            </Button>
          ))}
        </Flex>
        <Flex column grow>
          <ul>
            {Object.values(Day).map((day, i) => {
              const dayHours = hoursByDay[day]
                ? `${hoursByDay[day].startTime} - ${hoursByDay[day].endTime}`
                : 'Closed'
              return (
                <li key={i}>
                  <Text _width={15} inline isTitle capitalize>
                    {day}
                  </Text>
                  <Text inline isBody>
                    {dayHours}
                  </Text>
                </li>
              )
            })}
          </ul>
        </Flex>
      </Flex>
    </Card>
  )
}

export const HoursCardDisplay = styled(Component)`
  ${helpers}

  ${Button} {
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  li {
    margin-bottom: 0.5px;
  }
`
