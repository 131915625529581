import { CheckboxInterface, FieldOptionInterface } from 'types'

export interface ContactFormFieldInterface {
  type: ContactFormFieldType
  name: string
  label: string
  required?: boolean
  consent?: boolean
  phoneMaskDisabled?: boolean
  options?: FieldOptionInterface[] | CheckboxInterface[]
  maxCharacters?: number
  defaultValue?: { _id: string; text: string }
  defaultOption?: { value: string; label: string }
  isIPMatchingAvailable: boolean
  isRoutingAvailable: boolean
}

export interface VehicleDropdownParamsInterface {
  [x: string]: { required: boolean }
}

export enum ContactFormFieldType {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  VEHICLE_MAKE = 'vehicleMake',
  VEHICLE_MODEL = 'vehicleModel',
  VEHICLE_YEAR = 'vehicleYear',
  VEHICLE_MAKE_DROPDOWN = 'vehicleMakeDropdown',
  VEHICLE_MODEL_DROPDOWN = 'vehicleModelDropdown',
  VEHICLE_YEAR_DROPDOWN = 'vehicleYearDropdown',
  VEHICLE_OPEN_SEARCH = 'vehicleOpenSearch',
  CUSTOM_SINGLE_LINE_INPUT = 'customSingleLineInput',
  YES_NO_QUESTION = 'yesNoQuestion',
  DROPDOWN = 'dropdown',
  INQUIRY_TYPE_DROPDOWN = 'inquiry_type_dropdown',
  RADIO = 'radio',
  CHECKBOXES = 'checkboxes',
  CUSTOM_MULTI_LINE_INPUT = 'customMultiLineInput',
  ADDRESS = 'address',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zipCode'
}
