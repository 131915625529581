import { css } from 'styledComponents'

export const shadow = (variant: string) => {
  return {
    light: css`
      box-shadow: 0 0 7px 0 rgba(50, 50, 93, 0.1), 0 0 3px 0 rgba(0, 0, 0, 0.07);
    `,
    medium: css`
      box-shadow: 0 1px 7px 0 rgba(50, 50, 93, 0.1),
        0 0 3px 0 rgba(0, 0, 0, 0.07);
    `,
    mediumHover: css`
      box-shadow: 0px 5px 5px 0 rgba(50, 50, 93, 0.1),
        0px -1px 3px 0 rgba(0, 0, 0, 0.07);
    `,

    lightSpread: css`
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
    `,

    spread: css`
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.16);
    `,

    color: (color: string) => {
      return css`
        box-shadow: 0 0 18px ${color};
      `
    }
  }[variant]
}
