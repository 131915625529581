import { delay } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { setToast } from 'state/ui'
import { ToastInterface } from 'types'
import { logAction, showToast } from './_actions'

function* saga(action: Action<ToastInterface>) {
  const { variant, message, duration = 2500 } = action.payload
  try {
    yield put(setToast({ show: true, variant, message }))
    yield call(delay, duration)
    yield put(setToast({ show: false }))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* showToastSaga() {
  yield takeEvery(showToast, saga)
}
