import * as React from 'react'
import styled from 'styledComponents'

import { helpers } from 'style/mixins/'
import { HelpersProps } from 'types'

export interface Props extends HelpersProps {
  className?: string
  _id?: string
  onClick?: () => void
  children?: React.ReactNode
}

const Component = React.forwardRef(
  (
    { className, _id, onClick, children }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div ref={ref} className={className} id={_id} onClick={onClick}>
      {children}
    </div>
  )
)

Component.displayName = 'Container'

export const Container = styled(Component)`
  ${helpers}
`
