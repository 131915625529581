import actionCreatorFactory from 'typescript-fsa'

import {
  LauncherInterface,
  NavButtonInterface,
  ToastInterface,
  ToolbarModeInterface
} from 'types'
import { UIState } from './ui.reducer'

const actionCreator = actionCreatorFactory('ui')

export const setNavState = actionCreator<{ open: boolean }>('SET_NAVSTATE')
export const setLauncherState = actionCreator<Partial<LauncherInterface>>(
  'SET_LAUNCHERSTATE'
)
export const setWindowState = actionCreator<{ open: boolean }>(
  'SET_WINDOWSTATE'
)
export const setToast = actionCreator<Partial<ToastInterface>>('SET_TOAST')
export const setToolbarModeState = actionCreator<Partial<ToolbarModeInterface>>(
  'SET_TOOLBAR_MODE_STATE'
)
export const closeLauncher = actionCreator('CLOSE_LAUNCHER')
export const navigateView = actionCreator<{ viewId: string }>('NAVIGATE_VIEW')

export const playSound = actionCreator('PLAY_SOUND')
export const stopSound = actionCreator('STOP_SOUND')

export const setTitle = actionCreator<{ value: string }>('SET_TITLE')
export const startAnimateTitle = actionCreator<{ authorId?: string }>(
  'ANIMATE_TITLE_START'
)
export const stopAnimateTitle = actionCreator('ANIMATE_TITLE_STOP')
export const setTitleAnimation = actionCreator<{ isAnimated: boolean }>(
  'SET_ANIMATION_TITLE'
)

export const initTriggers = actionCreator('INIT_TRIGGERS')
export const disableTriggers = actionCreator('DISABLE_TRIGGERS')
export const chatBoxExpandOnTimeout = actionCreator('EXPAND_ON_TIMEOUT')
export const chatBoxExpandOnExit = actionCreator('EXPAND_ON_EXIT')
export const checkExpandOnExit = actionCreator('CHECK_EXPAND_ON_EXIT')
export const checkExpandOnHover = actionCreator('CHECK_EXPAND_ON_HOVER')
export const setExitTrigger = actionCreator<{ isAllowed: boolean }>(
  'SET_EXIT_TRIGGER'
)
export const hideLauncherOnMouseLeave = actionCreator('HIDE_ON_MOUSE_LEAVE')

export const rehydrateUIState = actionCreator<{ state: UIState }>('REHYDRATE')

// ************************ PREVIEW MODE ACTIONS ****************************************************
export const setWipViewId = actionCreator<{ wipViewId: string }>(
  'SET_WIP_VIEW_ID'
)
export const finishedInitializingPreview = actionCreator<{
  previewMode: string
}>('FINSHED_INITIALIZING_PREVIEW')
export const updatedWipCard = actionCreator<{}>('UPDATED_WIP_CARD')
export const addNavButton = actionCreator<{ index: number }>('ADD_NAV_BUTTON')
export const editHomeNav = actionCreator<{}>('EDIT_HOME_NAV')
export const updateNavButtons = actionCreator<{
  newNavButtons: NavButtonInterface[]
}>('UPDATE_NAV_BUTTONS')
export const updateWebdeskInfo = actionCreator<{ webdesk: any }>(
  'UPDATE_WEBDESK_INFO'
)
export const navigateDashboardView = actionCreator<{ viewId: string }>(
  'NAVIGATE_DASHBOARD_VIEW'
)
export const toggleHomeDeskLauncher = actionCreator<{ isHome: boolean }>(
  'TOGGLE_HOME_DESK_LAUNCHER'
)
