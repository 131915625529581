import * as React from 'react'
import styled from 'styledComponents'

import { Button } from 'components/_utility'

export interface Props {
  className?: string
  fullWidth?: boolean
  children: React.ReactNode
}

const Component = ({ className, children }: Props) => {
  return <div className={className}>{children}</div>
}

export interface StyledProps extends Props {
  padding?: string
}

export const ButtonGroup = styled(Component)<StyledProps>`
  width: 100%;
  text-align: right;
  padding: 6px 0 0;
  ${p => p.padding === 'full' && `padding: 0 24px 18px;`}
  ${p => p.padding === 'top' && `padding: 18px 0 0;`}
  ${p => p.padding === 'full-top' && `padding: 18px 24px 18px;`}
  ${p => p.padding === 'none' && `padding: 0;`}
  ${p => p.padding === 'card-top' && `padding: 3px 0 0;`}

  & > a {
    display: block;
    ${p => p.fullWidth && `margin-top: 6px;`};
    ${p => !p.fullWidth && `margin: 4px 3px;`};

    &:first-child {
      margin-top: 0;
    }
  }

  ${Button} {
    max-width: 100%;

    ${p => p.fullWidth && `margin-top: 6px;`};
    ${p => !p.fullWidth && `margin: 4px 3px;`};

    &:first-child {
      margin-top: 0;
    }
  }
`
