import * as React from 'react'
import {
  RemoteAudioPlayer,
  Disconnected,
  Connecting,
  Connected,
  Room
} from '@andyet/simplewebrtc'
import styled from 'styledComponents'

import { Flex, Text } from 'components/_utility'
import { shadow } from 'style/mixins/'
import { sizes } from 'style/variables'

import { PeerGrid, DevicesSettings, SelfVideo, Controls } from './components'
import { HiddenPeers } from './contexts'

export interface Props {
  className?: string
  mobileShrink?: boolean
  expandedVideoMode: boolean
  webdeskPosition: string
  isWindowOpen: boolean
  roomName: string
  displayName: string
  leaveCall: () => void
  setDisplayName: (name: string) => void
  toggleMobileShrink: () => void
}

interface State {
  activeSpeakerView: boolean
  hiddenPeers: string[]
  isSettingsOpen: boolean
}

// @ts-ignore
const LoadingState = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const ConnectedContainer = styled(Flex)`
  background-color: ${p => p.theme.gray.lightest};
`

class VideoRoom extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      activeSpeakerView: false,
      hiddenPeers: [],
      isSettingsOpen: true
    }
  }

  public componentDidMount() {
    this.props.setDisplayName(this.props.displayName)
    window.addEventListener('beforeunload', this.props.leaveCall, false)
  }

  public componentWillUnmount() {
    setTimeout(
      () =>
        window.removeEventListener('beforeunload', this.props.leaveCall, false),
      1000
    )
  }

  private changeIsSettingsOpen = () => {
    this.setState({ isSettingsOpen: !this.state.isSettingsOpen })
  }

  private togglePeer = (peerId: string) => {
    if (this.state.hiddenPeers.includes(peerId)) {
      const hiddenPeers = [...this.state.hiddenPeers]
      const index = hiddenPeers.indexOf(peerId)
      hiddenPeers.splice(index)
      this.setState({ hiddenPeers })
    } else {
      this.setState({ hiddenPeers: [...this.state.hiddenPeers, peerId] })
    }
  }

  public render() {
    const {
      className,
      roomName,
      expandedVideoMode,
      isWindowOpen,
      mobileShrink,
      toggleMobileShrink
    } = this.props

    if (!expandedVideoMode && !isWindowOpen) {
      return null
    }

    const flexHeight = expandedVideoMode
      ? undefined
      : mobileShrink
      ? 25
      : this.state.isSettingsOpen
      ? 75
      : 60

    return (
      <Flex
        onClick={mobileShrink ? toggleMobileShrink : undefined}
        className={className}
        mt={10}
        _height={flexHeight}
      >
        {/* <AutoCapture /> */}
        <RemoteAudioPlayer />
        {expandedVideoMode && !this.state.isSettingsOpen && <SelfVideo />}
        <Connecting>
          <LoadingState>
            <Text fs={20} isTitle>
              Connecting...
            </Text>
          </LoadingState>
        </Connecting>

        <Disconnected>
          <LoadingState>
            <Text fs={20} isTitle>
              Lost connection. Reattempting to join...
            </Text>
          </LoadingState>
        </Disconnected>

        <Connected>
          <Room name={roomName!}>
            {({ room }) => {
              if (!room.joined) {
                return (
                  <LoadingState>
                    <Text fs={20} isTitle>
                      Joining room...
                    </Text>
                  </LoadingState>
                )
              }
              return (
                <HiddenPeers.Provider
                  value={{
                    hiddenPeers: this.state.hiddenPeers,
                    togglePeer: this.togglePeer
                  }}
                >
                  <ConnectedContainer column _width={100} _height={100}>
                    {mobileShrink ? null : (
                      <DevicesSettings
                        isSettingsOpen={this.state.isSettingsOpen}
                        changeIsSettingsOpen={this.changeIsSettingsOpen}
                        leaveCall={this.props.leaveCall}
                      />
                    )}

                    {!this.state.isSettingsOpen && (
                      <>
                        <PeerGrid
                          roomAddress={room.address!}
                          activeSpeakerView={this.state.activeSpeakerView}
                        />
                        {mobileShrink ? null : <Controls />}
                      </>
                    )}
                  </ConnectedContainer>
                </HiddenPeers.Provider>
              )
            }}
          </Room>
        </Connected>
      </Flex>
    )
  }
}

export const VideoRoomDisplay = styled(VideoRoom)`
  position: fixed;
  background-color: ${p => p.theme.colors.lightest};
  ${shadow('lightSpread')}

  ${p =>
    p.expandedVideoMode
      ? `
      width: calc(100% - 414px);
      border-radius: 10px;
      ${p.webdeskPosition}: 18px;
      margin-top: 18px;
      margin-bottom: 18px;
      top: 0;
      bottom: 0;
      `
      : `
      ${sizes.isMobile ? 'width: 100%;' : 'width: 330px;'}
      top: ${sizes.isMobile && p.mobileShrink ? 38 : 76}px
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
`}
`
