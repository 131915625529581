import { put, select, takeEvery } from 'redux-saga/effects'
import { updateForm } from 'state/forms'
import { leadSelector } from 'state/lead'

import { FieldOptionInterface } from 'types'
import { getNestedField } from 'utility'
import { logAction, registerField } from './_actions'

function* saga(action: ReturnType<typeof registerField>) {
  const {
    formId,
    fieldName,
    required,
    maxCharacters,
    consent,
    disabled,
    type
  } = action.payload
  try {
    const lead = yield select(leadSelector)
    const field = getNestedField(fieldName, lead)
    const value =
      field && field.hasOwnProperty('value')
        ? (field as FieldOptionInterface).value
        : (field as string | number | string[] | undefined) || ''

    const label =
      field && field.hasOwnProperty('label')
        ? (field as FieldOptionInterface).label
        : undefined

    yield put(
      updateForm({
        formId,
        data: {
          [fieldName]: {
            value,
            required,
            label,
            maxCharacters,
            consent,
            disabled,
            type
          }
        }
      })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* registerFieldSaga() {
  yield takeEvery(registerField, saga)
}
