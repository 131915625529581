import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styledComponents'

import { logAction } from 'state/_sagas/_actions'

interface Props {
  className?: string
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

export class Component extends React.Component<Props & DispatchProps, State> {
  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  constructor(props: Props & DispatchProps) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(error: Error) {
    this.props.logException({ error })
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBlock>Oops! Something went wrong</ErrorBlock>
    }

    return this.props.children
  }
}

const ErrorBlock = styled('h1')<Props>`
  text-align: center;
  color: ${p => p.theme.colors.original};
`

interface DispatchProps {
  logException: (x: { error: Error }) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  logException: ({ error }) => {
    dispatch(logAction({ error }))
  }
})

export const ErrorBoundary = connect(null, mapDispatchToProps)(Component)
