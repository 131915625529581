import { Flex } from 'components/_utility'
import * as React from 'react'
import { shadow } from 'style/mixins'
import styled from 'styledComponents'
import { HelpersProps } from 'types'
import cars from './cars.png'
import ed from './edmunds.png'
import fb from './facebook.png'
import goog from './google.png'

export interface ReviewSourceInterface {
  sourceName: string
  url: string
}

export interface Props extends HelpersProps {
  className?: string
  reviewSources: ReviewSourceInterface[]
}

const getImageForSourceName = (sourceName: string) => {
  switch (sourceName) {
    case 'Google':
      return goog
    case 'Cars':
      return cars
    case 'Edmunds':
      return ed
    case 'Facebook':
      return fb
    default:
      return cars
  }
}

const Component = ({ className, reviewSources }: Props) => {
  return (
    <Flex className={className} row justifyContent="center" _wrap _width={100}>
      {reviewSources.map((reviewSource, i) => {
        if (!reviewSource.url.match(/^https?:\/\//g)) {
          reviewSource.url = `https://${reviewSource.url}`
        }

        return (
          <Flex
            row
            pt={i < 2 ? 18 : 9}
            _width={50}
            pb={9}
            key={reviewSource.sourceName + i}
            justifyContent="center"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={reviewSource.url}
              data-test="review-source-url"
            >
              <CircleImage
                size={81}
                imageSrc={getImageForSourceName(reviewSource.sourceName)}
              />
            </a>
          </Flex>
        )
      })}
    </Flex>
  )
}

const CircleImage = styled.div<{ size: number; imageSrc: string }>`
  flex-shrink: 0;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  margin-bottom: 8px;

  background: url(${p => p.imageSrc});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  ${shadow('light')}

  transition-property: transform;
  transition-duration: 0.2s;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    ${shadow('mediumHover')};
  }
`

export const ReviewSources = styled(Component)``
