import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import _get from 'lodash/get'

import settings from 'settings'
import { initializeDeskSuccess } from 'state/_sagas/_actions'
import { navigate } from 'state/router'
import { colorShades, grayShades } from 'style/variables'
import {
  BehaviorInterface,
  LauncherInterface,
  NavButtonInterface,
  ThemeInterface,
  ToastInterface
} from 'types'
import { LauncherType } from 'types/LauncherType'
import { getDeviceType } from '../../utility'
import {
  playSound,
  setExitTrigger,
  setLauncherState,
  setNavState,
  setTitle,
  setTitleAnimation,
  setToast,
  setWindowState,
  stopSound
} from '.'
import {
  rehydrateUIState,
  setWipViewId,
  toggleHomeDeskLauncher,
  updateNavButtons,
  updateWebdeskInfo
} from './ui.actions'

export interface UIState {
  nav: boolean
  window: boolean
  launcher: LauncherInterface
  homeViewId: string
  toast: ToastInterface
  theme: ThemeInterface
  navButtons: NavButtonInterface[]
  behavior: BehaviorInterface
  soundIsPlaying: boolean
  isExitTriggerAllowed: boolean
  title: {
    value: string
    isAnimated: boolean
  }
  rppreview: string
  wipViewId: string
  appearance: {
    position: string
    horizontalPadding: string
    verticalPadding: string
  }
  rehydrated: boolean
  webMoveUp: number
  mobileMoveUp: number
}

export const DEFAULT_STATE: UIState = {
  nav: false,
  window: false,
  launcher: {
    open: false,
    type: LauncherType.HIDDEN,
    cardIds: [],
    initialCardIds: []
  },
  homeViewId: '',
  toast: {
    show: false,
    variant: 'success',
    message: 'Initial Toast'
  },
  theme: {
    gradient: true,
    colors: colorShades('#2f9dde'),
    navColors: colorShades('#2f9dde'),
    gray: grayShades(),
    green: '#34cb94',
    orange: '#FFB241',
    red: '#EE184E',
    cadillacColors:colorShades('#282828'),
    cadillacBubble:'#d2d2d2',
    cadillacFontColor:'#fcfcfc',
    cadilacBackGroundColor:'#000000',
    isCadillacTheme: false,
  },
  navButtons: [],
  behavior: {
    triggers: {},
    newMessage: {
      sound: true,
      tabFlash: true
    },
    hideForVisitorsPercentage: 0,
    toolbarMode: false,
    disableTranslator: false
  },
  appearance: {
    position: 'right',
    horizontalPadding: '10',
    verticalPadding: '10'
  },
  soundIsPlaying: false,
  isExitTriggerAllowed: false,
  title: {
    value: '',
    isAnimated: false
  },
  rppreview: settings.previewMode,
  wipViewId: 'default',
  rehydrated: false,
  webMoveUp: settings.webMoveUp,
  mobileMoveUp: settings.mobileMoveUp
}

export const ui = (state: UIState = DEFAULT_STATE, action: Action) => {
  if (isType(action, setNavState)) {
    const nav = action.payload.open
      ? action.payload.open
      : state.launcher.type === LauncherType.BAR
    return {
      ...state,
      nav
    }
  }

  if (isType(action, setLauncherState)) {
    return {
      ...state,
      launcher: { ...state.launcher, ...action.payload }
    }
  }

  if (isType(action, setWindowState)) {
    return {
      ...state,
      window: action.payload.open
    }
  }

  if (isType(action, setToast)) {
    return {
      ...state,
      toast: { ...state.toast, ...action.payload }
    }
  }

  if (isType(action, navigate)) {
    return {
      ...state,
      nav: true,
      launcher: { ...state.launcher, open: false },
      window: true
    }
  }

  if (isType(action, playSound)) {
    return {
      ...state,
      soundIsPlaying: true
    }
  }

  if (isType(action, stopSound)) {
    return {
      ...state,
      soundIsPlaying: false
    }
  }

  if (isType(action, setExitTrigger)) {
    return {
      ...state,
      isExitTriggerAllowed: action.payload.isAllowed
    }
  }

  if (isType(action, setTitleAnimation)) {
    return {
      ...state,
      title: {
        ...state.title,
        isAnimated: action.payload.isAnimated
      }
    }
  }

  if (isType(action, setTitle)) {
    return {
      ...state,
      title: {
        ...state.title,
        value: action.payload.value
      }
    }
  }

  if (isType(action, rehydrateUIState)) {
    const hydratedState = action.payload.state
    return {
      ...hydratedState,
      title: state.title,
      rehydrated: true
    }
  }

  if (isType(action, initializeDeskSuccess)) {
    const { webdesk } = action.payload
    const device = getDeviceType()
    let launcherType = webdesk.launcher[device.toLocaleLowerCase()]
    let launcherCardIds = webdesk.launcher.cardIds

    let nav = [LauncherType.HOME_DESK, LauncherType.BAR].includes(launcherType)
    let window = launcherType === LauncherType.HOME_DESK
    let isLauncherOpened = launcherType === LauncherType.CARDS

    if (state.rehydrated) {
      nav = state.nav
      window = state.window
      isLauncherOpened = state.launcher.open
    }

    if (webdesk.behavior.toolbarMode) {
      nav = state.rehydrated ? state.nav : true
      window = state.rehydrated ? state.window : true
      isLauncherOpened = false
      launcherType = undefined
      launcherCardIds = []
    }

    if (settings.bookingMode || settings.inventoryMode) {
      nav = false
      window = true
      isLauncherOpened = false
      launcherType = LauncherType.ICON
    }

    return {
      ...state,
      homeViewId: webdesk.navigation.homeViewId,
      navButtons: webdesk.navigation.navButtons,
      behavior: webdesk.behavior,
      launcher: {
        open: isLauncherOpened,
        type: launcherType,
        cardIds: launcherCardIds || [],
        initialCardIds: webdesk.launcher.cardIds || []
      },
      appearance: {
        position: webdesk.appearance.position,
        horizontalPadding: webdesk.appearance.horizontalPadding,
        verticalPadding: webdesk.appearance.verticalPadding
      },
      theme: {
        ...state.theme,
        gradient: webdesk.isCadillac!=null && webdesk.isCadillac ===true?false:_get(webdesk, 'appearance.gradient', state.theme.gradient),
        colors: webdesk.isCadillac!=null  && webdesk.isCadillac ===true?colorShades(`#000000`):colorShades(`#${webdesk.appearance.color}`),
        navColors: webdesk.isCadillac!=null  && webdesk.isCadillac ===true?colorShades(`#000000`):colorShades(
          `#${webdesk.appearance.navColor || webdesk.appearance.color}`
        ),
        cadillacColors:colorShades('#282828'),
        cadillacBubble:'#d2d2d2',
        cadillacFontColor:'#fcfcfc',
        cadilacBackGroundColor:'#000000',
        isCadillacTheme: webdesk.isCadillac!=null?webdesk.isCadillac:false,
      },
      nav,
      window
    }
  }

  // ********************************* PREVIEW ACTIONS **********************************************

  if (isType(action, setWipViewId)) {
    return {
      ...state,
      wipViewId: action.payload.wipViewId
    }
  }

  if (isType(action, updateWebdeskInfo)) {
    const { webdesk } = action.payload
    const launcherType = webdesk.launcher.desktop.toLocaleLowerCase()

    return {
      ...state,
      homeViewId: webdesk.navigation.homeViewId,
      navButtons: webdesk.navigation.navButtons,
      behavior: webdesk.behavior,
      launcher: {
        ...state.launcher,
        type: launcherType === 'hidden' ? 'icon' : launcherType,
        cardIds: webdesk.launcher.cardIds || []
      },
      theme: {
        ...state.theme,
        gradient: _get(webdesk, 'appearance.gradient', state.theme.gradient),
        colors: colorShades(`#${webdesk.appearance.color}`),
        navColors: colorShades(
          `#${webdesk.appearance.navColor || webdesk.appearance.color}`
        )
      },
      appearance: {
        ...state.appearance,
        position: webdesk.appearance.position
      }
    }
  }

  if (isType(action, toggleHomeDeskLauncher)) {
    const { isHome } = action.payload
    if (isHome) {
      return {
        ...state,
        launcher: {
          ...state.launcher,
          open: false
        },
        window: true,
        nav: true
      }
    }
    return {
      ...state,
      launcher: {
        ...state.launcher,
        open: true
      },
      window: false,
      nav: false
    }
  }

  if (isType(action, updateNavButtons)) {
    return {
      ...state,
      navButtons: action.payload.newNavButtons
    }
  }

  return state
}
