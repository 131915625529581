import * as React from 'react'
import styled from 'styledComponents'
import Pagination from './Pagination'

import { withForm } from 'components/_hoc'
import {
  Button,
  Card,
  ConnectedSelect,
  Flex,
  Label,
  Spinner,
  Text
} from 'components/_utility'
import { REVIEWS_SORT_OPTIONS } from 'common/constants'
import { helpers } from 'style/mixins/'
import {
  CardHeaderProps,
  FieldOptionInterface,
  HelpersProps,
  ReviewInterface
} from 'types'

import { getReviewsAction } from 'state/_sagas/_actions'
import { Review } from './'

export interface Props extends HelpersProps {
  _id: string
  className?: string
  body: string
  header?: CardHeaderProps
  collapsed?: boolean
  index: number
  setPage: (index: number) => void
  reviews: ReviewInterface[]
  total?: number
  sites: FieldOptionInterface[]
  template: any
  update: (data: object) => void
  init: () => void
  expand?: boolean
}

class Reviews extends React.Component<Props> {
  private reviewsCardRef = React.createRef<HTMLDivElement>()

  public componentDidMount() {
    if (this.props.total === undefined) {
      this.props.init()
    }
  }

  public setPage = (page: number) => {
    const viewWrapper = document.getElementById('rp-view-wrapper')
    const reviewsCard = this.reviewsCardRef.current
    if (viewWrapper && reviewsCard) {
      viewWrapper.scrollTop = reviewsCard.offsetTop
    }
    this.props.setPage(page)
  }

  public renderReviews() {
    const { index, reviews = [], total, update, expand }: Props = this.props
    const expanded = expand !== undefined

    if (total === undefined) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Spinner size="2x" />
        </Flex>
      )
    }

    if (total === 0) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Text>No reviews found</Text>
        </Flex>
      )
    }

    return (
      <React.Fragment>
        <Flex column mb={24}>
          {reviews.map((review, i) => {
            if (!expanded && i > 2) return
            return <Review key={i} review={review} />
          })}
        </Flex>
        <Flex column _width={100} alignItems="center">
          {!expanded && (
            <Button
              primary
              _width={100}
              onClick={() => update({ expand: false })}
            >
              {' '}
              See More Reviews{' '}
            </Button>
          )}
          {expanded && reviews && total > 10 && (
            <Pagination
              total={total}
              currentPage={index}
              update={this.setPage}
            />
          )}
        </Flex>
      </React.Fragment>
    )
  }

  public render() {
    const {
      _id,
      className,
      collapsed = false,
      header,
      body,
      sites = []
    }: Props = this.props

    return (
      <Card
        className={className}
        header={header}
        collapsed={collapsed}
        ref={this.reviewsCardRef}
        customAttributes={{ 'data-test': 'reviews-card' }}
      >
        <Flex column mb={12}>
          <Text isTitle>Reviews</Text>
          <Text isBody>{body}</Text>
        </Flex>
        <Flex row mb={24}>
          <Flex column _width={50} pr={6}>
            <Label>Review Source</Label>
            <ConnectedSelect
              name="reviewsSource"
              options={sites}
              sideEffect={getReviewsAction({ index: 1, cardId: _id })}
            />
          </Flex>
          <Flex column _width={50} pl={6}>
            <Label>Sorted By</Label>
            <ConnectedSelect
              name="reviewsSort"
              options={REVIEWS_SORT_OPTIONS}
              sideEffect={getReviewsAction({ index: 1, cardId: _id })}
            />
          </Flex>
        </Flex>
        {this.renderReviews()}
      </Card>
    )
  }
}

export const ReviewsCardDisplay = styled(withForm(Reviews, 'reviews'))`
  ${helpers}
`
