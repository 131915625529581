import * as React from 'react'
import { UserControls } from '@andyet/simplewebrtc'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/pro-light-svg-icons/faMicrophone'
import { faMicrophoneSlash } from '@fortawesome/pro-light-svg-icons/faMicrophoneSlash'
import { faVideo } from '@fortawesome/pro-light-svg-icons/faVideo'
import { faVideoSlash } from '@fortawesome/pro-light-svg-icons/faVideoSlash'
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faExpandAlt } from '@fortawesome/pro-light-svg-icons/faExpandAlt'
import { faCompressAlt } from '@fortawesome/pro-light-svg-icons/faCompressAlt'
import { sizes } from 'style/variables'
import styled, { css, keyframes } from 'styled-components'

import { Flex, Button } from 'components/_utility'

export interface Props {
  expandedVideoMode: boolean
  leaveCall: () => void
  expandVideoScreen: () => void
  collapseVideoScreen: () => void
  toggleMobileShrink: () => void
}

const Container = styled(Flex)`
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 2;
  justify-content: center;
`

const pulseKeyFrames = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`

const ControlButton = styled(Button)<{
  isOff?: boolean
  isFlashing?: boolean
  isDefault?: boolean
}>`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 16px;
  margin-right: 10px;
  &:focus {
    border: solid 2px ${p => p.theme.colors.original};
  }

  color: ${p =>
    p.isDefault ? p.theme.colors.lighter : p.isOff ? p.theme.gray.light : ''};
  background-color: ${p =>
    p.isDefault
      ? p.theme.colors.original
      : p.isOff
      ? p.theme.gray.dark
      : p.theme.gray.lighter};

  }
  ${p =>
    p.isFlashing
      ? css`
          animation: ${pulseKeyFrames} 0.5s ease-in-out infinite;
        `
      : ''}
`

const LeaveButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 16px;
  background-color: #e83e3e;
  transform: rotate(225deg);
  color: ${p => p.theme.gray.lightest};
  &:focus {
    border: solid 2px ${p => p.theme.colors.original};
  }
`

export const ControlsDisplay = ({
  leaveCall,
  expandVideoScreen,
  collapseVideoScreen,
  expandedVideoMode,
  toggleMobileShrink
}: Props) => {
  return (
    <UserControls
      render={({
        isMuted,
        mute,
        unmute,
        isPaused,
        isSpeakingWhileMuted,
        pauseVideo,
        resumeVideo
      }) => (
        <Container>
          {sizes.isMobile ? (
            <ControlButton onClick={toggleMobileShrink} isDefault>
              <Icon icon={faCompressAlt} />
            </ControlButton>
          ) : (
            <ControlButton
              isDefault
              onClick={
                expandedVideoMode ? collapseVideoScreen : expandVideoScreen
              }
            >
              <Icon icon={expandedVideoMode ? faCompressAlt : faExpandAlt} />
            </ControlButton>
          )}

          <ControlButton
            isOff={isMuted}
            isFlashing={isSpeakingWhileMuted}
            onClick={() => (isMuted ? unmute() : mute())}
          >
            {isMuted ? (
              <Icon icon={faMicrophoneSlash} />
            ) : (
              <Icon icon={faMicrophone} />
            )}
          </ControlButton>
          <ControlButton
            isOff={isPaused}
            onClick={() => (isPaused ? resumeVideo() : pauseVideo())}
          >
            {isPaused ? <Icon icon={faVideoSlash} /> : <Icon icon={faVideo} />}
          </ControlButton>
          <LeaveButton onClick={leaveCall}>
            <Icon icon={faPhone} />
          </LeaveButton>
        </Container>
      )}
    />
  )
}
