import * as React from 'react'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { FormContext } from 'components/_hoc/withForm'
import { validateForm } from 'state/_sagas/_actions'
import { formSubmittedSelector, formValidSelectorFactory } from 'state/forms'
import { RootState } from 'types'
import { Props, SubmitButtonDisplay } from './SubmitButton.display'

import { Subtract } from 'utility-types'

export interface InjectedFieldProps {
  formValid: boolean
  validateForm: (formId: string, failureMessage: string) => void
}

interface OwnProps {
  formId: string
  failureMessage?: string
  disabled?: boolean
}

type StateProps = Pick<Props, 'formValid' | 'formSubmitted'>
const mapStateToPropsGenerator = () => {
  const formValidSelector = formValidSelectorFactory()
  return (state: RootState, ownProps: OwnProps): StateProps => ({
    formValid: formValidSelector(state, ownProps.formId),
    formSubmitted:
      ownProps.disabled || formSubmittedSelector(state, ownProps.formId)
  })
}

type DispatchProps = Pick<Props, 'validateForm'>
const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  validateForm: () => {
    dispatch(validateForm({ ...ownProps }))
  }
})

const ConnectedComponent = connect(
  mapStateToPropsGenerator,
  mapDispatchToProps
)(SubmitButtonDisplay)

export const SubmitButtonContainer = (
  props: Subtract<Props, InjectedFieldProps>
) => {
  return (
    <FormContext.Consumer>
      {({ formId }: { formId: string }) => (
        <ConnectedComponent {...props} formId={formId} />
      )}
    </FormContext.Consumer>
  )
}
