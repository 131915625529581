export enum VideoStatus {
  calling = 'calling',
  active = 'active',
  ended = 'ended',
  declined = 'declined'
}

enum ParticipantStatus {
  active = 'active',
  inactive = 'inactive'
}

enum ParticipantType {
  provider = 'provider',
  client = 'client'
}

interface Participant {
  _id: string
  type: ParticipantType
  status: ParticipantStatus
  firstName: string
  lastName: string
  photo?: string
}

export interface VideoInterface {
  status: VideoStatus
  participants: Participant[]
  startTime: string
  endTime?: string
}
