import * as React from 'react'
import styled from 'styledComponents'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPhoneSlash } from '@fortawesome/pro-solid-svg-icons/faPhoneSlash'

import { Flex, Text } from 'components/_utility'
import { shadow } from 'style/mixins/'

export interface Props {
  messageId: string
  answer: ({ messageId }: { messageId: string }) => void
  decline: ({ messageId }: { messageId: string }) => void
}

export const RingingDisplay = ({ messageId, answer, decline }: Props) => (
  <Flex mb={6} _width={100} justifyContent="space-around">
    <PhoneOption color="red" onClick={() => decline({ messageId })}>
      <Icon size="1x" icon={faPhoneSlash} color="red" />
      <Text color="red" ml={5}>
        Ignore
      </Text>
    </PhoneOption>
    <PhoneOption color="green" onClick={() => answer({ messageId })}>
      <Icon size="1x" icon={faPhone} color="green" />
      <Text color="green" ml={5}>
        Answer
      </Text>
    </PhoneOption>
  </Flex>
)

const PhoneOption = styled('button')<{ color: string }>`
  padding: 8px;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 0 7px 0 rgb(50 50 93 / 10%), 0 0 3px 0 rgb(0 0 0 / 7%);
  display: flex;
  background: none;
  cursor: pointer;
  ${shadow('light')}

  ${p => `
    border: 1px solid ${p.color};
    color: ${p.color};
  `}
`
