import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('video')

export const setRoomName = actionCreator<{ roomName: string | null }>(
  'SET_ROOM_NAME'
)

export const declineVideoCall = actionCreator<{ messageId: string }>(
  'DECLINE_CALL'
)

export const leaveVideoCall = actionCreator('LEAVE_CALL')

export const joinVideoCall = actionCreator<{
  messageId: string
}>('JOIN_CALL')

export const expandVideo = actionCreator('EXPAND')

export const collapseVideo = actionCreator('COLLAPSE')

export const toggleMobileShrink = actionCreator('MOBILE_SHRINK')
