import { put, takeEvery, select } from 'redux-saga/effects'
import { logAction } from 'state/_sagas/_actions'
import { reviews } from 'state/_sagas/_subgenerators'
import { getReviewsSites, updateCard } from 'state/entities/cards/cards.actions'
import { cardPropsSelector } from 'state/entities/cards'
import { Action } from 'typescript-fsa'

function* sitesSaga(action: Action<{ cardId: string }>) {
  try {
    const { reviewsClientId } = yield select(
      cardPropsSelector,
      action.payload.cardId
    )
    const sitesPayload = yield* reviews.getSites(reviewsClientId)
    yield put(
      updateCard({
        _id: action.payload.cardId,
        data: { props: { sites: sitesPayload } }
      })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* getSitesSaga() {
  yield takeEvery(getReviewsSites, sitesSaga)
}
