import * as moment from 'moment'
import * as React from 'react'
import styled from 'styledComponents'

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { withForm } from 'components/_hoc'
import {
  Button,
  Card,
  ConnectedSelect,
  Flex,
  Label,
  SubmitButton,
  Text
} from 'components/_utility'
import { getAppointmentDatetimes } from 'state/_sagas/_actions'
import { helpers } from 'style/mixins/'
import {
  AppointmentInterface,
  CardInterface,
  DatetimeInterface,
  FieldOptionInterface,
  HelpersProps
} from 'types'
import { getTimezone } from 'utility'

export interface Props extends HelpersProps {
  className?: string
  typeOptions: FieldOptionInterface[]
  datetimeOptions: DatetimeInterface[]
  datePickerIndex: number
  selectedDate?: string
  selectedTime?: string
  timezoneId: string
  updateThisCard: (data: Partial<CardInterface>) => void
  updateAppointment: (appointment: Partial<AppointmentInterface>) => void
  onNextClick: () => void
}

const Component = ({
  className,
  typeOptions,
  datetimeOptions,
  datePickerIndex,
  selectedDate,
  selectedTime,
  timezoneId,
  updateThisCard,
  updateAppointment,
  onNextClick
}: Props) => {
  if (!selectedDate) {
    selectedDate = (datetimeOptions[0] || {}).date
  }

  const dateOptions = datetimeOptions.map(datetime => datetime.date)
  const selectedDatetimeOption = datetimeOptions.find(
    datetime => datetime.date === selectedDate
  )
  const timeOptions = selectedDatetimeOption ? selectedDatetimeOption.times : []
  const showMoreTimes = selectedDatetimeOption
    ? selectedDatetimeOption.expanded
    : false

  const setDatePickerIndex = (i: number) => {
    updateThisCard({ props: { datePickerIndex: i } })
  }

  const expandTimeOptions = () => {
    const updatedDateTimeOptions = datetimeOptions.map(datetime => {
      if (datetime.date === selectedDate) {
        datetime.expanded = true
      }
      return datetime
    })

    updateThisCard({ props: { dateTimeOptions: updatedDateTimeOptions } })
  }

  const mapDatesToComponents = () => {
    return dateOptions
      .slice(datePickerIndex, datePickerIndex + 3)
      .map((date: string, i: number) => {
        const dateTimeOptionIndex = i + datePickerIndex
        return (
          <Button
            picker
            key={i}
            selected={date === selectedDate}
            onClick={() =>
              updateThisCard({
                props: { selectedDate: date, selectedTime: null }
              })
            }
          >
            <div>{datetimeOptions[dateTimeOptionIndex].dayOfTheWeek}</div>
            <div>{moment(date, 'YYYY-MM-DD').format('M/D')}</div>
          </Button>
        )
      })
  }

  const mapTimesToComponents = () => {
    return timeOptions
      .slice(0, showMoreTimes ? undefined : 6)
      .map((time: string, i: number) => {
        return (
          <Button
            picker
            key={i}
            selected={time === selectedTime}
            onClick={() => updateAppointment({ date: selectedDate, time })}
          >
            {moment(time, 'H:mm').format('h:mmA')}
          </Button>
        )
      })
  }

  const isNextButtonEnabled = dateOptions.length > 0 && !!selectedTime
  const DATES_PER_VIEW = 3

  return (
    <Card className={className}>
      <Flex column mb={12}>
        <Label>Appointment Type:</Label>
        <ConnectedSelect
          name="appointment.type"
          required={true}
          options={typeOptions}
          disabled={typeOptions.length === 1}
          sideEffect={getAppointmentDatetimes({})}
        />
      </Flex>
      {dateOptions.length > 0 && (
        <Flex column mb={6}>
          <Flex column mb={3}>
            <Label mb={6}>Select Preferred Date and Time:</Label>
            <DateSelector row>{mapDatesToComponents()}</DateSelector>
          </Flex>
          <Flex row justifyContent="space-between">
            <div>
              {datePickerIndex > 0 && (
                <TextButton
                  onClick={() =>
                    setDatePickerIndex(datePickerIndex - DATES_PER_VIEW)
                  }
                >{`« Before ${moment(
                  datetimeOptions[datePickerIndex].date,
                  'YYYY-MM-DD'
                ).format('M/D')}`}</TextButton>
              )}
            </div>
            <div>
              {dateOptions.length > datePickerIndex + DATES_PER_VIEW && (
                <TextButton
                  onClick={() =>
                    setDatePickerIndex(datePickerIndex + DATES_PER_VIEW)
                  }
                >{`After ${moment(
                  datetimeOptions[datePickerIndex + DATES_PER_VIEW - 1].date,
                  'YYYY-MM-DD'
                ).format('M/D')} »`}</TextButton>
              )}
            </div>
          </Flex>
        </Flex>
      )}
      {timeOptions.length > 0 && (
        <Flex column mb={12} alignItems="center">
          <Label>{`${getTimezone({
            type: 'full',
            timezoneId
          })} Time (${getTimezone({
            type: 'abbreviation',
            timezoneId
          })})`}</Label>
          <TimeSelector row>{mapTimesToComponents()}</TimeSelector>
          {!showMoreTimes && timeOptions.length > 6 && (
            <Button loadMore onClick={expandTimeOptions}>
              More <Icon icon={faChevronDown} />
            </Button>
          )}
        </Flex>
      )}
      {isNextButtonEnabled && (
        <SubmitButton
          _width={100}
          primary
          failureMessage="Please select a date and time"
          disabled={!selectedTime}
          onClick={onNextClick}
        >
          Next
        </SubmitButton>
      )}
    </Card>
  )
}

export const Step1 = styled(withForm(Component, 'appointment_1'))`
  ${helpers}
`

const DateSelector = styled(Flex)`
  ${Button} {
    width: calc(33.3333% - 12px);
    margin: 0 6px;

    div:first-child {
      font-size: 13px;
    }
  }
`

const TextButton = styled(Text)`
  cursor: pointer;
  font-size: 13px;
`

const TimeSelector = styled(Flex)`
  flex-wrap: wrap;
  width: 220px;

  ${Button} {
    margin: 3px;
    width: calc(50% - 6px);
  }
`
