import { put, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { logAction } from 'state/_sagas/_actions'
import { updateCard } from 'state/entities/cards'
import { updateLead } from 'state/lead'
import { AppointmentInterface } from 'types'
import { showToast, updateAppointment } from '../_actions'

// Note, updating appointment time triggers a complete update of the appointment on the lead data model
function* saga(
  action: Action<{ appointment: Partial<AppointmentInterface>; cardId: string }>
) {
  const { appointment, cardId } = action.payload
  try {
    yield put(
      updateCard({
        _id: cardId,
        data: {
          props: {
            selectedDate: appointment.date,
            selectedTime: appointment.time
          }
        }
      })
    )
    yield put(updateLead({ appointment }))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(
      showToast({ message: 'Failed to update appointment', variant: 'error' })
    )
  }
}

export function* updateAppointmentSaga() {
  yield takeEvery(updateAppointment, saga)
}
