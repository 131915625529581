import * as moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styledComponents'

import { withForm } from 'components/_hoc'
import {
  Card,
  ConnectedInput,
  ConnectedMaskedInput,
  ConnectedRadio,
  ConnectedTextarea,
  Flex,
  Label,
  SubmitButton,
  Text
} from 'components/_utility'
import { phoneMaskSelector } from 'state/company'
import { formSelector } from 'state/forms'
import { helpers } from 'style/mixins/'
import {
  AppointmentInterface,
  BookingField,
  FormInterface,
  HelpersProps,
  InputMask,
  RootState
} from 'types'

export interface Props extends HelpersProps {
  className?: string
  bookingFields: BookingField[]
  appointmentSummary?: AppointmentInterface
  filledAppointmentFields: FormInterface
  createAppointment: () => void
  phoneMask?: InputMask
}

type StateProps = Pick<Props, 'filledAppointmentFields' | 'phoneMask'>
const mapStateToProps = (state: RootState): StateProps => ({
  filledAppointmentFields: formSelector(state, 'appointment_2'),
  phoneMask: phoneMaskSelector(state)
})

class Component extends React.Component<Props> {
  public render() {
    const {
      className,
      appointmentSummary,
      createAppointment,
      bookingFields,
      filledAppointmentFields,
      phoneMask
    }: Props = this.props
    const extraFields = bookingFields.map(bookingField => {
      let field
      switch (bookingField.type) {
        case 'inputField':
          field = (
            <Flex key={bookingField._id} column mb={12}>
              <StyledLabel>
                <div>
                  {`${bookingField.label}${bookingField.required ? '*' : ''}`}
                </div>
                {filledAppointmentFields[
                  `appointment.fields.${bookingField._id}`
                ] &&
                  filledAppointmentFields[
                    `appointment.fields.${bookingField._id}`
                  ].value &&
                  (filledAppointmentFields[
                    `appointment.fields.${bookingField._id}`
                  ].value as string).length && (
                    <div>
                      {`${
                        (filledAppointmentFields[
                          `appointment.fields.${bookingField._id}`
                        ].value as string).length
                      }/${bookingField.maxCharacters}`}
                    </div>
                  )}
              </StyledLabel>
              <ConnectedInput
                name={`appointment.fields.${bookingField._id}`}
                required={bookingField.required}
                maxCharacters={bookingField.maxCharacters}
              />
            </Flex>
          )
          break
        case 'multiline':
          field = (
            <Flex key={bookingField._id} column mb={12}>
              <StyledLabel>
                <div>
                  {`${bookingField.label}${bookingField.required ? '*' : ''}`}
                </div>
                {filledAppointmentFields[
                  `appointment.fields.${bookingField._id}`
                ] &&
                  filledAppointmentFields[
                    `appointment.fields.${bookingField._id}`
                  ].value &&
                  (filledAppointmentFields[
                    `appointment.fields.${bookingField._id}`
                  ].value as string).length && (
                    <div>
                      {`${
                        (filledAppointmentFields[
                          `appointment.fields.${bookingField._id}`
                        ].value as string).length
                      }/${bookingField.maxCharacters}`}
                    </div>
                  )}
              </StyledLabel>
              <ConnectedTextarea
                name={`appointment.fields.${bookingField._id}`}
                required={bookingField.required}
                maxCharacters={bookingField.maxCharacters}
              />
            </Flex>
          )
          break
        case 'yesNo':
          field = (
            <Flex key={bookingField._id} column mb={12}>
              <Label>{bookingField.label}</Label>
              <Flex row>
                <ConnectedRadio
                  key={`appointment.fields.${bookingField._id}_yes`}
                  name={`appointment.fields.${bookingField._id}`}
                  required={bookingField.consent}
                  consent={bookingField.consent}
                  option="Yes"
                  label="Yes"
                />
                <ConnectedRadio
                  key={`appointment.fields.${bookingField._id}_no`}
                  name={`appointment.fields.${bookingField._id}`}
                  required={bookingField.consent}
                  consent={bookingField.consent}
                  option="No"
                  label="No"
                />
              </Flex>
            </Flex>
          )
          break
      }
      return field
    })

    return (
      <Card className={className}>
        {appointmentSummary && (
          <Flex column alignItems="center" mb={12}>
            <Text isTitle>{appointmentSummary.type.label}</Text>
            <Text>
              {moment(appointmentSummary.time, 'H:mm').format('h:mmA')} on{' '}
              {moment(appointmentSummary.date, 'YYYY-MM-DD').format('M/D')}
            </Text>
          </Flex>
        )}

        {/* wrapped to form to handle ENTER key submission */}
        <form onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}>
          <Flex row mb={12}>
            <Flex column _width={50} pr={6}>
              <Label asterisk>First Name</Label>
              <ConnectedInput name="firstName" required={true} />
            </Flex>
            <Flex column _width={50} pl={6}>
              <Label asterisk>Last Name</Label>
              <ConnectedInput name="lastName" required={true} />
            </Flex>
          </Flex>
          <Flex row mb={12}>
            <Flex column _width={50} pr={6}>
              <Label asterisk>Email</Label>
              <ConnectedInput name="email" required={true} />
            </Flex>
            <Flex column _width={50} pl={6}>
              <Label asterisk>Phone Number</Label>
              {phoneMask ? (
                <ConnectedMaskedInput
                  name="phone"
                  required={true}
                  mask={phoneMask}
                />
              ) : (
                <ConnectedInput name="phone" required={true} />
              )}
            </Flex>
          </Flex>
          {extraFields}
          <SubmitButton
            _width={100}
            primary
            failureMessage="Please enter a valid data"
            onClick={createAppointment}
          >
            Schedule Now
          </SubmitButton>
        </form>
      </Card>
    )
  }
}

const StyledLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
`

const StyledStep2 = styled(withForm(Component, 'appointment_2'))`
  ${helpers}
`

export const Step2 = connect(mapStateToProps)(StyledStep2)
