import * as React from 'react'
import styled from 'styledComponents'
import * as UsaStates from 'usa-states'

import {
  CHATBOT_FORM_MAIN_FIELDS,
  TERMS_AND_CONDITIONS_LINK
} from 'common/constants'
import {
  Card,
  ConnectedCheckbox,
  ConnectedInput,
  ConnectedMaskedInput,
  ConnectedRadio,
  ConnectedSelect,
  ConnectedTextarea,
  Flex,
  Label,
  Text
} from 'components/_utility'
import { SubmitButton } from 'components/_utility/_form'
import { helpers } from 'style/mixins/'
import {
  CardHeaderProps,
  CheckboxInterface,
  ContactFormFieldInterface,
  ContactFormFieldType,
  ContactFormInterface,
  FieldOptionInterface,
  FormFieldType,
  FormInterface,
  HelpersProps,
  InputMask,
  LeadState,
  VehicleDropdownParamsInterface
} from 'types'
import {
  getCurrentPosition,
  getDistanceFromLatLonInKm
} from 'utility/geolocation'
import { getShowdownLinkHtml } from 'utility'

import { updateVehicleInformationStep } from 'state/_sagas/_actions'

import { FormContext } from 'components/_hoc/withForm'

export interface Props extends HelpersProps {
  _id: string
  previewMode: string
  className?: string
  formData: ContactFormInterface
  onSubmit: (formId: string) => void
  collapsed?: boolean
  submitted?: boolean
  header?: CardHeaderProps
  title?: string
  body?: string
  disclaimer?: string
  privacyLink?: string
  updateForm: (formId: string, data: FormInterface) => void
  updateLead: (data: Partial<LeadState>) => void
  filledFormFields: FormInterface
  phoneMask?: InputMask
  form: FormInterface
  lead: LeadState
  initializeVehicleForm: (
    formId: string,
    vehicleDropdownParams: VehicleDropdownParamsInterface
  ) => void
}

const getNearestDropdownOption = (
  options: FieldOptionInterface[],
  coords: Position['coords']
) => {
  const { latitude: currentLatitude, longitude: currentLongitude } = coords

  const [nearestOption] = options
    .filter(o => o.latitude && o.longitude)
    .sort((option1: FieldOptionInterface, option2: FieldOptionInterface) => {
      const d1 = getDistanceFromLatLonInKm(
        currentLatitude,
        currentLongitude,
        option1.latitude!,
        option1.longitude!
      )
      const d2 = getDistanceFromLatLonInKm(
        currentLatitude,
        currentLongitude,
        option2.latitude!,
        option2.longitude!
      )
      return d1 - d2
    })

  return nearestOption
}

// @ts-ignore
const StyledLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
`

const getSelectOptions = (field: ContactFormFieldInterface) => {
  const { options, isRoutingAvailable } = field
  return (options as FieldOptionInterface[]).map(
    (fieldOption: FieldOptionInterface) => {
      const option: FieldOptionInterface = {
        label: fieldOption.label,
        value: fieldOption.value,
        inquiryTypeId: fieldOption.inquiryTypeId
      }
      if (isRoutingAvailable) {
        option.companyId = fieldOption.companyId
      }
      return option
    }
  )
}

const mapOptionsToRadios = (
  field: ContactFormFieldInterface,
  customProps: { disabled: boolean; options?: FieldOptionInterface[] }
) => (
  <React.Fragment>
    {(
      (customProps && customProps.options) ||
      (field.options as FieldOptionInterface[]) ||
      []
    ).map((option, i) => (
      <ConnectedRadio
        key={i}
        name={field.name}
        option={option.value}
        label={option.label}
        consent={field.consent}
        required={field.required}
        disabled={customProps.disabled}
      />
    ))}
  </React.Fragment>
)

const mapOptionsToCheckboxes = (
  field: ContactFormFieldInterface,
  customProps: { disabled: boolean }
) => (
  <React.Fragment>
    {((field.options as CheckboxInterface[]) || []).map((option, i) => (
      <ConnectedCheckbox
        key={i}
        name={option.name}
        label={option.label}
        required={option.required}
        disabled={customProps.disabled}
      />
    ))}
  </React.Fragment>
)

const mapFieldToComponent = (
  formId: string,
  field: ContactFormFieldInterface,
  phoneMask?: InputMask,
  disabled?: boolean
) => {
  switch (field.type) {
    case ContactFormFieldType.FIRST_NAME:
    case ContactFormFieldType.LAST_NAME:
    case ContactFormFieldType.EMAIL:
    case ContactFormFieldType.VEHICLE_MAKE:
    case ContactFormFieldType.VEHICLE_MODEL:
    case ContactFormFieldType.VEHICLE_YEAR:
    case ContactFormFieldType.VEHICLE_OPEN_SEARCH:
    case ContactFormFieldType.CUSTOM_SINGLE_LINE_INPUT:
    case ContactFormFieldType.CITY:
    case ContactFormFieldType.ZIP_CODE:
      return (
        <ConnectedInput
          name={field.name}
          required={field.required}
          disabled={disabled}
          maxCharacters={field.maxCharacters}
        />
      )
    case ContactFormFieldType.PHONE:
      if (phoneMask && !field.phoneMaskDisabled) {
        return (
          <ConnectedMaskedInput
            name={field.name}
            required={field.required}
            disabled={disabled}
            maxCharacters={field.maxCharacters}
            mask={phoneMask}
          />
        )
      }
      return (
        <ConnectedInput
          name={field.name}
          required={field.required}
          disabled={disabled}
          maxCharacters={field.maxCharacters}
        />
      )
    case ContactFormFieldType.YES_NO_QUESTION:
      return mapOptionsToRadios(field, {
        disabled: disabled || false,
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' }
        ]
      })
    case ContactFormFieldType.ADDRESS:
    case ContactFormFieldType.CUSTOM_MULTI_LINE_INPUT:
      return (
        <ConnectedTextarea
          name={field.name}
          required={field.required}
          disabled={disabled}
          maxCharacters={field.maxCharacters}
        />
      )
    case ContactFormFieldType.DROPDOWN:
    case ContactFormFieldType.INQUIRY_TYPE_DROPDOWN: {
      const options = getSelectOptions(field)
      return (
        <ConnectedSelect
          name={field.name}
          required={field.required}
          disabled={disabled}
          options={options}
        />
      )
    }
    case ContactFormFieldType.STATE: {
      const {
        states
      }: {
        states: UsaStates.IStateResult[]
      } = new (UsaStates as any).UsaStates()
      const options = states.map(state => ({
        label: state.name,
        value: state.abbreviation
      }))

      return (
        <ConnectedSelect
          name={field.name}
          required={field.required}
          disabled={disabled}
          options={options}
        />
      )
    }
    case ContactFormFieldType.RADIO:
      return mapOptionsToRadios(field, { disabled: disabled || false })
    case ContactFormFieldType.CHECKBOXES:
      return mapOptionsToCheckboxes(field, { disabled: disabled || false })
    case ContactFormFieldType.VEHICLE_YEAR_DROPDOWN:
      return (
        <ConnectedSelect
          name={'vehicleYearDropdown'}
          required={field.required}
          disabled={disabled}
          sideEffect={updateVehicleInformationStep({
            updatedField: 'vehicleYearDropdown',
            formId
          })}
        />
      )
    case ContactFormFieldType.VEHICLE_MAKE_DROPDOWN:
      return (
        <ConnectedSelect
          name={'vehicleMakeDropdown'}
          required={field.required}
          disabled={disabled}
          sideEffect={updateVehicleInformationStep({
            updatedField: 'vehicleMakeDropdown',
            formId
          })}
        />
      )
    case ContactFormFieldType.VEHICLE_MODEL_DROPDOWN:
      return (
        <ConnectedSelect
          name={'vehicleModelDropdown'}
          required={field.required}
          disabled={disabled}
          sideEffect={updateVehicleInformationStep({
            updatedField: 'vehicleModelDropdown',
            formId
          })}
        />
      )
    default:
      return null
  }
}

const mapContactFormToComponents = (
  formId: string,
  form: ContactFormInterface,
  filledFormFields: FormInterface,
  phoneMask?: InputMask,
  submitted = false
) => {
  return form.rows.map((row, i) => {
    const columnCount = row.columns.length

    return (
      <Flex key={i} row mb={12}>
        {row.columns.map((field, j) => {
          const { label, required, maxCharacters, name, type } = field
          const FieldComponent = mapFieldToComponent(
            formId,
            field,
            phoneMask,
            submitted
          )

          let LabelComponent
          if (
            filledFormFields &&
            [
              ContactFormFieldType.CUSTOM_SINGLE_LINE_INPUT,
              ContactFormFieldType.CUSTOM_MULTI_LINE_INPUT,
              ContactFormFieldType.VEHICLE_OPEN_SEARCH
            ].includes(type) &&
            maxCharacters !== undefined
          ) {
            LabelComponent = (
              <StyledLabel>
                <div>{`${label}${required ? '*' : ''}`}</div>
                {filledFormFields[name] &&
                  filledFormFields[name].value &&
                  (filledFormFields[name].value as string).length && (
                    <div>
                      {`${
                        (filledFormFields[name].value as string).length
                      }/${maxCharacters}`}
                    </div>
                  )}
              </StyledLabel>
            )
          } else {
            LabelComponent = <Label asterisk={required}>{label}</Label>
          }

          return (
            FieldComponent && (
              <Flex
                key={j}
                column
                _width={100 / columnCount}
                pl={columnCount > 1 && j > 0 ? 6 : 0}
                pr={columnCount > 1 && j < columnCount - 1 ? 6 : 0}
                justifyContent="flex-end"
              >
                {LabelComponent}
                {FieldComponent}
              </Flex>
            )
          )
        })}
      </Flex>
    )
  })
}

class Component extends React.Component<Props> {
  public componentDidMount() {
    const {
      formData,
      _id: formId,
      lead,
      previewMode,
      updateLead,
      updateForm
    } = this.props
    const defaultFormValues = {}
    const leadMainFieldValues = {}
    const vehicleDropdownParams = {}
    const geoFields: ContactFormFieldInterface[] = []
    let hasVehicleDropdown

    formData.rows.forEach(row => {
      row.columns.forEach(field => {
        const {
          type,
          consent,
          name,
          required,
          options,
          defaultOption,
          isIPMatchingAvailable
        } = field
        if (CHATBOT_FORM_MAIN_FIELDS.includes(type) && lead[type]) {
          leadMainFieldValues[name] = {
            value: lead[type]
          }
        }
        if (type === ContactFormFieldType.YES_NO_QUESTION) {
          defaultFormValues[name] = {
            value: consent ? 'no' : 'yes',
            status: consent ? 'invalid' : 'valid',
            required,
            consent,
            type: FormFieldType.RADIO
          }
        }
        if (
          [
            ContactFormFieldType.DROPDOWN,
            ContactFormFieldType.INQUIRY_TYPE_DROPDOWN
          ].includes(type)
        ) {
          const defaultFieldOption = (options! as FieldOptionInterface[]).find(
            o => o.value === defaultOption!.value
          )
          defaultFormValues[name] = {
            ...defaultFieldOption,
            value: (defaultFieldOption && defaultFieldOption.value) || '',
            status: 'valid',
            required,
            label: (defaultFieldOption && defaultFieldOption.label) || '',
            type: FormFieldType.SELECT
          }
        }
        if (isIPMatchingAvailable && previewMode === 'none') {
          geoFields.push(field)
        }
        if (
          [
            ContactFormFieldType.VEHICLE_YEAR_DROPDOWN,
            ContactFormFieldType.VEHICLE_MAKE_DROPDOWN,
            ContactFormFieldType.VEHICLE_MODEL_DROPDOWN
          ].includes(type)
        ) {
          hasVehicleDropdown = true
          vehicleDropdownParams[name] = {
            required: required || false
          }
        }
      })
    })

    updateLead(defaultFormValues)
    updateForm(formId, { ...defaultFormValues, ...leadMainFieldValues })

    if (hasVehicleDropdown) {
      this.props.initializeVehicleForm(formId, vehicleDropdownParams)
    }

    if (geoFields.length) {
      getCurrentPosition().then(({ coords }) => {
        const geoFieldsDefaultFormValues = {}
        geoFields.forEach(geoField => {
          const { isRoutingAvailable } = geoField
          const nearestOption = getNearestDropdownOption(
            geoField.options as FieldOptionInterface[],
            coords
          )
          if (!nearestOption) return

          geoFieldsDefaultFormValues[geoField.name] = {
            value: nearestOption.value || '',
            status: 'valid',
            required: geoField.required,
            label: nearestOption.label || '',
            inquiryTypeId: nearestOption.inquiryTypeId,
            companyId: isRoutingAvailable ? nearestOption.companyId : undefined
          }
        })

        updateLead(geoFieldsDefaultFormValues)
        updateForm(formId, geoFieldsDefaultFormValues)
        // eslint-disable-next-line no-console
      }, console.error)
    }
  }

  public render() {
    const {
      className,
      header,
      title,
      body,
      formData,
      disclaimer,
      privacyLink,
      onSubmit,
      filledFormFields,
      phoneMask,
      _id: formId,
      collapsed,
      submitted,
      padding
    } = this.props
    if (header && submitted) {
      header.status = 'complete'
    }

    const getDisclaimer = (text: string) => {
      let html = text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      html = getShowdownLinkHtml(html)
      return (
        <Text
          mt={6}
          isFootnote
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
      )
    }
    return (
      <FormContext.Provider value={{ formId }}>
        <Card
          className={className}
          header={header}
          collapsed={collapsed}
          padding={padding}
        >
          {title && <Text isTitle>{title}</Text>}
          {body && (
            <Text isBody mb={12}>
              {body}
            </Text>
          )}
          {/* wrapped to form to handle ENTER key submission */}
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault()
              onSubmit(formId)
            }}
          >
            {mapContactFormToComponents(
              formId,
              formData,
              filledFormFields,
              phoneMask,
              submitted
            )}
            <SubmitButton
              _width={100}
              primary
              failureMessage="Please enter a valid data"
              disabled={submitted}
            >
              Send
            </SubmitButton>
          </form>

          {/* If props.disclaimer exists, show that. Else, show the default disclaimer */}
          {disclaimer &&
            disclaimer.trim().length > 0 &&
            getDisclaimer(disclaimer)}
          {(!disclaimer || disclaimer.trim().length === 0) && !privacyLink && (
            <Text mt={6} isFootnote>
              By submitting this form, you confirm that you have read and agree
              to the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={TERMS_AND_CONDITIONS_LINK}
              >
                terms and conditions
              </a>
              .
            </Text>
          )}
          {(!disclaimer || disclaimer.trim().length === 0) && privacyLink && (
            <Text mt={6} isFootnote>
              By submitting this form, you confirm that you have read and agree
              to the{' '}
              <a target="_blank" rel="noopener noreferrer" href={privacyLink}>
                terms and conditions
              </a>
              .
            </Text>
          )}
        </Card>
      </FormContext.Provider>
    )
  }
}

export const FormCardDisplay = styled(Component)`
  ${helpers}
`
