import { all, put, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { rehydrateCardsState, updateCard } from './cards.actions'
import { CardsState } from './cards.reducer'

function* rehydrateCardsSaga(action: Action<{ cardsState: CardsState }>) {
  try {
    const { cardsState } = action.payload
    const cards = Object.values(cardsState.byId)
    for (const card of cards) {
      yield put(
        updateCard({
          _id: card._id,
          data: {
            props: {
              submitted: !!card.props.submitted,
              collapsed: !!card.props.collapsed
            }
          }
        })
      )
    }
  } catch (e) {
    console.error(e)
  }
}

export default function* cardsSaga() {
  yield all([takeEvery(rehydrateCardsState, rehydrateCardsSaga)])
}
