import { Middleware } from 'redux'

const allPreviewsBlacklist = ['ui/SET_WINDOWSTATE', 'ui/SET_NAVSTATE']

const launchersOrBehaviorViewBlacklist = ['router/NAVIGATE']

export const blockActionsForPreviewMiddleware: Middleware = store => next => action => {
  const previewMode = store.getState().ui.rppreview // dirty middleware selector

  if (previewMode !== 'none' && allPreviewsBlacklist.includes(action.type))
    return

  switch (previewMode) {
    case 'behaviorview': // fall through
    case 'launcherview':
      if (launchersOrBehaviorViewBlacklist.includes(action.type)) {
        return
      }
      break
    default:
      break
  }

  next(action)
}
