import { put, select, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { updateForm } from 'state/forms'
import { leadSelector } from 'state/lead'

import { FieldOptionInterface, FormInterface } from 'types'
import { logAction, updateVehicleInformationStep } from '../_actions'

import { kbb } from '../_subgenerators'

function* saga(action: Action<{ updatedField: string; formId: string }>) {
  const { updatedField, formId } = action.payload
  const updatedForm = {} as FormInterface
  let updatedMakeOptions
  let updatedModelOptions

  try {
    const {
      vehicleYearDropdown: year,
      vehicleMakeDropdown: make,
      vehicleModelDropdown: model
    } = yield select(leadSelector)

    if (updatedField === 'vehicleYearDropdown') {
      updatedMakeOptions = yield kbb.getTradeInVehicleMakes(year.value)
      updatedForm.vehicleMakeDropdown = {
        options: updatedMakeOptions,
        disabled: false
      }
      // Check if options include current make
      if (
        make &&
        !updatedMakeOptions
          .map((option: FieldOptionInterface) => option.value)
          .includes(make.value)
      ) {
        updatedForm.vehicleMakeDropdown.value = undefined
        updatedForm.vehicleMakeDropdown.status = 'invalid'
      }
    }

    if (
      make &&
      ['vehicleYearDropdown', 'vehicleMakeDropdown'].includes(updatedField)
    ) {
      updatedModelOptions = yield kbb.getTradeInVehicleModels(
        year.value,
        make.value
      )
      updatedForm.vehicleModelDropdown = {
        options: updatedModelOptions,
        disabled: false
      }
      // Check if options include current model
      if (
        model &&
        (!updatedModelOptions ||
          !updatedModelOptions
            .map((option: FieldOptionInterface) => option.value)
            .includes(model.value))
      ) {
        updatedForm.vehicleModelDropdown.value = undefined
        updatedForm.vehicleModelDropdown.status = 'invalid'
      }
    }

    yield put(
      updateForm({
        formId,
        data: { ...updatedForm }
      })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* updateVehicleInformationSaga() {
  yield takeEvery(updateVehicleInformationStep, saga)
}
