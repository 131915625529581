import { put, takeEvery } from 'redux-saga/effects'

import { updateForm } from 'state/forms'

import { initializeVehicleForm, logAction } from '../_actions'
import { kbb } from '../_subgenerators'

function* saga(action: ReturnType<typeof initializeVehicleForm>) {
  try {
    const { formId, vehicleDropdownParams } = action.payload

    const years = yield* kbb.getTradeInVehicleYears()
    yield put(
      updateForm({
        formId,
        data: {
          vehicleYearDropdown: {
            ...vehicleDropdownParams.vehicleYearDropdown,
            options: years,
            value: ''
          },
          vehicleMakeDropdown: {
            ...vehicleDropdownParams.vehicleMakeDropdown,
            disabled: true,
            value: ''
          },
          vehicleModelDropdown: {
            ...vehicleDropdownParams.vehicleModelDropdown,
            disabled: true,
            value: ''
          }
        }
      })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* initializeVehicleFormSaga() {
  yield takeEvery(initializeVehicleForm, saga)
}
