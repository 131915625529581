import { put, select, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { updateForm } from 'state/forms'
import { leadSelector } from 'state/lead'

import { FieldOptionInterface, FormInterface } from 'types'
import { showToast, updateTradeInStep1 } from '../_actions'
import { kbb } from '../_subgenerators'

function* saga(action: Action<{ updatedField: string }>) {
  const { updatedField } = action.payload
  let updatedForm = {} as FormInterface

  try {
    const {
      tradeInVehicle: { year, make, model }
    } = yield select(leadSelector)

    if (updatedField === 'tradeInVehicle.year') {
      const updatedMakeOptions = yield kbb.getTradeInVehicleMakes(year.value)

      // Previously selected make is still available
      if (
        make &&
        updatedMakeOptions
          .map((option: FieldOptionInterface) => option.value)
          .includes(make.value)
      ) {
        const updatedModelOptions = yield kbb.getTradeInVehicleModels(
          year.value,
          make.value
        )

        // Previously selected model is still available
        if (
          model &&
          updatedModelOptions
            .map((option: FieldOptionInterface) => option.value)
            .includes(model.value)
        ) {
          updatedForm = {
            'tradeInVehicle.make': { options: updatedMakeOptions },
            'tradeInVehicle.model': { options: updatedModelOptions },
            'tradeInVehicle.trim': {
              value: undefined,
              status: 'invalid',
              options: yield kbb.getTradeInVehicleTrims(year.value, model.value)
            }
          }
        } else {
          updatedForm = {
            'tradeInVehicle.make': { options: updatedMakeOptions },
            'tradeInVehicle.model': {
              value: undefined,
              status: 'invalid',
              options: updatedModelOptions
            },
            'tradeInVehicle.trim': {
              value: undefined,
              status: 'invalid',
              disabled: true
            }
          }
        }
      } else {
        updatedForm = {
          'tradeInVehicle.make': {
            value: undefined,
            status: 'invalid',
            options: updatedMakeOptions
          },
          'tradeInVehicle.model': {
            value: undefined,
            status: 'invalid',
            disabled: true
          },
          'tradeInVehicle.trim': {
            value: undefined,
            status: 'invalid',
            disabled: true
          }
        }
      }
    }

    if (updatedField === 'tradeInVehicle.make') {
      updatedForm = {
        'tradeInVehicle.model': {
          options: yield kbb.getTradeInVehicleModels(year.value, make.value),
          disabled: false
        },
        'tradeInVehicle.trim': {
          value: undefined,
          status: 'invalid',
          disabled: true
        }
      }
    }

    if (updatedField === 'tradeInVehicle.model') {
      updatedForm = {
        'tradeInVehicle.trim': {
          options: yield kbb.getTradeInVehicleTrims(year.value, model.value),
          value: undefined,
          status: 'invalid',
          disabled: false
        }
      }
    }

    yield put(
      updateForm({
        formId: 'trade_in_1',
        data: updatedForm
      })
    )
  } catch (e) {
    console.error('Error:', e)
    yield put(
      showToast({ message: 'Failed to update vehicle', variant: 'error' })
    )
  }
}

export function* updateTradeInStep1Saga() {
  yield takeEvery(updateTradeInStep1, saga)
}
