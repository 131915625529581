import * as React from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styledComponents'

import { Card, Flex } from 'components/_utility'
import { helpers } from 'style/mixins/'

import rpApiService from 'services/rp.api'
import { currentViewParamsSelector } from 'state/router'
import { FaqInterface, HelpersProps, RootState } from 'types'

import { Faq } from './Faq.display'

interface Props extends HelpersProps {
  className?: string
  actionId: string
  faqsOrder: string[]
}

interface State {
  faqs?: FaqInterface[]
}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { faqs: [] }
  }

  public componentDidMount() {
    const { actionId, faqsOrder } = this.props
    rpApiService.getChatbotFaqs(actionId).then((faqs: FaqInterface[]) => {
      const faqsSet: { [x: string]: FaqInterface } = {}
      faqs.forEach((faq: FaqInterface) => (faqsSet[faq._id] = faq))

      const orderedFaqs: FaqInterface[] = []
      faqsOrder.forEach((faqId: string) => {
        const faq = faqsSet[faqId]
        if (faq) {
          orderedFaqs.push(faq)
        }
      })

      this.setState({ faqs: orderedFaqs.length ? orderedFaqs : faqs })
    })
  }

  public render() {
    const { className } = this.props
    const { faqs } = this.state
    if (!faqs || !faqs.length) {
      return null
    }
    return (
      <div>
        <Card
          className={className}
          customAttributes={{ 'data-test': 'faq-card', tabindex: '0' }}
        >
          <Flex column>
            {faqs.map(faq => {
              return <Faq key={faq._id} {...faq} />
            })}
          </Flex>
        </Card>
      </div>
    )
  }
}

const styledComponent = styled(Component)`
  ${helpers}
  &:focus {
    border: 1px solid ${p => p.theme.colors.original};
    outline: none;
  }
`

const FaqsCardDisplay = withTheme(styledComponent)

type StateProps = Pick<Props, 'actionId' | 'faqsOrder'>

const mapStateToProps = (state: RootState): StateProps => {
  const { actionId, faqsOrder } = currentViewParamsSelector(state)
  return { actionId, faqsOrder }
}
const mapDispatchToProps = {}

export const FaqsCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqsCardDisplay)
