import { getStore } from 'getStore'
import { trackEvent } from 'state/_sagas/_actions'
import { TrackedEventDestinations } from 'types'

// Note - only use when events can't be tracked via analyticsMiddleware
// i.e. Redux Action does not exist or is not specific enough
// Use caution if event is preceded or followed by state update

export const trackCustomEvent = (
  trackingName: string,
  properties: { [x: string]: any },
  destinations: TrackedEventDestinations[]
) => {
  const store = getStore()
  store.dispatch(
    trackEvent({
      trackingName,
      properties,
      updateLead: false,
      destinations
    })
  )
}
