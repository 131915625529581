import * as React from 'react'
import styled from 'styledComponents'

import { Flex } from 'components/_utility'
import { helpers, shadow } from 'style/mixins/'
import { HelpersProps } from 'types'

export interface Props extends HelpersProps {
  className?: string
  images: string[]
}

const Component = ({ className, images }: Props) => (
  <Flex
    row
    justifyContent="center"
    _wrap
    className={className}
    data-test="gallery-content"
  >
    {images.map((image, index) => (
      <Image
        key={index}
        index={index}
        target="_blank"
        href={image}
        style={{ backgroundImage: `url(${image})` }}
      />
    ))}
  </Flex>
)

export const Gallery = styled(Component)`
  ${helpers}
  padding: 3px 0;
`

interface ImageProps {
  index: number
}

const Image = styled('a')<ImageProps>`
  flex-shrink: 0;
  position: relative;
  width: 86px;
  height: 86px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
  margin-bottom: 6px;
  border-radius: 9px;

  ${shadow('light')}
`
