import { all, put, select, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { NAVIGATION, VIRTUAL_CARDS } from 'common/constants'
import { checkIfMarkRead, messageSent } from 'state/entities/conversations'
import { navigate } from 'state/router'
import {
  closeLauncher,
  navigateDashboardView,
  navigateView,
  playSound,
  setLauncherState,
  startAnimateTitle
} from 'state/ui'
import {
  isLauncherOpenSelector,
  isWidgetOpen,
  launcherSelector,
  rpPreviewModeSelector
} from 'state/ui/ui.selectors'
import { sizes } from 'style/variables'
import { SocketMessage } from 'types'

function* messageSentSaga(action: Action<SocketMessage>) {
  const { authorId, conversationId } = action.payload
  if (authorId !== 'client') {
    yield put(playSound())
    yield put(startAnimateTitle({ authorId }))
    yield put(checkIfMarkRead({ conversationId }))
  }

  const isOpen = yield select(isWidgetOpen)
  if (!isOpen) {
    if (sizes.isMobile) {
      yield put(
        navigate({
          viewId: NAVIGATION.CONVERSATIONS,
          params: { conversationId }
        })
      )
    } else {
      yield put(
        setLauncherState({ cardIds: [VIRTUAL_CARDS.MESSAGES._id], open: true })
      )
    }
  } else {
    const isLauncherOpen = yield select(isLauncherOpenSelector)
    if (isLauncherOpen) {
      const launcher = yield select(launcherSelector)
      const launcherCardIds = [
        VIRTUAL_CARDS.MESSAGES._id,
        ...launcher.cardIds.filter(
          (id: string) => id !== VIRTUAL_CARDS.MESSAGES._id
        )
      ]
      yield put(
        setLauncherState({ cardIds: launcherCardIds, hideOnMouseLeave: false })
      )
    }
  }
}

function* closeLauncherSaga() {
  const previewMode = yield select(rpPreviewModeSelector)
  if (previewMode !== 'none') {
    return
  }

  yield put(setLauncherState({ open: false }))
}

function* navigateViewSaga(action: ReturnType<typeof navigateView>) {
  const { viewId } = action.payload
  const previewMode = yield select(rpPreviewModeSelector)

  if (previewMode === 'viewsview') {
    yield put(navigateDashboardView({ viewId }))
    return
  }

  yield put(navigate({ viewId }))
}

export default function* uiSaga() {
  yield all([
    takeEvery(messageSent, messageSentSaga),
    takeEvery(closeLauncher, closeLauncherSaga),
    takeEvery(navigateView, navigateViewSaga)
  ])
}
