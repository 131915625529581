import * as React from 'react'
import styled, { withTheme } from 'styledComponents'

import {
  faFacebook,
  faGoogle,
  faTripadvisor,
  faYahoo,
  faYelp
} from '@fortawesome/free-brands-svg-icons'
import { faStar, faStarHalf, faBrowser } from '@fortawesome/pro-solid-svg-icons'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { Flex, Text } from 'components/_utility'

import { ReviewInterface, ThemeInterface } from 'types'

export interface Props {
  className?: string
  theme: ThemeInterface
  review: ReviewInterface
}

const ratingToStars = (rating: number) => {
  const stars = []
  let i = 0

  while (rating > 0) {
    const icon = rating === 0.5 ? faStarHalf : faStar
    stars.push(<Icon key={i} icon={icon} color="gold" />)
    rating--
    i++
  }
  return stars
}

const sourceToIcon = (source: string) => {
  return {
    facebook: { icon: faFacebook, color: '#3B5998' },
    google: { icon: faGoogle, color: '#DB4437' },
    yelp: { icon: faYelp, color: '#DB4437' },
    yahoo: { icon: faYahoo, color: '#4d00ae' },
    tripadvisor: { icon: faTripadvisor, color: '#589442' },
    default: { icon: faBrowser, color: '00000000' }
  }[source.toLowerCase()]
}

class Component extends React.Component<Props, { expanded: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  public toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  public render() {
    const {
      className,
      theme,
      review: { author, date, rating, source, body }
    } = this.props
    const reviewSites = ['facebook', 'google', 'yahoo', 'tripadvisor']
    const { icon, color } =
      reviewSites.indexOf(source.toLowerCase()) !== -1
        ? sourceToIcon(source)
        : sourceToIcon('default')

    return (
      <Flex column className={className} data-test="review">
        <Flex
          row
          justifyContent="space-between"
          alignItems="center"
          _width={100}
          mb={6}
        >
          {color !== '00000000' && (
            <Icon icon={icon as IconDefinition} size="2x" color={color} />
          )}
          {color === '00000000' && <Text>{source}</Text>}
          <Flex column alignItems="flex-end">
            <Flex row>
              <RatingText isBody mr={3}>
                {rating}
              </RatingText>
              {ratingToStars(rating)}
            </Flex>
            <Text italic isBody data-test="review-date">
              {date}
            </Text>
          </Flex>
        </Flex>
        <Text inline isBody pointer onClick={this.toggleExpand}>
          {`${body
            .substring(0, this.state.expanded ? body.length : 100)
            .trim()}`}
          {!this.state.expanded && body.length > 100 && (
            <Text pointer inline isBody bold color={theme.colors.original}>
              &nbsp;...
            </Text>
          )}
        </Text>
        <Text isBody italic align="right" color={theme.gray.light}>
          —{author}
        </Text>
      </Flex>
    )
  }
}

const RatingText = styled(Text)`
  width: 20px;
  text-align: right;
`

const StyledComponent = styled(Component)`
  &:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: solid 1px ${p => p.theme.gray.lighter};
  }
`

export const Review = withTheme(StyledComponent)
