import { Lead, LeadState } from 'types'

export const mapLead = (leadData: Partial<Lead>): Partial<LeadState> => {
  const lead: Partial<LeadState> = {
    _id: leadData._id,
    firstName: leadData.firstName,
    lastName: leadData.lastName,
    email: leadData.clientEmail,
    phone: leadData.clientPhone,
    inquiryTypeId: leadData.inquiryTypeId
  }

  if (leadData.vehicle) {
    lead.vehicleMake = leadData.vehicle.make
    lead.vehicleModel = leadData.vehicle.model
    lead.vehicleYear = leadData.vehicle.year
    lead.vehicleVin = leadData.vehicle.vin
  }

  return lead
}
