import { connect } from 'react-redux'

import { sortedConversationIdsSelector } from 'state/entities/conversations'
import { currentViewIdSelector } from 'state/router'
import { homeViewIdSelector } from 'state/ui'
import { RootState } from 'types'
import { GreetingDisplay } from './Greeting.display'

const mapStateToProps = (state: RootState) => ({
  isShown:
    sortedConversationIdsSelector(state).length === 0 ||
    homeViewIdSelector(state) !== currentViewIdSelector(state)
})

export const Greeting = connect(mapStateToProps)(GreetingDisplay)
