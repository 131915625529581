import * as moment from 'moment'
import { DatetimeInterface } from 'types'

export const getDatetimeOptions = (times: {
  [date: string]: string[]
}): DatetimeInterface[] => {
  return Object.keys(times)
    .sort()
    .map(date => ({
      date,
      times: times[date].map(time => time),
      dayOfTheWeek: moment(date, 'YYYY-MM-DD').format('ddd')
    }))
}
