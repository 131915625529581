import * as React from 'react'
import styled, { css } from 'styledComponents'

import { Avatar, Flex } from 'components/_utility'

export interface Props {
  className?: string
  children: React.ReactNode
  avatar: string
  type: string
}

const Component = ({ className, children, type, avatar }: Props) => (
  <Flex row className={className} alignItems="flex-end" mb={15}>
    {type === 'user' && <Avatar src={avatar} />}
    <Flex column shrink grow ml={type === 'user' ? 8 : undefined}>
      {children}
    </Flex>
  </Flex>
)

export const BubbleCluster = styled(Component)<Props>`
  ${p => {
    return css`
      ${p.type === 'user' && `justify-content: flex-start;`}
      ${p.type === 'client' &&
        `
        justify-content: flex-end;
      `}

      > ${Flex} {
        width: 0;
        ${p.type === 'user' && `align-items: flex-start;`}
        ${p.type === 'client' && `align-items: flex-end;`}
      }
    `
  }}
`
