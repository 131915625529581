import * as React from 'react'
import styled from 'styledComponents'

import { Button, Card, Flex, Spinner, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import { ActionButtonActionInterface, HelpersProps } from 'types'

import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import * as moment from 'moment'

import kbbImage from './kbb.png'

export interface Props extends HelpersProps {
  className?: string
  vehicleSummary?: {
    make: string
    model: string
    year: number
    trim: string
    price: number
    standardOptions: string
  }
  actionButtons?: ActionButtonActionInterface[]
}

const Component = ({ className, vehicleSummary, actionButtons }: Props) => {
  const [showDisclaimer, setShowDisclaimer] = React.useState(false)

  const disclaimer = `
    © ${moment().format('YYYY')} Kelley Blue Book Co., Inc. All rights reserved.
    ${moment().format('M/D/YYYY')} Edition for 93101.
    Values and pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may vary.
    The value and pricing information displayed for a particular vehicle is based upon the specification,
    mileage and/or condition information provided by the person generating this report.
    Kelley Blue Book assumes no responsibility for errors or omissions.
  `

  if (!vehicleSummary)
    return (
      <Flex _p={48} column alignItems="center">
        <Spinner size="2x" />
      </Flex>
    )

  return (
    <Card
      className={className}
      actionButtons={actionButtons}
      actionButtonsDisplay={{ padding: 'top' }}
    >
      {vehicleSummary.price === 0 ? (
        <React.Fragment>
          <Flex column justifyContent="space-between">
            <Text isTitle>Info Currently Unavailable</Text>
            <Text isBody>
              One or more data points are missing so Kelley Blue Book cannot
              provide price.
            </Text>
            <Text isBody>Please check back soon.</Text>
          </Flex>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Flex row>
            <Flex column _width={84}>
              <Flex column mb={12}>
                <Text isTitle>Kelley Blue Book® Trade-In Value:</Text>
                <Text
                  isHero
                >{`$${vehicleSummary.price.toLocaleString()}`}</Text>
              </Flex>
            </Flex>
            <Flex column grow justifyContent="flex-start">
              <img src={kbbImage} />
            </Flex>
          </Flex>
          <Flex row justifyContent="space-between" alignItems="flex-end">
            <div>
              <Text isTitle>Vehicle:</Text>
              <Text isBody>
                {vehicleSummary.year} {vehicleSummary.make}{' '}
                {vehicleSummary.model} <br />
                {vehicleSummary.trim}
              </Text>
            </div>
            <div onClick={() => setShowDisclaimer(true)}>
              <InfoCircle icon={faInfoCircle} size="lg" />
            </div>
            <Disclaimer
              row
              alignItems="flex-start"
              pointer
              display={showDisclaimer ? 'flex' : 'none'}
              onClick={() => setShowDisclaimer(false)}
            >
              <Text isFootnote isJustified>
                <p>{disclaimer}</p>
                <p>{`Standard equipment for this vehicle: ${vehicleSummary.standardOptions}`}</p>
              </Text>
              <Button>
                <Icon icon={faTimes} />
              </Button>
            </Disclaimer>
          </Flex>
        </React.Fragment>
      )}
    </Card>
  )
}

export const Step4 = styled(Component)`
  ${helpers}
  position: relative;

  img {
    width: 44px;
    height: 78px;
  }
`

const InfoCircle = styled(Icon)`
  cursor: pointer;
  margin-right: 12px;
  color: ${p => p.theme.gray.light};
`

const Disclaimer = styled(Flex)`
  padding: 18px 0
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: white;

  ${Text} {
    height: 100%;
    overflow: auto;
    padding: 0 0 0 24px;
  }

  ${Button} {
    flex-shrink: 0;
    margin-right: 6px;
  }
`
