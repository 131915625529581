import styled, { css } from 'styledComponents'

import { helpers } from 'style/mixins/'
import { HelpersProps } from 'types'

export interface Props extends HelpersProps {
  row?: boolean
  column?: boolean
  justifyContent?: string
  alignItems?: string
  alignContent?: string
  grow?: boolean
  shrink?: boolean
  _wrap?: boolean
  hidden?: boolean
  basis?: string
}

export const Flex = styled('div')<Props>`
  display: flex;
  ${helpers}

  ${p => css`
    ${p.row && `flex-direction: row`};
    ${p.column && `flex-direction: column`};
    ${p.justifyContent && `justify-content: ${p.justifyContent}`};
    ${p.alignItems && `align-items: ${p.alignItems}`};
    ${p.alignContent && `align-content: ${p.alignContent}`}
    ${p.grow && `flex-grow: 1`};
    ${p.shrink ? `flex-shrink: 1` : `flex-shrink: 0`};
    ${p._wrap && `flex-wrap: wrap`};
    ${p.hidden && `visibility: hidden;`};
    ${p.basis && `flex-basis: ${p.basis}px`}
  `}
`
