import * as React from 'react'

import { lighten } from 'polished'
import { helpers } from 'style/mixins'
import styled, { css, withTheme } from 'styledComponents'
import { HelpersProps, InjectedFieldProps, ThemeInterface } from 'types'

import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Flex } from 'components/_utility'
import { sizes } from 'style/variables'

export interface Props extends InjectedFieldProps, HelpersProps {
  className?: string
  theme: ThemeInterface
  name: string
  placeholder?: string
  search?: boolean
  onSearchClick?: (x: any) => void
  disabled?: boolean
}

const Component = ({
  className,
  theme,
  name,
  placeholder,
  search,
  onSearchClick,
  disabled,
  value,
  status,
  updateField
}: Props) => {
  const onChange = (e: React.SyntheticEvent) => {
    updateField({
      [name]: {
        value: (e.target as HTMLInputElement).value
      }
    })
  }

  const onBlur = () => {
    updateField({
      [name]: {
        dirty: true
      }
    })
  }

  if (search) {
    status = undefined
  }

  return (
    <Flex className={className} row relative>
      <StyledInput
        name={name}
        value={value as string}
        status={status}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
      {status === 'invalid' && <Icon icon={faTimes} color={theme.red} />}
      {status === 'valid' && <Icon icon={faCheck} color={theme.green} />}

      {// no type definition for onClick in font awesome package
      search && (
        // @ts-ignore
        <Icon
          icon={faSearch}
          color={
            value && (value as string).length
              ? theme.colors.light
              : theme.colors.lighter
          }
          onClick={onSearchClick}
        />
      )}
    </Flex>
  )
}

const StyledInput = styled('input')<{ status?: string }>`
  ${helpers}
  padding: 8px 12px;
  border-radius: 6px;

  font-size: 14px;

  transition: border-color 0.25s;

  ${p => (p.disabled ? 'cursor: not-allowed;' : '')}

  ${p => {
    return css`
      background-color: ${p.theme.colors.lightest}
      color: ${p.theme.gray.dark}
      border: solid 2px ${p.theme.colors.lighter}
      ${p.status === 'invalid' && `border-color: ${lighten(0.25, p.theme.red)}`}
      ${p.status === 'valid' && `border-color: ${lighten(0.25, p.theme.green)}`}
    `
  }}

  &:focus {
    outline: none;

    ${p => !p.status && `border-color: ${p.theme.colors.light};`}
  }

  &::placeholder {
    color: ${p => p.theme.gray.lighter};
  }
`

const StyledComponent = styled(Component)`
  ${sizes.isMobile
    ? `
    transform: scale(1, 0.875);
    transform-origin: left top;
    overflow: hidden;
  `
    : ''}

  input {
    width: 100%;
    ${sizes.isMobile ? 'font-size: 16px;' : ''}
    ${p =>
      p.search || p.status === 'valid' || p.status === 'invalid'
        ? `padding-right: 33px;`
        : ``};
  }

  svg {
    ${sizes.isMobile ? 'font-size: 18px;' : ''}
    position: absolute;
    top: 10px;
    right: 12px;
    transition: all 0.25s;

    ${p =>
      p.search &&
      `
      &:hover {
        cursor: pointer;
      }
    `}
  }
`

export const Input = withTheme(StyledComponent)
