export const transformVideoUrl = (url: string) => {
  const youtubeMatches = url.match(
    /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|youtu\.be\/([a-zA-Z0-9_-]+)/
  )
  if (youtubeMatches) {
    const youtubeVideoId = youtubeMatches[1] || youtubeMatches[2]
    return `https://www.youtube.com/embed/${youtubeVideoId}?showinfo=0&controls=0&rel=0`
  }
  return url
}
