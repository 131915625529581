import * as React from 'react'
import styled from 'styledComponents'

import { helpers } from 'style/mixins'
import { HelpersProps } from 'types'

interface Props extends HelpersProps {
  className?: string
  asterisk?: boolean
  children: React.ReactNode
}

const Component = ({ className, children }: Props) => (
  <label className={className}>{children}</label>
)

export const Label = styled(Component)`
  margin-bottom: 3px;
  font-size: 13px;
  ${helpers}
  color: ${p => p.theme.colors.original};
  ${p =>
    p.asterisk &&
    `
    &:after {
      content: '*'
    }
  `}
`
