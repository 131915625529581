import { CHATBOT_FORM_MAIN_FIELDS, EXTRA_FIELDS_KEY } from 'common/constants'
import {
  CardInterface,
  CheckboxInterface,
  FormInterface,
  ContactFormFieldType
} from 'types'
import chatbotFormFieldConverter from './chatbotFormFieldConverter'

interface ExtraFieldInterface {
  _id: string
  label: string
  value: {
    text?: string | number | string[]
    inquiryTypeId?: string
    companyId?: string
  }
}

interface CardCheckboxInterface {
  _id: string
  type: string
  label: string
  required?: boolean
  consent?: boolean
  options: CheckboxInterface[]
}

export const getFormDataToSubmit = (
  form: FormInterface,
  card: CardInterface
) => {
  const dataToSubmit: any = {
    [EXTRA_FIELDS_KEY]: [],
    inquiryTypeId: card.props.inquiryTypeId,
    cardId: card._id
  }

  let extraFields: ExtraFieldInterface[] = []
  Object.keys(form).forEach(formFieldName => {
    const fieldData = form[formFieldName]
    const value =
      fieldData.value && fieldData.label ? fieldData.label : fieldData.value

    if (formFieldName === ContactFormFieldType.STATE) {
      dataToSubmit[formFieldName] = `${fieldData.value}`
      return
    }

    if (CHATBOT_FORM_MAIN_FIELDS.includes(formFieldName)) {
      dataToSubmit[formFieldName.replace('Dropdown', '')] = `${value}`
      return
    }

    const { fieldId, fieldLabel } = chatbotFormFieldConverter.parseFieldName(
      formFieldName
    )

    extraFields.push({
      _id: fieldId,
      label: fieldLabel,
      value: {
        text: value,
        inquiryTypeId: fieldData.inquiryTypeId,
        companyId: fieldData.companyId
      }
    })
  })

  const checkboxFields: CardCheckboxInterface[] = []
  card.props.formData.rows.forEach((row: any) => {
    row.columns.forEach((field: any) => {
      if (field.type === 'checkboxes') {
        checkboxFields.push(field)
      }
    })
  })

  checkboxFields.forEach(({ _id, label }) => {
    const value = extraFields
      .filter(field => field._id === _id && field.value.text === 'true')
      .map(field => field.label)
      .join(', ')

    extraFields = extraFields.filter(field => field._id !== _id)
    extraFields.push({
      _id,
      label,
      value: {
        text: value
      }
    })
  })

  dataToSubmit[EXTRA_FIELDS_KEY] = extraFields.filter(field => field.value.text)
  /* CLEAR EMPTY FIELDS */
  Object.keys(dataToSubmit).forEach(submitKey => {
    if (submitKey === EXTRA_FIELDS_KEY) {
      dataToSubmit[EXTRA_FIELDS_KEY] = dataToSubmit[EXTRA_FIELDS_KEY].filter(
        (extraField: any) => extraField.value
      )
    } else if (!dataToSubmit[submitKey]) {
      delete dataToSubmit[submitKey]
    }
  })

  return dataToSubmit
}
