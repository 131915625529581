import * as React from 'react'
import styled, { css } from 'styledComponents'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPhoneSlash } from '@fortawesome/pro-solid-svg-icons/faPhoneSlash'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { VideoInterface } from 'types'

import { Flex, Text, Button } from 'components/_utility'

export interface Props extends VideoInterface {
  className?: string
  header: string
  description: string
  messageId: string
  joinCall: (messageId: string) => void
}

const ParticipantImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 6px;
`

const IconWrapper = styled(Flex)<{ status: string }>`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.original};
  margin-right: 10px;
  color: white;
  border-radius: 5px;

  ${p => {
    return css`
      ${p.status === 'declined' && `background-color: ${p.theme.gray.light};`}
      ${p.status === 'ended' && `background-color: ${p.theme.gray.light};`}
    `
  }}
`

const DescriptionWrapper = styled(Flex)`
  padding: 10px;
  border-bottom: 1px solid ${p => p.theme.gray.lighter};
`

const ParticipantIconWrapper = styled(Flex)`
  width: 40px;
  height: 40px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  color: ${p => p.theme.gray.light};
  border: 1px solid ${p => p.theme.gray.lighter};
`

export const Component: React.SFC<Props> = ({
  className,
  header,
  description,
  participants,
  status,
  messageId,
  joinCall
}: Props) => {
  const participantsBlock = participants
    .filter(p => (status === 'active' ? p.status === 'active' : true))
    .map(p => {
      return p.photo ? (
        <ParticipantImage
          key={p._id}
          src={p.photo}
          title={`${p.firstName} ${p.lastName}`}
        />
      ) : (
        <ParticipantIconWrapper
          title={p.type === 'client' ? 'You' : `${p.firstName} ${p.lastName}`}
          key={p._id}
        >
          <Icon icon={faUser} />
        </ParticipantIconWrapper>
      )
    })

  const icon = React.useMemo(() => {
    switch (status) {
      case 'declined':
        return faPhoneSlash
      default:
        return faPhone
    }
  }, [status])

  const isJoinButton =
    status === 'active' &&
    participants.find(p => p.type === 'client' && p.status === 'inactive')
  return (
    <Flex className={className} column>
      <DescriptionWrapper>
        <IconWrapper status={status}>
          <Icon icon={icon} />
        </IconWrapper>
        <Flex column justifyContent="center" shrink>
          <Text bold>{header}</Text>
          <Text fs={14}>{description}</Text>
        </Flex>
      </DescriptionWrapper>
      <Flex _p={10}>
        <Flex _width={100} justifyContent="space-between" row>
          <Flex>{participantsBlock}</Flex>
          {isJoinButton && (
            <Flex>
              <Button primary onClick={() => joinCall(messageId)}>
                Join
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export const CommonMessageDisplay = styled(Component)`
  border: 1px solid ${p => p.theme.gray.lighter};
  border-radius: 6px;
  background-color: ${p => p.theme.gray.lightest};
  margin-bottom: 6px;
`
