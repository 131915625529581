export interface AdobeTagInterface {
    enabled: boolean;
    srcUrl? : string;
}

export interface AdobeSatelliteData {
    visitorID : string;
    toolProvider?: string;
    duration?: string;
    chatShows?: boolean;
    hoursDir?: boolean;
}

export enum AdobeSatelliteEvents {
    CHAT_STARTED = 'chat-init',
    CHAT_ENDED = 'chat-end',
    CHAT_SHOWS = 'chat-shows',
    HOURS_DIRECTIONS = 'hours-directions'
}

export enum AdobeSatelliteMetrics {
    visitorID = 'eVar113',
    toolProvider = 'eVar114',
    duration = 'eVar114',
    chatShows = 'event316',
    hoursDir = 'event22',
    chatInit = 'event81'
}

export interface AdobeSatellite {
    track(event: AdobeSatelliteEvents, data?: AdobeSatelliteData) : void;
    setVar(name: string, data: any): void;
}