import { transparentize } from 'polished'
import styled, { css } from 'styledComponents'
import { HelpersProps } from 'types'
import { helpers } from '../../../style/mixins'

interface Props extends HelpersProps {
  inline?: boolean
  isHeader?: boolean
  isTitle?: boolean
  isSubtitle?: boolean
  isBody?: boolean
  isFootnote?: boolean
  isHero?: boolean
  truncated?: boolean
  color?: string
  transparent?: boolean
  shadow?: boolean
  thin?: boolean
  semibold?: boolean
  bold?: boolean
  italic?: boolean
  align?: string
  underline?: boolean
  capitalize?: boolean
  isJustified?: boolean
  breakWord?: boolean
}

export const Text = styled('div')<Props>`
  line-height: 1.4;
  font-size: 15px;
  ${helpers}

  a {
    color: ${p => (p.isFootnote ? p.theme.gray.light : p.theme.colors.light)};
  }

  ${p => {
    return css`
      color: ${p.theme.gray.dark};
      ${p.inline && `display: inline-block`};
      ${p.isHeader &&
        `
        font-size: 24px;
        line-height: 1.1;
        font-family: ${p.theme.isCadillacTheme?"CadillacGothicWideBold":"Roboto"};
      `}
      ${p.truncated &&
        `
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      `}
      ${p.isTitle &&
        `
        font-weight: 600;
      `};
      ${p.isSubtitle &&
        `
        font-weight: 400;
      `};
      ${p.isBody &&
        `
        font-size: 14px;
        color: ${p.theme.gray.light}
      `};
      ${p.isFootnote &&
        `
        font-size: 11px;
        line-height: 1.2;
        color: ${p.theme.gray.light}
      `};
      ${p.isJustified &&
        `
        text-align: justify;
      `}

      ${p.isHero &&
        `
        font-size: 24px;
        color: ${p.theme.colors.original}
        line-height: 1.1;
      `};
      ${p.color && `color: ${p.color}`};
      ${p.transparent && `opacity: 0.8`};
      ${p.shadow && `text-shadow: 0 1px 2px ${transparentize(0.8, 'black')}`};
      ${p.thin && `font-weight: 300`};
      ${p.semibold && `font-weight: 500`};
      ${p.bold && `font-weight: 700`};
      ${p.italic && `font-style: italic`};
      ${p.align && `text-align: ${p.align}`};
      ${p.capitalize && `text-transform: capitalize`};
      ${p.underline && `text-decoration: underline`};
      ${p.breakWord && `word-wrap: break-word`};
      `
  }}
`
