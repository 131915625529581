import { CardInterface } from 'types'

export const getCardHeaderFromCard = (
  card: CardInterface,
  isLast?: boolean
) => {
  if (card.header.alwaysShow || 'index' in card.props) return card.header
  if (
    (card.props.collapsed || card.props.submitted || isLast === false) &&
    card.template === 'form'
  ) {
    return {
      title: 'Details',
      status: 'complete'
    }
  }

  return undefined
}
