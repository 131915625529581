import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styledComponents'

import { Card } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  HelpersProps
} from 'types'
import { getCardFromTree } from 'utility'

import { Step1, Step2Finance, Step2Lease, Step3 } from './'

export interface Props extends HelpersProps {
  className?: string
  _id: string
  fields?: string[]
  collapsed?: boolean
  header: CardHeaderProps
  actionButtons: ActionButtonActionInterface[]
  length: number
  index: number
  setIndex: (index: number) => void
  paymentVariant: string
}

const Component = ({
  className,
  _id,
  collapsed = false,
  header,
  index,
  setIndex,
  paymentVariant
}: Props) => {
  const incrementIndex = () => setIndex(index + 1)

  const VariantCard = () => <Step1 key={0} cardId={_id} />
  const FinanceCard = () => <Step2Finance key={1} submit={incrementIndex} />
  const LeaseCard = () => <Step2Lease key={2} submit={incrementIndex} />
  const SummaryCard = () => <Step3 key={3} />

  const decisionTree = {
    component: VariantCard,
    nodes: [
      {
        condition: paymentVariant === 'finance',
        component: FinanceCard,
        nodes: [{ component: SummaryCard }]
      },
      {
        condition: paymentVariant === 'lease',
        component: LeaseCard,
        nodes: [{ component: SummaryCard }]
      },
      {
        condition: paymentVariant === 'cash',
        component: SummaryCard
      }
    ]
  }

  const { CurrentCard, hasNext } = getCardFromTree(decisionTree, index + 1)

  const currentHeader = {
    ...header,
    onPrevious: index > 0 ? () => setIndex(index - 1) : undefined,
    onNext: hasNext ? () => setIndex(index + 1) : undefined
  }

  return (
    <Card
      className={className}
      header={currentHeader}
      collapsed={collapsed}
      padding="none"
    >
      <CSSTransition
        classNames="card-step"
        key={index}
        timeout={120}
        appear={true}
        in={true}
      >
        <CurrentCard />
      </CSSTransition>
    </Card>
  )
}

export const PaymentDriverCardDisplay = styled(Component)`
  ${helpers}

  ${Card} {
    background: none;
    box-shadow: none;
    border-radius: none;
  }

  /* .card-step-appear {
    opacity: 0;
  }

  .card-step-enter-done {
    transition: opacity 420ms;
  } */
`
