import {
  getReviewsAction,
  initializeReviews,
  logAction,
  showToast
} from '../_actions'

import { put, takeEvery } from 'redux-saga/effects'
import { getReviewsSites } from 'state/entities/cards/cards.actions'
import { Action } from 'typescript-fsa'

function* saga(action: Action<{ cardId: string }>) {
  try {
    const { cardId } = action.payload
    yield put(getReviewsAction({ index: 1, cardId }))
    yield put(getReviewsSites({ cardId }))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(
      showToast({ message: 'Failed to retrieve reviews', variant: 'error' })
    )
  }
}

export function* initializeReviewSaga() {
  yield takeEvery(initializeReviews, saga)
}
