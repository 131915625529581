import { connectField } from 'components/_hoc'
import { FormFieldType } from 'types'

export { Label } from './Label.display'

import { Input } from './Input.display'
export const ConnectedInput = connectField(Input, { type: FormFieldType.TEXT })

import { MaskedInput } from './MaskedInput.display'
export const ConnectedMaskedInput = connectField(MaskedInput, {
  type: FormFieldType.TEXT
})

import { Textarea } from './Textarea.display'
export const ConnectedTextarea = connectField(Textarea, {
  type: FormFieldType.TEXTAREA
})

import { Select } from './Select.display'
export const ConnectedSelect = connectField(Select, {
  type: FormFieldType.SELECT
})

import { Radio } from './Radio.display'
export const ConnectedRadio = connectField(Radio, { type: FormFieldType.RADIO })

import { Checkbox } from './Checkbox.display'
export const ConnectedCheckbox = connectField(Checkbox, {
  type: FormFieldType.CHECKBOX
})

import { Slider } from './Slider.display'
export const ConnectedSlider = connectField(Slider, {
  type: FormFieldType.SLIDER
})

import { CustomSelect } from './CustomSelect.display'
export const ConnectedCustomSelect = connectField(CustomSelect, {
  type: FormFieldType.SELECT
})

export { SubmitButtonContainer as SubmitButton } from './SubmitButton/SubmitButton.container'
