import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { RootState } from 'types'

import {
  leaveVideoCall,
  expandVideo,
  collapseVideo,
  expandedModeSelector,
  toggleMobileShrink
} from 'state/video'
import { ControlsDisplay, Props } from './Controls.display'

type StateProps = Pick<Props, 'expandedVideoMode'>
const mapStateToProps = (state: RootState): StateProps => {
  return {
    expandedVideoMode: expandedModeSelector(state)
  }
}

type DispatchProps = Pick<
  Props,
  | 'leaveCall'
  | 'expandVideoScreen'
  | 'collapseVideoScreen'
  | 'toggleMobileShrink'
>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  leaveCall: () => {
    dispatch(leaveVideoCall())
  },
  expandVideoScreen: () => {
    dispatch(expandVideo())
  },
  collapseVideoScreen: () => {
    dispatch(collapseVideo())
  },
  toggleMobileShrink: () => {
    dispatch(toggleMobileShrink())
  }
})

export const ControlsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlsDisplay)
