import * as React from 'react'
import styled, { css } from 'styledComponents'

import { lighten, darken } from 'polished'
import { InjectedFieldProps } from 'types'

interface Props extends InjectedFieldProps {
  className?: string
  name: string
  option: string
  value: string
  label?: string
  disabled?: boolean
}

const Component = ({
  className,
  name,
  label,
  option,
  value,
  updateField,
  disabled,
  status
}: Props) => {
  const onChange = (e: React.SyntheticEvent) => {
    updateField({
      [name]: {
        label,
        value: (e.target as HTMLInputElement).value,
        dirty: true
      }
    })
  }

  return (
    <div className={className}>
      <div className="pretty p-default p-round p-pulse">
        <input
          type="radio"
          name={name}
          value={option}
          onChange={onChange}
          checked={value === option}
          disabled={disabled}
        />
        <div className="state p-primary">
          <label data-test={status}>{label}</label>
        </div>
      </div>
    </div>
  )
}

export const Radio = styled(Component)<Props>`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 14px;

  .pretty input:focus ~ .state.p-primary label:before {
    background-color: ${p => darken(0.2, p.theme.colors.original)} !important;
  }

  .pretty input:checked ~ .state.p-primary label:after {
    background-color: ${p => p.theme.colors.original} !important;
  }

  .pretty .state > label {
    margin-top: 0.6px;
    color: ${p => p.theme.gray.dark};

    &:before {
      ${p => {
        return css`
          ${p.status === 'invalid' &&
            `border-color: ${lighten(0.1, p.theme.red)}`}
          ${p.status === 'valid' &&
            `border-color: ${lighten(0.1, p.theme.green)}`}
        `
      }}
    }
  }
`
