import { path } from 'ramda'
import { createSelector } from 'reselect'

import { RootState } from 'state/reducer'

import { CardSequenceViewInterface, LauncherType } from 'types'
import { getMappedViewCards } from 'utility'

export const themeSelector = createSelector(
  (state: RootState) => state.ui.theme,
  theme => theme
)

export const webMoveUpSelector = createSelector(
  (state: RootState) => state.ui.webMoveUp,
  webMoveUp => webMoveUp
)

export const mobileMoveUpSelector = createSelector(
  (state: RootState) => state.ui.mobileMoveUp,
  mobileMoveUp => mobileMoveUp
)

export const navButtonsSelector = createSelector(
  (state: RootState) => state.ui.navButtons,
  navButtons => navButtons
)

export const navSelector = createSelector(
  (state: RootState) => state.ui.nav,
  (state: RootState) => state.ui.launcher.type,
  navButtonsSelector,
  (nav, launcherType, navButtons) =>
    (nav && !!navButtons.length) || launcherType === LauncherType.BAR
)

export const launcherSelector = createSelector(
  (state: RootState) => state.ui.launcher,
  launcher => launcher
)

export const launcherCardsSelector = createSelector(
  (state: RootState) => state.ui.launcher.cardIds,
  (state: RootState) => state.entities.cards,
  (launcherCardIds, cards) => {
    return getMappedViewCards(launcherCardIds, cards)
  }
)

export const windowSelector = createSelector(
  (state: RootState) => state.ui.window,
  window => window
)

export const toastSelector = createSelector(
  (state: RootState) => state.ui.toast,
  toast => toast
)

export const progressSelector = createSelector(
  (state: RootState, viewId: string) => state.entities.views.byId[viewId],
  (cardSequence: CardSequenceViewInterface) =>
    cardSequence ? cardSequence.progress : 0
)

export const toolbarModeSelector = createSelector(
  (state: RootState) => state.ui.toolbarMode,
  toolbarMode => toolbarMode
)

export const isToolbarEnabledSelector = createSelector(
  (state: RootState) => state.ui.behavior.toolbarMode,
  toolbarMode => toolbarMode
)

export const behaviorSelector = createSelector(
  (state: RootState) => state.ui.behavior,
  behavior => behavior
)

export const isTranslatorDisabledSelector = createSelector(
  (state: RootState) => state.ui.behavior.disableTranslator,
  disableTranslator => disableTranslator
)

export const homeViewIdSelector = createSelector(
  (state: RootState) => state.ui.homeViewId,
  homeViewId => homeViewId
)

export const defaultViewIdSelector = createSelector(
  homeViewIdSelector,
  isToolbarEnabledSelector,
  navButtonsSelector,
  (homeViewId, isToolbarEnabled, navButtons) =>
    isToolbarEnabled ? path([0, 'viewId'], navButtons) : homeViewId
)

// Conversation
export const playSoundSelector = createSelector(
  (state: RootState) => state.ui.behavior.newMessage.sound,
  (state: RootState) => state.ui.soundIsPlaying,
  (soundEnabled, soundIsPlaying) => soundEnabled && soundIsPlaying
)

export const tabFlashSelector = createSelector(
  (state: RootState) => state.ui.behavior.newMessage.tabFlash,
  tabFlash => tabFlash
)

export const titleSelector = createSelector(
  (state: RootState) => state.ui.title,
  title => title
)

export const exitTriggerAllowedSelector = createSelector(
  (state: RootState) => state.ui.isExitTriggerAllowed,
  isExitTriggerAllowed => isExitTriggerAllowed
)

export const isWidgetOpen = createSelector(
  (state: RootState) => state.ui.window,
  (state: RootState) => state.ui.launcher.open,
  (isWindowOpen, isLauncherOpen) => isWindowOpen || isLauncherOpen
)

export const isLauncherOpenSelector = (state: RootState) =>
  state.ui.launcher.open

export const isWideSelector = createSelector(
  (state: RootState) => state.ui.window,
  (state: RootState) => state.ui.launcher,
  (state: RootState) => state.ui.nav,
  (window, launcher, nav) =>
    window ||
    launcher.type === LauncherType.BAR ||
    nav ||
    (launcher.open && !!launcher.cardIds.length)
)

export const initialCardIdsSelector = (state: RootState) =>
  state.ui.launcher.initialCardIds || []

// *********************** PREVIEW MODE SELECTORS ***********************************

export const rpPreviewModeSelector = createSelector(
  (state: RootState) => state.ui.rppreview,
  rppreview => rppreview
)

export const wipViewIdSelector = createSelector(
  (state: RootState) => state.ui.wipViewId,
  wipViewId => wipViewId
)

export const wipWebdeskSelector = createSelector(
  (state: RootState) => state.ui.homeViewId,
  (state: RootState) => state.ui.navButtons,
  (state: RootState) => state.ui.behavior,
  (state: RootState) => state.ui.launcher.type,
  (state: RootState) => state.ui.launcher.cardIds,
  (state: RootState) => state.ui.theme.colors,
  (state: RootState) => state.ui.theme.navColors,
  (state: RootState) => state.ui.appearance.position,
  (
    homeViewId,
    navButtons,
    behavior,
    launcherType,
    launcherCardIds,
    themeColors,
    navColors,
    position
  ) => ({
    navigation: {
      homeViewId,
      navButtons
    },
    behavior,
    launcher: {
      desktop: launcherType,
      cardIds: launcherCardIds
    },
    appearance: {
      color: themeColors.original.split('#')[1],
      navColor: navColors.original.split('#')[1],
      position
    }
  })
)

export const webdeskPositionSelector = createSelector(
  (state: RootState) => state.ui.appearance.position,
  position => position
)

export const webdeskHorizontalPaddingSelector = createSelector(
  (state: RootState) => state.ui.appearance.horizontalPadding,
  horizontalPadding => horizontalPadding
)

export const webdeskVerticalPaddingSelector = createSelector(
  (state: RootState) => state.ui.appearance.verticalPadding,
  verticalPadding => verticalPadding
)
