import * as React from 'react'
import styled from 'styledComponents'

import { Card, Flex, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import { CardHeaderProps, HelpersProps } from 'types'

export interface Props extends HelpersProps {
  className?: string
  _id: string
  facebookPageName: string
  facebookPageExists: boolean
  header?: CardHeaderProps
  collapsed?: boolean
  navigate: (currentView: string) => void
}

const Component = ({
  className,
  collapsed = false,
  header,
  facebookPageName,
  facebookPageExists
}: Props) => {
  const pageNameCleaned = facebookPageName
    .toLowerCase()
    .split(/(\s+)/)
    .reduce((acc, curr) => (acc += curr.trim()), '')
  const fBSource = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${pageNameCleaned}%2F&tabs=timeline&small_header=false&width=300&height=609&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=180957852691805`
  
  return (
    <Card
      className={className}
      header={header}
      collapsed={collapsed}
      padding="3px 18px 0px 18px"
    >
      <Flex
        row
        alignItems="center"
        justifyContent="center"
        _width={100}
        _height={100}
      >
        {facebookPageExists && (
          <iframe
            id="facebook-iframe"
            src={fBSource}
            width="300"
            height="609"
            style={{ border: 'none', overflow: 'hidden' }}
            scrolling="no"
            allowTransparency
            allow="encrypted-media"
          />
        )}
        {!facebookPageExists && (
          <Flex
            className="errorMessage"
            column
            justifyContent="flex-start"
            alignItems="center"
          >
            <Text pt={50} pr={18} pl={18} align="center">
              A Facebook page with the specified username does not exist
            </Text>
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

export const FacebookFeedCardDisplay = styled(Component)`
  ${helpers}

  .errorMessage {
    width: 336px;
    height: 609px;
  }
`
