import { connect } from 'react-redux'

import { VideoCardDisplay } from './VideoCard.display'

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export const VideoCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoCardDisplay)
