import actionCreatorFactory, { Action } from 'typescript-fsa'

import { RouterState } from 'state/router'
import { UIState } from 'state/ui'
import {
  AppointmentInterface,
  FormFieldType,
  LeadState,
  TrackedEventInterface,
  VehicleDropdownParamsInterface
} from 'types'

const actionCreator = actionCreatorFactory()

export const initializeDesk = actionCreator('sagas/INITIALIZE_DESK_REQUESTED')
export const initializeVehicleForm = actionCreator<{
  formId: string
  vehicleDropdownParams: VehicleDropdownParamsInterface
}>('sagas/INITIALIZE_VEHICLE_INFORMATION_FORM')

export const initializeDeskSuccess = actionCreator<{
  webdesk: any
  views: any
  cards: any
  lastConversationId: string
}>('sagas/INITIALIZE_DESK_SUCCESS')
export const initializeDeskFail = actionCreator('sagas/INITIALIZE_DESK_FAIL')

export const trackEvent = actionCreator<TrackedEventInterface>(
  'sagas/TRACK_EVENT'
)

export const showToast = actionCreator<{
  variant: string
  message: string
  duration?: number
}>('sagas/SHOW_TOAST')

export const initializeTradeIn = actionCreator('sagas/INITIALIZE_TRADE_IN')
export const updateTradeInStep1 = actionCreator<{}>(
  'sagas/UPDATE_TRADE_IN_STEP_1'
)
export const updateVehicleInformationStep = actionCreator<{
  updatedField: string
  formId: string
}>('sagas/UPDATE_VEHICLE_FORM')

export const getTradeInValue = actionCreator<{ cardId: string }>(
  'sagas/GET_TRADE_IN_VALUE'
)

export const registerField = actionCreator<{
  formId: string
  fieldName: string
  required: boolean
  maxCharacters?: number
  consent?: boolean
  disabled?: boolean
  type: FormFieldType
}>('sagas/REGISTER_FIELD')
export const updateField = actionCreator<{
  formId: string
  data: Partial<LeadState>
  required: boolean
  maxCharacters?: number
  consent?: boolean
  sideEffect?: Action<object>
  type: FormFieldType
}>('sagas/UPDATE_FIELD')
export const validateForm = actionCreator<{
  formId: string
  failureMessage?: string
}>('sagas/VALIDATE_FORM')

export const getAppointmentDatetimes = actionCreator<{}>(
  'sagas/GET_APPOINTMENT_DATETIMES'
)
export const updateAppointment = actionCreator<{
  appointment: Partial<AppointmentInterface>
  cardId: string
}>('sagas/UPDATE_APPOINTMENT')

export const initializeReviews = actionCreator<{ cardId: string }>(
  'sagas/INITIALIZE_REVIEWS'
)
export const handleDashboardAction = actionCreator<{
  dashboardAction: Action<any>
}>('sagas/HANDLE_DASHBOARD_ACTION')

export const getReviewsAction = actionCreator<{
  index: number
  cardId: string
}>('cards/UPDATE_CARD_REVIEWS')

export const initializePreview = actionCreator<{}>('sagas/INITIALIZE_PREVIEW')

export const logAction = actionCreator<{ error: Error }>('sagas/LOG')

export const rehydrateAction = actionCreator<{
  ui: UIState
  router: RouterState
}>('persist/REHYDRATE')

export const auditWidgetUrl = actionCreator('sagas/AUDIT_WIDGET_URL')
