import { FormInterface } from 'types'
import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('forms')

export const updateForm = actionCreator<{
  formId: string
  data: FormInterface
}>('UPDATE_FORM')
export const dirtifyForm = actionCreator<{ formId: string }>('DIRTIFY_FORM')
export const setFormSubmitted = actionCreator<{
  formId: string
  submitted: boolean
}>('DISABLE_FORM')
export const runConversionCode = actionCreator<{ conversionCode: string }>(
  'RUN_CONVERSION_CODE'
)
export const submitViewForm = actionCreator<{ cardId: string }>(
  'SUBMIT_VIEW_FORM'
)
