import { Action } from 'redux'
import { isType } from 'typescript-fsa'

import { setRpApiSessionToken, updateCompanySettings } from './company.actions'

export interface CompanySettingsState {
  timezoneId: string
  bookingFields: BookingField[]
  country: string
  rpApiSessionToken: string
  inquiryTypes: InquiryType[]
  title: string
  status: 'active' | 'inactive' | 'removed'
  isCadillac: string
}

export interface BookingField {
  _id: string
  type: string
  label: string
  maxCharacters: number
  required: boolean
  consent: boolean
}

export interface InquiryType {
  _id: string
  name: string
}

export const DEFAULT_STATE: CompanySettingsState = {
  timezoneId: 'US/Pacific',
  bookingFields: [],
  country: 'United States',
  rpApiSessionToken: '',
  inquiryTypes: [],
  title: '',
  status: 'active',
  isCadillac:''
}

export const company = (
  state: CompanySettingsState = DEFAULT_STATE,
  action: Action
) => {
  if (isType(action, updateCompanySettings)) {
    return {
      ...state,
      timezoneId: action.payload.timezoneId,
      bookingFields: action.payload.bookingFields,
      country: action.payload.country,
      inquiryTypes: action.payload.inquiryTypes,
      title: action.payload.title,
      status: action.payload.status
    }
  }

  if (isType(action, setRpApiSessionToken)) {
    return {
      ...state,
      rpApiSessionToken: action.payload.rpApiSessionToken
    }
  }

  return state
}
