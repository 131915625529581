export const getCurrentPosition = () => {
  return new Promise<Position>((resolve, reject) => {
    if ('geolocation' in window.navigator) {
      const options = {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 0
      }

      window.navigator.geolocation.getCurrentPosition(resolve, reject, options)
    } else {
      reject(new Error('Geolocation is not supported'))
    }
  })
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}
