/* eslint-disable class-methods-use-this */
import * as React from 'react'
import styled from 'styledComponents'
import { ConnectedComponent } from 'react-redux'

import { CardInterface, CardSequenceViewInterface, ToastInterface } from 'types'
import { VIRTUAL_CARDS } from 'common/constants'
import { cardMap } from 'components/_cards'
import { Container, Flex } from 'components/_utility'
import { CardSequenceFooter } from '.'

export interface Props {
  className?: string
  view: CardSequenceViewInterface
  sequenceIndex: number
  cards: CardInterface[]
  setSequenceIndex: (index: number) => void
  showToast: (toast: ToastInterface) => void
  submitForm: (formId: string) => void
}

const mapCardsToComponents = (
  cards: CardInterface[],
  sequenceIndex: number,
  setSequenceIndex: Props['setSequenceIndex'],
  submitForm: Props['submitForm']
) => {
  return cards.map((card: CardInterface, i: number) => {
    const props: { [x: string]: any } = {
      ...card.props,
      _id: card._id,
      collapsed: i !== sequenceIndex,
      mb: 9,
      header: {
        title: card.header.title,
        alwaysShow: true,
        type: '',
        cardIndex: i,
        onClick: () => (i !== sequenceIndex ? setSequenceIndex(i) : null)
      }
    }
    if (card.template === 'form') {
      props.onSubmit = (formId: string) => {
        submitForm(formId)
        setSequenceIndex(sequenceIndex + 1)
      }
    }
    if (i < sequenceIndex) {
      props.header.status = 'complete'
    }
    if (i < sequenceIndex) {
      props.header.type = 'btn'
    }

    const CardComponent = cardMap[card.template]
    if (i !== sequenceIndex - 1 && card.template !== 'reviews') {
      return <CardComponent key={i} {...props} />
    }

    return (
      <Container key={i} _id="sequenceTop">
        <CardComponent {...props} />
      </Container>
    )
  })
}

const mapMessagesCardToComponent = (card: CardInterface) => {
  const props = {
    _width: 100,
    _id: card._id,
    mb: 9,
    ...card.props
  }
  const CardComponent: ConnectedComponent<any, any> = cardMap[card.template]
  return (
    <Container key={card._id}>
      <CardComponent {...props} />
    </Container>
  )
}

class Component extends React.Component<Props, {}> {
  public render() {
    const {
      className,
      sequenceIndex,
      cards,
      setSequenceIndex,
      showToast,
      submitForm
    } = this.props

    const messagesCard = cards.find(c => c._id === VIRTUAL_CARDS.MESSAGES._id)
    const sequenceCards = cards.filter(
      c => c._id !== VIRTUAL_CARDS.MESSAGES._id
    )
    return (
      <Flex className={className} column _height={100}>
        <div>
          <div>
            {messagesCard && mapMessagesCardToComponent(messagesCard)}
            {mapCardsToComponents(
              sequenceCards.slice(0, sequenceIndex + 1),
              sequenceIndex,
              setSequenceIndex,
              submitForm
            )}
          </div>
          <Container />
        </div>
        {sequenceIndex < sequenceCards.length - 1 && (
          <CardSequenceFooter
            card={sequenceCards[sequenceIndex + 1]}
            disabled={
              'complete' in cards[sequenceIndex + 1].props &&
              cards[sequenceIndex + 1].props.complete
            }
            showToast={showToast}
            setSequenceIndex={() => setSequenceIndex(sequenceIndex + 1)}
          />
        )}
      </Flex>
    )
  }

  public componentDidMount() {
    const node = document.getElementById('sequenceTop')
    if (node) {
      node.scrollIntoView({ block: 'start' })
    }
  }

  public componentDidUpdate() {
    const node = document.getElementById('sequenceTop')
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
}

export const CardSequenceDisplay = styled(Component)`
  > :first-child {
    flex-grow: 1;
    overflow: auto;
    padding: 0 12px;

    > :first-child {
      height: 100%;
    }

    > ${Container} {
      height: ${p => p.sequenceIndex * 51}px;
    }
  }
`
