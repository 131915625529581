import * as React from 'react'

import { Text } from 'components/_utility'

export interface Props {
  text: string
}

export const SystemMessage = ({ text }: Props) => (
  <Text isBody capitalize mb={15} align="center">
    {text}
  </Text>
)
