import { put, select, takeEvery } from 'redux-saga/effects'

import { setView } from 'state/entities/views'
import { navigate } from 'state/router'
import { setWipViewId } from 'state/ui'
import {
  finishedInitializingPreview,
  toggleHomeDeskLauncher,
  updateWebdeskInfo
} from 'state/ui/ui.actions'
import {
  defaultViewIdSelector,
  rpPreviewModeSelector,
  wipWebdeskSelector
} from 'state/ui/ui.selectors'
import { initializePreview } from './_actions'

function* saga() {
  const previewMode = yield select(rpPreviewModeSelector)
  const wipWebdesk = yield select(wipWebdeskSelector)
  switch (previewMode) {
    case 'viewsview':
      const viewId = yield select(defaultViewIdSelector)
      yield put(setWipViewId({ wipViewId: viewId }))
      yield put(navigate({ viewId }))
      wipWebdesk.launcher.desktop = 'cards'
      break
    case 'behaviorview':
      yield put(toggleHomeDeskLauncher({ isHome: true }))
      wipWebdesk.launcher.desktop = 'cards'
      break
    case 'launcherview':
      break
    case 'cardmodal':
      // create and set a "card_preview" view to use to show single card preview
      const newView = {
        _id: 'card_preview',
        template: 'cardStack',
        header: {},
        cardIds: []
      }
      // set wipviewid to the card_preview view
      yield put(setView({ view: newView }))
      yield put(setWipViewId({ wipViewId: newView._id }))
      // navigate to that view
      yield put(navigate({ viewId: newView._id }))
      break
    default:
      break
  }
  yield put(updateWebdeskInfo({ webdesk: wipWebdesk }))

  // tell the Dashboard that the preview is finished initializing
  yield put(finishedInitializingPreview({ previewMode }))
}

export function* initializePreviewSaga() {
  yield takeEvery(initializePreview, saga)
}
