import * as React from 'react'
import styled from 'styledComponents'

import { Flex, Text } from 'components/_utility'

import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import { faArrowRight, faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

interface StatusProps {
  className?: string
  status?: string
}

const Status = ({ className, status }: StatusProps) => {
  if (status === 'complete') {
    return <Icon className={className} icon={faCheckCircle} />
  }
  if (status === 'incomplete') {
    return <Icon className={className} icon={faArrowRight} />
  }
  if (status === 'disabled') {
    return null
  }
  if (status) {
    return <i className={className}>{status}</i>
  }
  return null
}

export interface Props {
  className?: string
  title: string
  cardIndex?: number
  collapsed?: boolean
  status?: string
  type?: string
  onClick?: () => void
  onPrevious?: () => void
  onNext?: () => void
}

const Component = ({
  className,
  title,
  cardIndex,
  collapsed,
  status,
  type,
  onClick,
  onPrevious,
  onNext
}: Props) => {
  const isSequence = typeof cardIndex === 'number'
  return (
    <Flex row alignItems="center" className={className} onClick={onClick}>
      {isSequence && (collapsed || !onPrevious) && (
        <HeaderNav> {(cardIndex as number) + 1}.</HeaderNav>
      )}
      {onPrevious && !collapsed && (
        <HeaderNav onClick={onPrevious}>
          <button>
            <Icon icon={faChevronLeft} />
          </button>
        </HeaderNav>
      )}
      <HeaderContent
        row
        grow
        justifyContent="space-between"
        alignItems="center"
      >
        {type === 'btn' && (
          <Text title={title} truncated mr={6}>
            <button className="ctm-nk-footer-btn">{title}</button>
          </Text>
        )}
        {type !== 'btn' && (
          <Text title={title} truncated mr={6}>
            {title}
          </Text>
        )}
        {status && <StyledStatus status={status} />}
      </HeaderContent>
      {onNext && !collapsed && (
        <HeaderNav onClick={onNext}>
          <button>
            <Icon icon={faChevronRight} />
          </button>
        </HeaderNav>
      )}
    </Flex>
  )
}

export const CardHeader = styled(Component)`
  cursor: ${p =>
    p.collapsed && (p.onPrevious || p.onClick || p.onClick)
      ? 'pointer'
      : 'auto'};
  height: 39px;
  margin-top: 3px;
  background-color: ${p => p.theme.colors.lightest};
`

const HeaderContent = styled(Flex)`
  flex-shrink: 1;
  min-width: 0;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }
  .ctm-nk-footer-btn {
    border: none;
    background: none;
    outline: none;
    &:focus {
      color: ${p => p.theme.colors.dark};
      text-decoration: underline;
    }
  }
`

const HeaderNav = styled(Flex)`
  cursor: ${p => (p.onClick ? 'pointer' : 'inherit')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;

  color: ${p => p.theme.colors.original};

  transition: all 0.25s;

  &:hover {
    color: ${p => p.theme.colors.dark};
  }
  > button {
    border: none;
    background: none;
    color: ${p => p.theme.colors.original};
    &:focus {
      border: 1px solid ${p => p.theme.colors.original};
      outline: none;
    }
  }
`

const StyledStatus = styled(Status)`
  color: ${p => p.theme.green};
`
