import * as React from 'react'
import styled from 'styledComponents'

import { Flex, Text } from 'components/_utility'
import { Avatar } from '../Avatar'

export interface Props {
  imageUrl: string
  name: string
  text: string
  isShown?: boolean
}

const Component = ({ imageUrl, name, text, isShown }: Props) => (
  <React.Fragment>
    {isShown && (
      <Flex row>
        <Flex column alignItems="center" mr={26}>
          <Avatar data-test="avatar" src={imageUrl} />
          <Text isFootnote>{name}</Text>
        </Flex>
        <Flex column justifyContent="center" shrink overflow="auto">
          <Text isBody mb={12}>
            {text}
          </Text>
        </Flex>
      </Flex>
    )}
  </React.Fragment>
)

export const GreetingDisplay = styled(Component)<Props>``
