import CustomStorage from './customStorage'

class CustomWindow {
  public localStorage: CustomStorage | Storage
  public sessionStorage: CustomStorage | Storage

  constructor() {
    try {
      this.localStorage = window.localStorage
      this.sessionStorage = window.sessionStorage
      if (!window.localStorage || !window.sessionStorage) {
        throw new Error()
      }
      const testStorageKey = 'storage_test'
      window.localStorage.setItem(testStorageKey, '1')
      window.localStorage.removeItem(testStorageKey)
      window.sessionStorage.setItem(testStorageKey, '1')
      window.sessionStorage.removeItem(testStorageKey)
    } catch (e) {
      this.localStorage = new CustomStorage('local')
      this.sessionStorage = new CustomStorage('session')
    }
  }
}

export default new CustomWindow()
