import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styledComponents'

import { Card } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  FormInterface,
  HelpersProps,
  InputMask
} from 'types'

import { Step1, Step2, Step3, Step4 } from './'

export interface Props extends HelpersProps {
  className?: string
  _id: string
  fields?: string[]
  collapsed?: boolean
  header: CardHeaderProps
  actionButtons: ActionButtonActionInterface[]
  length: number
  index: number
  completedIndex: number
  isCurrentStepFilled: boolean
  setIndex: (index: number, completedIndex?: number) => void
  getTradeInValue: (cardId: string) => void
  getStandardOptions: () => void
  step1_form: FormInterface
  vehicleSummary?: {
    make: string
    model: string
    year: number
    trim: string
    price: number
    standardOptions: string
  }
  phoneMask?: InputMask
}

const Component = ({
  className,
  _id,
  collapsed = false,
  header,
  actionButtons,
  index = 0,
  completedIndex = 0,
  setIndex,
  getTradeInValue,
  step1_form,
  vehicleSummary,
  phoneMask
}: Props) => {
  const incrementIndex = () =>
    setIndex(index + 1, Math.max(index + 1, completedIndex))

  const getTradeInValueAndIncrementIndex = () => {
    getTradeInValue(_id)
    incrementIndex()
  }

  const cards = [
    <Step1 key={0} submit={incrementIndex} form={step1_form} />,
    <Step2 key={1} submit={incrementIndex} />,
    <Step3
      key={2}
      submit={getTradeInValueAndIncrementIndex}
      phoneMask={phoneMask}
    />,
    <Step4
      key={3}
      vehicleSummary={vehicleSummary}
      actionButtons={actionButtons}
    />
  ]
  const CurrentCard = cards[index]

  const isInvalidStep1 = Object.values(step1_form || {}).find(
    field => field.status === 'invalid'
  )

  const currentHeader = {
    status: `${index + 1}/${cards.length}`,
    ...header,
    onPrevious: index > 0 ? () => setIndex(index - 1) : undefined,
    onNext:
      completedIndex > index && !isInvalidStep1
        ? CurrentCard.props.submit
        : undefined
  }

  return (
    <Card
      className={className}
      header={currentHeader}
      collapsed={collapsed}
      padding="none"
      customAttributes={{ 'data-test': 'tradein-card' }}
    >
      <CSSTransition
        classNames="card-step"
        key={index}
        timeout={120}
        appear={true}
        in={true}
      >
        {CurrentCard}
      </CSSTransition>
    </Card>
  )
}

export const TradeInCardDisplay = styled(Component)`
  ${helpers}

  ${Card} {
    background: none;
    box-shadow: none;
    border-radius: none;
  }

  /* .card-step-appear {
    opacity: 0;
  }

  .card-step-enter-done {
    transition: opacity 420ms;
  } */
`
