import * as React from 'react'
import { Omit } from 'utility-types'

import { ActionButtonActionInterface, ActionButtonContainer } from 'components'
import { ButtonGroup } from 'components/_utility'
import { ButtonProps, HelpersProps } from 'types'

export interface Props
  extends Omit<ButtonProps & HelpersProps, 'children' | 'onClick'> {
  className?: string
  padding?: string
  fullWidth?: boolean
  actions: ActionButtonActionInterface[]
}

export const ActionButtonGroup = ({
  className,
  actions,
  fullWidth,
  padding,
  ...otherProps
}: Props) => {
  const buttons = actions.map(
    (action: ActionButtonActionInterface, i: number) => (
      <ActionButtonContainer
        key={i}
        {...action}
        {...otherProps}
        rounded={!fullWidth}
        _width={fullWidth ? 100 : undefined}
      />
    )
  )

  return (
    <ButtonGroup className={className} fullWidth={fullWidth} padding={padding}>
      {buttons}
    </ButtonGroup>
  )
}
