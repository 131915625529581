import * as React from 'react'
import { hot } from 'react-hot-loader'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styledComponents'
import { ThemeInterface } from 'types'
import { Provider } from '@andyet/simplewebrtc'
import settings from 'settings'

import { FrameContainer, VideoRoom } from './components'
import { ErrorBoundary } from './ErrorBoundary'

import { RootState } from './state/reducer'
import { themeSelector } from './state/ui'
import { roomNameSelector } from './state/video'

export interface Props {
  theme: ThemeInterface
  initialized: boolean
  roomName?: string
  companyIsActive: boolean
}

const component = ({
  theme,
  initialized,
  roomName,
  companyIsActive
}: Props) => {
  if (!initialized || !companyIsActive) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <FrameContainer />
        {roomName && (
          <Provider
            configUrl={`https://api.simplewebrtc.com/config/guest/${settings.SWRTCApiKey}`}
          >
            <VideoRoom roomName={roomName} />
          </Provider>
        )}
      </ErrorBoundary>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: RootState) => ({
  theme: themeSelector(state),
  roomName: roomNameSelector(state),
  initialized: state.initialized,
  companyIsActive: state.company.status === 'active'
})

export const App = connect(mapStateToProps)(hot(module)(component))
