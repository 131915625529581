import { FormFieldType } from 'types'
import { chatbotFormFieldConverter, validatePhoneNumber } from 'utility'
import { equals, isAfter, isBefore, isEmail, isLength } from 'validator'

interface LeadValidateData {
  fieldName: string
  value: string | number | string[]
  required: boolean
  maxCharacters?: number
  consent?: boolean
  type?: FormFieldType
}

export const leadValidator = ({
  fieldName,
  value,
  required,
  maxCharacters,
  consent,
  type
}: LeadValidateData) => {
  // Use for checkboxes
  const isBoolString = (val: string) =>
    required ? val === 'true' : ['true', 'false'].includes(val)

  let field = fieldName
  if (field.indexOf('appointment.fields.') !== -1) {
    field = 'appointment.fields'
  } else if (field.indexOf(chatbotFormFieldConverter.getPrefix()) !== -1) {
    field = 'chatbot.fields'
  } else if (field.indexOf('vehicleSearch') !== -1) {
    field = 'vehicleOpenSearch'
  }

  // allow next year
  const maxYear = new Date().getFullYear() + 2

  const validator = {
    email: (val: string) => isEmail(val),
    phone: (val: string) =>
      /^\+?\d{7,15}$/.test(val.toString().replace(/ *\(*\)*-*/g, '')),
    firstName: (val: string) =>
      Number.isNaN(Number(val)) && isLength(val, { min: 1, max: 60 }),
    lastName: (val: string) =>
      Number.isNaN(Number(val)) && isLength(val, { min: 1, max: 60 }),
    city: (val: string) =>
      Number.isNaN(Number(val)) && isLength(val, { min: 1, max: 50 }),
    address: (val: string) => isLength(val, { min: 1, max: 60 }),
    state: (val: string) => isLength(val, { min: 1 }),
    zipCode: (val: string) => /[0-9]{5,}/g.test(val),
    inquiryType: (val: string) => isLength(val, { min: 1 }),
    vehicleSearch: () => true,
    sales: isBoolString,
    service: isBoolString,
    finance: isBoolString,
    vehicleMake: (val: string) => isLength(val, { min: 1 }),
    vehicleModel: (val: string) => isLength(val, { min: 1 }),
    vehicleYear: (val: number) => {
      const yearString = val.toString()
      return (
        yearString.length === 4 &&
        isAfter(yearString, '1900-01-01') &&
        isBefore(yearString, maxYear.toString())
      )
    },
    vehicleOpenSearch: (val: string) =>
      isLength(val, { min: 1, max: maxCharacters }),
    reviewsSource: () => true,
    reviewsSort: () => true,

    'tradeInVehicle.year': (val: number) => val.toString().length === 4,
    'tradeInVehicle.make': () => true,
    'tradeInVehicle.model': () => true,
    'tradeInVehicle.trim': () => true,
    'tradeInVehicle.mileage': () => true,
    'tradeInVehicle.condition': (val: string) => isLength(val, { min: 1 }),
    phoneInput: (val: string) => validatePhoneNumber(val),
    'appointment.fields': (val: string) => {
      if (maxCharacters && !isLength(val, { min: 1, max: maxCharacters })) {
        return false
      }
      if (consent && !equals(val, 'Yes')) {
        return false
      }
      if (required && !val) {
        return false
      }
      return true
    },
    'chatbot.fields': (val: string) => {
      if (type && type === FormFieldType.CHECKBOX) {
        return isBoolString(val)
      }
      if (type && type === FormFieldType.SELECT) {
        return !required || (!!val && val !== 'none')
      }
      if (maxCharacters && !isLength(val, { min: 1, max: maxCharacters })) {
        return false
      }
      if (consent && !equals(val, 'yes')) {
        return false
      }
      if (required && !val) {
        return false
      }
      return true
    },
    'appointment.comments': (val: string) => isLength(val, { min: 1 }),
    'appointment.type': (val: string) => isLength(val, { min: 1 }),
    vehicleYearDropdown: (val: string) => !(required && !val),
    vehicleMakeDropdown: (val: string) => !(required && !val),
    vehicleModelDropdown: (val: string) => !(required && !val)
  }[field]

  try {
    return validator(value) || (!value && !required && !consent)
      ? 'valid'
      : 'invalid'
  } catch (e) {
    return 'invalid'
  }
}

export default leadValidator
