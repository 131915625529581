import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { CtxTagKeys } from '@microsoft/applicationinsights-common'
import { LeadState } from 'types'
import settings from 'settings'

class MonitoringService {
  private appInsights: ApplicationInsights

  private leadData: Partial<LeadState> | null

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: settings.applicationinsights.key,
        accountId: settings.companyId,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        disableTelemetry: !settings.applicationinsights.enable,
        enableDebug: settings.applicationinsights.debug,
        enableUnhandledPromiseRejectionTracking: true,
        disableCorrelationHeaders: false,
        enableCorsCorrelation: false
      }
    })
    this.leadData = null
  }

  public start() {
    this.appInsights.loadAppInsights()

    this.setupClientTags({ appName: 'EP' })
  }

  public setupClientTags({ appName }: { appName: string }) {
    this.appInsights.addTelemetryInitializer(envelope => {
      // eslint-disable-next-line no-param-reassign
      envelope.tags![CtxTagKeys.cloudRole] = appName
      // eslint-disable-next-line no-param-reassign
      envelope.tags![CtxTagKeys.cloudRoleInstance] = appName
    })
  }

  public setLeadData(lead: Partial<LeadState>) {
    this.leadData = lead
    if (this.leadData && this.leadData._id) {
      this.appInsights.setAuthenticatedUserContext(this.leadData._id)
    }
  }

  public trackPageView({
    view,
    properties
  }: {
    view: string
    properties: { [key: string]: any }
  }) {
    this.appInsights.trackPageView({
      name: 'EP view',
      uri: view,
      refUri: window.location.href,
      properties
    })
  }

  public trackException(error: Error, properties: any) {
    this.appInsights.trackException({ exception: error, properties })
  }

  public trackLog(message: string, properties: any) {
    this.appInsights.trackTrace({ message }, properties)
  }
}

export const monitoringService = new MonitoringService()
