import { connect } from 'react-redux'
import { RootState } from 'types'
import { fullLeadNameSelector } from 'state/lead/lead.selectors'
import { companyNameSelector } from 'state/company/company.selectors'

import { FinancingCertificateDisplay } from './FinancingCertificate.display'

const mapStateToProps = (state: RootState) => ({
  fullName: fullLeadNameSelector(state),
  companyName: companyNameSelector(state)
})

const mapDispatchToProps = () => ({})

export const FinancingCertificateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancingCertificateDisplay)
