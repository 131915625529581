import * as React from 'react'
import ReactSelect from 'react-select'
import { withTheme } from 'styledComponents'

import { FieldOptionInterface, InjectedFieldProps, ThemeInterface } from 'types'

const customStyles = (theme: ThemeInterface, status?: string) => ({
  container: (base: any) => ({
    ...base,
    color: `${theme.gray.dark}`,
    fontSize: `14px`
  }),
  control: (base: any, state: any) => {
    return {
      ...base,
      cursor: 'pointer',
      minHeight: `36px`,
      borderRadius: `6px`,
      backgroundColor: !state.isDisabled
        ? `${theme.colors.lightest}`
        : `${theme.gray.lightest}`,
      border: state.isFocused
        ? `solid 2px ${theme.colors.light}`
        : `solid 2px ${
            status !== 'invalid' || state.isDisabled
              ? theme.colors.lighter
              : theme.red
          }`,
      boxShadow: `none`,
      transition: `all .25s`,
      '&:hover': {
        borderColor: `${theme.colors.light}`
      }
    }
  },
  input: (base: any) => ({
    ...base,
    margin: 0
  }),
  placeholder: (base: any) => ({
    ...base,
    margin: 0,
    color: `${theme.gray.lighter}`
  }),
  singleValue: (base: any) => ({
    ...base,
    color: `${theme.gray.base}`
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: `6px 8px`,
    color: `${theme.colors.lighter}`,
    '&:hover': {
      color: `${theme.colors.dark}`
    }
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    backgroundColor: `${theme.colors.lighter}`
  }),
  menu: (base: any) => ({
    ...base,
    overflow: `auto`,
    boxShadow: `0 3px 20px rgba(0, 0, 0, .16)`
  }),
  option: (base: any, state: any) => ({
    ...base,
    cursor: `pointer`,
    fontSize: `14px`,
    padding: `8px 12px`,
    color: state.isSelected ? `white` : `${theme.gray.dark}`,
    backgroundColor: (() => {
      if (state.isSelected) {
        return `${theme.colors.original}`
      }
      if (state.isFocused) {
        return `${theme.colors.lighter}`
      }
      return `white`
    })(),
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.colors.lighter}`
    }
  })
})

interface Props extends InjectedFieldProps {
  theme: ThemeInterface
  options?: FieldOptionInterface[]
  disabled?: boolean
  placeholder?: string
  name: string
}

const Component = ({
  theme,
  name,
  options = [],
  placeholder = 'Select...',
  value,
  status,
  disabled,
  updateField
}: Props) => {
  const onChange = (option: FieldOptionInterface) => {
    updateField({ [name]: { ...option } })
  }
  return (
    <div
      style={{ cursor: disabled ? 'not-allowed' : 'inherit' }}
      data-test="select"
    >
      <ReactSelect
        name={name}
        styles={customStyles(theme, status)}
        options={options}
        maxMenuHeight={150}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        value={options.filter(option => option.value === value)}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable={false}
        isDisabled={disabled}
      />
    </div>
  )
}

export const Select = withTheme(Component)
