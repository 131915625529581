import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { phoneMaskSelector } from 'state/company'
import {
  cardCompletedIndexSelector,
  cardIndexSelector,
  setCardIndex
} from 'state/entities/cards'
import { formSelector } from 'state/forms'
import { tradeInVehicleSummarySelector } from 'state/lead'

import { getTradeInValue } from 'state/_sagas/_actions'
import { RootState } from 'types'
import { Props, TradeInCardDisplay } from './TradeInCard.display'

type StateProps = Pick<
  Props,
  'index' | 'completedIndex' | 'step1_form' | 'vehicleSummary' | 'phoneMask'
>
const mapStateToProps = (
  state: RootState,
  ownProps: { _id: string }
): StateProps => {
  const index = cardIndexSelector(state, ownProps._id)
  const completedIndex = cardCompletedIndexSelector(state, ownProps._id)

  return {
    index,
    completedIndex,
    step1_form: formSelector(state, 'trade_in_1'),
    vehicleSummary: tradeInVehicleSummarySelector(state),
    phoneMask: phoneMaskSelector(state)
  }
}

type DispatchProps = Pick<Props, 'setIndex' | 'getTradeInValue'>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { _id: string }
): DispatchProps => ({
  setIndex: (index, completedIndex) => {
    dispatch(setCardIndex({ _id: ownProps._id, index, completedIndex }))
  },
  getTradeInValue: (cardId: string) => {
    dispatch(getTradeInValue({ cardId }))
  }
})

export const TradeInCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradeInCardDisplay)
