import { Action } from 'redux'
import { isType } from 'typescript-fsa'

import {
  setRoomName,
  expandVideo,
  collapseVideo,
  leaveVideoCall,
  toggleMobileShrink
} from './video.actions'

export interface VideoSettingsState {
  roomName: string | null
  expandedMode: boolean
  mobileShrink: boolean
}

export const DEFAULT_STATE: VideoSettingsState = {
  roomName: null,
  expandedMode: false,
  mobileShrink: false
}

export const video = (
  state: VideoSettingsState = DEFAULT_STATE,
  action: Action
) => {
  if (isType(action, setRoomName)) {
    return {
      ...state,
      roomName: action.payload.roomName
    }
  }

  if (isType(action, expandVideo)) {
    return {
      ...state,
      expandedMode: true
    }
  }

  if (isType(action, collapseVideo)) {
    return {
      ...state,
      expandedMode: false
    }
  }

  if (isType(action, leaveVideoCall)) {
    return {
      ...state,
      roomName: null
    }
  }

  if (isType(action, toggleMobileShrink)) {
    return {
      ...state,
      mobileShrink: !state.mobileShrink
    }
  }

  return state
}
