import { Action, combineReducers } from 'redux'
import { isType } from 'typescript-fsa'
import { DeepReadonly } from 'utility-types'
import * as SWRTC from '@andyet/simplewebrtc'

import { initializeDeskSuccess } from './_sagas/_actions'

import { company, CompanySettingsState } from './company'
import { entities, EntitiesState } from './entities'
import { forms, FormsState } from './forms'
import { lead, LeadState } from './lead'
import { router, RouterState } from './router'
import { ui, UIState } from './ui'
import { video, VideoSettingsState } from './video'

export type RootState = DeepReadonly<{
  [index: string]: any
  entities: EntitiesState
  forms: FormsState
  company: CompanySettingsState
  lead: LeadState
  router: RouterState
  ui: UIState
  initialized: boolean
  simplewebrtc: any
  video: VideoSettingsState,
  visitorId: string
}>

const initializeReducer = (state = false, action: Action) => {
  if (isType(action, initializeDeskSuccess)) {
    return true
  }

  return state
}

const visitorReducer = (state = null, action: Action) => {
  if (isType(action, initializeDeskSuccess)) {
    return 'cxxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'
    .replace(/[xy]/g, c => {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
    .substring(0, 32)
  }
  return state
}

export const reducer = combineReducers({
  entities,
  forms,
  initialized: initializeReducer,
  company,
  lead,
  router,
  ui,
  simplewebrtc: SWRTC.reducer,
  video,
  visitorId: visitorReducer
})
