import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { joinVideoCall, declineVideoCall } from 'state/video'
import { RingingDisplay, Props } from './Ringing.display'

type DispatchProps = Pick<Props, 'answer' | 'decline'>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  decline: ({ messageId }) => {
    dispatch(declineVideoCall({ messageId }))
  },
  answer: ({ messageId }) => {
    dispatch(joinVideoCall({ messageId }))
  }
})

export const RingingContainer = connect(
  () => ({}),
  mapDispatchToProps
)(RingingDisplay)
