import * as React from 'react'
import { LocalMediaList, Video } from '@andyet/simplewebrtc'
import styled from 'styled-components'
import { Flex } from 'components/_utility'

const LocalVideo = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 14px;
    padding: 8px;
  }

  video {
    display: block;
    object-fit: cover;
    /* width: 400px; */
    width: 100%;
  }
`

const EmptyVideo = styled.div`
  width: 100%;
  background-color: #262a2c;
`
interface Props {
  className?: string
}

const Component = ({ className }: Props) => {
  return (
    <Flex className={className}>
      <LocalVideo>
        <LocalMediaList
          shared={true}
          render={({ media }) => {
            const videos = media.filter(m => m.kind === 'video')
            if (videos.length > 0) {
              const shownVideo = media.find(m => m.kind === 'video')

              if (shownVideo) {
                return <Video key={shownVideo.id} media={shownVideo} />
              }
            }

            return <EmptyVideo />
          }}
        />
      </LocalVideo>
    </Flex>
  )
}

export const SelfVideo = styled(Component)`
  position: absolute;
  width: 200px;
  top: 0;
  right: 0;
`
