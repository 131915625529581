import { call } from 'redux-saga/effects'

import { capitalize } from 'utility'

import { execute, makePromise } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import gql from 'graphql-tag'
import * as moment from 'moment'
import settings from 'settings'

const link = new HttpLink({
  uri: settings.rpRelayApiUrl,
  headers: {
    'apollographql-client-name': 'EP2',
    'apollographql-client-version': '1.0'
  }
})

function* getTradeInVehicleYears() {
  const yearsFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbYears {
            kbbYears {
              yearId
            }
          }
        `
      })
    )
  )

  const {
    data: { kbbYears }
  } = yield yearsFetch
  return kbbYears.map((item: { yearId: number }) => {
    const { yearId } = item
    return { value: yearId, label: yearId }
  })
}

function* getTradeInVehicleMakes(yearId: number) {
  const makesFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbMakes($yearId: Int!) {
            kbbMakes(yearId: $yearId) {
              makeId
              makeName
            }
          }
        `,
        variables: {
          yearId
        }
      })
    )
  )

  const {
    data: { kbbMakes }
  } = yield makesFetch
  return kbbMakes.map((item: { makeId: number; makeName: string }) => {
    const { makeId, makeName } = item
    return { value: makeId, label: capitalize(makeName) }
  })
}

function* getTradeInVehicleModels(yearId: number, makeId: number) {
  const modelsFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbModels($yearId: Int!, $makeId: Int!) {
            kbbModels(yearId: $yearId, makeId: $makeId) {
              makeId
              makeName
              modelId
              modelName
            }
          }
        `,
        variables: {
          yearId,
          makeId
        }
      })
    )
  )
  const {
    data: { kbbModels }
  } = yield modelsFetch
  return kbbModels.map((item: { modelId: number; modelName: string }) => {
    const { modelId, modelName } = item
    return { value: modelId, label: capitalize(modelName) }
  })
}

function* getTradeInVehicleTrims(yearId: number, modelId: number) {
  const trimsFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbTrims($yearId: Int!, $modelId: Int!) {
            kbbTrims(yearId: $yearId, modelId: $modelId) {
              trimId
              trimName
              vehicleClass
              modelYearId
              modelPlusTrimName
              makeId
              makeName
              modelId
              modelName
            }
          }
        `,
        variables: {
          yearId,
          modelId
        }
      })
    )
  )
  const {
    data: { kbbTrims }
  } = yield trimsFetch
  return kbbTrims
    .filter(
      (trim: { trimId: number; trimName: string; modelYearId: number }) =>
        trim.trimName != null
    )
    .map((item: { trimId: number; trimName: string; modelYearId: number }) => {
      const { trimId, trimName } = item
      return { value: trimId, label: capitalize(trimName) }
    })
}

function* getTradeInVehiclePrices(
  yearId: number,
  makeId: number,
  modelId: number,
  trimId: number,
  mileage: number
) {
  const vehicleIdsFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbVehicles(
            $yearId: Int!
            $makeId: Int!
            $modelId: Int!
            $trimId: Int!
          ) {
            kbbVehicles(
              yearId: $yearId
              makeId: $makeId
              modelId: $modelId
              trimId: $trimId
            ) {
              vehicleId
            }
          }
        `,
        variables: {
          yearId,
          makeId,
          modelId,
          trimId
        }
      })
    )
  )
  const {
    data: { kbbVehicles }
  } = yield vehicleIdsFetch
  const { vehicleId } = kbbVehicles[0]

  const optionsFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbOptions($vehicleId: Int!) {
            kbbOptions(vehicleId: $vehicleId) {
              optionName
              categoryName
              vehicleOptionId
            }
          }
        `,
        variables: {
          vehicleId
        }
      })
    )
  )

  const {
    data: { kbbOptions }
  } = yield optionsFetch

  const standardOptions = kbbOptions.reduce(
    (
      accumulator: string,
      current: { categoryName?: string; optionName: string },
      index: number
    ) => {
      const { categoryName, optionName } = current
      const optionText = categoryName
        ? `${categoryName}: ${optionName}`
        : `${optionName}`
      return `${accumulator}${index > 0 ? ', ' : ''}${optionText}`
    },
    ''
  )

  const vehicleOptionIds = kbbOptions.map(
    (option: { vehicleOptionId: number }) => option.vehicleOptionId
  )
  const pricesFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getKbbPrices(
            $vehicleId: Int!
            $vehicleOptionIds: [Int!]!
            $mileage: Int!
            $zipCode: String!
            $valuationDate: String!
          ) {
            kbbPrices(
              vehicleId: $vehicleId
              vehicleOptionIds: $vehicleOptionIds
              mileage: $mileage
              zipCode: $zipCode
              valuationDate: $valuationDate
            ) {
              priceTypeId
              priceTypeDisplay
              condition
              configuredValue
            }
          }
        `,
        variables: {
          vehicleId,
          vehicleOptionIds,
          mileage: mileage ? mileage : 0,
          zipCode: '93101',
          valuationDate: moment.utc().format()
        }
      })
    )
  )
  const {
    data: { kbbPrices }
  } = yield pricesFetch
  return {
    prices: kbbPrices,
    standardOptions
  }
}

export const kbb = {
  getTradeInVehicleYears,
  getTradeInVehicleMakes,
  getTradeInVehicleModels,
  getTradeInVehicleTrims,
  getTradeInVehiclePrices
}
