/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import { Media, Peer, Video } from '@andyet/simplewebrtc'
import * as React from 'react'
import styled from 'styled-components'
import { faVolumeMute } from '@fortawesome/pro-light-svg-icons/faVolumeMute'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { Flex } from 'components/_utility'

const PictureInPictureContainer = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& video:first-of-type': {},
  '& video:last-of-type': {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: '100px'
  }
})

interface PeerGridItemMediaProps {
  media: Media[]
}

const useMediaStreams = (media: Media[]) => {
  const videoStreams = React.useMemo(
    () => media.filter(m => m.kind === 'video' && !m.remoteDisabled),
    [media]
  )

  const audioStreams = React.useMemo(
    () => media.filter(m => m.kind === 'audio'),
    [media]
  )

  return { videoStreams, audioStreams }
}

// PeerGridItemMedia renders a different visualization based on what media is
// available from a peer. It will render video if the peer is sending video,
// otherwise it renders an audio-only display.
const PeerGridItemMedia: React.FC<PeerGridItemMediaProps> = ({ media }) => {
  const { videoStreams, audioStreams } = useMediaStreams(media)

  if (videoStreams.length > 0) {
    // Choose last media as it is most likely the screenshare.
    const webcamStreams = videoStreams.filter(s => !s.screenCapture)
    const screenCaptureStreams = videoStreams.filter(s => s.screenCapture)

    if (videoStreams.length === 1 || screenCaptureStreams.length === 0) {
      return <Video media={webcamStreams[0]} />
    }

    return (
      <PictureInPictureContainer>
        {/* Screenshare */}
        <Video media={screenCaptureStreams[0]} />
        {/* Camera */}
        <Video media={webcamStreams[0]} />
      </PictureInPictureContainer>
    )
  }
  if (audioStreams.length > 0) {
    // TODO: AUDIO
    return (
      <Flex _p={5} _width={100} justifyContent="center" alignItems="center">
        Voice
      </Flex>
    )
  }

  return (
    <Flex _p={5} _width={100} justifyContent="center" alignItems="center">
      No media
    </Flex>
  )
}

interface PeerGridItemProps {
  peer: Peer
  media: Media[]
}

const Overlay = styled(Flex)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 5;
  padding: 5px;
  border-radius: 5px;
  background-color: ${p => p.theme.gray.dark};
  color: white;
`

function allAudioIsUnmuted(media: Media[]): boolean {
  for (const m of media) {
    if (m.kind === 'audio' && m.remoteDisabled) {
      return false
    }
  }
  return true
}

interface PeerGridItemOverlayProps {
  peer: Peer
  audioIsMuted: boolean
}

const PeerGridItemOverlay: React.SFC<PeerGridItemOverlayProps> = ({
  peer,
  audioIsMuted
}) => {
  return (
    <Overlay fs={14}>
      {peer.muted || audioIsMuted ? (
        <Flex alignItems="center" fs={16} mr={5}>
          <Icon icon={faVolumeMute} />
        </Flex>
      ) : null}
      {`${peer.displayName}`}
    </Overlay>
  )
}

// PeerGridItem renders various controls over a peer's media.
const PeerGridItem: React.SFC<PeerGridItemProps> = ({ peer, media }) => (
  <>
    {(peer.muted || peer.displayName) && (
      <PeerGridItemOverlay
        peer={peer}
        audioIsMuted={!allAudioIsUnmuted(media)}
      />
    )}
    <PeerGridItemMedia media={media} />
  </>
)

export default PeerGridItem
