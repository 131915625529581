import { call } from 'redux-saga/effects'

import rpApiService from 'services/rp.api'
import { LeadState } from 'types'
import { getDatetimeOptions } from 'utility'

function* getAppointmentDatetimeOptions(
  appointmentTypeId: string,
  lead: LeadState
) {
  const availability = yield call(
    rpApiService.getAvailability,
    appointmentTypeId,
    lead
  )
  return getDatetimeOptions(availability.times)
}

export const appointment = {
  getAppointmentDatetimeOptions
}
