import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled, { withTheme } from 'styledComponents'
import { Assign } from 'utility-types'

import { NAVIGATION } from 'common/constants'
import {
  Button,
  ButtonGroup,
  Card,
  Flex,
  Greeting,
  Spinner
} from 'components/_utility'
import rpApiService from 'services/rp.api'
import { logAction, showToast, trackEvent } from 'state/_sagas/_actions'
import { pingConversation } from 'state/entities/conversations'
import { currentViewParamsSelector, navigate } from 'state/router'
import { rpPreviewModeSelector, updatedWipCard } from 'state/ui'
import { helpers } from 'style/mixins'
import {
  CardHeaderProps,
  ChatbotInterface,
  ConversationAction,
  HelpersProps,
  RootState,
  Service,
  TrackedEventDestinations
} from 'types'

export interface Props extends HelpersProps {
  className?: string
  chatbotId: string
  previewMode: string
  collapsed?: boolean
  header?: CardHeaderProps
}

export interface State {
  chatbot?: ChatbotInterface
  chatbotActionsDisabled: boolean
  loading: boolean
  autoReplyMessage: string
}

interface BotCTAButtonProps {
  onClick: () => void
  cta: string
  disabled: boolean
  previewMode: string
}

const BotCTAButton = ({
  onClick,
  cta,
  disabled,
  previewMode
}: BotCTAButtonProps) => (
  <Button
    grayOutline
    tall
    multiline
    _width={100}
    disabled={disabled}
    onClick={
      previewMode === 'none' || previewMode === 'viewsview'
        ? onClick
        : function onClickPreview() {
            // do nothing.
          }
    }
  >
    {cta}
  </Button>
)

class Component extends React.Component<Props & DispatchProps, State> {
  constructor(props: Props & DispatchProps) {
    super(props)

    this.state = {
      chatbotActionsDisabled: false,
      loading: true,
      autoReplyMessage: ''
    }
  }

  public handleChatCTAClick = (pathId: string, autoReplyMessage: string) => {
    this.setState({ chatbotActionsDisabled: true })
    this.props.pingConversation({
      chatbotId: this.props.chatbotId,
      pathId,
      autoReplyMessage
    })
  }

  public handleBookingCTAClick = (showAll: boolean, service: Service) => {
    this.setState({ chatbotActionsDisabled: true })
    this.props.displayBooking({ showAll, service })
  }

  public handleFAQCTAClick = (action: ConversationAction) => {
    this.setState({ chatbotActionsDisabled: true })
    this.props.displayFaqs({
      actionId: action._id,
      faqsOrder: action.order as string[]
    })
  }

  public componentDidMount() {
    rpApiService
      .getChatbot(this.props.chatbotId)
      .then((chatbot: ChatbotInterface) => {
        this.setState({ chatbot, loading: false })
        if (this.props.previewMode !== 'none') {
          this.props.updatePreviewUI()
        }
      })
      .catch((e: Error) => {
        this.props.logException({ error: e })
        this.props.handleBotLoadFail()
        this.setState({ loading: false })
      })
  }

  public render() {
    const { className, collapsed, header, previewMode } = this.props
    const {
      chatbot,
      chatbotActionsDisabled,
      loading,
      autoReplyMessage
    } = this.state

    let cardContent = null
    if (loading) {
      cardContent = (
        <Flex justifyContent="center" alignItems="center">
          <Spinner size="2x" />
        </Flex>
      )
    } else if (chatbot) {
      const actionButtons = chatbot.actions.map(
        (action: ConversationAction, index: number) => {
          const botCTAButtonProps = {} as Assign<
            BotCTAButtonProps,
            { key: string }
          >
          botCTAButtonProps.cta = action.cta
          botCTAButtonProps.disabled = chatbotActionsDisabled
          botCTAButtonProps.previewMode = previewMode
          switch (action.type) {
            case 'booking':
              botCTAButtonProps.onClick = () =>
                this.handleBookingCTAClick(!!action.showAll, action.service!)
              break
            case 'chat':
              botCTAButtonProps.onClick = () =>
                this.handleChatCTAClick(action.pathId!, autoReplyMessage)
              break
            case 'faq':
              botCTAButtonProps.onClick = () => this.handleFAQCTAClick(action)
              break
            default:
              break
          }
          return <BotCTAButton key={index} {...botCTAButtonProps} />
        }
      )
      cardContent = (
        <React.Fragment>
          <Greeting
            imageUrl={chatbot.avatar}
            name={chatbot.name}
            text={chatbot.initialPrompt}
          />
          {!chatbot.isAutoReply ? null : (
            <textarea
              placeholder="Please write your reply..."
              style={{
                width: '100%',
                border: '1px solid #dadada',
                borderRadius: '5px',
                minHeight: '70px',
                padding: '10px 15px',
                fontSize: '14px'
              }}
              onChange={e => {
                this.setState({ autoReplyMessage: e.target.value })
              }}
            />
          )}
          <ButtonGroup fullWidth>{actionButtons}</ButtonGroup>
        </React.Fragment>
      )
    }
    return (
      <Card
        className={className}
        collapsed={collapsed}
        header={header}
        customAttributes={{ 'data-test': 'chatbot-card' }}
      >
        {cardContent}
      </Card>
    )
  }
}

const styledComponent = styled(Component)`
  ${helpers}
`

const ChatbotCardDisplay = withTheme(styledComponent)

type StateProps = Pick<Props, 'chatbotId' | 'previewMode'>
interface DispatchProps {
  pingConversation: (x: {
    pathId: string
    chatbotId: string
    autoReplyMessage: string
  }) => void
  displayFaqs: (x: { actionId: string; faqsOrder: string[] }) => void
  displayBooking: (x: { showAll: boolean; service: Service }) => void
  handleBotLoadFail: () => void
  logException: (x: { error: Error }) => void
  updatePreviewUI: () => void
}

const mapStateToProps = (
  state: RootState,
  ownProps: { chatbotId: string }
): StateProps => {
  const { chatbotId } = currentViewParamsSelector(state)
  return {
    chatbotId: ownProps.chatbotId || chatbotId,
    previewMode: rpPreviewModeSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  pingConversation: ({ pathId, chatbotId, autoReplyMessage }) => {
    dispatch(pingConversation({ pathId, chatbotId, autoReplyMessage }))
    dispatch(
      trackEvent({
        trackingName: 'CHAT_CLICK',
        properties: {},
        destinations: [TrackedEventDestinations.SHIFT]
      })
    )
    dispatch(
      trackEvent({
        trackingName: 'CHAT_STARTED',
        properties: {},
        destinations: [
          TrackedEventDestinations.SHIFT,
          TrackedEventDestinations.ADOBE
        ]
      })
    )
  },
  displayFaqs: ({ actionId, faqsOrder }) => {
    dispatch(
      trackEvent({
        trackingName: 'CHAT_CLICK',
        properties: {},
        destinations: [TrackedEventDestinations.SHIFT]
      })
    )
    dispatch(
      navigate({ viewId: NAVIGATION.FAQS, params: { actionId, faqsOrder } })
    )
  },
  displayBooking: ({ showAll, service }) => {
    dispatch(
      trackEvent({
        trackingName: 'CHAT_CLICK',
        properties: {},
        destinations: [TrackedEventDestinations.SHIFT]
      })
    )
    dispatch(
      navigate({ viewId: NAVIGATION.BOOKING, params: { showAll, service } })
    )
  },
  handleBotLoadFail: () => {
    dispatch(
      showToast({ message: 'Failed to load assistant', variant: 'error' })
    )
  },
  logException: ({ error }) => {
    dispatch(logAction({ error }))
  },
  updatePreviewUI: () => {
    dispatch(updatedWipCard({}))
  }
})

export const ChatbotCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatbotCardDisplay)
