import * as React from 'react'
import { withTheme } from 'styledComponents'

import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { ThemeInterface } from 'types'

export interface Props {
  className?: string
  theme: ThemeInterface
  size?: string
}

const Component = ({ className, size = '1x', theme }: Props) => (
  <Icon
    className={className}
    icon={faCircleNotch}
    size={size as SizeProp}
    spin
    color={theme.colors.light}
  />
)

export const Spinner = withTheme(Component)
