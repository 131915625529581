import {
  ConversationAction,
  ConversationSession,
  ProviderTypingInterface,
  SocketMessage
} from 'types'
import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('conversations')

export interface FetchConversationPayload {
  conversationId: string
}
export interface PingConversationPayload {
  chatbotId: string
  pathId: string
  autoReplyMessage: string
}
export interface ReplyActionPayload {
  conversationId: string
  conversationAction: ConversationAction
}

export interface ReplyTextPayload {
  conversationId: string
  text: string
}
export interface SubmitFormPayload {
  conversationId: string
  cardId: string
  formId: string
  conversionCode: string
}

export const fetchConversation = actionCreator<FetchConversationPayload>(
  'FETCH_CONVERSATION'
)
export const fetchConversationSuccess = actionCreator<{
  conversationId: string
}>('FETCH_CONVERSATION_SUCCESS')
export const pingConversation = actionCreator<PingConversationPayload>('PING')
export const updateConversationSession = actionCreator<
  Pick<
    ConversationSession,
    'conversationId' | 'messages' | 'companyId' | 'sourceCompanyId'
  >
>('UPDATE_SESSION')
export const messageSent = actionCreator<SocketMessage>('MESSAGE_SENT')
export const messageUpdated = actionCreator<SocketMessage>('MESSAGE_UPDATED')
export const replyAction = actionCreator<ReplyActionPayload>('REPLY_ACTION')
export const replyText = actionCreator<ReplyTextPayload>('REPLY_TEXT')
export const submitForm = actionCreator<SubmitFormPayload>('SUBMIT_FORM')
export const providerTyping = actionCreator<ProviderTypingInterface>(
  'PROVIDER_TYPING'
)
export const botTyping = actionCreator<ProviderTypingInterface>('BOT_TYPING')
export const cancelProviderTyping = actionCreator<
  Pick<ProviderTypingInterface, '_id' | 'conversationId'>
>('CANCEL_PROVIDER_TYPING')
export const typeKey = actionCreator<{ conversationId: string }>('TYPE_KEY')
export const checkIfMarkRead = actionCreator<{ conversationId?: string }>(
  'CHECK_MARK_READ'
)
export const markRead = actionCreator<{ conversationId: string }>('MARK_READ')
export const markReadInState = actionCreator<{ conversationId: string }>(
  'MARK_CONVERSATION_READ'
)
export const submitConversationCard = actionCreator<{
  conversationId: string
  cardId: string
  data: any
}>('SUBMIT_CONVERSATION_CARD')
