import { createSelector } from 'reselect'

import { RootState } from 'state/reducer'

export const cardIndexSelector = createSelector(
  (state: RootState, _id: string) => state.entities.cards.byId[_id].props.index,
  index => index || 0
)

export const cardCompletedIndexSelector = createSelector(
  (state: RootState, _id: string) =>
    state.entities.cards.byId[_id].props.completedIndex,
  index => index || 0
)

export const cardByIdSelector = createSelector(
  (state: RootState, _id: string) => state.entities.cards.byId[_id],
  card => card
)

export const cardPropsSelector = createSelector(
  cardByIdSelector,
  card => card.props
)
