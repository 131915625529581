import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { navigate } from 'state/router'
import { isToolbarEnabledSelector } from 'state/ui'
import { RootState } from 'types'
import { ActionButtonDisplay, Props } from './ActionButton.display'

const mapStateToProps = (state: RootState) => ({
  isToolbarModeEnabled: isToolbarEnabledSelector(state)
})

type DispatchProps = Pick<Props, 'navigate'>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: (viewId, params) => {
    dispatch(navigate({ viewId, params }))
  }
})

export const ActionButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionButtonDisplay)
