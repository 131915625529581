import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { navigate } from 'state/router'
import { GenericCardDisplay, Props } from './GenericCard.display'

const mapStateToProps = () => ({})

type DispatchProps = Pick<Props, 'navigate'>

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: viewId => {
    dispatch(navigate({ viewId }))
  }
})

export const GenericCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericCardDisplay)
