import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { joinVideoCall } from 'state/video'
import { CommonMessageDisplay, Props } from './CommonMessage.display'

type DispatchProps = Pick<Props, 'joinCall'>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  joinCall: messageId => {
    dispatch(joinVideoCall({ messageId }))
  }
})

export const CommonMessageContainer = connect(
  () => ({}),
  mapDispatchToProps
)(CommonMessageDisplay)
