import { combineReducers } from 'redux'

import { authors, AuthorsState } from './authors'
import { cards, CardsState } from './cards'
import { conversations, ConversationsState } from './conversations'
import { views, ViewsState } from './views'

export interface EntitiesState {
  authors: AuthorsState
  cards: CardsState
  conversations: ConversationsState
  views: ViewsState
}

export const entities = combineReducers({
  authors,
  cards,
  conversations,
  views
})
