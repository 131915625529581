import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import {
  conversationSelector,
  fetchConversation,
  replyAction,
  submitConversationCard,
  submitForm
} from 'state/entities/conversations'
import { RootState } from 'types'
import { ConversationDisplay, Props } from './Conversation.display'

type StateProps = Pick<Props, 'conversation'>

const mapStateToProps = (
  state: RootState,
  ownProps: { conversationId: string }
): StateProps => ({
  conversation: conversationSelector(state, ownProps.conversationId)
})

type DispatchProps = Pick<
  Props,
  'replyAction' | 'fetchConversation' | 'submitConversationCard' | 'submitForm'
>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchConversation: conversationId => {
    dispatch(fetchConversation({ conversationId }))
  },
  replyAction: ({ conversationId, conversationAction }) => {
    dispatch(replyAction({ conversationId, conversationAction }))
  },
  submitConversationCard: ({ conversationId, cardId, data }) => {
    dispatch(submitConversationCard({ conversationId, cardId, data }))
  },
  submitForm: ({ conversationId, formId, cardId, conversionCode }) => {
    dispatch(submitForm({ conversationId, formId, cardId, conversionCode }))
  }
})

export const ConversationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationDisplay)
