import * as React from 'react'
import styled from 'styledComponents'

import { withForm } from 'components/_hoc'
import {
  Card,
  ConnectedRadio,
  ConnectedSlider,
  Flex,
  Label,
  SubmitButton
} from 'components/_utility'
import { helpers } from 'style/mixins/'
import { HelpersProps } from 'types'

export interface Props extends HelpersProps {
  className?: string
  submit: () => void
}

const Component = ({ className, submit }: Props) => {
  return (
    <Card className={className}>
      {/* wrapped to form to handle ENTER key submission */}
      <form onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}>
        <Flex column mb={12}>
          <Label>Mileage</Label>
          <ConnectedSlider
            required
            name="tradeInVehicle.mileage"
            min={0}
            max={200000}
          />
        </Flex>
        <Flex column mb={12}>
          <Label>Condition</Label>
          <ConnectedRadio
            required
            name="tradeInVehicle.condition"
            option="Fair"
            label="Fair"
          />
          <ConnectedRadio
            required
            name="tradeInVehicle.condition"
            option="Good"
            label="Good"
          />
          <ConnectedRadio
            required
            name="tradeInVehicle.condition"
            option="Very Good"
            label="Very Good"
          />
          <ConnectedRadio
            required
            name="tradeInVehicle.condition"
            option="Excellent"
            label="Excellent"
          />
        </Flex>
        <SubmitButton
          _width={100}
          primary
          failureMessage="Please select a mileage and condition"
          onClick={submit}
        >
          Next
        </SubmitButton>
      </form>
    </Card>
  )
}

export const Step2 = styled(withForm(Component, 'trade_in_2'))`
  ${helpers}
`
