import { put, select, takeEvery } from 'redux-saga/effects'
import { getReviewsAction, logAction } from 'state/_sagas/_actions'
import { reviews } from 'state/_sagas/_subgenerators'
import { cardPropsSelector, updateCard } from 'state/entities/cards'
import { formSelector } from 'state/forms'
import { Action } from 'typescript-fsa'

function* saga(action: Action<{ index: number; cardId: string }>) {
  const { index, cardId } = action.payload
  try {
    const { reviewsSource, reviewsSort } = yield select(formSelector, 'reviews')
    const { reviewsClientId } = yield select(cardPropsSelector, cardId)
    const reviewsPayload = yield* reviews.getReviews(
      index,
      reviewsSort,
      reviewsSource,
      reviewsClientId
    )
    // eslint-disable-next-line func-names
    if (reviewsSort.value === 'rating:asc') {
      // eslint-disable-next-line func-names
      reviewsPayload.reviews.sort(function(
        a: { rating: number },
        b: { rating: number }
      ) {
        return a.rating - b.rating
      })
    } else if (reviewsSort.value === 'rating:desc') {
      // eslint-disable-next-line func-names
      reviewsPayload.reviews.sort(function(
        a: { rating: number },
        b: { rating: number }
      ) {
        return b.rating - a.rating
      })
    } else if (reviewsSort.value === 'date:asc') {
      // eslint-disable-next-line func-names
      reviewsPayload.reviews.sort(function(
        a: { date: string },
        b: { date: string }
      ) {
        const c = new Date(a.date)
        const d = new Date(b.date)
        return c.getTime() - d.getTime()
      })
    } else if (reviewsSort.value === 'date:desc') {
      // eslint-disable-next-line func-names
      reviewsPayload.reviews.sort(function(
        a: { date: string },
        b: { date: string }
      ) {
        const c = new Date(a.date)
        const d = new Date(b.date)
        return d.getTime() - c.getTime()
      })
    }
    yield put(updateCard({ _id: cardId, data: { props: reviewsPayload } }))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(updateCard({ _id: cardId, data: { props: { total: 0 } } }))
  }
}

export function* getReviewsSaga() {
  yield takeEvery(getReviewsAction, saga)
}
