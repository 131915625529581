import { path } from 'ramda'
import * as React from 'react'
import { ConnectedComponent } from 'react-redux'
import styled from 'styledComponents'
import { CardInterface, ToastInterface } from 'types'

import { cardMap } from 'components/_cards'
import { Container } from 'components/_utility'

export interface Props {
  className?: string
  card: CardInterface
  disabled: boolean
  setSequenceIndex: () => void
  showToast: (toast: ToastInterface) => void
}

const Component = ({
  className,
  card,
  disabled,
  setSequenceIndex,
  showToast
}: Props) => {
  const title = path(['header', 'title'], card)
  const props = {
    topBar: true,
    _id: card._id,
    ...card.props,
    collapsed: true,
    header: {
      title: `Next${title ? `: ${title}` : ''}`,
      status: card.props.status,
      type: 'btn',
      alwaysShow: true,
      onClick: () => {
        disabled
          ? showToast({
              variant: 'error',
              message: 'Please complete the current step first'
            })
          : setSequenceIndex()
      }
    }
  }

  const CardComponent: ConnectedComponent<any, any> = cardMap[card.template]

  return (
    <Container className={className}>
      <div>
        <CardComponent {...props} />
      </div>
    </Container>
  )
}

export const CardSequenceFooter = styled(Component)`
  padding: 0 12px 12px;
`
