import * as React from 'react'
import styled, { keyframes } from 'styledComponents'

import { faUser, faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { Text } from 'components/_utility'
import { ThemeInterface } from 'types'

// import { shadow } from 'style/mixins'

export interface Props {
  className?: string
  theme: ThemeInterface
  users: string[]
}

const Component = ({ className, users }: Props) => {
  const getTypingUsersString = () => {
    const delimiter = users.length > 2 ? `, ` : ` `
    const usersString = users.reduce(
      (accumulator, user, index) =>
        `${accumulator}${index ? delimiter : ''}${
          index && index === users.length - 1 ? 'and ' : ''
        }${user}`,
      ''
    )
    return `${usersString} ${users.length === 1 ? 'is' : 'are'} typing`
  }
  return (
    <Text className={className} isBody>
      <Icon icon={users.length > 1 ? faUsers : faUser} />
      <span>{getTypingUsersString()}</span>
      <TypingIndicator />
    </Text>
  )
}

const ellipsis = keyframes`
  50% { opacity: 1  }
`

const TypingIndicator = styled(({ className }: { className?: string }) => (
  <span className={className}>
    <span />
    <span />
    <span />
  </span>
))`
  margin-left: 6px;

  > span {
    margin-right: 3px;
    background-color: ${p => p.theme.gray.base};
    opacity: 0.3;

    border-radius: 50%;
    height: 7px;
    width: 7px;
    display: inline-block;

    &:nth-child(1) {
      animation: ${ellipsis} 1.2s infinite;
    }

    &:nth-child(2) {
      animation: ${ellipsis} 1.2s 0.3s infinite;
    }

    &:nth-child(3) {
      animation: ${ellipsis} 1.2s 0.6s infinite;
      margin-right: 0;
    }
  }
`

export const ConversationsTyping = styled(Component)`
  padding: 9px 12px;
  border-top: solid 1px ${p => p.theme.gray.lightest};
  font-size: 13px !important;
  background: #fff;
  svg {
    margin-right: 6px;
  }
`
