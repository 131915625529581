import { path } from 'ramda'
import { delay } from 'redux-saga'
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { Action, isType } from 'typescript-fsa'

import rpApiService from 'services/rp.api'
import socket from 'services/rp.socket'
import settings from 'settings'
import { logAction, trackEvent } from 'state/_sagas/_actions'
import { init as initSocket } from 'state/socket'
import { InventoryElement, TrackedEventDestinations } from 'types'
import customWindow from 'utility/storage/customWindow'
import {
  fetchLead,
  FetchLeadPayload,
  pingLead,
  pingLeadFail,
  pingLeadSuccess,
  selectVehicle,
  updateLead
} from './lead.actions'
import { leadSelector } from './lead.selectors'

function* fetchLeadSaga(action: Action<FetchLeadPayload>) {
  try {
    const { leadId } = action.payload

    const leadData = yield call(rpApiService.getLead, leadId)
    yield put(updateLead(leadData))
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

function* pingLeadSaga(
  action: Action<{ performUpdate: boolean; withoutDelay: boolean }>
) {
  try {
    if (!socket.isInitialized()) {
      yield put(initSocket())
      while (!socket.isInitialized()) {
        yield delay(1000)
      }
    }
    const { performUpdate, withoutDelay } = action.payload
    if (!withoutDelay) {
      yield call(delay, 5000)
    }

    const lead = yield select(leadSelector)

    let leadId: string | null = lead._id
    if (!leadId) {
      const { _id }: { _id: string } = yield call(rpApiService.createLead, {
        deviceType: lead.deviceType,
        customData: lead.customData
      })
      leadId = _id
      yield put(updateLead({ _id }))
    }

    if (performUpdate) {
      const leadData: any = {
        clientEmail: lead.email,
        clientPhone: lead.phone,
        firstName: lead.firstName,
        lastName: lead.lastName,
        vehicleMake: lead.vehicleMake,
        vehicleModel: lead.vehicleModel,
        vehicleYear: lead.vehicleYear,
        vehicleVin: lead.vehicleVin,
        deviceType: lead.deviceType,
        customData: lead.customData,
        inquiryTypeId: lead.inquiryTypeId
      }
      if (lead.tradeInVehicle) {
        leadData.tradeInVehicle = {
          make: path(['tradeInVehicle', 'make', 'label'], lead),
          model: path(['tradeInVehicle', 'model', 'label'], lead),
          year: path(['tradeInVehicle', 'year', 'label'], lead),
          trim: path(['tradeInVehicle', 'trim', 'label'], lead),
          condition: path(['tradeInVehicle', 'condition', 'value'], lead),
          mileage: path(['tradeInVehicle', 'mileage'], lead) || 0,
          price: path(['tradeInVehicle', 'price'], lead)
        }
      }
      // if the client is shift enabled on ep, let api know for special shift adfxml changes
      if (window.parent.document.getElementById('shiftScript')) {
        leadData.isShiftEnabled = true
      }
      yield call(rpApiService.patchLead, leadId, leadData)

      if (
        customWindow.localStorage.getItem(
          settings.getFullLeadIdStorageKey(leadId)
        ) !== 'true' &&
        ((lead.email && lead.phone) ||
          (lead.vehicleMake &&
            lead.vehicleModel &&
            lead.vehicleYear &&
            lead.vehicleVin) ||
          lead.tradeInVehicle)
      ) {
        customWindow.localStorage.setItem(
          settings.getFullLeadIdStorageKey(leadId),
          'true'
        )
        // tell shift about the lead creation with collected email and phone or vehicle information
        yield put(
          trackEvent({
            trackingName: 'LEAD_CREATED',
            properties: { leadId },
            destinations: [TrackedEventDestinations.SHIFT]
          })
        )
      }
    }
    yield put(pingLeadSuccess())
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(pingLeadFail())
  }
}

function* selectVehicleSaga(action: Action<InventoryElement>) {
  try {
    yield put(pingLead({ performUpdate: true, withoutDelay: true }))
    const pingLeadResultAction = yield take([pingLeadSuccess, pingLeadFail])
    if (isType(pingLeadResultAction, pingLeadFail)) {
      throw new Error('Unable to ping lead')
    }

    const lead = yield select(leadSelector)
    yield call(rpApiService.updateLeadVehicle, lead._id, action.payload)

    if (
      customWindow.localStorage.getItem(
        settings.getFullLeadIdStorageKey(lead._id)
      ) !== 'true'
    ) {
      customWindow.localStorage.setItem(
        settings.getFullLeadIdStorageKey(lead._id),
        'true'
      )
      // tell shift about the lead creation with collected email and phone or vehicle information
      yield put(
        trackEvent({
          trackingName: 'LEAD_CREATED',
          properties: { leadId: lead._id },
          destinations: [TrackedEventDestinations.SHIFT]
        })
      )
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export default function* conversationsSaga() {
  yield all([
    takeEvery(fetchLead, fetchLeadSaga),
    takeLatest(pingLead, pingLeadSaga),
    takeEvery(selectVehicle, selectVehicleSaga)
  ])
}
