import actionCreatorFactory from 'typescript-fsa'

import { CompanySettingsState } from 'types'

const actionCreator = actionCreatorFactory('company')

export const updateCompanySettings = actionCreator<CompanySettingsState>(
  'UPDATE_SETTINGS'
)

export const setRpApiSessionToken = actionCreator<{
  rpApiSessionToken: string
}>('SET_RP_API_SESSION_TOKEN')
