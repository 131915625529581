import * as React from 'react'
import styled from 'styledComponents'

import { setCardIndex } from 'state/entities/cards'

import { Card, ConnectedCustomSelect, Flex, Text } from 'components/_utility'
import { CustomSelectOptionInterface } from 'types'

import {
  faFileSignature,
  faMoneyBill,
  faPiggyBank
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

export interface Props {
  className?: string
  cardId: string
}

const Component = ({ className, cardId }: Props) => (
  <Card className={className}>
    <ConnectedCustomSelect
      name="payment.variant"
      sideEffect={setCardIndex({ _id: cardId, index: 1 })}
      column
    >
      <StyledOption value="lease">
        <IconWrapper>
          <Icon size="lg" icon={faFileSignature} />
        </IconWrapper>
        <Text>Lease</Text>
      </StyledOption>
      <StyledOption value="finance">
        <IconWrapper>
          <Icon size="lg" icon={faPiggyBank} />
        </IconWrapper>
        <Text>Finance</Text>
      </StyledOption>
      <StyledOption value="cash">
        <IconWrapper>
          <Icon size="lg" icon={faMoneyBill} />
        </IconWrapper>
        <Text> Cash</Text>
      </StyledOption>
    </ConnectedCustomSelect>
  </Card>
)

export const Step1 = styled(Component)``
interface OptionProps extends CustomSelectOptionInterface {
  className?: string
  children: React.ReactNode
}

const Option = ({ className, children, selectValue }: OptionProps) => (
  <Flex
    className={className}
    onClick={() => selectValue && selectValue()}
    _p={12}
    pointer
  >
    {children}
  </Flex>
)

const StyledOption = styled(Option)<OptionProps>`
  border: solid 2px ${p => p.theme.colors.lighter};
  background-color: ${p => p.theme.colors.lightest};

  transition: border-color 250ms;

  ${p =>
    p.selected &&
    `
    border-color: ${p.theme.colors.original};
  `}

  &:hover {
    border-color: ${p => p.theme.colors.original};
  }

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  svg {
    color: ${p => p.theme.colors.original};
  }
`

const IconWrapper = styled.div`
  width: 36px;
`
