import { delay } from 'redux-saga'
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest
} from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { logAction } from 'state/_sagas/_actions'
import { authorSelector } from 'state/entities/authors'
import {
  setTitle,
  startAnimateTitle,
  stopAnimateTitle,
  titleSelector
} from 'state/ui'

const TITLE_ANIMATION_INTERVAL = 3000

function* animateTitleBackgroundSaga(
  action: Action<{ authorId?: string }>,
  originalTitle: string
) {
  try {
    while (true) {
      const { value: prevTitle, isAnimated } = yield select(titleSelector)
      if (!isAnimated) {
        return yield put(stopAnimateTitle())
      }

      let typingTitle
      const author = yield select(authorSelector, action.payload.authorId)
      if (action.payload.authorId && author) {
        typingTitle = `${author.firstName} says...`
      } else {
        typingTitle = '(1) ' + originalTitle
      }

      const newTitle = prevTitle === typingTitle ? originalTitle : typingTitle

      yield put(setTitle({ value: newTitle }))
      yield call(delay, TITLE_ANIMATION_INTERVAL)
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(stopAnimateTitle())
  }
}

let original = ''
function* saga(action: Action<{ authorId: string }>) {
  original = original || window.top.document.title
  const { isAnimated } = yield select(titleSelector)

  if (!window.document.hasFocus() && isAnimated) {
    const animateTitleTask = yield fork(
      animateTitleBackgroundSaga,
      action,
      original
    )
    yield take(stopAnimateTitle)
    yield cancel(animateTitleTask)
  } else {
    yield put(stopAnimateTitle())
  }
  yield put(setTitle({ value: '' }))
  window.top.document.title = original
}

export function* animateTitleSaga() {
  yield takeLatest(startAnimateTitle, saga)
}
