import { delay } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import settings from 'settings'
import { navigate } from 'state/router'
import {
  behaviorSelector,
  chatBoxExpandOnExit,
  chatBoxExpandOnTimeout,
  checkExpandOnExit,
  checkExpandOnHover,
  disableTriggers,
  exitTriggerAllowedSelector,
  hideLauncherOnMouseLeave,
  initialCardIdsSelector,
  initTriggers,
  isToolbarEnabledSelector,
  isWidgetOpen,
  launcherSelector,
  playSound,
  setExitTrigger,
  setLauncherState,
  setNavState,
  startAnimateTitle,
  windowSelector
} from 'state/ui'
import { sizes } from 'style/variables'
import customWindow from 'utility/storage/customWindow'

function* checkExpandOnExitSaga() {
  const isAllowed = yield select(exitTriggerAllowedSelector)
  if (!isAllowed) return

  const behavior = yield select(behaviorSelector)
  const trigger = behavior.triggers.exitTrigger
  const isOpen = yield select(isWidgetOpen)
  const isTriggerDisabled =
    customWindow.localStorage.getItem(settings.triggersDisabledStorageKey) ===
    'true'
  if (!isOpen && !isTriggerDisabled) {
    yield put(setLauncherState({ cardIds: trigger.cardIds, open: true }))
    yield put(playSound())
    yield put(startAnimateTitle({}))
    yield put(disableTriggers())
  }
}

function* chatBoxExpandOnExitTimeoutSaga() {
  const behavior = yield select(behaviorSelector)

  if (!behavior.triggers.exitTrigger) return

  const trigger = behavior.triggers.exitTrigger
  yield call(delay, (trigger.time || 0) * 1000)
  yield put(setExitTrigger({ isAllowed: true }))
}

function* checkExpandOnHoverSaga() {
  const isOpen = yield select(isWidgetOpen)
  const initialCardIds = yield select(initialCardIdsSelector)
  const isToolbarOpen = yield select(windowSelector)
  const isToolbarEnabled = yield select(isToolbarEnabledSelector)

  if (isToolbarEnabled && !isToolbarOpen) {
    yield put(setNavState({ open: true }))
    return
  }

  if (!isOpen && initialCardIds.length > 0) {
    yield put(
      setLauncherState({
        cardIds: initialCardIds,
        open: true,
        hideOnMouseLeave: true
      })
    )
  }
}

function* hideLauncherOnMouseLeaveSaga() {
  const launcher = yield select(launcherSelector)

  if (launcher.hideOnMouseLeave) {
    yield put(setLauncherState({ open: false, hideOnMouseLeave: false }))
  }
}

function* chatBoxExpandOnTimeoutSaga() {
  const behavior = yield select(behaviorSelector)
  if (!behavior.triggers.timeTrigger) return

  const trigger = behavior.triggers.timeTrigger
  yield call(delay, (trigger.time || 0) * 1000)
  const isOpen = yield select(isWidgetOpen)
  const isTriggerDisabled =
    customWindow.localStorage.getItem(settings.triggersDisabledStorageKey) ===
    'true'

  if (!isOpen && !isTriggerDisabled) {
    yield put(setLauncherState({ cardIds: trigger.cardIds, open: true }))
    yield put(playSound())
    yield put(startAnimateTitle({}))
    yield put(disableTriggers())
  }
}

function disableTriggersSaga() {
  customWindow.localStorage.setItem(settings.triggersDisabledStorageKey, 'true')
}

function* saga() {
  const isToolbarEnabled = yield select(isToolbarEnabledSelector)
  if (sizes.isMobile || isToolbarEnabled) {
    yield put(disableTriggers())
  } else {
    yield put(chatBoxExpandOnTimeout())
    yield put(chatBoxExpandOnExit())
  }
}

export function* initTriggersSaga() {
  yield takeEvery(initTriggers, saga)
  yield takeEvery(chatBoxExpandOnTimeout, chatBoxExpandOnTimeoutSaga)
  yield takeEvery(chatBoxExpandOnExit, chatBoxExpandOnExitTimeoutSaga)
  yield takeEvery(checkExpandOnExit, checkExpandOnExitSaga)
  yield takeEvery(checkExpandOnHover, checkExpandOnHoverSaga)
  yield takeEvery(hideLauncherOnMouseLeave, hideLauncherOnMouseLeaveSaga)
  yield takeEvery(disableTriggers, disableTriggersSaga)
  yield takeEvery(navigate, disableTriggersSaga)
}
