import { lighten } from 'polished'
import * as React from 'react'
import styled, { withTheme } from 'styledComponents'

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Flex, Text } from 'components/_utility'
import { sizes } from 'style/variables'
import { ThemeInterface, ToastInterface } from 'types'

export interface Props {
  className?: string
  isToolbarEnabled?: boolean
  theme: ThemeInterface
  toast: ToastInterface
}

const Component = ({
  className,
  theme,
  toast: { variant, message }
}: Props) => {
  const color = {
    success: theme.green,
    warning: theme.orange,
    error: theme.red
  }[variant]

  const icon = {
    success: faCheck,
    warning: faExclamationTriangle,
    error: faTimes
  }[variant]

  return (
    <Flex
      className={className}
      row
      _width={100}
      _height={100}
      _p={18}
      justifyContent="center"
      alignItems="center"
    >
      <Icon icon={icon} color={color} />
      <Text ml={12} color={color}>
        {message}
      </Text>
    </Flex>
  )
}

export const DockToast = withTheme(styled(Component)`
  position: absolute;
  height: ${p =>
    p.isToolbarEnabled ? sizes.toolbarHeight : sizes.utilityHeight}px;
  bottom: ${p =>
    p.isToolbarEnabled ? sizes.toolbarHeight : sizes.utilityHeight}px;

  z-index: 3;

  ${p =>
    p.toast.variant === 'success' &&
    `background-color: ${lighten(0.45, p.theme.green)}`};
  ${p =>
    p.toast.variant === 'warning' &&
    `background-color: ${lighten(0.35, p.theme.orange)}`};
  ${p =>
    p.toast.variant === 'error' &&
    `background-color: ${lighten(0.45, p.theme.red)}`};

  ${Text} {
    max-height: calc(${sizes.utilityHeight}px - 2px);
    overflow: auto;
  }

  &.toast-enter {
    bottom: ${p =>
      p.isToolbarEnabled ? sizes.toolbarHeight : sizes.utilityHeight}px;
  }

  &.toast-enter-done {
    bottom: 0;
    transition: bottom ease 500ms;
  }

  &.toast-exit {
    bottom: 0;
  }

  &.toast-exit-done {
    bottom: -${p => (p.isToolbarEnabled ? sizes.toolbarHeight : sizes.utilityHeight)}px;
    transition: bottom ease 500ms;
  }
`)
