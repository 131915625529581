import { InventoryElement, LeadState, PathAttributes } from 'types'
import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('lead')

export interface FetchLeadPayload {
  leadId: string
}
export const pingLead = actionCreator<{
  performUpdate: boolean
  withoutDelay?: boolean
}>('PING_LEAD')
export const pingLeadSuccess = actionCreator('PING_LEAD_SUCCESS')
export const pingLeadFail = actionCreator('PING_LEAD_FAIL')

export const fetchLead = actionCreator<FetchLeadPayload>('FETCH_LEAD')
export const updateLead = actionCreator<Partial<LeadState>>('UPDATE_LEAD')
export const setSequenceIndex = actionCreator<{ _id: string; index: number }>(
  'SET_SEQUENCEINDEX'
)

export const setLeadCustomData = actionCreator<PathAttributes>(
  'SET_CUSTOM_DATA'
)
export const selectVehicle = actionCreator<InventoryElement>('SELECT_VEHICLE')
