import { createSelector } from 'reselect'

import { RootState } from 'state/reducer'
import {
  AppointmentInterface,
  BookingField,
  FieldOptionInterface,
  LeadState
} from 'types'

export const leadSelector = createSelector(
  (state: RootState) => state.lead,
  lead => lead
)

export const cardSequenceIndexSelector = createSelector(
  (state: RootState, _id: string) => state.lead._viewState[_id].index,
  index => index
)

export const vehicleSelector = createSelector(
  (state: RootState) => state.lead || {},
  lead => {
    const { vehicleMake, vehicleModel, vehicleYear, vehicleVin } = lead
    return { vehicleMake, vehicleModel, vehicleYear, vehicleVin }
  }
)

export const tradeInVehicleSummarySelector = createSelector(
  (state: RootState) => state.lead.tradeInVehicle || {},
  tradeInVehicle => {
    const { make, model, year, trim, price, standardOptions } = tradeInVehicle
    if (!(make && model && year && price !== undefined)) {
      return undefined
    }
    return {
      make: make.label,
      model: model.label,
      year: year.value,
      trim: trim.label,
      price,
      standardOptions
    }
  }
)

export const appointmentSummarySelector = createSelector(
  (state: RootState) => state.lead.appointment && state.lead.appointment.type,
  (state: RootState) => state.lead.appointment && state.lead.appointment.date,
  (state: RootState) => state.lead.appointment && state.lead.appointment.time,
  (type?: FieldOptionInterface, date?: string, time?: string) => {
    if (!(type && date && time)) {
      return undefined
    }
    return {
      type,
      date,
      time
    } as AppointmentInterface
  }
)

export const leadInfoSelector = createSelector(
  (state: RootState) => state.company.bookingFields,
  (state: RootState) => state.lead,
  (bookingFields: BookingField[], lead: LeadState) => {
    const requiredBookingFields = bookingFields.filter(
      bookingField => bookingField.required || bookingField.consent
    )
    const { firstName, lastName, phone, email, customData, appointment } = lead
    const fields = (appointment && appointment.fields) || {}
    const isRequiredFieldsFilled = requiredBookingFields.every(requiredField =>
      Boolean(fields[requiredField._id])
    )

    const filledBookingFields = Object.keys(fields)
      .filter(key => Boolean(fields[key]))
      .map(key => ({
        _id: key,
        value: fields[key].value || fields[key]
      }))

    if (!(firstName && lastName && email && phone && isRequiredFieldsFilled)) {
      return undefined
    }
    return {
      firstName,
      lastName,
      phone,
      email,
      extraFields: filledBookingFields,
      customData
    }
  }
)

export const appointmentServiceIdSelector = createSelector(
  (state: RootState) =>
    state.lead.appointment && state.lead.appointment.type.value,
  (value: string) => value
)

export const paymentVariantSelector = createSelector(
  (state: RootState) => state.lead.payment && state.lead.payment.variant,
  (value: string) => value
)

export const fullLeadNameSelector = createSelector(
  (state: RootState) => state.lead.firstName,
  (state: RootState) => state.lead.lastName,
  (firstName: string, lastName: string) => `${firstName} ${lastName}`
)

export const videoDisplayNameSelector = createSelector(
  (state: RootState) => state.lead.firstName,
  (state: RootState) => state.lead.lastName,
  (firstName: string, lastName: string) =>
    firstName || lastName ? `${firstName} ${lastName}` : `Visitor`
)
