import * as React from 'react'

interface Context {
  hiddenPeers: string[]
  togglePeer: (peerId: string) => void
}

export const HiddenPeers = React.createContext<Context>({
  hiddenPeers: [],
  togglePeer: () => null
})
