import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { unreadCountSelector } from 'state/entities/conversations'
import {
  isToolbarEnabledSelector,
  navButtonsSelector,
  navigateView,
  progressSelector
} from 'state/ui'
import { RootState } from 'types'
import { NavButtonDisplay, Props } from './NavButton.display'

type StateProps = Pick<
  Props,
  'progress' | 'unreadCount' | 'navButtonsLength' | 'toolbarMode'
>
const mapStateToProps = (
  state: RootState,
  ownProps: { viewId: string }
): StateProps => ({
  progress: progressSelector(state, ownProps.viewId),
  unreadCount: unreadCountSelector(state),
  navButtonsLength: navButtonsSelector(state).length,
  toolbarMode: isToolbarEnabledSelector(state)
})

type DispatchProps = Pick<Props, 'navigate'>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: viewId => dispatch(navigateView({ viewId }))
})

export const NavButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavButtonDisplay)
