import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { trackEvent } from 'state/_sagas/_actions'
import { goBack, previousViewIdSelector } from 'state/router'
import { setNavState, setWindowState } from 'state/ui'
import { RootState, TrackedEventDestinations } from 'types'
import { HeaderDisplay, Props } from './Header.display'

type StateProps = Pick<Props, 'previousViewId'>
const mapStateToProps = (state: RootState): StateProps => ({
  previousViewId: previousViewIdSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goBack: () => dispatch(goBack({})),
  closeWindow: () => {
    dispatch(setWindowState({ open: false }))
    dispatch(setNavState({ open: false }))
    dispatch(
      trackEvent({
        trackingName: 'CHAT_CLOSED',
        properties: {},
        destinations: [TrackedEventDestinations.SHIFT, TrackedEventDestinations.ADOBE]
      })
    )
  }
})

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderDisplay)
