import { CHATBOT_FORM_MAIN_FIELDS } from 'common/constants'
import {
  CheckboxInterface,
  ContactFormFieldInterface,
  ContactFormFieldType,
  FieldOptionInterface
} from 'types'
import chatbotFormFieldConverter from './chatbotFormFieldConverter'

export const getFormCardRows = (rows: any) => {
  const formRows: any = []
  rows.forEach((formRow: any) => {
    const columns: ContactFormFieldInterface[] = []
    formRow.columns.forEach((field: any) => {
      const column: any = {
        _id: field._id,
        type: field.type,
        name: '',
        label: field.label,
        required: field.required,
        consent: field.consent,
        options: field.options,
        phoneMaskDisabled: field.phoneMaskDisabled || false,
        maxCharacters: field.maxCharacters,
        defaultOption: field.defaultOption,
        isIPMatchingAvailable: field.isIPMatchingAvailable || false,
        isRoutingAvailable: field.isRoutingAvailable || false
      }

      if (CHATBOT_FORM_MAIN_FIELDS.includes(field.type)) {
        column.name = field.type
      } else {
        column.name = chatbotFormFieldConverter.createFieldName({
          fieldId: field._id,
          fieldLabel: field.label
        })
      }

      if (
        [
          ContactFormFieldType.DROPDOWN,
          ContactFormFieldType.INQUIRY_TYPE_DROPDOWN
        ].includes(column.type)
      ) {
        const { defaultOption, options = [] } = field
        const isOptionsHasDefault = options.some(
          (option: FieldOptionInterface) =>
            defaultOption && option.value === defaultOption.value
        )
        column.options = isOptionsHasDefault
          ? [...options]
          : [defaultOption, ...options]
      }

      if ([ContactFormFieldType.CHECKBOXES].includes(column.type)) {
        column.options = field.options.map((option: CheckboxInterface) => ({
          ...option,
          name: chatbotFormFieldConverter.createFieldName({
            fieldId: field._id,
            fieldLabel: option.label
          })
        }))
      }

      columns.push(column)
    })

    formRows.push({
      columns
    })
  })

  return formRows
}
