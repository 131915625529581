import { connect } from 'socket.io-client'

const DEFAULT_CONNECT_OPTS = {
  autoConnect: false,
  forceNew: true,
  transports: ['websocket']
}

export default class SocketHelper {
  private url: string
  private ws: SocketIOClient.Socket
  private initialized: boolean

  constructor(url: string, options?: SocketIOClient.ConnectOpts) {
    this.url = url
    this.ws = connect(this.url, Object.assign(DEFAULT_CONNECT_OPTS, options))
    this.initialized = false
  }

  public on(type: string, listener: any) {
    this.ws.on(type, listener)

    return this
  }

  public off(type: string, listener: any) {
    this.ws.off(type, listener)
  }

  public connect() {
    if (!this.ws.connected) {
      this.ws.connect()
    }

    return this
  }

  public disconnect() {
    this.ws.connected && this.ws.disconnect()

    return this
  }

  public isConnected() {
    return this.ws.connected
  }

  public emit(event: string, ...args: any[]) {
    this.ws.emit(event, ...args)
  }

  public isInitialized() {
    return this.initialized
  }

  public setInitialized(v: boolean) {
    this.initialized = v
  }
}
