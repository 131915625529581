import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { cardIndexSelector, setCardIndex } from 'state/entities/cards'
import { paymentVariantSelector } from 'state/lead'

import { RootState } from 'types'
import { PaymentDriverCardDisplay, Props } from './PaymentDriverCard.display'

type StateProps = Pick<Props, 'index' | 'paymentVariant'>
const mapStateToProps = (
  state: RootState,
  ownProps: { _id: string }
): StateProps => ({
  index: cardIndexSelector(state, ownProps._id),
  paymentVariant: paymentVariantSelector(state)
})

type DispatchProps = Pick<Props, 'setIndex'>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { _id: string }
): DispatchProps => ({
  setIndex: index => {
    dispatch(setCardIndex({ _id: ownProps._id, index }))
  }
})

export const PaymentDriverCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDriverCardDisplay)
