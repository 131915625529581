import { execute, makePromise } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { REVIEWS_SORT_OPTIONS } from 'common/constants'
import gql from 'graphql-tag'
import * as moment from 'moment'
import { call } from 'redux-saga/effects'
import settings from 'settings'

const link = new HttpLink({
  uri: settings.rpRelayApiUrl,
  headers: {
    'apollographql-client-name': 'EP2',
    'apollographql-client-version': '1.0'
  }
})

const ITEMS_PER_PAGE = 500

const SUPPORTED_REVIEW_SOURCES = ['Google', 'Facebook']

function* getReviews(
  index: number,
  reviewsSort: any,
  reviewsSource: any,
  reviewsClientId: string
) {
  const sort = REVIEWS_SORT_OPTIONS.find(
    option => option.value === reviewsSort.value
  )
  const offset = (index - 1) * ITEMS_PER_PAGE
  let sources = [reviewsSource.value]
  // if (reviewsSource.value === 'All') {
  //   sources = SUPPORTED_REVIEW_SOURCES
  // }
  const reviewsFetch = yield call(() =>
    makePromise(
      execute(link, {
        query: gql`
          query getReviewsReviews(
            $clientId: String!
            $sources: [String!]!
            $sort: String!
            $offset: Int!
            $limit: Int!
          ) {
            reviewsReviews(
              clientId: $clientId
              sources: $sources
              sort: $sort
              offset: $offset
              limit: $limit
            ) {
              total_count
              elements {
                reviewer
                review
                date
                site
                rating
              }
            }
          }
        `,
        variables: {
          clientId: reviewsClientId,
          sources,
          sort: sort ? sort.value : 'rating:desc',
          offset,
          limit: ITEMS_PER_PAGE
        }
      })
    )
  )

  const {
    data: {
      reviewsReviews: { elements, total_count }
    }
  } = yield reviewsFetch
  return {
    index,
    total: total_count,
    reviews: elements.map((reviewIndex: any) => {
      const { site, rating, reviewer, review, date } = reviewIndex
      const formattedDate = moment(date)
        .utc()
        .format('MM-DD-YYYY')
      return {
        source: site,
        rating,
        author: reviewer,
        body: review,
        date: formattedDate
      }
    })
  }
}

function* getSites(reviewsClientId: string) {
  try {
    const GET_REVIEWS_SITES = gql`
      query getReviewsSites($clientId: String!) {
        reviewsSites(clientId: $clientId)
      }
    `
    const sitesFetch = yield call(() =>
      makePromise(
        execute(link, {
          query: GET_REVIEWS_SITES,
          variables: {
            clientId: reviewsClientId
          }
        })
      )
    )

    const {
      data: { reviewsSites }
    } = yield sitesFetch
    return [
      { value: 'All', label: 'All' },
      ...reviewsSites
        .map((item: string) => {
          return { value: item, label: item }
        })
    ]
  } catch (err) {
    console.error(err)
    return [
      { value: 'All', label: 'All' },
      ...SUPPORTED_REVIEW_SOURCES.map(item => ({ value: item, label: item }))
    ]
  }
}

export const reviews = {
  getReviews,
  getSites
}
