import { css } from 'styledComponents'

export interface HelpersProps {
  _p?: number
  pt?: number
  pr?: number
  pb?: number
  pl?: number
  _m?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  fs?: number
  _width?: number
  _height?: number
  overflow?: string
  display?: string
  hidden?: boolean
  relative?: boolean
  padding?: string
  pointer?: boolean
}

export const helpers = (p: HelpersProps) => {
  return css`
    ${p._p ? `padding: ${p._p}px` : ''};
    ${p.pt ? `padding-top: ${p.pt}px` : ''};
    ${p.pr ? `padding-right: ${p.pr}px` : ''};
    ${p.pb ? `padding-bottom: ${p.pb}px` : ''};
    ${p.pl ? `padding-left: ${p.pl}px` : ''};
    ${p.padding === 'standard' ? `padding: 18px 24px` : ''};

    ${p._m ? `margin: ${p._m}px` : ''};
    ${p.mt ? `margin-top: ${p.mt}px` : ''};
    ${p.mr ? `margin-right: ${p.mr}px` : ''};
    ${p.mb ? `margin-bottom: ${p.mb}px` : ''};
    ${p.ml ? `margin-left: ${p.ml}px` : ''};

    ${p.fs ? `font-size: ${p.fs}px` : ''};

    ${p._width ? `width: ${p._width}%` : ''};
    ${p._height ? `height: ${p._height}%` : ''};
    ${p.overflow ? `overflow: ${p.overflow}` : ''};
    ${p.display ? `display: ${p.display}` : ''};
    ${p.hidden ? `visibility: hidden` : ''};
    ${p.relative ? `position: relative` : ''};
    ${p.pointer ? `cursor: pointer` : ''};
  `
}
