import { mergeDeepRight } from 'ramda'
import { Action } from 'redux'
import { isType } from 'typescript-fsa'

import { VIRTUAL_CARDS } from 'common/constants'
import { setCardIndex, updateCard } from './'

import { initializeDeskSuccess } from 'state/_sagas/_actions'
import { CardInterface } from 'types'
import { setCard } from './cards.actions'

export interface CardsState {
  byId: { [_id: string]: CardInterface }
  allIds: string[]
}

const getCardsDefaultState = () => {
  const defaultState: CardsState = { byId: {}, allIds: [] }

  /* ================ init virtual cards ================= */
  Object.keys(VIRTUAL_CARDS).forEach(cardKey => {
    const card = VIRTUAL_CARDS[cardKey]
    const cardId = card._id

    defaultState.byId[cardId] = card
    defaultState.allIds.push(cardId)
  })
  /* ================ init virtual cards ================= */

  return defaultState
}
export const DEFAULT_STATE: CardsState = getCardsDefaultState()

export const cards = (state: CardsState = DEFAULT_STATE, action: Action) => {
  if (isType(action, updateCard)) {
    const { _id, data } = action.payload
    const newState = { ...state }
    newState.byId[_id] = mergeDeepRight(newState.byId[_id], data)
    return newState
  }

  if (isType(action, setCard)) {
    const card = action.payload.card
    const newState = { ...state }
    newState.byId[card._id] = card
    if (!newState.allIds.includes(card._id)) newState.allIds.push(card._id)
    return newState
  }

  if (isType(action, initializeDeskSuccess)) {
    const newCards = action.payload.cards
    const newState = { ...state }
    for (const card of newCards) {
      newState.byId[card._id] = mergeDeepRight(newState.byId[card._id], card)
    }
    newState.allIds = Object.keys(newState.byId)
    return newState
  }

  if (isType(action, setCardIndex)) {
    const { _id, index, completedIndex } = action.payload

    const newState = { ...state }
    newState.byId[_id].props.index = index

    if (completedIndex) {
      newState.byId[_id].props.completedIndex = completedIndex
    }
    return newState
  }

  return state
}
