import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { showToast } from 'state/_sagas/_actions'
import { submitViewForm } from 'state/forms'
import { cardSequenceIndexSelector, setSequenceIndex } from 'state/lead'
import { currentViewCardsSelector } from 'state/router'
import { RootState } from 'types'
import { CardSequenceDisplay, Props } from './CardSequence.display'

type StateProps = Pick<Props, 'cards' | 'sequenceIndex'>

const mapStateToProps = (
  state: RootState,
  ownProps: { view: { _id: string } }
): StateProps => ({
  cards: currentViewCardsSelector(state),
  sequenceIndex: cardSequenceIndexSelector(state, ownProps.view._id)
})

type DispatchProps = Pick<
  Props,
  'setSequenceIndex' | 'showToast' | 'submitForm'
>

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { view: { _id: string } }
): DispatchProps => ({
  setSequenceIndex: index => {
    dispatch(setSequenceIndex({ _id: ownProps.view._id, index }))
  },
  showToast: toast => {
    dispatch(showToast(toast))
  },
  submitForm: (formId: string) => {
    dispatch(submitViewForm({ cardId: formId }))
  }
})

export const CardSequenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardSequenceDisplay)
