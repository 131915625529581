import * as moment from 'moment'
import { path } from 'ramda'
import * as React from 'react'
import styled, { withTheme } from 'styledComponents'

import { Avatar, Card, Flex, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import { CardHeaderProps, HelpersProps, ThemeInterface } from 'types'

export interface Props extends HelpersProps {
  className?: string
  theme?: ThemeInterface
  latest?: {
    authorName: string
    body: string
    conversationId: string
    authorPhoto?: string
    createdAt: string
  }
  unreadCount: number
}

const getTimeAgoFromDateTimeString = (dateTimeString?: string) => {
  if (!dateTimeString) return undefined

  const from = moment(dateTimeString)
  const monthsDiff = moment().diff(from, 'months')
  if (monthsDiff > 12) {
    return from.format('MMMM Do')
  }

  const daysDiff = moment().diff(from, 'days')
  const daysInMonth = from.daysInMonth()

  if (daysDiff > daysInMonth) {
    return `${monthsDiff}mth ago`
  } else if (daysDiff > 0) {
    return `${daysDiff}d ago`
  }
  const hoursDiff = moment().diff(from, 'hours')
  if (hoursDiff > 0) {
    return `${hoursDiff}h ago`
  }
  const minutesDiff = moment().diff(from, 'minutes')
  if (minutesDiff > 0) {
    return `${minutesDiff}m ago`
  } else {
    return 'just now'
  }
}

class Component extends React.Component<Props, { status?: string }> {
  private statusTimerId: number

  constructor(props: Props) {
    super(props)

    const msgDateStr: string | undefined = path(['latest', 'createdAt'], props)
    this.state = {
      status: getTimeAgoFromDateTimeString(msgDateStr)
    }
  }

  public componentDidMount() {
    this.statusTimerId = window.setInterval(() => this.setStatus(), 1000)
  }

  public componentWillUnmount() {
    clearInterval(this.statusTimerId)
  }

  public render() {
    const { className, latest, unreadCount } = this.props
    if (!latest) {
      return null
    }

    const { authorName, authorPhoto, body, conversationId } = latest

    return (
      <Card
        className={className}
        header={
          {
            title: unreadCount > 0 ? 'New Message!' : 'Your Conversation',
            status: this.state.status
          } as CardHeaderProps
        }
        actionButtons={[
          {
            label: unreadCount > 0 ? 'Reply' : 'Message',
            type: 'view',
            destination: '__conversations__',
            destinationProps: { conversationId }
          }
        ]}
        actionButtonsDisplay={{ chevronRight: true }}
      >
        <Flex row>
          <Flex column alignItems="center" mr={12}>
            <Avatar src={authorPhoto} size={40} />
          </Flex>
          <Flex column shrink overflow="hidden">
            <Text>{authorName}:</Text>
            <MessageTruncateContainer>
              <Text isBody mb={12} dangerouslySetInnerHTML={{ __html: body }} />
            </MessageTruncateContainer>
          </Flex>
        </Flex>
      </Card>
    )
  }

  private setStatus = () => {
    const msgDateStr: string | undefined = path(
      ['latest', 'createdAt'],
      this.props
    )

    this.setState({
      status: getTimeAgoFromDateTimeString(msgDateStr)
    })
  }
}

const MessageTruncateContainer = styled.div`
  height: 24px;

  * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const styledComponent = styled(Component)`
  ${helpers}
`

export const MessagesCardDisplay = withTheme(styledComponent)
