class IframeCommunication {
  private parentWindow = window.top

  public changeDimensions = ({
    height,
    width,
    webMoveUp,
    mobileMoveUp,
    withoutAdditionalHeight,
    videoExpanded
  }: {
    height?: number | string
    width?: string
    webMoveUp?: number
    mobileMoveUp?: number
    withoutAdditionalHeight?: boolean
    videoExpanded?: boolean
  }) =>
    this.parentWindow.postMessage(
      {
        type: 'changeDimensions',
        data: {
          height,
          width,
          webMoveUp,
          mobileMoveUp,
          withoutAdditionalHeight,
          videoExpanded
        }
      },
      '*'
    )

  public webdeskPosition = ({
    position,
    horizontalPadding,
    verticalPadding
  }: {
    position: string
    horizontalPadding: string
    verticalPadding: string
  }) =>
    this.parentWindow.postMessage(
      {
        type: 'webdeskPosition',
        data: {
          position,
          horizontalPadding,
          verticalPadding
        }
      },
      '*'
    )

  public conversionCode = ({ code }: { code: string }) =>
    this.parentWindow.postMessage(
      {
        type: 'conversionCode',
        data: { code }
      },
      '*'
    )

  public initializeTranslator = () =>
    this.parentWindow.postMessage(
      {
        type: 'initializeTranslator',
        data: {}
      },
      '*'
    )

  public collapseVideo = () =>
    this.parentWindow.postMessage(
      {
        type: 'collapseVideo',
        data: {}
      },
      '*'
    )
}

export default new IframeCommunication()
