import * as moment from 'moment'
import * as React from 'react'
import styled from 'styledComponents'

import { Card, Flex, SuccessCheck, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  AppointmentInterface,
  FormInterface,
  HelpersProps
} from 'types'
import { getTimezone } from 'utility'

export interface Props extends HelpersProps {
  className?: string
  appointmentSummary?: AppointmentInterface
  actionButtons?: ActionButtonActionInterface[]
  form?: FormInterface
  timezoneId: string
}

const Component = ({
  className,
  appointmentSummary,
  actionButtons,
  timezoneId
}: Props) => {
  return (
    <Card className={className} actionButtons={actionButtons}>
      <Flex column alignItems="center">
        <Text isHero mb={3}>
          Thank you!
        </Text>
        <Text mb={12} align="center">
          A member of our team will reach out shortly to confirm the details
        </Text>
        <SuccessCheck />
      </Flex>
      {appointmentSummary && (
        <Flex mt={12} column alignItems="center">
          <Text inline>
            Type: &nbsp;
            <Text inline isTitle>
              {appointmentSummary.type.label}
            </Text>
          </Text>
          <Text inline>
            Date: &nbsp;
            <Text inline isTitle>
              {moment(appointmentSummary.date, 'YYYY-MM-DD').format(
                'MMMM D, YYYY'
              )}
            </Text>
          </Text>
          <Text inline>
            Time: &nbsp;
            <Text inline isTitle>{`${moment(
              appointmentSummary.time,
              'H:mm'
            ).format('h:mmA')} ${getTimezone({
              type: 'abbreviation',
              timezoneId
            })}`}</Text>
          </Text>
        </Flex>
      )}
    </Card>
  )
}

export const Step3 = styled(Component)`
  ${helpers}
`
