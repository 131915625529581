import * as React from 'react'
import { connect } from 'react-redux'

import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/_utility'
import { isTranslatorDisabledSelector } from 'state/ui'
import { RootState } from 'types'
import { LanguagesModalContainer } from './LanguagesModal.container'

const mapStateToProps = (state: RootState) => ({
  isTranslatorDisabled: isTranslatorDisabledSelector(state)
})

const mapDispatchToProps = {}

type ConnectProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

interface Props extends ConnectProps {
  className?: string
}

const Component = ({ className, isTranslatorDisabled }: Props) => {
  if (isTranslatorDisabled) {
    return null
  }

  const buttonRef = React.useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <React.Fragment>
      <Button
        header
        onClick={() => setIsOpen(!isOpen)}
        ref={buttonRef}
        className={className}
      >
        <Icon icon={faGlobe} />
      </Button>
      {isOpen && (
        <LanguagesModalContainer
          anchorRef={buttonRef}
          onClose={() => setIsOpen(false)}
        />
      )}
    </React.Fragment>
  )
}

export const TranslateButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
