import { lensPath, set } from 'ramda'
import { getNestedField } from './getNestedField'

export const setNestedField = (
  name: string,
  value: any,
  object: object
): object => {
  const lens = lensPath(name.split('.'))
  const nestedValue =
    typeof value !== 'object' || Array.isArray(value) || value === null
      ? value
      : Object.keys(value).reduce(
          (accumulator, key) => {
            return setNestedField(key, value[key], accumulator)
          },
          { ...getNestedField(name, object) }
        )
  return set(lens, nestedValue, object)
}
