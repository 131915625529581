import { connect } from 'react-redux'

import { LocationCardDisplay } from './LocationCard.display'

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export const LocationCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationCardDisplay)
