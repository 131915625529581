import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { getDeviceType, mergeNestedFields } from 'utility'

import { initializeDeskSuccess } from 'state/_sagas/_actions'
import {
  AppointmentInterface,
  CardSequenceViewInterface,
  CardStackViewInterface,
  PathAttributes
} from 'types'
import { setLeadCustomData, setSequenceIndex, updateLead } from './'

export interface LeadState {
  _id?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  appointment?: Partial<AppointmentInterface>
  tradeInVehicle?: {
    year?: { value: number; label: number }
    make?: { value: number; label: string }
    model?: { value: number; label: string }
    trim?: { value: number; label: string }
    mileage?: number
    condition?: { value: number; label: string }
    price?: number
    standardOptions?: string[]
  }
  vehicleMake?: string
  vehicleModel?: string
  vehicleYear?: string
  vehicleVin?: string
  payment?: {
    variant?: string
  }
  _viewState: { [x: string]: { index: number } }
  deviceType?: string
  extraFields?: ExtraField[]
  customData: PathAttributes
  inquiryTypeId?: string
}

interface ExtraField {
  _id: string
  value: string
}

export const DEFAULT_STATE: LeadState = {
  _viewState: {},
  deviceType: getDeviceType(),
  customData: {}
}

export const lead = (state: LeadState = DEFAULT_STATE, action: Action) => {
  if (isType(action, initializeDeskSuccess)) {
    const { views } = action.payload
    const initialViewState = views
      .filter(
        (v: CardSequenceViewInterface | CardStackViewInterface) =>
          v.template === 'cardSequence'
      )
      .reduce(
        (
          viewState: Pick<LeadState, '_viewState'>,
          view: CardSequenceViewInterface
        ) => ({
          ...viewState,
          [view._id]: { index: 0 }
        }),
        {}
      )

    return {
      ...state,
      _viewState: initialViewState
    }
  }
  if (isType(action, updateLead)) {
    return mergeNestedFields({ ...state }, action.payload)
  }

  if (isType(action, setSequenceIndex)) {
    const { _id, index } = action.payload
    const { _viewState } = state
    _viewState[_id] = { ..._viewState[_id], index }
    return { ...state, _viewState }
  }

  if (isType(action, setLeadCustomData)) {
    return {
      ...state,
      customData: {
        ...state.customData,
        ...action.payload
      }
    }
  }

  return state
}
