import { findLast, last, path } from 'ramda'
import { connect } from 'react-redux'

import {
  conversationSelector,
  lastConversationIdSelector,
  unreadCountSelector
} from 'state/entities/conversations'
import {
  ConversationBlockInterface,
  ConversationElementInterface,
  ConversationInterface,
  RootState
} from 'types'

import { MessagesCardDisplay, Props } from './MessagesCard.display'

const getLatestMessageFromConversation = (
  conversation: ConversationInterface
): StateProps['latest'] => {
  const lastBlock: ConversationBlockInterface | undefined = findLast(
    block => path(['author', 'type'], block) !== 'client',
    conversation.blocks
  )
  if (!lastBlock) {
    return undefined
  }
  const lastBlockElement: ConversationElementInterface = last(
    lastBlock.elements
  ) as ConversationElementInterface
  let latestMessageBody: string
  switch (lastBlockElement.type) {
    case 'message':
    case 'system':
      latestMessageBody = lastBlockElement.contents as string
      break
    case 'card':
      latestMessageBody = 'Showed a card'
      break
    case 'form':
      latestMessageBody = 'Asked for info'
      break
    case 'video-call':
      latestMessageBody = 'Video Call'
      break
    default:
      latestMessageBody = ''
      break
  }

  const latestMessageAuthorPhoto: string =
    path(['author', 'photo'], lastBlock) || ''
  const latestMessageAuthorName: string =
    path(['author', 'firstName'], lastBlock) || 'Operator'

  return {
    conversationId: conversation._id,
    authorName: latestMessageAuthorName,
    body: latestMessageBody,
    authorPhoto: latestMessageAuthorPhoto,
    createdAt: lastBlockElement.createdAt
  }
}

type StateProps = Pick<Props, 'latest' | 'unreadCount'>
const mapStateToProps = (state: RootState): StateProps => {
  let latestMessage: StateProps['latest']
  const lastConversationId: string = lastConversationIdSelector(state)
  const unreadCount = unreadCountSelector(state)
  if (lastConversationId) {
    const lastConversation: ConversationInterface = conversationSelector(
      state,
      lastConversationId
    )
    latestMessage = getLatestMessageFromConversation(lastConversation)
  }

  return {
    latest: latestMessage,
    unreadCount
  }
}

const mapDispatchToProps = () => ({})

export const MessagesCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesCardDisplay)
