import { CardInterface } from 'types'
import { mapCardToState } from './mapCardToState'

export const getMappedViewCards = (
  cardIds: string[],
  cards: { byId: { [_id: string]: CardInterface } }
) => {
  return cardIds.map(cardId => {
    const card = cards.byId[cardId]

    return mapCardToState(card)
  })
}
