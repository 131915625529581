import { all } from 'redux-saga/effects'

import * as sagas from 'state/_sagas'
import cardsSaga from 'state/entities/cards/cards.saga'
import conversationsSaga from 'state/entities/conversations/conversations.saga'
import formsSaga from 'state/forms/forms.saga'
import leadSaga from 'state/lead/lead.saga'
import socketSaga from 'state/socket/socket.saga'
import uiSaga from 'state/ui/ui.saga'
import videoSaga from 'state/video/video.saga'

export default function* rootSaga() {
  yield all([
    leadSaga(),
    socketSaga(),
    uiSaga(),
    cardsSaga(),
    conversationsSaga(),
    formsSaga(),
    videoSaga(),
    ...Object.values(sagas).map((saga: () => Generator) => saga())
  ])
}
