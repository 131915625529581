import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import settings from 'settings'
import iframeCommunication from 'services/iframe'
import { mobileMoveUpSelector, webMoveUpSelector } from 'state/ui/ui.selectors'
import styled, { css } from 'styledComponents'
import { RootState } from 'types'

const mapStateToProps = (state: RootState) => ({
  webMoveUp: webMoveUpSelector(state),
  mobileMoveUp: mobileMoveUpSelector(state)
})

const mapDispatchToProps = {}

type ConnectProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

interface Props extends ConnectProps {
  anchorRef: React.RefObject<HTMLElement>
  onClose: () => void
}

export const LanguagesModal = ({
  anchorRef,
  onClose,
  webMoveUp,
  mobileMoveUp
}: Props) => {
  const doc =
    // @ts-ignore
    window.google && window.google.translate
      ? window.document
      : window.parent.document
  const languageIframeSelector = settings.livepreview
    ? '.goog-te-menu-frame.skiptranslate'
    : '#chatpath-iframe ~ .goog-te-menu-frame.skiptranslate'

  const anchorRect = anchorRef.current!.getBoundingClientRect()
  const modalHeight = 100
  const modalVerticalDirection =
    document.documentElement.clientHeight >
    anchorRect.top + anchorRect.height + modalHeight
      ? 'bottom'
      : 'top'
  const modalHorizontalDirection = anchorRect.left - 94 < 0 ? 'right' : 'left'

  const getLanguages = () => {
    const iframes = window.parent.document!.querySelectorAll<HTMLIFrameElement>(
      'iframe.skiptranslate'
    )
    if (!iframes) {
      return []
    }
    const iframe = iframes.length !== 1 ? iframes[1] : iframes[0]

    const langElements = iframe.contentDocument!.querySelectorAll<HTMLElement>(
      'a'
    )
    let langElementArray = []
    if (iframes.length !== 1)
      langElementArray = [langElements[0], langElements[1], langElements[2]]
    else langElementArray = [langElements[1], langElements[2], langElements[3]]

    return Array.from(langElementArray).map(el => ({
      name: el.querySelector('.text')!.textContent,
      selected: el.className === 'goog-te-menu2-item-selected',
      originalEl: el
    }))
  }

  const [languages, setLanguages] = React.useState(getLanguages())

  const observer = new MutationObserver(() => {
    setLanguages(getLanguages())
  })

  const onLangClick = (
    lang: {
      name: string | null
      originalEl: HTMLElement
    },
    index: number
  ) => {
    // eslint-disable-next-line no-unused-expressions
    lang.originalEl.click()
    onClose()
  }

  React.useEffect(() => {
    const iframe = doc.querySelector<HTMLIFrameElement>(languageIframeSelector)
    if (iframe && iframe.contentWindow) {
      observer.observe(iframe.contentWindow.document.body, {
        attributes: true,
        characterData: true,
        childList: true
      })
    }
    observer.observe(doc.body, {
      attributes: true,
      characterData: true,
      childList: true
    })

    return () => observer.disconnect()
  })

  React.useEffect(() => {
    const { clientHeight } = document.documentElement
    const isSpaceForModal =
      modalVerticalDirection === 'bottom' || anchorRect.top > modalHeight
    if (!isSpaceForModal) {
      const height = anchorRect.top + anchorRect.height + modalHeight + 10
      iframeCommunication.changeDimensions({ height, webMoveUp, mobileMoveUp })
    }

    return () => {
      if (!isSpaceForModal) {
        iframeCommunication.changeDimensions({
          height: clientHeight,
          withoutAdditionalHeight: true,
          webMoveUp,
          mobileMoveUp
        })
      }
    }
  })

  return ReactDOM.createPortal(
    <Modal>
      <ModalContent
        anchorRect={anchorRect}
        vertDir={modalVerticalDirection}
        horizDir={modalHorizontalDirection}
      >
        <ModalList>
          {languages.map((lang, i) => (
            <ModalListItem
              key={i}
              data-test="lang-item"
              selected={lang.selected}
              onClick={() => onLangClick(lang, i)}
            >
              <button className="text_cont_btn" autoFocus={lang.selected}>
                {lang.name}
              </button>
            </ModalListItem>
          ))}
        </ModalList>
      </ModalContent>
      <ModalBackdrop onClick={onClose} />
    </Modal>,
    document.body
  )
}

export const LanguagesModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguagesModal)

const Modal = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 70000;
  position: fixed;
`

const ModalContent = styled.div<{
  anchorRect: ClientRect
  vertDir: string
  horizDir: string
}>`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.lightest};
  overflow: hidden;
  width: 140px;

  ${p =>
    p.horizDir === 'right' &&
    css`
      left: ${p.anchorRect.left - 25}px;
    `}

  ${p =>
    p.horizDir === 'left' &&
    css`
      left: ${p.anchorRect.left - 94}px;
    `}

  ${p =>
    p.vertDir === 'bottom' &&
    css`
      top: ${p.anchorRect.top + p.anchorRect.height}px;
    `}

  ${p =>
    p.vertDir === 'top' &&
    css`
      bottom: ${document.documentElement.clientHeight - p.anchorRect.top}px;
    `}
`

const ModalList = styled.div`
  max-height: 192px;
  overflow-y: auto;
  box-sizing: content-box;
`

const ModalListItem = styled.div<{ selected: boolean }>`
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 8px 12px;
  box-sizing: content-box;
  .text_cont_btn {
    border: none;
    background: none;
    color: inherit;
    &:focus {
      border: none;
      outline: none;
    }
  }
  ${p =>
    p.selected &&
    css`
      background-color: ${p.theme.colors.original};
      color: ${p.theme.colors.lightest};
    `}

  ${p =>
    !p.selected &&
    css`
      &:hover {
        background-color: ${p.theme.colors.lighter};
        color: ${p.theme.colors.darkest};
      }
      &:focus-within {
        background-color: ${p.theme.colors.lighter};
        color: ${p.theme.colors.darkest};
      }
    `}
`

const ModalBackdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  outline: 0;
`
