import { last } from 'ramda'
import { createSelector } from 'reselect'

import { RootState } from 'state/reducer'
import {
  // ContactFormFieldType,
  ConversationBlockInterface,
  ConversationElementInterface,
  ConversationInterface,
  Message,
  Participant,
  UnreadMessageTypes
} from 'types'

// const contactFormFieldTypesMap = {
//   firstName: ContactFormFieldType.FIRST_NAME,
//   lastName: ContactFormFieldType.LAST_NAME,
//   email: ContactFormFieldType.EMAIL,
//   phone: ContactFormFieldType.PHONE,
//   vehicleMake: ContactFormFieldType.VEHICLE_MAKE,
//   vehicleModel: ContactFormFieldType.VEHICLE_MODEL,
//   vehicleYear: ContactFormFieldType.VEHICLE_YEAR,
//   vehicleMakeDropdown: ContactFormFieldType.VEHICLE_MAKE_DROPDOWN,
//   vehicleModelDropdown: ContactFormFieldType.VEHICLE_MODEL_DROPDOWN,
//   vehicleYearDropdown: ContactFormFieldType.VEHICLE_YEAR_DROPDOWN,
//   vehicleOpenSearch: ContactFormFieldType.VEHICLE_OPEN_SEARCH,
//   inputField: ContactFormFieldType.CUSTOM_SINGLE_LINE_INPUT,
//   multiline: ContactFormFieldType.CUSTOM_MULTI_LINE_INPUT,
//   dropdown: ContactFormFieldType.DROPDOWN,
//   inquiry_type_dropdown: ContactFormFieldType.INQUIRY_TYPE_DROPDOWN,
//   yesNo: ContactFormFieldType.YES_NO_QUESTION
// }

const mapApiMessageToClientMessage = (message: Message) => {
  let clientMessage: ConversationElementInterface = {
    type: 'message',
    contents: message.htmlValue || message.text,
    createdAt: message.createdAt
  }
  if (message.type === 'system') {
    clientMessage = {
      ...clientMessage,
      type: 'system'
    }
  } else if (message.type === 'paths' && message.actions) {
    clientMessage.type = 'buttonGroup'
  } else if (message.type === 'card' && message.card) {
    clientMessage = {
      ...clientMessage,
      type: 'card',
      card: message.card
    }
  } else if (message.type === 'CL-certificate') {
    clientMessage = {
      ...clientMessage,
      type: 'CL-certificate'
    }
  } else if (message.type === 'video-call') {
    clientMessage = {
      ...clientMessage,
      type: 'video-call',
      video: { ...message.videoChat!, messageId: message._id }
    }
  }

  if (message.actions && message.actions.length) {
    clientMessage.actions = message.actions
  }

  if (message.attachments && message.attachments.length) {
    clientMessage.attachments = message.attachments
  }

  return clientMessage
}

const messagesToBlocks = (messages: Message[] = []) => {
  const newBlocks = [...messages].reduce(
    (blocks: ConversationBlockInterface[], message: Message) => {
      const clientMessage = mapApiMessageToClientMessage(message)

      let lastBlock: ConversationBlockInterface | undefined = last(blocks)
      if (clientMessage.type === 'buttonGroup') {
        lastBlock = { authorId: 'client', elements: [clientMessage] }
        blocks.push(lastBlock)
        return blocks
      }
      if (!lastBlock || lastBlock.authorId !== message.authorId) {
        lastBlock = { authorId: message.authorId, elements: [] }
        blocks.push(lastBlock)
      }
      lastBlock.elements.push(clientMessage)

      return blocks
    },
    []
  )

  return newBlocks
}

export const sortedConversationIdsSelector = createSelector(
  (state: RootState) => state.entities.conversations.byId,
  conversationsById => {
    // sort by lastUpdated in ascending order
    return (
      Object.keys(conversationsById).map(key => conversationsById[key]._id) ||
      []
    )
  }
)

export const lastConversationIdSelector = createSelector(
  sortedConversationIdsSelector,
  ids => last(ids)
)

export const conversationSelector = createSelector(
  (state: RootState, conversationId: string) =>
    state.entities.conversations.byId[conversationId],
  (state: RootState) => state.entities.cards,
  (state: RootState) => state.entities.authors,
  (conversation = {}, cards, authors): ConversationInterface => {
    // denormalize conversation blocks
    const blocks: ConversationBlockInterface[] = messagesToBlocks(
      conversation.messages
    ).map((block: ConversationBlockInterface) => {
      const author = authors.byId[block.authorId]
      const elements: ConversationElementInterface[] = block.elements.map(
        (element: ConversationElementInterface) => {
          if (element.card) {
            return {
              ...element,
              card: cards.byId[element.card._id]
            }
          }
          return element
        }
      )

      return {
        ...block,
        author,
        elements
      }
    })
    return {
      ...conversation,
      blocks
    }
  }
)

export const typingUsersSelector = createSelector(
  (state: RootState, conversationId: string) =>
    state.entities.conversations.byId[conversationId].typingUsers,
  (typingUsers = {}): string[] => Object.values(typingUsers)
)

export const isBotTypingSelector = createSelector(
  (state: RootState, conversationId: string) =>
    state.entities.conversations.byId[conversationId].isBotTyping,
  (isBotTyping = false): boolean => isBotTyping
)

export const participantSelector = createSelector(
  (state: RootState, conversationId: string, userId: string) =>
    state.entities.conversations.byId[conversationId].participants.find(
      (p: Participant) => p._id === userId
    ),
  (participant = {}): Participant => participant
)

export const companyIdsSelector = createSelector(
  (state: RootState, conversationId: string) =>
    state.entities.conversations.byId[conversationId].companyId,
  (state: RootState, conversationId: string) =>
    state.entities.conversations.byId[conversationId].sourceCompanyId,
  (companyId, sourceCompanyId) => ({ companyId, sourceCompanyId })
)

export const unreadCountSelector = createSelector(
  lastConversationIdSelector,
  (state: RootState) => state.entities.conversations.byId,
  (
    conversationId: string,
    conversationsById: { [_id: string]: ConversationInterface }
  ) => {
    if (!conversationId) return 0
    const { messages } = conversationsById[conversationId]
    return messages.filter(
      m => UnreadMessageTypes.includes(m.type) && m.status === 'unread'
    ).length
  }
)
