import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Action } from 'typescript-fsa'

import {
  registerField as registerFieldAction,
  updateField as updateFieldAction
} from 'state/_sagas/_actions'
import { formFieldSelectorFactory } from 'state/forms'

import { FormContext } from 'components/_hoc/withForm'

import {
  FieldOptionInterface,
  FormFieldInterface,
  FormFieldType,
  LeadState,
  RootState
} from 'types'

import { Subtract } from 'utility-types'

interface ConsumedProps {
  formId: string
}

const withFormConsumer = <T extends object>(
  Component: React.ComponentType<T>
): React.ComponentType<T> => {
  return class WithFormConsumerComponent extends React.Component<T> {
    public render() {
      return (
        <FormContext.Consumer>
          {({ formId }: ConsumedProps) => (
            <Component {...this.props} formId={formId} />
          )}
        </FormContext.Consumer>
      )
    }
  }
}

interface OwnProps {
  name: string
  sideEffect?: Action<any>
  required?: boolean
  options?: FieldOptionInterface[]
  maxCharacters?: number
  disabled?: boolean
  consent?: boolean
}
interface FieldComponentInterface extends ConsumedProps, OwnProps {
  fieldData: FormFieldInterface
  updateField: (data: Partial<LeadState>) => void
  registerField: () => void
}
const withFieldEnhancer = <T extends object>(
  Component: React.ComponentType<T>
): React.ComponentType<T & FieldComponentInterface> => {
  return class FieldComponent extends React.Component<
    T & FieldComponentInterface
  > {
    public componentDidMount() {
      if (!this.props.fieldData.status) {
        this.props.registerField()
      }
    }

    public render() {
      const {
        fieldData: {
          value,
          status,
          dirty,
          options: fieldDataOptions,
          disabled: fieldDataDisabled
        },
        updateField,
        required,
        consent,
        options: propsOptions,
        disabled: propsDisabled
      } = this.props

      return (
        <Component
          {...this.props}
          value={value}
          options={fieldDataOptions || propsOptions || []}
          status={
            dirty && !(value === '' && !required) && !(consent === false)
              ? status
              : undefined
          }
          updateField={updateField}
          disabled={fieldDataDisabled || propsDisabled}
        />
      )
    }
  }
}

export interface InjectedFieldProps {
  value: string | number | string[] | undefined
  status?: string
  updateField: (data: Partial<LeadState>) => void
  registerField: () => void
}

export interface FieldParams {
  type: FormFieldType
}

export const connectField = <T extends InjectedFieldProps>(
  Field: React.ComponentType<T>,
  fieldParams: FieldParams = { type: FormFieldType.TEXT }
): React.ComponentType<Subtract<T, InjectedFieldProps> & OwnProps> => {
  const EnhancedField = withFieldEnhancer(Field)

  type ConnectOwnProps = T & ConsumedProps & OwnProps
  type StateProps = Pick<FieldComponentInterface, 'fieldData'>
  const mapStateToProps = (
    state: RootState,
    ownProps: ConnectOwnProps
  ): StateProps => {
    const formFieldSelector = formFieldSelectorFactory()
    return {
      fieldData: formFieldSelector(state, ownProps.formId, ownProps.name)
    }
  }
  type DispatchProps = Pick<
    FieldComponentInterface,
    'updateField' | 'registerField'
  >
  const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: ConnectOwnProps
  ): DispatchProps => {
    const {
      formId,
      required = false,
      sideEffect,
      name,
      consent,
      maxCharacters,
      disabled
    } = ownProps
    const { type } = fieldParams

    return {
      updateField: (data: Partial<LeadState>) => {
        dispatch(
          updateFieldAction({
            formId,
            data,
            required,
            sideEffect,
            maxCharacters,
            consent,
            type
          })
        )
      },
      registerField: () => {
        dispatch(
          registerFieldAction({
            formId,
            fieldName: name,
            required,
            maxCharacters,
            consent,
            disabled,
            type
          })
        )
      }
    }
  }

  const ConnectedField = connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(EnhancedField)
  return withFormConsumer(ConnectedField)
}
