
import * as moment from 'moment'
import { AdobeSatelliteData, AdobeSatelliteEvents, AdobeSatellite } from 'types'
import CustomStorage from '../../../utility/storage/customStorage'

export default function* trackEventAdobeSubgenerator(
    trackingName: string,
    properties: { [x: string]: any }
  ) {
    let satellite: AdobeSatellite = (window as any)._satellite
    if (!satellite) return

    switch (trackingName) {
    case 'CHAT_CLICK':
        handleChatClick(satellite, properties)
        break
    case 'CHAT_STARTED':
        handleChatStarted(satellite, properties)
        break
    case 'CHAT_CLOSED':
        handleChatClosed(satellite, properties)
        break
    case 'VIEW':
        handleChatHoursLocation(satellite, properties)
        break
    default:
        break
    }
    yield
}

function handleChatStarted(satellite: AdobeSatellite, properties: { [x: string]: any }) {
    let storage: CustomStorage = new CustomStorage('adobeTagging'),
        metrics: AdobeSatelliteData = {
            visitorID: properties['visitorId'],
            toolProvider: 'digitalairstrike'
         }
    satellite.track(AdobeSatelliteEvents.CHAT_STARTED, metrics)
    storage.setItem('chatStartedTime', (new Date).getTime().toString())
}

function handleChatClosed(satellite: AdobeSatellite, properties: { [x: string]: any }) {
    let storage: CustomStorage = new CustomStorage('adobeTagging'),
        chatStartTime = storage.getItem('chatStartedTime')
    if (chatStartTime) {
        let duration: moment.Duration = moment.duration(moment().diff(new Date(parseInt(chatStartTime)))),
            metrics: AdobeSatelliteData = {
                visitorID: properties['visitorId'],
                toolProvider: 'digitalairstrike',
                duration: `${duration.get('m')}:${duration.get('s')}`
            }
        satellite.track(AdobeSatelliteEvents.CHAT_ENDED, metrics)
        storage.clear()
    }
}

function handleChatClick(satellite: AdobeSatellite, properties: { [x: string]: any }) {
    let metrics: AdobeSatelliteData = {  toolProvider: 'digitalairstrike', visitorID: properties['visitorId'] }
    satellite.track(AdobeSatelliteEvents.CHAT_SHOWS, metrics)
}

function handleChatHoursLocation(satellite: AdobeSatellite, properties: { [x: string]: any }) {
    let viewNames = ['Location View', 'Hours View']
    if (viewNames.includes(properties['viewName'])) {
        let metrics: AdobeSatelliteData = {  toolProvider: 'digitalairstrike', visitorID: properties['visitorId'] }
        satellite.track(AdobeSatelliteEvents.HOURS_DIRECTIONS, metrics)
    }
}
