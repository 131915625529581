import { put, takeEvery } from 'redux-saga/effects'
import { dirtifyForm } from 'state/forms'
import { Action } from 'typescript-fsa'
import { logAction, showToast, validateForm } from './_actions'

function* saga(action: Action<{ formId: string; failureMessage?: string }>) {
  const { formId, failureMessage: message } = action.payload
  try {
    yield put(dirtifyForm({ formId }))
    if (message) {
      yield put(showToast({ message, variant: 'error' }))
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* validateFormSaga() {
  yield takeEvery(validateForm, saga)
}
