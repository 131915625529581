import * as qs from 'querystring'

const responseHandler = async (response: Response) => {
  if (response.status === 204) {
    return null
  }

  if (response.status >= 400 || response.status >= 500) {
    const isJSON = response.headers.get('Content-Type') === 'application/json'

    if (isJSON) {
      const errorObject = await response.json()
      throw new Error(JSON.stringify(errorObject))
    }

    const errorText = await response.text()
    throw new Error(errorText)
  }

  return response.json()
}

type ApiClientProps = {
  baseApiUrl: string
  headers?: object
}

class ApiClient {
  baseApiUrl: string

  headers?: object

  constructor({ baseApiUrl, headers }: ApiClientProps) {
    if (!(this instanceof ApiClient)) {
      return new ApiClient({ baseApiUrl, headers })
    }
    this.baseApiUrl = baseApiUrl
    this.headers = headers
  }

  buildUrl = (path: string, queryString?: object | null) => {
    return `${this.baseApiUrl}/${path}${queryString ? '?' : ''}${qs.stringify({
      ...queryString
    })}`
  }

  _makeRequest = async (method: string, url: string, body?: object | null) => {
    const headers = { Accept: 'application/json' }
    if (method !== 'GET') {
      headers['Content-type'] = 'application/json; charset=UTF-8'
    }

    const response = await fetch(url, {
      method,
      headers: {
        ...headers,
        ...this.headers,
        'x-rp-source-url': `${window.location.origin}${window.location.pathname}`
      },
      body: JSON.stringify(body)
    })

    return responseHandler(response)
  }

  get = (path: string, queryString?: object) => {
    return this._makeRequest('GET', this.buildUrl(path, queryString))
  }

  post = (path: string, queryString: object | null, body: object | null) => {
    return this._makeRequest('POST', this.buildUrl(path, queryString), body)
  }

  patch = (path: string, queryString: object | null, body: object | null) => {
    return this._makeRequest('PATCH', this.buildUrl(path, queryString), body)
  }

  put = (path: string, queryString: object | null, body: object | null) => {
    return this._makeRequest('PUT', this.buildUrl(path, queryString), body)
  }

  del = (path: string, queryString: object | null, body: object | null) => {
    return this._makeRequest('DELETE', this.buildUrl(path, queryString), body)
  }
}

// todo make it not do this
export default function(...args: [ApiClientProps]) {
  return new ApiClient(...args)
}
