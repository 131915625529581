import { darken } from 'polished'
import { range } from 'ramda'
import * as React from 'react'
import styled from 'styledComponents'

import { Button } from 'components/_utility/Button'
import { ButtonCluster } from 'components/_utility/ButtonCluster'
import { Flex } from 'components/_utility/Flex'
import { ButtonProps } from 'types'

export interface Props {
  currentPage: number
  total: number
  update: (index: number) => void
}

const Pagination = ({ currentPage, total, update }: Props) => {
  let scope = []
  const page = currentPage
  const pageCount = Math.ceil(total / 500)
  if (total <= 20) {
    scope = range(page, page + 2)
  } else if (page === 1) {
    scope = range(page, page + 3)
  } else if (page === pageCount) {
    scope = range(page - 2, page + 1)
  } else {
    scope = range(page - 1, page + 2)
  }
  return (
    <Flex column _width={100} alignItems="center">
      <ButtonCluster>
        {currentPage > 1 && (
          <Button primary onClick={() => update(currentPage - 1)}>
            Prev
          </Button>
        )}
        {scope.map(item => {
          const isCurrentPage = item === currentPage
          if (item <= pageCount) {
            return (
              <PageButton
                isCurrentPage={isCurrentPage}
                key={item}
                primary
                onClick={() => (isCurrentPage ? {} : update(item))}
              >
                {item}
              </PageButton>
            )
          }
          return null
        })}
        {currentPage < pageCount && (
          <Button primary onClick={() => update(currentPage + 1)}>
            Next
          </Button>
        )}
      </ButtonCluster>
    </Flex>
  )
}

interface PageButtonProps extends ButtonProps {
  isCurrentPage: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PageButton = styled(({ isCurrentPage, ...rest }) => <Button {...rest} />)<
  PageButtonProps
>`
  ${p =>
    p.isCurrentPage &&
    `
    cursor: default;
    background-color: ${darken(0.1, p.theme.colors.original)};

    &:hover {
      background-color: ${darken(0.1, p.theme.colors.original)};
    }
  `}
`

export default Pagination
