import { uniq } from 'ramda'
import { Action, combineReducers } from 'redux'
import { updateAuthors } from 'state/entities/authors/authors.actions'
import { AuthorInterface, Participant } from 'types'
import { isType } from 'typescript-fsa'

export interface AuthorsState {
  byId: { [_id: string]: AuthorInterface }
  allIds: string[]
}

const byId = (
  state: { [id: string]: AuthorInterface } = {},
  action: Action
) => {
  if (isType(action, updateAuthors)) {
    const newAuthors = (action.payload.participants || []).reduce(
      (res: Participant, participant: Participant) => {
        if (participant.type !== 'client') {
          // DB: (bot && provider === 'user') (client === 'client')
          participant.type = 'user'
        }
        res[participant._id] = {
          type: participant.type,
          id: participant._id,
          firstName: participant.firstName || '',
          lastName: participant.lastName || '',
          hashName: participant.hashName || '',
          photo: participant.photo || ''
        }
        return res
      },
      {}
    )
    return {
      ...state,
      ...newAuthors
    }
  }

  return state
}

const allIds = (state: string[] = [], action: Action) => {
  if (isType(action, updateAuthors)) {
    const authorIds = action.payload.participants.map(
      (participant: Participant) => participant._id
    )
    return uniq([...state, authorIds])
  }

  return state
}

export const authors = combineReducers({
  byId,
  allIds
})
