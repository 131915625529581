import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { initializeVehicleForm } from 'state/_sagas/_actions'
import { phoneMaskSelector } from 'state/company'
import { formSelector, updateForm } from 'state/forms'
import { leadSelector, updateLead } from 'state/lead'
import { rpPreviewModeSelector } from 'state/ui'
import {
  FormInterface,
  LeadState,
  RootState,
  VehicleDropdownParamsInterface
} from 'types'
import { FormCardDisplay, Props } from './FormCard.display'

const mapStateToProps = (state: RootState, ownProps: Partial<Props>) => ({
  filledFormFields: formSelector(state, ownProps._id!),
  phoneMask: phoneMaskSelector(state),
  form: formSelector(state, ownProps._id!),
  previewMode: rpPreviewModeSelector(state),
  lead: leadSelector(state)
})

type DispatchProps = Pick<
  Props,
  'updateLead' | 'updateForm' | 'initializeVehicleForm'
>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateForm: (formId: string, data: FormInterface) => {
    dispatch(updateForm({ formId, data }))
  },
  updateLead: (data: Partial<LeadState>) => {
    dispatch(updateLead(data))
  },
  initializeVehicleForm: (
    formId: string,
    vehicleDropdownParams: VehicleDropdownParamsInterface
  ) => {
    dispatch(initializeVehicleForm({ formId, vehicleDropdownParams }))
  }
})

export const FormCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCardDisplay)
