const separator = ':'
const prefix = 'chatbot.form.fields.'

const createFieldName = (x: { fieldId: string; fieldLabel: string }) => {
  return `${prefix}${x.fieldId}${separator}${x.fieldLabel}`
}

const parseFieldName = (fieldName: string) => {
  const withoutPrefix = fieldName.replace(prefix, '')
  const indexOfSeparator = withoutPrefix.indexOf(separator)
  const fieldId = withoutPrefix.substring(0, indexOfSeparator)
  const fieldLabel = withoutPrefix.substring(
    indexOfSeparator + separator.length
  )

  return { fieldId, fieldLabel }
}

export default {
  createFieldName,
  parseFieldName,
  getPrefix: () => prefix
}
