import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux'
import { trackEvent } from 'state/_sagas/_actions'
import { TrackedEventDestinations, TrackedEventInterface } from 'types'

const analyticsWhiteList: TrackedEventInterface[] = [
  {
    actionName: 'router/NAVIGATE',
    trackingName: 'VIEW',
    destinations: [TrackedEventDestinations.MIXPANEL, TrackedEventDestinations.ADOBE]
  },
  {
    actionName: 'conversations/PING',
    trackingName: 'CONVERSATION_START',
    destinations: [TrackedEventDestinations.MIXPANEL]
  },
  {
    actionName: 'sagas/INITIALIZE_DESK_SUCCESS',
    trackingName: 'PAGE_VIEW',
    destinations: [TrackedEventDestinations.RP]
  }
]

const runWhiteListTrackedEvents = (store: MiddlewareAPI<Dispatch<AnyAction>, any>, action: any) => {
  const previewMode = store.getState().ui.rppreview // dirty middleware selector
  if (previewMode === 'none') {
    const { payload, type } = action
    const trackedEvent = analyticsWhiteList.find(
      event => event.actionName === type
    )

    if (trackedEvent && !(payload.hasOwnProperty('track') && !payload.track)) {
      // Event specific properties
      const properties = {} as { [x: string]: any }
      if (trackedEvent.trackingName === 'VIEW') {
        properties.viewId = payload.viewId
      }

      store.dispatch(
        trackEvent({
          ...trackedEvent,
          properties
        })
      )
    }
  }
}

const runAdobeTrackedEvents = (store: MiddlewareAPI<Dispatch<AnyAction>, any>, action: any) => {
  let { payload, type } = action,
    eventNames = ['CHAT_STARTED', 'CHAT_CLOSED', 'CHAT_CLICK', 'VIEW']
  
  if (type === 'sagas/TRACK_EVENT' && 
      eventNames.includes(payload.trackingName) && 
      payload.destinations.includes(TrackedEventDestinations.ADOBE)) {
      payload.properties = { ...payload.properties, visitorId : store.getState().visitorId }
  }
}

export const analyticsMiddleware: Middleware = store => next => action => {
  runWhiteListTrackedEvents(store, action)
  runAdobeTrackedEvents(store, action)
  next(action)
}
