import { createSelector } from 'reselect'

import { RootState } from 'state/reducer'

export const formFieldSelector = createSelector(
  (state: RootState, formId: string, fieldName: string) => {
    return (
      (state.forms[formId] && state.forms[formId][fieldName]) || { value: '' }
    )
  },
  formField => formField
)

export const formFieldSelectorFactory = () => formFieldSelector

export const formSelector = createSelector(
  (state: RootState, formId: string) => state.forms[formId],
  form => form
)

export const formSubmittedSelector = createSelector(
  (state: RootState, formId: string) => state.forms[formId],
  form => form && form.submitted
)

export const formValidSelector = createSelector(formSelector, form => {
  if (!form) {
    return false
  }
  return Object.keys(form).reduce((accumulator, current) => {
    // check only fields
    if (current === 'submitted') return accumulator
    return accumulator && form[current].status === 'valid'
  }, true)
})

export const formCardSelector = createSelector(
  (state: RootState) => state.entities.cards,
  cards => cards
)

export const formValidSelectorFactory = () => formValidSelector
