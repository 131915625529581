import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { submitViewForm } from 'state/forms'
import {
  closeLauncher,
  launcherCardsSelector,
  launcherSelector,
  rpPreviewModeSelector,
  webdeskPositionSelector,
  webdeskHorizontalPaddingSelector,
  webdeskVerticalPaddingSelector,
  windowSelector
} from 'state/ui'
import { RootState } from 'types'
import { LauncherDisplay, Props } from './Launcher.display'

type StateProps = Pick<
  Props,
  'launcher' | 'cards' | 'previewMode' | 'webdeskPosition' | 'isDeskOpen' | 'horizontalPadding' | 'verticalPadding'
>

const mapStateToProps = (state: RootState): StateProps => {
  const cards = launcherCardsSelector(state)

  return {
    launcher: launcherSelector(state),
    cards,
    previewMode: rpPreviewModeSelector(state),
    webdeskPosition: webdeskPositionSelector(state),
    horizontalPadding: webdeskHorizontalPaddingSelector(state),
    verticalPadding: webdeskVerticalPaddingSelector(state),
    isDeskOpen: windowSelector(state)
  }
}

type DispatchProps = Pick<Props, 'closeLauncher' | 'submitForm'>
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeLauncher: () => dispatch(closeLauncher()),
  submitForm: (formId: string) => {
    dispatch(submitViewForm({ cardId: formId }))
  }
})

export const LauncherContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LauncherDisplay)
