/* eslint-disable react/prop-types */
import { Media, Video } from '@andyet/simplewebrtc'
import * as React from 'react'
import styled from 'styled-components'

import { Flex } from 'components/_utility'

const Container = styled(Flex)({
  '& video': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: '#262a2c'
  }
})

const BlankVideo = styled(Flex)({
  backgroundColor: '#262a2c',
  color: '#e9ecec'
})

const NoVideo = () => (
  <BlankVideo
    _width={100}
    _height={100}
    alignItems="center"
    justifyContent="center"
  >
    No video selected
  </BlankVideo>
)

interface MediaPreviewProps {
  video?: Media
}

const MediaPreview: React.SFC<MediaPreviewProps> = ({ video }) => (
  <Container _height={60} _width={100} mb={8}>
    {video && video.loaded ? <Video media={video} /> : <NoVideo />}
  </Container>
)

export default MediaPreview
