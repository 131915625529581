import { shadow } from 'style/mixins'
import styled, { css } from 'styledComponents'

import image from './avatar.png'

interface Props {
  size?: number
  src?: string
}

const Avatar = styled.div`
  flex-shrink: 0;
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size}px`};
  margin-bottom: 8px;
  background-image: url(${image});

  ${(props: Props) =>
    props.src &&
    css`
      background-image: url(${props.src});
    `}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  ${shadow('light')}
`

Avatar.defaultProps = {
  size: 46
}

export { Avatar }
