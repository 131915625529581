import { Action } from 'redux'
import { isType } from 'typescript-fsa'

import { initializeDeskSuccess } from 'state/_sagas/_actions'
import {
  CardSequenceViewInterface,
  CardStackViewInterface,
  ConversationsViewInterface
} from 'types'
import { setView } from './views.actions'

export interface ViewsState {
  byId: {
    [_id: string]:
      | CardStackViewInterface
      | CardSequenceViewInterface
      | ConversationsViewInterface
  }
  allIds: string[]
}

export const DEFAULT_STATE: ViewsState = {
  byId: {},
  allIds: []
}

export const views = (state: ViewsState = DEFAULT_STATE, action: Action) => {
  if (isType(action, initializeDeskSuccess)) {
    const newViews = action.payload.views
    const newState = {
      byId: {},
      allIds: [] as string[]
    }
    for (const view of newViews) {
      newState.byId[view._id] = view
      newState.allIds.push(view._id)
    }
    return newState
  }

  if (isType(action, setView)) {
    const view = action.payload.view
    const newState = { ...state }
    newState.byId[view._id] = view
    if (!newState.allIds.includes(view._id)) newState.allIds.push(view._id)
    return newState
  }

  return state
}
