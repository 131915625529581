import * as React from 'react'
import styled from 'styledComponents'

import { Card } from 'components/_utility'

export interface Props {
  className?: string
}

const Component = ({ className }: Props) => {
  return <Card className={className}>Deal Summary</Card>
}

export const Step3 = styled(Component)``
