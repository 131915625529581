type PathET = () => {
  companyId: string
  webdeskId: string
  bookingMode: boolean
  bookingModeAppointmentServiceId?: string
  previewMode?: string
  webMoveUp?: number
  mobileMoveUp?: number
  shiftRetailerId?: string
  inventoryMode: boolean
  conversationId?: string
  cardId?: string
  livepreview?: boolean
}
// @ts-ignore
const PathET: PathET = window.PathET || window.top.PathET
// webdeskId must not have default value because of audience matching
const {
  companyId,
  webdeskId,
  bookingMode,
  bookingModeAppointmentServiceId,
  previewMode = 'none',
  webMoveUp = 0,
  mobileMoveUp = 0,
  shiftRetailerId,
  inventoryMode,
  conversationId,
  cardId,
  livepreview
} = PathET()
export default {
  env: ChatPathEPSettings.config.env,
  rpApiUrl: ChatPathEPSettings.config.apiUrl,
  rootUrl: ChatPathEPSettings.config.rootUrl,
  rpSocketUrl: ChatPathEPSettings.config.baseSocketURL,
  rpRelayApiUrl: ChatPathEPSettings.config.relayApiUrl,
  storeExpirationTime: ChatPathEPSettings.config.storeExpirationTime,
  applicationinsights: {
    key: ChatPathEPSettings.config.applicationinsights.key,
    enable:
      ChatPathEPSettings.config.env === 'aptible' ||
      ChatPathEPSettings.config.env === 'staging' ||
      ChatPathEPSettings.config.applicationinsights.enableInDev,
    debug: ChatPathEPSettings.config.applicationinsights.debug
  },
  SWRTCApiKey: ChatPathEPSettings.config.SWRTCApiKey,
  companyId,
  webdeskId,
  bookingMode,
  bookingModeAppointmentServiceId,
  previewMode,
  webMoveUp,
  mobileMoveUp,
  shiftRetailerId,
  getFullLeadIdStorageKey: (leadId: string) =>
    `__response-path-id-${companyId}-lead-id-${leadId}__`, // Is lead with collected email and phone or vehicle information?
  leadRoomIdStorageKey: `__response-path-id-${companyId}__`,
  isWebdeskHiddenStorageKey: `__response-path-${companyId}-${webdeskId}-is-hidden__`,
  triggersDisabledStorageKey: `__response-path-${companyId}-${webdeskId}-triggers-disabled__`,
  persistExpireKey: `__response-path-${companyId}-booking-${bookingMode}-persist-expires-at__`,
  userIdStorageKey: '_epajs_user_id',
  sessionIdStorageKey: '_epajs_session_id',
  inventoryMode,
  conversationId,
  cardId,
  livepreview
}
