import { darken } from 'polished'
import * as React from 'react'
import styled, { css } from 'styledComponents'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { helpers } from 'style/mixins/'
import { HelpersProps } from 'types'

interface Props extends HelpersProps {
  className?: string
  children: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  onDisabled?: () => void
  chevronRight?: boolean
  type?: string // 'submit' | 'reset' | 'button' | 'href' | 'view' | 'custom' | 'bot'
  customAttributes?: { [x: string]: string } | {}
  id?: string
}

const Component = React.forwardRef(
  (
    {
      className,
      type = 'button',
      children,
      disabled,
      onClick,
      onDisabled,
      chevronRight,
      customAttributes = {},
      id
    }: Props,
    ref: any
  ) => (
    <button
      {...customAttributes}
      className={className}
      id={id}
      ref={ref}
      onClick={e => {
        const handler = disabled ? onDisabled : onClick
        if (handler) {
          /**
           * to prevent form submission error by enter btn click
           * have to call preventDefault();
           * placed here because no need in prevention when button is inside <a> tag
           */
          e.preventDefault()
          handler()
        }
      }}
      type={type as 'submit' | 'reset' | 'button'}
    >
      {children}
      {chevronRight && <ChevronRight icon={faChevronRight} />}
    </button>
  )
)

Component.displayName = 'Button'

export interface StyledProps extends Props {
  primary?: boolean
  cadillacTheme?: boolean
  outline?: boolean
  grayOutline?: boolean
  textonly?: boolean
  picker?: boolean
  header?: boolean
  rounded?: boolean
  capitalize?: boolean
  multiline?: boolean
  tall?: boolean
  selected?: boolean
  navFooter?: boolean
  loadMore?: boolean
}

export const Button = styled(Component)<StyledProps>`
  cursor: pointer;
  padding: 9px ${(p: StyledProps) => (p.chevronRight ? '9px' : '15px')} 9px 15px;
  border-radius: 6px;
  border: none;
  background-color: transparent;

  font-size: 14px;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: background-color 250ms, color 250ms, border-color 250ms,
    opacity 500ms;

  &:focus {
    outline: none;
  }

  ${(p: any) => {
    return css`
      /* Colors */
      ${p.primary &&
        `
        color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:'white'};
        background-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
        text-transform: ${p.theme.isCadillacTheme?'uppercase !important':'capitalize'};
        &:hover {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:darken(0.05, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
        &:focus {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:darken(0.1, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
        &:disabled {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacBubble:darken(0.1, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacBubble:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.cadillacFontColor:'white'};
        }
        &:active {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:darken(0.05, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
      `}

      ${p.cadillacTheme &&
        `
        color: #fcfcfc;
        background-color: #282828;

        &:hover {
          background-color: #fcfcfc;
          color: #282828
        }
        &:focus {
          background-color: #fcfcfc;
          color: #282828
        }
      `}
      ${p.outline &&
        `
        background-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:"transparent"};
        color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:p.theme.colors.original};
        border: solid 2px ${p.theme.colors.light};
        padding: 7px ${p.chevronRight ? '7px' : '13px'} 7px 13px;
        text-transform: ${p.theme.isCadillacTheme?'uppercase !important':'capitalize'};

        &:hover {
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
          border-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:p.theme.colors.original};
        }
        &:focus {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:darken(0.1, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
        &:disabled {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacBubble:darken(0.1, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacBubble:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.cadillacFontColor:'white'};
        }
        &:active {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacBubble:darken(0.05, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
      `}

      ${p.grayOutline &&
        `
        background-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.lightest};
        border: solid 1.5px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.lighter};
        color: ${p.theme.isCadillacTheme?'#fcfcfc':'black'};
        padding: 7px ${p.chevronRight ? '7px' : '13px'} 7px 13px;
        text-transform: ${p.theme.isCadillacTheme?'uppercase !important':'capitalize'};

        ${p.tall &&
          `
          padding: 10px ${p.chevronRight ? '10px' : '18px'} 10px 15px;
        `}

        &:hover {
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
          border-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:p.theme.colors.original};
        }
        &:focus {
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
          border-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:p.theme.colors.original};
        }
        &:disabled {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacBubble:darken(0.1, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacBubble:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.cadillacFontColor:'white'};
        }
        &:active {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:darken(0.05, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
      `}

      ${p.textonly &&
        `
        background-color:  ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:"transparent"};
        color: ${p.theme.isCadillacTheme?'#fcfcfc':'black'};
        text-transform: ${p.theme.isCadillacTheme?'uppercase !important':'capitalize'};

        &:hover {
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
          border: solid 2px  ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:p.theme.colors.original};
        }
        &:focus {
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
          border: solid 2px  ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:p.theme.colors.original};
        }
        &:active {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacFontColor:darken(0.05, p.theme.colors.original)};
          border: solid 2px ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original};
          color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:'white'};
        }
      `}

      ${p.picker &&
        `
        padding: 6px ${p.chevronRight ? '9px' : '15px'} 6px 15px;

        border: solid 2px ${p.theme.colors.lighter};

        &:hover {
          border: solid 2px ${p.theme.colors.light};
        }

        &:focus {
          border: solid 2px ${p.theme.colors.light};
        }

        ${p.selected &&
          `
          border: solid 2px ${p.theme.colors.light};
        `}
      `}

      ${p.header &&
        `
        color: ${p.theme.colors.lighter};

        &:hover {
          background-color: ${p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original}
        }

        &:focus {
          background-color: ${darken(0.1, p.theme.isCadillacTheme?p.theme.cadillacColors.original:p.theme.colors.original)}
        }
      `}

      ${p.disabled && p.theme.isCadillacTheme &&
        `
        opacity: 0.55;
        cursor: not-allowed;
        background-color: ${p.theme.cadillacBubble};
        border: solid 2px ${p.theme.cadillacBubble};
        color: ${p.theme.cadillacColors.cadillacFontColor};
        text-transform: ${p.theme.isCadillacTheme?'uppercase':'capitalize'};
      `}

      ${p.disabled &&
        `
        opacity: 0.55;
        cursor: not-allowed;
      `}

      ${p.loadMore &&
        `
        border: 1px solid white;
        &:focus {
          border: solid 1px ${p.theme.colors.light};
        }
      `}

        /* Shapes */
        ${p.rounded && `border-radius: 200px`};
        ${p.capitalize && `text-transform: capitalize`};
        ${p.multiline && `white-space: normal;`};
    `
  }}
  ${helpers}
`

const ChevronRight = styled(Icon)`
  margin-right: 3px;
  margin-left: 9px;

  transition: margin-left ease-in-out 0.25s, margin-right ease-in-out 0.25s;

  ${Button}:hover & {
    margin-left: 12px;
    margin-right: 0;
  }
`
