interface Node {
  component: () => JSX.Element
  condition?: boolean
  nodes?: Node[]
}

const isNodeValid = (innerNode: Node) => {
  return !!innerNode.condition || typeof innerNode.condition === 'undefined'
}

export const getCardFromTree = (
  node: Node,
  steps: number
): { CurrentCard: () => JSX.Element; hasNext: boolean } => {
  const nextNode = node.nodes && node.nodes.find(isNodeValid)

  if (steps > 1 && nextNode) {
    return getCardFromTree(nextNode, steps - 1)
  } else {
    return {
      CurrentCard: node.component,
      hasNext: !!node.nodes && node.nodes.some(isNodeValid)
    }
  }
}
