import actionCreatorFactory from 'typescript-fsa'

import { CardInterface } from 'types'
import { CardsState } from './cards.reducer'

const actionCreator = actionCreatorFactory('cards')

export const setCardIndex = actionCreator<{
  _id: string
  index: number
  completedIndex?: number
}>('SET_CARDINDEX')
export const updateCard = actionCreator<{
  _id: string
  data: Partial<CardInterface>
}>('UPDATE_CARD')
export const setCard = actionCreator<{ card: CardInterface }>('SET_CARD')
export const updateCardProps = actionCreator<{ index: number }>(
  'UPDATE_CARD_REVIEWS'
)
export const updateCardAction = actionCreator<{
  _id: string
  data: Partial<CardInterface>
}>('UPDATE')
export const getReviewsSites = actionCreator<{ cardId: string }>('GET_REVIEWS')

export const rehydrateCardsState = actionCreator<{ cardsState: CardsState }>(
  'REHYDRATE'
)
