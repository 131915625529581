import 'react-hot-loader'
import 'isomorphic-fetch'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Store } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import { monitoringService } from 'services/monitoring'
import { datadogService } from 'services/datadog'

import 'normalize.css'
import 'typeface-roboto'
import './index.css'

import { initializeDesk } from 'state/_sagas/_actions'

import settings from 'settings'
import { App } from './App'
import { getStore, persistor } from './getStore'
import publicApi from './publicApi'
import apiService from './services/rp.api'

const installAdobeTagging = async () => {
  apiService.init('')
  let adobeSettings = await apiService.getAdobeTag(settings.companyId)
  if (adobeSettings.enabled) {
      let head = document.getElementsByTagName('head')[0]
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = adobeSettings.srcUrl!
      head.appendChild(script)
  }
}

const initApp = () => {
  // @ts-ignore
  const isIE = /* @cc_on!@ */ false || !!document.documentMode
  if (isIE) return

  monitoringService.start()
  datadogService.start()
  const store = getStore()
  store.dispatch(initializeDesk())
  installAdobeTagging()

  let containerElement = document.getElementById('chatpath-ep')
  if (!containerElement) {
    containerElement = document.createElement('div')
    containerElement.id = 'chatpath-ep'
    document.body.appendChild(containerElement)
  }

  const Main = ({ state }: { state: Store }) => (
    <Provider store={state}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )

  const render = (renderedStore: Store) => {
    ReactDOM.render(
      <div>
        <Main state={renderedStore} />
      </div>,
      containerElement
    )
  }

  if (module.hot) {
    module.hot.accept()
  }

  render(store)

  if (settings.previewMode === 'none') {
    publicApi.init()
  }
}

initApp()
