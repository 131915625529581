import { takeEvery } from 'redux-saga/effects'

import rpApiService from 'services/rp.api'

import { auditWidgetUrl } from './_actions'

function saga() {
  const widgetScript = window.parent.document.querySelector(
    'script[src*="widget.js"]'
  )
  if (widgetScript) {
    rpApiService.auditWidgetUrl({
      widgetUrl: widgetScript.getAttribute('src') as string,
      sourceUrl: window.location.hostname
    })
  }
}

export function* auditWidgetUrlSaga() {
  yield takeEvery(auditWidgetUrl, saga)
}
