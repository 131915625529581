import * as React from 'react'
import styled, { css } from 'styledComponents'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { CardStackContainer } from 'components'
import { Button, Container } from 'components/_utility'
import { sizes } from 'style/variables'

import { MAX_WINDOW_HEIGHT, MIN_WINDOW_HEIGHT } from 'common/constants'
import {
  CardSequenceViewInterface,
  CardStackViewInterface,
  ConversationsViewInterface,
  NavButtonInterface
} from 'types'

export interface Props {
  className?: string
  open: boolean
  view:
    | CardSequenceViewInterface
    | CardStackViewInterface
    | ConversationsViewInterface
  navButtons: NavButtonInterface[]
  previewMode: string
  webMoveUp: number
  mobileMoveUp: number
  webdeskPosition: string
  closeWindow: () => void
}

const Component = ({
  className,
  view,
  open,
  closeWindow,
  navButtons,
  previewMode,
  webMoveUp,
  webdeskPosition
}: Props) => {
  if (previewMode === 'none') {
    React.useEffect(() => {
      const resizeHandler = () => {
        const el = document.getElementById('rp-toolbar-window')
        const computedHeight = window.top.innerHeight - 72 - 36 - 18 - webMoveUp
        if (el instanceof HTMLElement) {
          const singleButtonNavAdjustment = navButtons.length ? 0 : 36
          const newHeight =
            window.top.innerWidth > sizes.mobileWidth
              ? `${computedHeight}px`
              : `${window.top.innerHeight -
                  72 -
                  singleButtonNavAdjustment -
                  webMoveUp}px`
          el.style.height = newHeight
          el.style.maxHeight = `${
            computedHeight < MAX_WINDOW_HEIGHT - webMoveUp
              ? computedHeight
              : MAX_WINDOW_HEIGHT - webMoveUp
          }px`
          el.style.minHeight = `${MIN_WINDOW_HEIGHT - webMoveUp}px`
        }
      }

      window.top.addEventListener('resize', resizeHandler)
      return () => window.top.removeEventListener('resize', resizeHandler)
    }, [])
  }

  const viewRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if ((view as CardSequenceViewInterface | CardStackViewInterface)._id) {
      viewRef.current!.scrollTop = 0
    }
  }, [(view as CardSequenceViewInterface | CardStackViewInterface)._id])

  return (
    <React.Fragment>
      {open && (
        <CloseButton
          mb={4}
          webdeskPosition={webdeskPosition}
          onClick={closeWindow}
        >
          Close
          <Icon icon={faTimes} />
        </CloseButton>
      )}
      <Container
        ref={viewRef}
        _id="rp-toolbar-window"
        className={className}
        mb={9}
        display={open ? 'block' : 'none'}
      >
        <CardStackContainer view={view as CardStackViewInterface} />
      </Container>
    </React.Fragment>
  )
}

export const ToolbarWindowDisplay = styled(Component)`
  position: relative;
  z-index: 0;
  width: 100%;
  ${p => css`
    max-height: ${window.top.innerHeight - 72 - 36 - 9 - 8 - p.webMoveUp <
    MAX_WINDOW_HEIGHT - p.webMoveUp
      ? window.top.innerHeight - 72 - 36 - 9 - 8 - p.webMoveUp
      : MAX_WINDOW_HEIGHT - p.webMoveUp}px;
  `}
  box-shadow: 0 4px 40px rgba(0,0,0,.12);
  border-radius: 6px;

  overflow: auto;

  ${p =>
    sizes.isMobile &&
    css`
      max-height: ${window.top.innerHeight -
        (p.navButtons.length ? 72 : 108) -
        p.mobileMoveUp -
        27}px;
      border-radius: 0;
      margin-bottom: 0;
    `}

  .view-appear {
    opacity: 0;
  }

  .view-enter-done {
    transition: opacity 270ms;
    opacity: 1;
  }
`

const CloseButton = styled(Button)<{ webdeskPosition: string }>`
  ${p =>
    p.webdeskPosition === 'left' &&
    css`
      align-self: flex-start;
    `}
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 9px;
  border-radius: 6px;

  font-size: 11px;
  color: white;
  background-color: ${p => p.theme.gray.light};
  opacity: 0.4;

  &:hover {
    background-color: ${p => p.theme.gray.base};
  }

  svg {
    margin-left: 3px;
  }
`
