import * as React from 'react'
import styled from 'styledComponents'

import { shadow } from 'style/mixins/'
import { ConversationElementInterface, MessageAttachmentInterface } from 'types'

export interface Props {
  element: ConversationElementInterface
}

const Image = styled('div')<{ url: string }>`
  height: 150px;
  width: 100%;
  background-image: url(${p => p.url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 9px;
  ${shadow('light')}
`

const Attachment = styled('div')`
  margin-top: 8px;
`

const Link = styled('a')`
  font-weight: 500;
`

export const MessageDisplay = ({ element }: Props) => {
  const mapAtachments = (attachments: MessageAttachmentInterface[]) => {
    return attachments.map(attachment => {
      if (attachment.type.indexOf('image/') !== -1) {
        return (
          <Attachment key={attachment.id}>
            <Link
              href={attachment.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image url={attachment.url} />
            </Link>
          </Attachment>
        )
      }

      return (
        <Attachment key={attachment.id}>
          <Link href={attachment.url} target="_blank" rel="noopener noreferrer">
            {attachment.name}
          </Link>
        </Attachment>
      )
    })
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: element.contents as string }} />
      {element.attachments && mapAtachments(element.attachments)}
    </div>
  )
}
