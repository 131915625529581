import * as React from 'react'

import { faCaretSquareLeft } from '@fortawesome/pro-light-svg-icons/faCaretSquareLeft'
import { faCaretSquareRight } from '@fortawesome/pro-light-svg-icons/faCaretSquareRight'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/_utility'
import styled, { css } from 'styledComponents'

interface Props {
  className?: string
  isToolbarExpanded: boolean
  isShortTooltip: boolean
  webdeskPosition: string
  setIsToolbarExpanded: (isToolbarExpanded: boolean) => void
}

const Component = ({
  className,
  isToolbarExpanded,
  setIsToolbarExpanded,
  webdeskPosition,
  isShortTooltip
}: Props) => {
  const onClick = React.useCallback(
    () => setIsToolbarExpanded(!isToolbarExpanded),
    [isToolbarExpanded]
  )

  const icon = React.useMemo(() => {
    if (
      (isToolbarExpanded && webdeskPosition === 'right') ||
      (!isToolbarExpanded && webdeskPosition === 'left')
    ) {
      return faCaretSquareRight
    }
    return faCaretSquareLeft
  }, [isToolbarExpanded, webdeskPosition])

  const tooltip = React.useMemo(() => {
    if (isShortTooltip) {
      return isToolbarExpanded ? 'Less' : 'More'
    }
    return isToolbarExpanded ? 'Show Less' : 'Show More'
  }, [isToolbarExpanded, isShortTooltip])

  // touch devices interpret touch as hover
  const isMobile = React.useMemo(
    () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
    []
  )
  return (
    <Button header onClick={onClick} className={className}>
      <Icon icon={icon} />
      {!isMobile && (
        <Tooltip size={30} tooltipRight={webdeskPosition === 'right'}>
          {tooltip}
        </Tooltip>
      )}
    </Button>
  )
}

interface TooltipProps {
  size: number
  tooltipRight?: boolean
}

const Tooltip = styled('div')<TooltipProps>`
  visibility: hidden;
  position: absolute;
  z-index: 11;
  ${p =>
    p.tooltipRight &&
    css`
      left: ${p.size * 1.1}px;
    `}
  ${p =>
    !p.tooltipRight &&
    css`
      right: ${p.size * 1.1}px;
    `}
  font-size: ${p => p.size * 0.5}px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  text-align: right;
  border-radius: 3px;
  background-color: ${p => p.theme.gray.darker};
  color: white;
`

export const ControlToolbarButton = styled(Component)`
  overflow: visible;
  &:hover ${Tooltip} {
    visibility: visible;
  }
`
