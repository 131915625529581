import * as React from 'react'
import styled, { withTheme } from 'styledComponents'

import { Card, Flex, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import { CardHeaderProps, HelpersProps, ThemeInterface } from 'types'

export interface Props extends HelpersProps {
  className?: string
  theme: ThemeInterface
  fullName: string
  companyName: string
  header?: CardHeaderProps
}

const Component = ({
  className,
  header,
  fullName,
  theme,
  companyName
}: Props) => (
  <Card
    className={className}
    padding="15px"
    topbar={false}
    gradientBorder
    header={header}
  >
    <Flex column alignItems="center">
      <Text
        fs={18}
        pb={10}
        semibold
        align="center"
        color={theme.colors.original}
      >
        Pre-Selected Certificate
      </Text>
      <Text thin align="center">
        This Certificate is presented to:
      </Text>
      <Text
        fs={17}
        pt={3}
        pb={3}
        underline
        align="center"
        color={theme.colors.original}
      >
        {fullName}
      </Text>
      <Text thin align="center">
        You have been Pre Approved for Financing
      </Text>
      <Text thin align="center">
        Valid Only At
      </Text>
      <Text fs={17} pt={3} align="center" color={theme.colors.original}>
        {companyName}
      </Text>
    </Flex>
  </Card>
)

export const FinancingCertificateDisplay = withTheme(styled(Component)`
  ${helpers}
`)
