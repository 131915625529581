import * as React from 'react'
import styled from 'styledComponents'

import { Card } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  HelpersProps
} from 'types'
import { transformVideoUrl } from 'utility'

export interface Props extends HelpersProps {
  className?: string
  _id: string
  url: string
  collapsed?: boolean
  header?: CardHeaderProps
  actionButtons?: ActionButtonActionInterface[]
}

const Component = ({
  className,
  url,
  collapsed = false,
  header,
  actionButtons
}: Props) => (
  <Card
    className={className}
    padding="none"
    header={header}
    collapsed={collapsed}
    actionButtons={actionButtons}
    actionButtonsDisplay={{ padding: 'full-top' }}
    customAttributes={{ 'data-test': 'video-card' }}
  >
    <iframe src={transformVideoUrl(url)} frameBorder="0" allowFullScreen />
  </Card>
)

export const VideoCardDisplay = styled(Component)`
  ${helpers}

  iframe {
    width: 100%;
    height: 200px;
  }
`
