import { put, takeEvery } from 'redux-saga/effects'

import { updateForm } from 'state/forms'

import { initializeTradeIn } from '../_actions'
import { kbb } from '../_subgenerators'

function* saga() {
  try {
    const years = yield* kbb.getTradeInVehicleYears()
    yield put(
      updateForm({
        formId: 'trade_in_1',
        data: {
          'tradeInVehicle.year': {
            options: years,
            status: 'invalid'
          },
          'tradeInVehicle.make': {
            status: 'invalid'
          },
          'tradeInVehicle.model': {
            status: 'invalid'
          },
          'tradeInVehicle.trim': {
            status: 'invalid'
          }
        }
      })
    )
  } catch (e) {
    console.error('Error:', e)
  }
}

export function* initializeTradeInSaga() {
  yield takeEvery(initializeTradeIn, saga)
}
