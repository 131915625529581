import * as React from 'react'
import styled from 'styledComponents'

import { withForm } from 'components/_hoc'
import {
  Card,
  ConnectedSelect,
  Flex,
  Label,
  SubmitButton,
  Text
} from 'components/_utility'
import { updateTradeInStep1 } from 'state/_sagas/_actions'
import { helpers } from 'style/mixins/'
import { FormInterface, HelpersProps } from 'types'

import kbbImage from './kbb.png'

export interface Props extends HelpersProps {
  className?: string
  form?: FormInterface
  submit: () => void
}

const Component = ({ className, form = {}, submit }: Props) => {
  const {
    'tradeInVehicle.year': year = { options: null },
    'tradeInVehicle.make': make = { options: null },
    'tradeInVehicle.model': model = { options: null },
    'tradeInVehicle.trim': trim = { options: null }
  } = form || {}

  return (
    <Card className={className}>
      <Flex row mb={12}>
        <Flex column _width={84}>
          <Text isTitle>Get a trade-in value</Text>
          <Text isBody>
            Enter the details of your car to help
            <br />
            us estimate its value.
          </Text>
        </Flex>
        <Flex column grow justifyContent="flex-start" alignItems="flex-end">
          <img src={kbbImage} />
        </Flex>
      </Flex>

      {/* wrapped to form to handle ENTER key submission */}
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      >
        {year.options && (
          <Flex column mb={12}>
            <Label>Year</Label>
            <ConnectedSelect
              name="tradeInVehicle.year"
              required
              sideEffect={updateTradeInStep1({
                updatedField: 'tradeInVehicle.year'
              })}
            />
          </Flex>
        )}
        {make.options && (
          <Flex column mb={12}>
            <Label>Make</Label>
            <ConnectedSelect
              name="tradeInVehicle.make"
              required
              sideEffect={updateTradeInStep1({
                updatedField: 'tradeInVehicle.make'
              })}
            />
          </Flex>
        )}
        {model.options && (
          <Flex column mb={12}>
            <Label>Model</Label>
            <ConnectedSelect
              name="tradeInVehicle.model"
              required
              sideEffect={updateTradeInStep1({
                updatedField: 'tradeInVehicle.model'
              })}
            />
          </Flex>
        )}
        {trim.options && (
          <Flex column mb={12}>
            <Label>Trim</Label>
            <ConnectedSelect
              name="tradeInVehicle.trim"
              required
              sideEffect={updateTradeInStep1({
                updatedField: 'tradeInVehicle.trim'
              })}
            />
          </Flex>
        )}
        <SubmitButton
          _width={100}
          primary
          failureMessage="Please select a valid vehicle"
          onClick={submit}
        >
          Next
        </SubmitButton>
      </form>
    </Card>
  )
}

export const Step1 = styled(withForm(Component, 'trade_in_1'))`
  ${helpers}

  img {
    width: 44px;
    height: 78px;
  }
`
