import { put, select, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import { cardPropsSelector } from 'state/entities/cards'
import { leadSelector, pingLead, updateLead } from 'state/lead'
import { getTradeInValue, showToast } from '../_actions'
import { kbb } from '../_subgenerators'

function* saga(action: Action<{ cardId: string }>) {
  try {
    const { cardId } = action.payload
    const { inquiryTypeId } = yield select(cardPropsSelector, cardId)

    yield put(
      updateLead({ tradeInVehicle: { price: undefined }, inquiryTypeId })
    )
    const {
      tradeInVehicle: {
        year: { value: yearId },
        make: { value: makeId },
        model: { value: modelId },
        trim: { value: trimId },
        mileage: mileage,
        condition: { value: condition }
      }
    } = yield select(leadSelector)
    const payload = yield* kbb.getTradeInVehiclePrices(
      yearId,
      makeId,
      modelId,
      trimId,
      mileage
    )
    let tradeInVehicle
    if (payload.prices.length === 0) {
      tradeInVehicle = {
        price: 0
      }
    } else {
      const { configuredValue: tradeInPrice } = payload.prices.find(
        (price: { condition: string }) => price.condition === condition
      )
      tradeInVehicle = {
        price: tradeInPrice,
        standardOptions: payload.standardOptions
      }
    }
    yield put(updateLead({ tradeInVehicle }))
    yield put(pingLead({ performUpdate: true }))
  } catch (e) {
    console.error('Error:', e)
    yield put(
      showToast({ message: 'Failed to get trade-in price', variant: 'error' })
    )
  }
}

export function* getTradeInValueSaga() {
  yield takeEvery(getTradeInValue, saga)
}
