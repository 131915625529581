import { Middleware } from 'redux'

const actionsToEmit = [
  'ui/FINSHED_INITIALIZING_PREVIEW',
  'ui/ADD_NAV_BUTTON',
  'ui/EDIT_HOME_NAV',
  'ui/UPDATE_NAV_BUTTONS',
  'ui/NAVIGATE_DASHBOARD_VIEW',
  'ui/UPDATED_WIP_CARD',
  'router/NAVIGATE'
]

export const emitEP2ToDashboardMiddleware: Middleware = store => next => action => {
  const previewMode = store.getState().ui.rppreview // dirty middleware selector
  const emittableEvent = actionsToEmit.find(
    (actionName: string) => actionName === action.type
  )
  if (previewMode !== 'none' && emittableEvent) {
    // emit window event
    window.top.postMessage({ action, type: 'EP2Preview' }, window.location.href)
  }
  next(action)
}
