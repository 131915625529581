/* eslint-disable react/prop-types */
import {
  GridLayout,
  Peer,
  PeerList,
  RemoteMediaList
} from '@andyet/simplewebrtc'
import * as React from 'react'
import styled from 'styled-components'

import { Flex, Text } from 'components/_utility'
import { HiddenPeers } from '../contexts'
import PeerGridItem from './PeerGridItem'

const StyledGridLayout = styled(GridLayout)`
  height: 100%;

  video {
    width: 100%;
  }
` as any // TODO: Fix this!

interface Props {
  roomAddress: string
  activeSpeakerView: boolean
}

// PeerGrid is the main video display for Talky. It matches remoteMedia to
// peers and then renders a PeerGridItem for each peer in the room.
export const PeerGrid: React.FC<Props> = ({
  roomAddress,
  activeSpeakerView
}) => {
  const { hiddenPeers } = React.useContext(HiddenPeers)
  return (
    <PeerList
      speaking={activeSpeakerView || undefined}
      room={roomAddress}
      render={({ peers }) => {
        const visiblePeers = peers.filter(p => !hiddenPeers.includes(p.id))

        return visiblePeers.length > 0 || activeSpeakerView ? (
          <StyledGridLayout
            items={visiblePeers}
            renderCell={(peer: Peer) => (
              <RemoteMediaList
                peer={peer.address}
                render={({ media }) => (
                  <PeerGridItem media={media} peer={peer} />
                )}
              />
            )}
          />
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            _width={100}
            _height={100}
          >
            <Text fs={20} isTitle>
              There is not anyone here (yet!)
            </Text>
          </Flex>
        )
      }}
    />
  )
}
