import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { NavButtonInterface } from 'components/NavButton'
import { trackEvent } from 'state/_sagas/_actions'
import { currentViewIdSelector, navigate } from 'state/router'
import {
  addNavButton,
  defaultViewIdSelector,
  editHomeNav,
  isToolbarEnabledSelector,
  launcherSelector,
  navButtonsSelector,
  navSelector,
  rpPreviewModeSelector,
  themeSelector,
  toastSelector,
  webdeskPositionSelector
} from 'state/ui'
import { RootState, TrackedEventDestinations } from 'types'
import { DockDisplay, Props } from './Dock.display'

type StateProps = Partial<Props>

const mapStateToProps = (state: RootState): StateProps => {
  const nav = navSelector(state)
  const navButtons = navButtonsSelector(state)
  const isToolbarEnabled = isToolbarEnabledSelector(state)
  const maxButtonsCount = isToolbarEnabled ? 5 : 4

  const currentViewId =
    currentViewIdSelector(state) || defaultViewIdSelector(state)
  return {
    nav,
    navButtons,
    launcher: launcherSelector(state),
    currentViewId,
    toast: toastSelector(state),
    theme: themeSelector(state),
    previewMode: rpPreviewModeSelector(state),
    webdeskPosition: webdeskPositionSelector(state),
    isToolbarEnabled,
    maxButtonsCount
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNavState: (currentViewId: string) => {
    dispatch(navigate({ viewId: currentViewId }))
    // send to shift digital
    dispatch(
      trackEvent({
        trackingName: 'CHAT_CLICK',
        properties: {},
        destinations: [TrackedEventDestinations.SHIFT, TrackedEventDestinations.ADOBE]
      })
    )
  },
  addNavButton: (navButtons: NavButtonInterface[]) => {
    const index = navButtons.length
    dispatch(addNavButton({ index }))
  },
  editHomeNavigation: () => {
    dispatch(editHomeNav({}))
  }
})

export const DockContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DockDisplay)
