/* eslint-disable no-restricted-syntax */
import * as React from 'react'
import {
  DeviceList,
  LocalMediaList,
  RequestUserMedia
} from '@andyet/simplewebrtc'

export interface AutoCaptureState {
  allowInitialAutoCapture: boolean
  previewAudioId?: string
  previewVideoId?: string
}

export class AutoCapture extends React.Component<{}, AutoCaptureState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      allowInitialAutoCapture: true
    }
  }

  public render() {
    return (
      <LocalMediaList
        screen={false}
        render={() => {
          return (
            <DeviceList
              render={() => {
                return this.initialAutoCapture()
              }}
            />
          )
        }}
      />
    )
  }

  private initialAutoCapture() {
    const auto =
      this.state.allowInitialAutoCapture &&
      !this.state.previewAudioId &&
      !this.state.previewVideoId

    if (!auto) {
      return null
    }

    setTimeout(() => {
      this.setState({
        allowInitialAutoCapture: false
      })
    }, 0)

    return (
      <RequestUserMedia
        share
        auto
        audio
        video
        onError={() => {
          this.setState({
            allowInitialAutoCapture: false
          })
        }}
        onSuccess={ids => {
          this.setState({
            allowInitialAutoCapture: false,
            previewAudioId: ids && ids.audio,
            previewVideoId: ids && ids.video
          })
        }}
        render={() => null}
      />
    )
  }
}
