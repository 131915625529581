import { call, put } from 'redux-saga/effects'
import * as uuidv4 from 'uuid/v4'

import settings from 'settings'
import { logAction } from 'state/_sagas/_actions'
import api from 'utility/api/api.client'
import customWindow from 'utility/storage/customWindow'

function getSessionId() {
  let id = window.sessionStorage.getItem(settings.sessionIdStorageKey)
  if (!id) {
    id = uuidv4()
    window.sessionStorage.setItem(settings.sessionIdStorageKey, id)
  }

  return id
}

function isGoogleBot() {
  return navigator.userAgent.indexOf('Googlebot') !== -1
}

const apiClient = api({ baseApiUrl: `${settings.rpApiUrl}/api/v1` })

const eventNamesMap = {
  PAGE_VIEW: 'Page View'
}
export default function* trackEventRPSubgenerator(
  trackingName: string,
  data: { [x: string]: any }
) {
  try {
    if (isGoogleBot()) return

    let userId = customWindow.localStorage.getItem(settings.userIdStorageKey)
    if (!userId) {
      userId = uuidv4()
      customWindow.localStorage.setItem(settings.userIdStorageKey, userId)
    }

    yield call(apiClient.post, 'analytics', null, {
      userId,
      sessionId: getSessionId(),
      event: eventNamesMap[trackingName],
      properties: data,
      context: {
        host: document.location.hostname,
        path: document.location.pathname,
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        timezone: new Date().getTimezoneOffset()
      }
    })
  } catch (err) {
    console.error(err)
    yield put(logAction({ error: err }))
  }
}
