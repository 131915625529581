import { NavButtonInterface } from 'types'

export const calculateToolbarWidth = (
  navButtons: NavButtonInterface[],
  isToolbarExpanded: boolean,
  numberOfHelpButtons: number
): string => {
  const buttons = isToolbarExpanded
    ? navButtons
    : navButtons.filter(button => !button.hiddenInToolbarMode)
  const reducingForHelpButtons = buttons.length > 4 ? numberOfHelpButtons : 0
  let widthOfNavButton

  // depends on NavButton.display.tsx
  switch (reducingForHelpButtons) {
    case 2:
      widthOfNavButton = 56
      break
    case 1:
      widthOfNavButton = 64
      break
    default:
      widthOfNavButton = 66
      break
  }

  const widthOfHelpButton = 40
  const iframeMargins = 18 + 18

  return `${widthOfNavButton * buttons.length +
    numberOfHelpButtons * widthOfHelpButton +
    iframeMargins}px`
}
