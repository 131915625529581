import { createSelector } from 'reselect'
import { RootState } from 'state/reducer'

export const roomNameSelector = createSelector(
  (state: RootState) => state.video.roomName,
  roomName => roomName
)

export const expandedModeSelector = createSelector(
  (state: RootState) => state.video.expandedMode,
  expandedMode => expandedMode
)

export const mobileShrinkSelector = createSelector(
  (state: RootState) => state.video.mobileShrink,
  mobileShrink => mobileShrink
)
