import * as React from 'react'

import { Flex } from 'components/_utility'
import {
  CustomSelectOptionInterface,
  FlexProps,
  InjectedFieldProps
} from 'types'

export interface Props extends FlexProps, InjectedFieldProps {
  name: string
  children: React.ReactNode
}

export const CustomSelect = ({
  name,
  children,
  value = 'blue',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  status,
  updateField,
  ...flexProps
}: Props) => {
  return (
    <Flex {...flexProps}>
      {React.Children.map(
        children,
        (child: React.ReactElement<CustomSelectOptionInterface>) => {
          return React.cloneElement(child, {
            selected: child.props.value === value,
            selectValue: () =>
              updateField({ [name]: { value: child.props.value } })
          })
        }
      )}
    </Flex>
  )
}
