import { buffers, END, eventChannel } from 'redux-saga'
import {
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery
} from 'redux-saga/effects'

import { logAction } from 'state/_sagas/_actions'
import {
  botTyping,
  fetchConversation,
  markReadInState,
  messageSent,
  messageUpdated,
  providerTyping
} from 'state/entities/conversations'
import { updateLead } from 'state/lead/lead.actions'

import socket, { RPSocketService } from 'services/rp.socket'
import settings from 'settings'
import { Lead, ProviderTypingInterface, SocketMessage } from 'types'
import { mapLead } from 'utility/mapLead'
import customWindow from 'utility/storage/customWindow'
import { init } from './socket.actions'

function createSocketChannel(io: RPSocketService) {
  return eventChannel(emit => {
    const handleConnect = (): void => {
      socket.emit('client:subscribe', {
        companyId: settings.companyId,
        roomId: customWindow.localStorage.getItem(settings.leadRoomIdStorageKey)
      })
    }
    const handleDisconnect = (reason: string): void => {
      if (reason === 'io client disconnect') {
        emit(END)
      }
    }
    const handleError = (error: Error): void => {
      emit(logAction({ error }))
      // eslint-disable-next-line no-console
      console.error(error)
    }
    const handleClientSubscribed = (): void => {
      socket.setInitialized(true)
    }
    const handleLeadProfileUpdated = ({ lead }: { lead: Lead }): void => {
      if (lead) {
        emit(updateLead(mapLead(lead)))
      }
    }
    const handleConversationStarted = (conversationId: string): void => {
      emit(fetchConversation({ conversationId }))
    }
    const handleConversationUpdated = (conversationId: string): void => {
      emit(fetchConversation({ conversationId }))
    }
    const handleConversationRead = (conversationId: string): void => {
      emit(markReadInState({ conversationId }))
    }
    const handleMessageSent = (message: SocketMessage): void => {
      emit(messageSent(message))
    }
    const handleMessageUpdated = (message: SocketMessage): void => {
      emit(messageUpdated(message))
    }

    const handleProviderTyping = (
      providerData: ProviderTypingInterface
    ): void => {
      emit(providerTyping(providerData))
    }
    const handleBotTyping = (providerData: ProviderTypingInterface): void => {
      emit(botTyping(providerData))
    }

    io.on('connect', handleConnect)
    io.on('disconnect', handleDisconnect)
    io.on('error', handleError)
    io.on('client:subscribed', handleClientSubscribed)
    io.on('lead-profile:updated', handleLeadProfileUpdated)
    io.on('conversation:started', handleConversationStarted)
    io.on('conversation:updated', handleConversationUpdated)
    io.on('conversation:read', handleConversationRead)
    io.on('message:sent', handleMessageSent)
    io.on('message:updated', handleMessageUpdated)
    io.on('provider:typing', handleProviderTyping)
    io.on('bot:typing', handleBotTyping)

    return () => {
      io.off('connect', handleConnect)
      io.off('disconnect', handleDisconnect)
      io.off('error', handleError)
      io.off('client:subscribed', handleClientSubscribed)
      io.off('lead-profile:updated', handleLeadProfileUpdated)
      io.off('conversation:started', handleConversationStarted)
      io.off('conversation:updated', handleConversationUpdated)
      io.off('message:sent', handleMessageSent)
      io.off('message:updated', handleMessageUpdated)
      io.off('provider:typing', handleProviderTyping)
      io.off('bot:typing', handleBotTyping)
    }
  }, buffers.expanding(50))
}

let socketEventChannel: any
function* watchSocket() {
  if (!socketEventChannel) {
    // to avoid duplicates if this saga is run again and previous channel isn't closed
    socketEventChannel = yield call(createSocketChannel, socket)
  } else {
    return
  }

  try {
    while (true) {
      const action = yield take(socketEventChannel)
      yield put(action)
    }
  } catch (error) {
    yield put(logAction({ error }))
    // eslint-disable-next-line no-console
    console.log(error)
  } finally {
    if (yield cancelled()) {
      socketEventChannel.close()
    }
  }
}

function* initSocketSaga() {
  yield fork(watchSocket)
  if (!socket.isConnected()) {
    socket.connect()
  }
}

export default function* socketSaga() {
  yield all([takeEvery(init, initSocketSaga)])
}
