import { setLightness } from 'polished'

export const grayShades = () => {
  return {
    lightest: '#f6f6f6',
    lighter: '#e6e6e6',
    light: '#9a9a9a',
    base: 'gray',
    dark: '#676767',
    darker: '#4d4d4d',
    darkest: '#0d0d0d'
  }
}

export const colorShades = (color: string) => {
  return {
    original: color,
    lightest: setLightness(0.99, color),
    lighter: setLightness(0.93, color),
    light: setLightness(0.63, color),
    base: setLightness(0.5, color),
    dark: setLightness(0.43, color),
    darker: setLightness(0.33, color),
    darkest: setLightness(0.08, color)
  }
}
