import * as React from 'react'

import { Button } from 'components/_utility'
import { ButtonProps, HelpersProps } from 'types'

export interface Props extends ButtonProps, HelpersProps {
  formValid: boolean
  failureMessage?: string
  formSubmitted?: boolean
  validateForm: () => void
}

export const SubmitButtonDisplay = ({
  children,
  formValid,
  formSubmitted,
  validateForm,
  ...props
}: Props) => (
  <Button
    {...props}
    disabled={!formValid || formSubmitted}
    chevronRight={formValid && !formSubmitted}
    onDisabled={() => (formSubmitted ? null : validateForm())}
    type="submit"
  >
    {children}
  </Button>
)
