export default class CustomStorage {
  private data: object
  private type: string | undefined

  constructor(type: string | undefined) {
    this.type = type
    this.data = this._getData()
  }

  public clear = (): void => {
    this.data = {}
    this._clearData()
  }

  public getItem = (key: string): string | null => {
    return this.data[key] === undefined ? null : this.data[key]
  }

  public setItem = (key: string, value: string): void => {
    this.data[key] = value + ''
    this._setData(this.data)
  }

  public removeItem = (key: string): void => {
    delete this.data[key]
    this._setData(this.data)
  }

  private _createCookie = (name: string, value: string, days: number): void => {
    let date
    let expires

    if (days) {
      date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    } else {
      expires = ''
    }
    document.cookie = `${name}=${value}${expires}; path=/`
  }

  private _readCookie = (name: string): string | null => {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(';')
    let i
    let c

    for (i = 0; i < ca.length; i++) {
      c = ca[i].trim()

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length)
      }
    }
    return null
  }

  private _setData = (data: object): void => {
    const dataURI = encodeURIComponent(JSON.stringify(data))
    if (this.type === 'session') {
      this._createCookie(this._getSessionName(), dataURI, 365)
    } else {
      this._createCookie('localStorage', dataURI, 365)
    }
  }

  private _clearData = (): void => {
    if (this.type === 'session') {
      this._createCookie(this._getSessionName(), '', 365)
    } else {
      this._createCookie('localStorage', '', 365)
    }
  }

  private _getData = (): object => {
    const data: string | null =
      this.type === 'session'
        ? this._readCookie(this._getSessionName())
        : this._readCookie('localStorage')
    return data ? JSON.parse(decodeURIComponent(data)) : {}
  }

  private _getSessionName = (): string => {
    if (!window.name) {
      window.name = new Date().getTime().toString()
    }
    return 'sessionStorage' + window.name
  }
}
