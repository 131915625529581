import { delay } from 'redux-saga'
import { call, put, take, takeEvery } from 'redux-saga/effects'
import * as uuidv4 from 'uuid/v4'

import {
  setRpApiSessionToken,
  updateCompanySettings
} from 'state/company/company.actions'
import {
  fetchConversation,
  fetchConversationSuccess
} from 'state/entities/conversations'
import { updateLead } from 'state/lead/lead.actions'
import { init as initSocket } from 'state/socket'

import rpApiService from 'services/rp.api'
import socket from 'services/rp.socket'
import settings from 'settings'
import { setLeadCustomData } from 'state/lead'
import { initTriggers } from 'state/ui'
import { CardInterface, PathAttributes, TrackedEventDestinations } from 'types'
import { getDeviceType } from 'utility'
import api from 'utility/api/api.client'
import { mapLead } from 'utility/mapLead'
import customWindow from 'utility/storage/customWindow'
import {
  initializeDesk,
  initializeDeskFail,
  initializeDeskSuccess,
  initializePreview,
  initializeTradeIn,
  logAction,
  trackEvent,
  auditWidgetUrl
} from './_actions'

function* saga() {
  try {
    const tempApiClient = api({ baseApiUrl: `${settings.rpApiUrl}/api/v1` })

    const isPreview = settings.previewMode !== 'none'
    if (isPreview && settings.previewMode !== 'cardmodal') {
      customWindow.localStorage.removeItem(settings.leadRoomIdStorageKey)
      customWindow.localStorage.removeItem(settings.isWebdeskHiddenStorageKey)
      customWindow.localStorage.removeItem(settings.triggersDisabledStorageKey)
    }
    const leadRoomId =
      customWindow.localStorage.getItem(settings.leadRoomIdStorageKey) ||
      uuidv4()

    const customLead: PathAttributes = (window.top as any).pathAttributes || {}
    delete (window.top as any).pathAttributes
    yield put(setLeadCustomData(customLead))

    const {
      sessionToken,
      lead = {},
      lastConversationId,
      cards,
      views,
      webdesk,
      companySettings,
      roomId: relevantRoomId // need to update if lead was merged
    } = yield call(tempApiClient.post, 'messenger/web/ping', null, {
      companyId: settings.companyId,
      webdeskId: settings.webdeskId,
      roomId: leadRoomId,
      bookingMode: settings.bookingMode,
      customLead,
      deviceType: getDeviceType(),
      isPreview,
      inventoryMode: settings.inventoryMode,
      conversationId: settings.conversationId,
      cardId: settings.cardId
    })
    customWindow.localStorage.setItem(
      settings.leadRoomIdStorageKey,
      relevantRoomId
    )

    if (!lead._id && !isPreview) {
      let isHidden = customWindow.localStorage.getItem(
        settings.isWebdeskHiddenStorageKey
      )
      if (!isHidden) {
        const random = Math.floor(Math.random() * 100)
        isHidden =
          100 - webdesk.behavior.hideForVisitorsPercentage <= random
            ? 'true'
            : 'false'
        customWindow.localStorage.setItem(
          settings.isWebdeskHiddenStorageKey,
          isHidden
        )
      }

      if (isHidden === 'true') return
    }
    if (lead && lead._id) {
      if (!socket.isInitialized()) {
        yield put(initSocket())
        while (!socket.isInitialized()) {
          yield delay(1000)
        }
      }
    }
    
    if(companySettings['isCadillac'] == "Yes"){
      webdesk['isCadillac'] = true
      document.documentElement.style.setProperty('font-family', 'CadillacGothic')
    }
    else{
      webdesk['isCadillac'] = false
      document.documentElement.style.setProperty('font-family', 'Roboto')
    }

    rpApiService.init(sessionToken)
    // save RP session token in state
    yield put(setRpApiSessionToken({ rpApiSessionToken: sessionToken }))

    yield put(updateCompanySettings(companySettings))

    yield put(auditWidgetUrl())

    if (lead) {
      yield put(updateLead(mapLead(lead)))
    }

    if (lastConversationId) {
      yield put(fetchConversation({ conversationId: lastConversationId }))
      yield take(fetchConversationSuccess)
    }

    yield put(
      initializeDeskSuccess({
        webdesk,
        views,
        cards,
        lastConversationId
      })
    )

    yield put(initTriggers())
    if (
      cards.filter((card: CardInterface) => card.template === 'trade_in').length
    ) {
      yield put(initializeTradeIn())
    }

    if (isPreview) {
      // initialize the ep2 based on the preview-specific entry state
      yield put(initializePreview({}))
    }

    yield put(
      trackEvent({
        trackingName: 'DESK_INITIALIZED',
        properties: {},
        destinations: [TrackedEventDestinations.SHIFT]
      })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    // eslint-disable-next-line no-console
    console.error('Error:', e)
    yield put(initializeDeskFail())
  }
}

export function* initializeDeskSaga() {
  yield takeEvery(initializeDesk, saga)
}
