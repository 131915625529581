import { connect } from 'react-redux'

import {
  conversationSelector,
  isBotTypingSelector,
  typingUsersSelector
} from 'state/entities/conversations'
import { currentViewParamsSelector } from 'state/router'
import { RootState } from 'types'
import { ConversationsDisplay, Props } from './Conversations.display'

type StateProps = Pick<
  Props,
  | 'currentConversationId'
  | 'isLastMessageHasActions'
  | 'isLastMessageForm'
  | 'isLastMessageUnsubmittedCard'
  | 'messagesCount'
  | 'typingUsers'
  | 'isBotTyping'
>

const mapStateToProps = (state: RootState): StateProps => {
  const { conversationId: currentConversationId } = currentViewParamsSelector(
    state
  )
  const { messages } = conversationSelector(state, currentConversationId)
  const isLastMessageHasActions = Boolean(
    messages && messages[messages.length - 1].actions
  )
  const isLastMessageForm = Boolean(
    messages && messages[messages.length - 1].type === 'form'
  )
  const isLastMessageUnsubmittedCard = Boolean(
    messages &&
      messages[messages.length - 1].type === 'card' &&
      !messages[messages.length - 1].card!.props.submitted
  )

  return {
    currentConversationId,
    isLastMessageHasActions,
    isLastMessageForm,
    isLastMessageUnsubmittedCard,
    messagesCount: messages ? messages.length : 0,
    typingUsers: typingUsersSelector(state, currentConversationId),
    isBotTyping: isBotTypingSelector(state, currentConversationId)
  }
}

export const ConversationsContainer = connect(
  mapStateToProps
)(ConversationsDisplay)
