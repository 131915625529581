import { navigate } from 'state/router'
import { homeViewIdSelector } from 'state/ui'
import { getStore } from './getStore'

const store = getStore()

const openChatbox = () => {
  const homeViewId = homeViewIdSelector(store.getState())
  store.dispatch(navigate({ viewId: homeViewId }))
}

const initHTMLAPI = () => {
  const classNames = [
    'ap-open', // for old customers
    'ap-chat', // for old customers
    'ap-book', // for old customers
    'ap-faq', // for old customers
    'rp-open'
  ]
  const classNamesSelector = classNames.map(c => `[class*=${c}]`).join(', ')
  ;[].slice
    .call(window.top.document.querySelectorAll(classNamesSelector))
    .filter((elem: Element) => {
      const elemClassNames = elem.className.toLocaleLowerCase().split(/\s/)
      let elemClassNamesContainPattern = false
      for (const elemClassName of elemClassNames) {
        if (
          classNames.some(className => !!elemClassName.match(`^${className}`))
        ) {
          elemClassNamesContainPattern = true
          break
        }
      }

      return elemClassNamesContainPattern
    })
    .forEach((elem: Element) => {
      elem.addEventListener('click', () => {
        openChatbox()
      })
    })
}

const initJSAPI = () => {
  // @ts-ignore
  window.top.PathET.openChatbox = () => {
    openChatbox()
  }
}

class PublicAPI {
  private initialized: boolean

  constructor() {
    this.initialized = false
  }

  public init() {
    if (this.initialized) return

    initHTMLAPI()
    initJSAPI()
  }
}

export default new PublicAPI()
