/* eslint-disable no-restricted-syntax */
import * as React from 'react'
import {
  DeviceList,
  LocalMediaList,
  Media,
  RequestUserMedia,
  UserControls
} from '@andyet/simplewebrtc'
import { faVolumeUp } from '@fortawesome/pro-light-svg-icons/faVolumeUp'
import { faVideo } from '@fortawesome/pro-light-svg-icons/faVideo'
import { faMicrophone } from '@fortawesome/pro-light-svg-icons/faMicrophone'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Flex, Button, Text } from 'components/_utility'
import MediaPreview from './MediaPreview'

type GetMedia = (
  additional?: MediaStreamConstraints
) => Promise<{ audio?: string; video?: string }>

export interface DevicesSettingsProps {
  isSettingsOpen: boolean
  changeIsSettingsOpen: () => void
  leaveCall: () => void
}

export interface DevicesSettingsState {
  allowInitialAutoCapture: boolean
  previewAudioId?: string
  previewVideoId?: string
}

function getDeviceForTrack(
  devices: MediaDeviceInfo[],
  track: MediaStreamTrack
) {
  let deviceId: string | undefined
  const deviceLabel = track.label
  const deviceKind = `${track.kind}input`

  if (track.getSettings) {
    const settings = track.getSettings()
    deviceId = settings.deviceId
  }

  if (deviceId) {
    for (const device of devices) {
      if (device.deviceId === deviceId) {
        return device
      }
    }
  }
  for (const device of devices) {
    if (deviceLabel === device.label && deviceKind === device.kind) {
      return device
    }
  }
  return undefined
}

const Select = styled.select`
  max-width: 210px;
`

const SettingsButton = styled(Button)<{ isOff: boolean }>`
  position: absolute;
  z-index: 3;
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 16px;

  color: ${p => (p.isOff ? p.theme.gray.light : '')};
  background-color: ${p =>
    p.isOff ? p.theme.gray.dark : p.theme.gray.lightest};

  }
  &:focus {
    background-color: ${p => p.theme.colors.original};
    color: #fff;
  }
`

export class DevicesSettings extends React.Component<
  DevicesSettingsProps,
  DevicesSettingsState
> {
  constructor(props: DevicesSettingsProps) {
    super(props)

    this.state = {
      allowInitialAutoCapture: true
    }
  }

  public render() {
    return (
      <>
        <SettingsButton
          isOff={!this.props.isSettingsOpen}
          onClick={this.props.changeIsSettingsOpen}
        >
          <Icon icon={faCog} />
        </SettingsButton>

        {this.props.isSettingsOpen && (
          <LocalMediaList
            screen={false}
            render={({ media, removeMedia, shareLocalMedia }) => {
              const previewVideo = media.filter(
                m => m.id === this.state.previewVideoId
              )[0]
              const previewAudio = media.filter(
                m => m.id === this.state.previewAudioId
              )[0]
              return (
                <Flex column _width={100} _height={100}>
                  <MediaPreview video={previewVideo} />
                  <Flex column fs={14}>
                    <DeviceList
                      render={({
                        devices,
                        hasCamera,
                        requestingCameraCapture,
                        cameraPermissionDenied,
                        hasMicrophone,
                        requestingMicrophoneCapture,
                        microphonePermissionDenied,
                        cameraPermissionGranted,
                        microphonePermissionGranted,
                        requestingCapture
                      }) => {
                        if (!hasCamera && !hasMicrophone) {
                          return (
                            <Flex column _m={8}>
                              <h3>
                                Your browser does not support features for
                                accessing media devices. Please upgrade your
                                browser or install another one.
                              </h3>
                            </Flex>
                          )
                        }
                        const audioInputs = devices.filter(
                          d => d.kind === 'audioinput'
                        )
                        const audioOutputs = devices.filter(
                          d => d.kind === 'audiooutput'
                        )
                        const videoInputs = devices.filter(
                          d => d.kind === 'videoinput'
                        )

                        const currentAudioDevice = previewAudio
                          ? getDeviceForTrack(devices, previewAudio.track)
                          : undefined
                        const currentVideoDevice = previewVideo
                          ? getDeviceForTrack(devices, previewVideo.track)
                          : undefined

                        return (
                          <>
                            {this.initialAutoCapture(
                              microphonePermissionGranted,
                              cameraPermissionGranted,
                              requestingCapture
                            )}
                            <UserControls
                              render={({ user, setAudioOutputDevice }) => (
                                <label>
                                  <Flex _m={5} alignItems="center">
                                    <Icon icon={faVolumeUp} size="1x" />
                                    <Text mr={5} ml={5} fs={14}>
                                      Speaker:
                                    </Text>
                                    <Select
                                      defaultValue={user.audioOutputDeviceId}
                                      disabled={!devices.length}
                                      onChange={e => {
                                        setAudioOutputDevice(e.target.value)
                                      }}
                                    >
                                      {audioOutputs.length &&
                                        audioOutputs.map(device => (
                                          <option
                                            key={device.deviceId}
                                            value={device.deviceId}
                                          >
                                            {device.label}
                                          </option>
                                        ))}
                                      {!audioOutputs.length && (
                                        <option key="" value="">
                                          Default
                                        </option>
                                      )}
                                    </Select>
                                  </Flex>
                                </label>
                              )}
                            />
                            <label>
                              <Flex _m={5} alignItems="center">
                                <Icon icon={faVideo} size="1x" />
                                <Text mr={5} ml={5} fs={14}>
                                  Camera:
                                </Text>
                                {this.renderInputSelector(
                                  'video',
                                  hasCamera,
                                  cameraPermissionGranted,
                                  cameraPermissionDenied,
                                  requestingCameraCapture!,
                                  videoInputs,
                                  currentVideoDevice,
                                  currentAudioDevice,
                                  previewVideo,
                                  previewAudio,
                                  removeMedia,
                                  'No cameras detected.',
                                  'Camera permissions denied.',
                                  'Requesting cameras...',
                                  'Allow camera access',
                                  'Disable Camera'
                                )}
                              </Flex>
                            </label>
                            <Flex>
                              <label>
                                <Flex _m={5} alignItems="center">
                                  <Icon icon={faMicrophone} size="1x" />
                                  <Text mr={5} ml={5} fs={14}>
                                    Microphone:
                                  </Text>
                                  {this.renderInputSelector(
                                    'audio',
                                    hasMicrophone,
                                    microphonePermissionGranted,
                                    microphonePermissionDenied,
                                    requestingMicrophoneCapture!,
                                    audioInputs,
                                    currentAudioDevice,
                                    currentVideoDevice,
                                    previewAudio,
                                    previewVideo,
                                    removeMedia,
                                    'No microphones detected.',
                                    'Microphone permissions denied.',
                                    'Requesting microphones...',
                                    'Allow microphone access',
                                    'Disable Microphone'
                                  )}
                                </Flex>
                              </label>
                            </Flex>
                          </>
                        )
                      }}
                    />
                    <Flex row justifyContent="space-around" mt={10}>
                      <Button grayOutline onClick={this.props.leaveCall}>
                        Leave call
                      </Button>
                      <Button
                        disabled={media.length === 0}
                        onClick={() => {
                          if (previewAudio) {
                            shareLocalMedia(previewAudio.id)
                          }
                          if (previewVideo) {
                            shareLocalMedia(previewVideo.id)
                          }
                          this.props.changeIsSettingsOpen()
                        }}
                        primary
                      >
                        Save
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              )
            }}
          />
        )}
      </>
    )
  }

  private initialAutoCapture(
    microphonePermissionGranted: boolean,
    cameraPermissionGranted: boolean,
    requestingCapture?: boolean
  ) {
    const auto =
      this.state.allowInitialAutoCapture &&
      (microphonePermissionGranted || cameraPermissionGranted) &&
      !requestingCapture &&
      !this.state.previewAudioId &&
      !this.state.previewVideoId
    if (!auto) {
      return null
    }

    setTimeout(() => {
      this.setState({
        allowInitialAutoCapture: false
      })
    }, 0)

    return (
      <RequestUserMedia
        share={false}
        auto={auto}
        audio={microphonePermissionGranted}
        video={cameraPermissionGranted}
        replaceAudio={this.state.previewAudioId}
        replaceVideo={this.state.previewVideoId}
        onError={() => {
          this.setState({
            allowInitialAutoCapture: false
          })
        }}
        onSuccess={ids => {
          this.setState({
            allowInitialAutoCapture: false,
            previewAudioId: ids && ids.audio,
            previewVideoId: ids && ids.video
          })
        }}
        render={() => null}
      />
    )
  }

  private renderInputSelector(
    kind: 'audio' | 'video',
    hasDevice: boolean,
    permissionGranted: boolean,
    permissionDenied: boolean,
    requestingCapture: boolean,
    devices: MediaDeviceInfo[],
    currentDevice: MediaDeviceInfo | undefined,
    currentOtherDevice: MediaDeviceInfo | undefined,
    preview: Media,
    otherPreview: Media,
    removeMedia: (id: string) => void,
    noDevicesLabel: string,
    noPermissionLabel: string,
    capturingLabel: string,
    requestPermissionLabel: string,
    disableLabel: string
  ) {
    if (hasDevice === false) {
      return noDevicesLabel
    }
    if (permissionDenied) {
      return noPermissionLabel
    }
    if (requestingCapture) {
      return capturingLabel
    }

    const constraints: MediaTrackConstraints = {
      [kind]: true,
      [kind === 'audio' ? 'video' : 'audio']: currentOtherDevice
        ? {
            deviceId: { exact: currentOtherDevice.deviceId }
          }
        : !!otherPreview
    }

    return (
      <RequestUserMedia
        share={true}
        {...constraints}
        replaceAudio={this.state.previewAudioId}
        replaceVideo={this.state.previewVideoId}
        render={getMedia => {
          if (!preview && !permissionGranted) {
            return (
              <Button
                primary
                _p={5}
                onClick={() => {
                  this.runGetMedia(getMedia)
                }}
              >
                {requestPermissionLabel}
              </Button>
            )
          }
          return (
            <Select
              value={currentDevice ? currentDevice.deviceId : 'disable'}
              onChange={e => {
                const deviceId = e.target.value
                if (!deviceId) {
                  return null
                }

                this.setState({ allowInitialAutoCapture: false })
                if (deviceId !== 'disable') {
                  if (kind === 'video') {
                    removeMedia(this.state.previewVideoId!)
                  }
                  this.runGetMedia(getMedia, {
                    [kind]: {
                      deviceId: { exact: deviceId }
                    }
                  })
                } else if (kind === 'audio') {
                  removeMedia(this.state.previewAudioId!)
                  this.setState({ previewAudioId: undefined })
                } else {
                  removeMedia(this.state.previewVideoId!)
                  this.setState({ previewVideoId: undefined })
                }
                return null
              }}
            >
              {devices.map(device => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
              <option key="disabled" value="disable">
                {disableLabel}
              </option>
            </Select>
          )
        }}
      />
    )
  }

  private runGetMedia(
    getMedia: GetMedia,
    additional?: MediaStreamConstraints
  ): void {
    this.setState({ allowInitialAutoCapture: false })
    getMedia(additional).then(ids => {
      this.setState({
        previewAudioId: ids && ids.audio,
        previewVideoId: ids && ids.video
      })
    })
  }
}
