import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { currentViewSelector } from 'state/router'
import {
  isToolbarEnabledSelector,
  mobileMoveUpSelector,
  navButtonsSelector,
  navSelector,
  rpPreviewModeSelector,
  setWindowState,
  webMoveUpSelector,
  windowSelector,
  webdeskPositionSelector
} from 'state/ui'
import { RootState } from 'types'
import { Props, WindowDisplay } from './CommonWindow.display'

const mapStateToProps = (state: RootState): Partial<Props> => ({
  view: currentViewSelector(state),
  open: windowSelector(state),
  navButtons: navButtonsSelector(state),
  previewMode: rpPreviewModeSelector(state),
  webMoveUp: webMoveUpSelector(state),
  mobileMoveUp: mobileMoveUpSelector(state),
  nav: navSelector(state),
  toolbarMode: isToolbarEnabledSelector(state),
  webdeskPosition: webdeskPositionSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeWindow: () => {
    dispatch(setWindowState({ open: false }))
  }
})

export const WindowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WindowDisplay)
