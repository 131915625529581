import * as React from 'react'
import styled from 'styledComponents'

import {
  Card,
  ConnectedCustomSelect,
  ConnectedRadio,
  ConnectedSelect,
  ConnectedSlider,
  Flex,
  Label,
  Text
} from 'components/_utility'
import { CustomSelectOptionInterface } from 'types'

export interface Props {
  className?: string
  submit: () => void
}

const Component = ({ className }: Props) => {
  return (
    <Card className={className}>
      <Flex column mb={12}>
        <Label>Down Payment</Label>
        <ConnectedSlider name="payment.down" min={0} max={50000} />
      </Flex>

      <Flex column mb={12}>
        <Label>Term</Label>
        <ConnectedCustomSelect name="payment.leaseTerm" row mt={6}>
          <StyledOption value="24">
            <Text>24 Mo</Text>
          </StyledOption>
          <StyledOption value="36">
            <Text>36 Mo</Text>
          </StyledOption>
          <StyledOption value="60">
            <Text>60 Mo</Text>
          </StyledOption>
        </ConnectedCustomSelect>
      </Flex>

      <Flex column mb={12}>
        <Label>Incentive</Label>
        <ConnectedSelect name="payment.interest" />
      </Flex>

      <Flex column mb={12}>
        <Label>Your Credit Score</Label>
        <ConnectedRadio
          required
          name="payment.creditScore"
          option="Fair"
          label="0-250"
        />
        <ConnectedRadio
          required
          name="payment.creditScore"
          option="Good"
          label="250-500"
        />
        <ConnectedRadio
          required
          name="payment.creditScore"
          option="Very Good"
          label="500-750"
        />
        <ConnectedRadio
          required
          name="payment.creditScore"
          option="Excellent"
          label="750-850"
        />
      </Flex>
    </Card>
  )
}

export const Step2Finance = styled(Component)``
interface OptionProps extends CustomSelectOptionInterface {
  className?: string
  children: React.ReactNode
}

const Option = ({ className, children, selectValue }: OptionProps) => (
  <Flex
    className={className}
    onClick={() => selectValue && selectValue()}
    _p={12}
    pointer
    column
    alignItems="center"
    grow
  >
    {children}
  </Flex>
)

const StyledOption = styled(Option)<OptionProps>`
  flex-basis: 0;
  border: solid 2px ${p => p.theme.colors.lighter};
  background-color: ${p => p.theme.colors.lightest};

  transition: border-color 250ms;
  ${p =>
    p.selected &&
    `
    border-color: ${p.theme.colors.original};
  `}

  &:hover {
    border-color: ${p => p.theme.colors.original};
  }

  &:not(:last-child) {
    margin-right: 6px;
  }
`
