import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styledComponents'

import { NavContainer } from 'components'
import { Cirbutt, Flex } from 'components/_utility'
import settings from 'settings'
import { shadow } from 'style/mixins/'
import { sizes } from 'style/variables'
import { LauncherInterface, ThemeInterface, ToastInterface } from 'types'

import { CirbuttProps } from 'components/_utility/Cirbutt'
import { NavButtonInterface } from 'components/NavButton'
import logo from './logo.png'
import logo_cadillac from './logo_cadillac.png'
import { DockToast } from '.'

export interface Props {
  className?: string
  nav: boolean
  navButtons: NavButtonInterface[]
  toast: ToastInterface
  launcher: LauncherInterface
  theme: ThemeInterface
  previewMode: string
  webdeskPosition: string
  setNavState: (currentViewId: string) => {}
  addNavButton: (navButtons: NavButtonInterface[]) => void
  editHomeNavigation: () => void
  currentViewId: string
  isToolbarEnabled: boolean
  maxButtonsCount: number
}

const Component = ({
  className,
  currentViewId,
  nav,
  toast,
  setNavState,
  theme,
  previewMode,
  addNavButton,
  navButtons,
  editHomeNavigation,
  maxButtonsCount,
  isToolbarEnabled,
  webdeskPosition
}: Props) => (
  <React.Fragment>
    {previewMode === 'viewsview' && navButtons.length === 0 && (
      <React.Fragment>
        {!isToolbarEnabled && (
          <EditHomeButton
            color={theme.colors.original}
            icon={faHome}
            size={30}
            onClick={() => editHomeNavigation()}
            tooltip="Change Home View"
          />
        )}
        {maxButtonsCount > navButtons.length && (
          <AddNavButton
            isToolbarEnabled={isToolbarEnabled}
            color={theme.colors.original}
            icon={faPlus}
            size={30}
            onClick={() => addNavButton(navButtons)}
            tooltip="Add Nav Button"
          />
        )}
      </React.Fragment>
    )}
    <DockFlex
      row
      justifyContent="center"
      alignItems="center"
      className={className}
      onClick={() =>
        settings.bookingMode || nav ? null : setNavState(currentViewId)
      }
    >
      <button>
        <img src={theme.isCadillacTheme?logo_cadillac:logo} alt="logo" />
      </button>
      {!nav && <Tooltip position={webdeskPosition}>Chat Assistance</Tooltip>}
      {nav && <NavContainer />}
    </DockFlex>
    <ToastWrapper nav={nav} isToolbarEnabled={isToolbarEnabled}>
      <CSSTransition in={toast.show} classNames="toast" timeout={0}>
        <DockToast toast={toast} isToolbarEnabled={isToolbarEnabled} />
      </CSSTransition>
    </ToastWrapper>
  </React.Fragment>
)

interface AddNavButtonProps {
  isToolbarEnabled?: boolean
}

const EditHomeButton = styled(Cirbutt)<CirbuttProps & AddNavButtonProps>`
  position: absolute;
  right: 123px;
  bottom: 21px;
`

const AddNavButton = styled(Cirbutt)<CirbuttProps & AddNavButtonProps>`
  position: absolute;
  right: 83px;
  bottom: ${p => (p.isToolbarEnabled ? '10px' : '21px')};
`

export const DockDisplay = styled(Component)`
  position: relative;
  z-index: 3;
  cursor: pointer;
  width: ${sizes.utilityHeight}px;
  height: ${p =>
    p.isToolbarEnabled ? sizes.toolbarHeight : sizes.utilityHeight}px;
  overflow: hidden;
  ${p =>
    p.webdeskPosition === 'right' &&
    css`
      align-self: flex-end;
    `}

  ${p =>
    p.webdeskPosition === 'left' &&
    css`
      align-self: flex-start;
    `}

  border-radius: 100%;
  background-color: ${p => p.theme.colors.original};
  ${shadow('spread')}

  ${p =>
    p.nav &&
    css`
      width: ${p.isToolbarEnabled ? 'auto' : '100%'};
      border-radius: ${p.isToolbarEnabled ? '25px' : '12px'};
      background-color: ${p.theme.colors.lightest};
      transition: width 0.4s ease 0.1s, border-radius 0.2s,
        background-color 0.2s, margin-right 0.2s, margin-bottom 0.2s;

      ${sizes.isMobile &&
        css`
          width: 100%;
          border-radius: 0;
        `}
    `}

  ${p =>
    !p.nav &&
    css`
      transition: width 0.4s, border-radius 0.2s ease 0.4s,
        background-color 0.2s ease 0.4s, margin-right 0.2s, margin-bottom 0.2s;

      ${sizes.isMobile &&
        css`
          margin-top: 18px;
          margin-right: 18px;
          margin-bottom: 18px;
        `}
    `}

  >button{
    background: inherit;
    border: none;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    &:focus {
      border: none;
      outline:  ${p => (p.nav ? 'none' : 'auto')};
    }
    > img {
      position: relative;
      top: 1px;
      left: 1px;
      width: ${p => (p.theme.isCadillacTheme ? '45px':'30px')};
      opacity: 1;

      ${p =>
        p.nav &&
        css`
          width: 0;
          opacity: 0;
        `}

      ${p =>
        !p.nav &&
        css`
          transition: width 0s ease 0.4s, opacity 0.2s ease 0.4s;
        `}
    }
  }
`

const Tooltip = styled.div<{ position: string }>`
  visibility: hidden;
  position: absolute;
  left: ${p => (p.position === 'left' ? '35px' : '-70px')};
  top: 0;
  font-size: ${27 * 0.5}px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  text-align: right;
  border-radius: 3px;
  background-color: ${p => p.theme.gray.darker};
  color: white;
  transition: visibility 2s, ease 0.4s;
`

const DockFlex = styled(Flex)`
  overflow: visible;

  &:hover ${Tooltip} {
    visibility: visible;
  }
`

const ToastWrapper = styled.div<{ nav: boolean; isToolbarEnabled?: boolean }>`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: ${p =>
    p.isToolbarEnabled ? sizes.toolbarHeight : sizes.utilityHeight}px;

  border-radius: ${p =>
    p.nav ? (sizes.isMobile ? 0 : '12px') : sizes.utilityHeight};
`
