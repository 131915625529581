import * as React from 'react'
import styled from 'styledComponents'

import { ReviewSourceInterface, ReviewSources } from 'components'
import { Card, Flex, Gallery, Greeting, Text } from 'components/_utility'
import { helpers } from 'style/mixins/'
import {
  ActionButtonActionInterface,
  CardHeaderProps,
  HelpersProps
} from 'types'
import avatarImg from '../../_utility/Avatar/avatar.png'

export interface GreetingInterface {
  avatarName: string
  imageUrl: string
  greetingText: string
}

export interface Props extends HelpersProps {
  className?: string
  _id: string
  image?: string
  title?: string
  body?: string
  actionButtons?: ActionButtonActionInterface[]
  gallery?: string[]
  reviewSources?: ReviewSourceInterface[]
  header?: CardHeaderProps
  greeting?: GreetingInterface
  collapsed?: boolean
  navigate: (currentView: string) => void
}

const BodyImg = styled.img`
  height: 54px;
  margin-right: 24px;
  border-radius: 9px;
`

const Component = ({
  className,
  image,
  title,
  body,
  gallery,
  reviewSources,
  actionButtons,
  collapsed = false,
  header,
  greeting
}: Props) => (
  <Card
    className={className}
    header={header}
    actionButtons={actionButtons}
    actionButtonsDisplay={{
      padding: title || body || image ? 'top' : 'card-top'
    }}
    collapsed={collapsed}
  >
    {greeting && (
      <Greeting
        imageUrl={greeting.imageUrl || avatarImg}
        name={greeting.avatarName}
        text={greeting.greetingText}
      />
    )}
    <Flex row alignItems="center" _height={100}>
      {image && <BodyImg src={image} />}
      <Flex column grow shrink justifyContent="center" _width={100}>
        {title && <Text isTitle>{title}</Text>}
        {body && <Text isBody>{body}</Text>}
        {gallery && <Gallery images={gallery} mt={9} />}
        {reviewSources && <ReviewSources reviewSources={reviewSources} />}
      </Flex>
    </Flex>
  </Card>
)

export const GenericCardDisplay = styled(Component)`
  ${helpers}
`
