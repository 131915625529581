import * as React from 'react'
import styled from 'styledComponents'

import { Flex } from 'components/_utility'

export const FooterContainer = ({
  footer
}: {
  footer?: string
  isConversations: boolean
}) => (
  <FixedHeader justifyContent="center">
    <p className="footer-text">{footer}</p>
  </FixedHeader>
)

// @ts-ignore
const FixedHeader = styled(Flex)`
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding-left: 5px;
  width: 100%;
  height: 20px;
  ${p =>
    // @ts-ignore
    p.isConversations || p.theme.gradient
      ? `background-color: 'transparent';`
      : `background-color: ${p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.colors.original};`}

  .footer-text {
    color: ${p =>
      // @ts-ignore
      p.theme.gradient ? p.theme.colors.base : p.theme.colors.lightest};
    font-size: 12px;
    line-height: 1.4;
    opacity: 0.8;
    font-weight: 300;
    word-wrap: break-word;
  }
`
