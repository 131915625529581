import * as styledComponents from 'styled-components'
import { ThemeInterface } from 'types'

// Uncast to allow for recast with ThemeInterface
const untypedStyledComponents = styledComponents as any

const {
  default: styled,
  keyframes,
  ThemeProvider,
  withTheme,
  css
} = untypedStyledComponents as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>

export { keyframes, ThemeProvider, withTheme, css }
export default styled
