import * as React from 'react'
import { connect } from 'react-redux'
import Textarea from 'react-textarea-autosize'
import { Dispatch } from 'redux'
import styled from 'styledComponents'

import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faArrowAltCircleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { ENTER_KEY_CODE } from 'common/constants'
import { Flex } from 'components/_utility'
import { replyText, typeKey } from 'state/entities/conversations'
import { currentViewParamsSelector } from 'state/router'
import { RootState, ThemeInterface } from 'types'

import { shadow } from 'style/mixins'
import { sizes } from 'style/variables'

export interface Props {
  className?: string
  theme: ThemeInterface
  conversationId: string
  replyText: (conversationId: string, text: string) => void
  typeKey: (conversationId: string) => void
}

type DispatchProps = Pick<Props, 'replyText' | 'typeKey'>

export interface State {
  message: string
}

const mapStateToProps = (state: RootState) => {
  const { conversationId: currentConversationId } = currentViewParamsSelector(
    state
  )
  return {
    conversationId: currentConversationId
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  replyText: (conversationId, text) => {
    dispatch(replyText({ conversationId, text }))
  },
  typeKey: conversationId => {
    dispatch(typeKey({ conversationId }))
  }
})

class Component extends React.Component<Props, State> {
  public input: HTMLTextAreaElement
  constructor(props: Props) {
    super(props)

    this.state = {
      message: ''
    }
  }

  public onMessageChange = (e: React.SyntheticEvent) => {
    this.setState({ message: (e.target as HTMLInputElement).value })
  }

  public sendMessage = () => {
    if (this.state.message.trim()) {
      this.props.replyText(this.props.conversationId, this.state.message)
      this.setState({ message: '' })
    }
  }

  public onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      e.preventDefault()
      this.sendMessage()
    } else {
      this.props.typeKey(this.props.conversationId)
    }
  }

  public render() {
    const { className } = this.props
    return (
      <Flex
        row
        shrink={false}
        padding="standard"
        className={className}
        data-test="chat-input"
      >
        <Textarea
          inputRef={(input: HTMLTextAreaElement) => {
            this.input = input
          }}
          placeholder="Chat with us..."
          rows={1}
          onChange={this.onMessageChange}
          onKeyDown={this.onKeyDown}
          value={this.state.message}
        />
        <div style={{ visibility: 'hidden', position: 'absolute' }}>
          Chat with us...
        </div>
        <Flex row mt={2}>
          <SendIcon icon={faArrowAltCircleRight} onClick={this.sendMessage} />
        </Flex>
      </Flex>
    )
  }
}

interface StyledIconProps {
  onClick?: () => void
  icon: IconDefinition
  isVisible?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledIcon = styled(({ isVisible, ...rest }) => <Icon {...rest} />)<
  StyledIconProps
>`
  cursor: pointer;
  margin-right: 10px;
  color: #c2c2c2;

  &:first-child {
    margin-left: 6px;
  }

  &:last-child {
    margin-right: 0;
  }

  transition: color 0.2s;

  &:hover {
    color: ${p => p.theme.colors.original};
  }
`

const SendIcon = styled(StyledIcon)<StyledIconProps>`
  color: ${p => p.theme.colors.original};

  &:hover {
    color: ${p => p.theme.colors.dark};
  }
`

const styledComponent = styled(Component)`
  background-color: white;
  border-top: solid 1px ${p => p.theme.gray.lightest}

  textarea {
    flex:  1;
    border:  none;
    resize: none;

    font-size: ${sizes.isMobile ? '16px' : '14px'};
    ${
      sizes.isMobile
        ? `
      transform: scale(0.875);
      transform-origin: left top;
      overflow: hidden;
    `
        : ''
    }
    color: ${p => p.theme.gray.darker};

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #c2c2c2;
    }
  }

  /* textarea:focus & {
    ${p => shadow('color')(p.theme.colors.lighter)}
  } */
`

export const ConversationsFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(styledComponent)
