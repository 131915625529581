import * as React from 'react'

import {
  CardSequenceViewInterface,
  CardStackViewInterface,
  ConversationsViewInterface,
  NavButtonInterface
} from 'types'
import { ToolbarWindowDisplay } from './ToolbarWindow.display'
import { WindowDisplay as DefaultWindowDisplay } from './Window.display'

export interface Props {
  open: boolean
  view:
    | CardSequenceViewInterface
    | CardStackViewInterface
    | ConversationsViewInterface
  navButtons: NavButtonInterface[]
  previewMode: string
  webMoveUp: number
  mobileMoveUp: number
  toolbarMode: boolean
  nav: boolean
  webdeskPosition: string
  closeWindow: () => void
}

export const WindowDisplay = ({
  view,
  open,
  navButtons,
  nav,
  previewMode,
  webMoveUp,
  mobileMoveUp,
  toolbarMode,
  closeWindow,
  webdeskPosition
}: Props) => {
  return (
    <React.Fragment>
      {toolbarMode ? (
        <ToolbarWindowDisplay
          view={view}
          open={open}
          navButtons={navButtons}
          previewMode={previewMode}
          webMoveUp={webMoveUp}
          mobileMoveUp={mobileMoveUp}
          closeWindow={closeWindow}
          webdeskPosition={webdeskPosition}
        />
      ) : (
        <DefaultWindowDisplay
          view={view}
          open={open}
          navButtons={navButtons}
          previewMode={previewMode}
          webMoveUp={webMoveUp}
          mobileMoveUp={mobileMoveUp}
          nav={nav}
        />
      )}
    </React.Fragment>
  )
}
