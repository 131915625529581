import * as React from 'react'
import styled from 'styledComponents'

import { ConversationContainer } from 'components'
import { Flex } from 'components/_utility'
import { ConversationsFooter, ConversationsTyping } from '.'

export interface Props {
  className?: string
  currentConversationId: string
  typingUsers: string[]
  isLastMessageHasActions: boolean
  isLastMessageForm: boolean
  isLastMessageUnsubmittedCard: boolean
  messagesCount: number // hack property to re-render component on new message (scroll to bottom feature)
  isBotTyping: boolean
}

interface State {
  typingUsers: string[]
  isBotTyping: boolean
}

class Component extends React.Component<Props, State> {
  public messagesEnd: HTMLElement

  public typingTimeoutId: number | null

  constructor(props: Props) {
    super(props)
    this.state = {
      typingUsers: [],
      isBotTyping: false
    }
  }

  public render() {
    const {
      className,
      currentConversationId,
      isLastMessageHasActions,
      isLastMessageForm,
      isLastMessageUnsubmittedCard
    } = this.props
    const { typingUsers, isBotTyping } = this.state
    const isChatbotTyping = typingUsers.length !== 0 && isBotTyping
    const isInputAvailable = !(
      isLastMessageHasActions ||
      isLastMessageForm ||
      isLastMessageUnsubmittedCard ||
      isChatbotTyping
    )
    return (
      <Flex column className={className}>
        <Flex tabIndex={0} id="scrollContext" column grow shrink overflow="auto" _p={24}>
          <ConversationContainer conversationId={currentConversationId} />
          <div
            ref={el => {
              this.messagesEnd = el as HTMLDivElement
            }}
          />
        </Flex>
        {Boolean(typingUsers.length) && (
          <ConversationsTyping users={typingUsers} />
        )}
        {isInputAvailable && (
          <ConversationsFooter />
        )}
      </Flex>
    )
  }

  public componentDidMount() {
    this.scrollToBottom()
  }

  public componentDidUpdate(prevProps: Props) {
    const { typingUsers, isBotTyping } = this.props
    if (prevProps.typingUsers !== typingUsers) {
      if (!this.state.typingUsers.length) {
        this.setState({ typingUsers, isBotTyping })
      } else {
        this.postponeUpdateTypingUsers()
      }
    }

    this.scrollToBottom()
  }

  private scrollToBottom = (): void => {
    this.messagesEnd.scrollIntoView()
  }

  // Need to avoid blinking effect if bot messages comes one by one
  private postponeUpdateTypingUsers = () => {
    const { isBotTyping, typingUsers } = this.props

    if (this.typingTimeoutId) {
      clearTimeout(this.typingTimeoutId)
    }

    this.typingTimeoutId = window.setTimeout(() => {
      this.setState({ typingUsers, isBotTyping })
    }, 500)
  }
}

export const ConversationsDisplay = styled(Component)`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  > #scrollContext {
    border: 1px solid #fff;
    &:focus {
      border: 1px solid ${p => p.theme.colors.original};
      outline: none;
    }
  }
`
