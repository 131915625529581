import { ConnectedComponent } from 'react-redux'

import {
  AppointmentCardContainer,
  AppointmentCardInterface
} from './Appointment'
import { CarouselCardContainer, CarouselCardInterface } from './Carousel'
import { ChatbotCardContainer, ChatbotCardInterface } from './Chatbot'
import {
  FacebookFeedCardContainer,
  FacebookFeedCardInterface
} from './FacebookFeed'
import { FaqsCardContainer } from './Faq'
import { FormCardContainer, FormCardInterface } from './Form'
import { GenericCardContainer, GenericCardInterface } from './Generic'
import { HoursCardContainer, HoursCardInterface } from './Hours'
import { LocationCardContainer, LocationCardInterface } from './Location'
import { MessagesCardContainer, MessagesCardInterface } from './Messages'
import {
  PaymentDriverCardContainer,
  PaymentDriverCardInterface
} from './PaymentDriver'
import { ReviewsCardContainer, ReviewsCardInterface } from './Reviews'
import { TradeInCardContainer, TradeInCardInterface } from './TradeIn'
import { VideoCardContainer, VideoCardInterface } from './Video'

export {
  MessagesCardContainer,
  MessagesCardInterface,
  FaqsCardContainer,
  FormCardContainer,
  FormCardInterface,
  TradeInCardContainer,
  TradeInCardInterface,
  GenericCardContainer,
  GenericCardInterface,
  VideoCardContainer,
  VideoCardInterface,
  CarouselCardContainer,
  CarouselCardInterface,
  LocationCardContainer,
  LocationCardInterface,
  HoursCardContainer,
  HoursCardInterface,
  ReviewsCardContainer,
  ReviewsCardInterface,
  ChatbotCardContainer,
  ChatbotCardInterface,
  AppointmentCardContainer,
  AppointmentCardInterface,
  PaymentDriverCardContainer,
  PaymentDriverCardInterface,
  FacebookFeedCardContainer,
  FacebookFeedCardInterface
}

export const cardMap: { [x: string]: ConnectedComponent<any, any> } = {
  carousel: CarouselCardContainer,
  chatbot: ChatbotCardContainer,
  faqs: FaqsCardContainer,
  messages: MessagesCardContainer,
  generic: GenericCardContainer,
  video: VideoCardContainer,
  form: FormCardContainer,
  trade_in: TradeInCardContainer,
  location: LocationCardContainer,
  hours: HoursCardContainer,
  reviews: ReviewsCardContainer,
  appointment: AppointmentCardContainer,
  payment_driver: PaymentDriverCardContainer,
  facebook_feed: FacebookFeedCardContainer
}
