import { createSelector } from 'reselect'
import { RootState } from 'state/reducer'

export const companySettingsSelector = createSelector(
  (state: RootState) => state.company.timezoneId,
  (state: RootState) => state.company.bookingFields,
  (timezoneId, bookingFields) => ({
    timezoneId,
    bookingFields
  })
)

export const inquiryTypesSelector = createSelector(
  (state: RootState) => state.company.inquiryTypes,
  inquiryTypes => inquiryTypes
)

export const phoneMaskSelector = createSelector(
  (state: RootState) => state.company.country,
  country => {
    if (['United States', 'Canada'].includes(country)) {
      return [
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]
    }

    return undefined
  }
)

export const rpApiSessionTokenSelector = createSelector(
  (state: RootState) => state.company.rpApiSessionToken,
  token => token
)

export const companyNameSelector = createSelector(
  (state: RootState) => state.company.title,
  title => title
)
