import * as React from 'react'
import { useState } from 'react'
import styled, { withTheme } from 'styledComponents'

import { Range, getTrackBackground } from 'react-range'

import { Flex, Text } from 'components/_utility'
import { shadow } from 'style/mixins'

import { InjectedFieldProps, ThemeInterface } from 'types'

interface Props extends InjectedFieldProps {
  className?: string
  name: string
  min: number
  max: number
  step?: number
  theme: ThemeInterface
}

const Component = ({
  className,
  name,
  min,
  max,
  step = 1,
  value = 0,
  theme,
  updateField
}: Props) => {
  const [values, setValues] = useState([0])
  const onChange = (sliderValue: number[]) => {
    setValues(sliderValue)
    updateField({ [name]: { value: sliderValue[0] } })
  }

  if (value === '') {
    value = min
  }

  return (
    <Flex row alignItems="center" _width={100} className={className}>
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={data => onChange(data)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              className="range_slider__bar"
              ref={props.ref}
              style={{
                height: '6px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: [theme.colors.original, '#ccc'],
                  min,
                  max
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            className="range_slider__handle"
            id="range_slider__handle"
            {...props}
            style={{
              ...props.style,
              height: '15px',
              width: '15px',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          ></div>
        )}
      />
      <Text isBody ml={6}>
        {value.toLocaleString()}
      </Text>
    </Flex>
  )
}

export const Slider = withTheme(styled(Component)`
  > div:first-child {
    flex-grow: 1;
  }
  .range_slider__handle {
    border: solid 1px ${p => p.theme.gray.lighter};
    ${shadow('medium')}
  }
`)
