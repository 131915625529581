import { uniq } from 'ramda'
import { Action, combineReducers } from 'redux'
import { ConversationInterface, Message, SocketMessage } from 'types'
import { isType } from 'typescript-fsa'
import {
  botTyping,
  cancelProviderTyping,
  markReadInState,
  messageSent,
  messageUpdated,
  providerTyping,
  updateConversationSession
} from './conversations.actions'

export interface ConversationsState {
  byId: { [_id: string]: ConversationInterface }
  allIds: string[]
}

const isBefore = (
  firstMsg: Message | SocketMessage,
  secondMsg: Message | SocketMessage
) => {
  const firstIndex = firstMsg.index
  const secondIndex = secondMsg.index

  return secondIndex > firstIndex
}

const mergeMessages = (
  oldMessages: Array<Message | SocketMessage>,
  newMessages: Array<Message | SocketMessage>
) => {
  if (oldMessages.length === 0) {
    return newMessages
  }

  const nextState = [...oldMessages]

  // eslint-disable-next-line no-restricted-syntax
  for (const newMessage of newMessages) {
    for (let j = nextState.length - 1; j >= 0; j -= 1) {
      if (nextState[j]._id === newMessage._id) {
        nextState[j] = newMessage
        break
      }
      if (isBefore(nextState[j], newMessage)) {
        nextState.splice(j + 1, 0, newMessage)
        break
      }
    }
  }

  return nextState
}

const byId = (
  state: { [_id: string]: ConversationInterface } = {},
  action: Action
) => {
  if (isType(action, updateConversationSession)) {
    const { conversationId } = action.payload
    const oldMessages =
      (state[conversationId] && state[conversationId].messages) || []
    return {
      ...state,
      [conversationId]: {
        ...state[conversationId],
        _id: conversationId,
        companyId: action.payload.companyId,
        sourceCompanyId: action.payload.sourceCompanyId,
        header: {
          navigation: 'Messenger'
        },
        messages: mergeMessages(oldMessages, action.payload.messages)
      }
    }
  }
  if (isType(action, messageSent)) {
    const { conversationId } = action.payload
    const oldMessages =
      (state[conversationId] && state[conversationId].messages) || []
    return {
      ...state,
      [conversationId]: {
        ...state[conversationId],
        messages: mergeMessages(oldMessages, [action.payload])
      }
    }
  }
  if (isType(action, messageUpdated)) {
    const { _id, conversationId } = action.payload
    const oldMessages =
      (state[conversationId] && state[conversationId].messages) || []
    const messageIndex = oldMessages.findIndex(message => message._id === _id)

    const newState = [...oldMessages]
    if (messageIndex !== -1) {
      newState[messageIndex] = action.payload
    }

    return {
      ...state,
      [conversationId]: {
        ...state[conversationId],
        messages: newState
      }
    }
  }
  if (isType(action, providerTyping) || isType(action, botTyping)) {
    const { conversationId, name, _id: providerId } = action.payload
    const isBotTyping = isType(action, botTyping)
    if (!conversationId || (conversationId && !state[conversationId])) {
      return state
    }
    return {
      ...state,
      [conversationId]: {
        ...state[conversationId],
        typingUsers: {
          ...state[conversationId].typingUsers,
          [providerId]: name
        },
        isBotTyping
      }
    }
  }
  if (isType(action, cancelProviderTyping)) {
    const { conversationId, _id: providerId } = action.payload
    if (!conversationId) {
      return state
    }
    if (
      state[conversationId] &&
      state[conversationId].typingUsers &&
      state[conversationId].typingUsers[providerId]
    ) {
      const typingUsers = { ...state[conversationId].typingUsers }
      delete typingUsers[providerId]
      return {
        ...state,
        [conversationId]: {
          ...state[conversationId],
          typingUsers,
          isBotTyping: false
        }
      }
    }
    return state
  }
  if (isType(action, markReadInState)) {
    const { conversationId } = action.payload
    const oldMessages =
      (state[conversationId] && state[conversationId].messages) || []
    const messages = oldMessages.map(m => ({ ...m, status: 'read' }))
    return {
      ...state,
      [conversationId]: {
        ...state[conversationId],
        messages
      }
    }
  }

  return state
}

const allIds = (state: string[] = [], action: Action) => {
  if (isType(action, updateConversationSession)) {
    return uniq([...state, action.payload.conversationId])
  }

  return state
}

export const conversations = combineReducers({
  byId,
  allIds
})
