import * as React from 'react'
import styled from 'styledComponents'

import { Flex } from 'components/_utility'
import { helpers } from 'style/mixins/'
import { HelpersProps } from 'types'

export interface Props extends HelpersProps {
  className?: string
  children: React.ReactNode
}

const Component = ({ className, children }: Props) => (
  <Flex className={className}>{children}</Flex>
)

export const ButtonCluster = styled(Component)<Props>`
  ${helpers}

  >:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: solid 1px white;
  }

  > :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`
