/* eslint-disable no-prototype-builtins */
import { mergeDeepRight } from 'ramda'
import { isType } from 'typescript-fsa'
import { isLength } from 'validator'

import { Action } from 'redux'

import { leadValidator } from 'state/lead/lead.validator'
import { FormInterface } from 'types'
import { dirtifyForm, setFormSubmitted, updateForm } from '.'

export interface FormsState {
  [x: string]: FormInterface
}

export const DEFAULT_STATE: FormsState = {
  reviews: {
    reviewsSource: {
      status: 'valid',
      value: 'All'
    },
    reviewsSort: {
      status: 'valid',
      value: 'date:desc',
      label: 'Newest'
    }
  }
}

export const forms = (state: FormsState = DEFAULT_STATE, action: Action) => {
  if (isType(action, updateForm)) {
    const { formId, data } = action.payload
    // Run validation
    const merged = mergeDeepRight({ ...state[formId] }, data)
    Object.keys(data).forEach(fieldName => {
      if (data.hasOwnProperty(fieldName)) {
        const { value, type } = data[fieldName]
        if (typeof value !== 'undefined') {
          const { required = false, maxCharacters, consent } = merged[fieldName]
          merged[fieldName].status = leadValidator({
            fieldName,
            value,
            required,
            maxCharacters,
            consent,
            type
          })
        }
      }
    })

    // Validate full name
    const { firstName, lastName } = merged
    if (firstName && firstName.value && lastName && lastName.value) {
      const fullName = `${firstName.value} ${lastName.value}`
      if (!isLength(fullName, { min: 3, max: 60 })) {
        merged.firstName.status = 'invalid'
        merged.lastName.status = 'invalid'
      }
    }

    return {
      ...state,
      [formId]: merged
    }
  }

  if (isType(action, dirtifyForm)) {
    const { formId } = action.payload

    const form = { ...state[formId] }

    Object.keys(form).forEach(fieldName => {
      if (form.hasOwnProperty(fieldName)) {
        form[fieldName] = {
          ...form[fieldName],
          dirty: true
        }
      }
    })

    return {
      ...state,
      [formId]: form
    }
  }

  if (isType(action, setFormSubmitted)) {
    const { formId, submitted } = action.payload

    return {
      ...state,
      [formId]: {
        ...state[formId],
        submitted
      }
    }
  }

  return state
}
