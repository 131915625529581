import * as React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
  size?: number
  color?: string
}

export const SuccessCheck = ({ size = 52, color = '#34cb94' }: Props) => (
  <Icon size={size} color={color} viewBox="0 0 52 52">
    <IconCircle color={color} cx="26" cy="26" r="25" fill="none" />
    <IconCheck fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </Icon>
)

const scale = keyframes`
  0%, 100% {
      transform: none;
  }
  50% {
      transform: scale3d(1.1, 1.1, 1);
  }
`

const stroke = keyframes`
  100% {
      stroke-dashoffset: 0;
  }
`

const fill = keyframes`
  100% {
      box-shadow: inset 0px 0px 0px 30px #fff;
  }
`

const Icon = styled('svg')<Props>`
  width: ${p => `${p.size}px`};
  height: ${p => `${p.size}px`};
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${p => p.color};
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #fff;
  animation: ${fill} 0.4s ease-in-out 0.4s forwards,
    ${scale} 0.3s ease-in-out 0.9s both;
`

const IconCircle = styled('circle')<{ color?: string }>`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: ${p => p.color};
  fill: #fff;
  animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`

const IconCheck = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`
