import * as React from 'react'
import * as moment from 'moment'

import { FORM_CARD_ID_SEPARATOR } from 'common/constants'
import { cardMap } from 'components/_cards'
import {
  Bubble,
  BubbleCluster,
  Button,
  ButtonGroup,
  Container,
  SystemMessage,
  videoMessageMap
} from 'components/_utility'
import {
  CardInterface,
  ConversationAction,
  ConversationBlockInterface,
  ConversationElementInterface,
  ConversationInterface,
  VideoInterface
} from 'types'
import { getCardHeaderFromCard } from 'utility'
import { FinancingCertificateContainer } from 'components/FinancingCertificate'
import { MessageDisplay } from './Message.display'

type replyActionType = (x: {
  conversationId: string
  conversationAction: ConversationAction
}) => void
type submitConversationCardType = (x: {
  conversationId: string
  cardId: string
  data?: any
}) => void
type submitFormType = (x: {
  conversationId: string
  formId: string
  cardId: string
  conversionCode: string
}) => void
export interface Props {
  conversationId: string
  conversation: ConversationInterface // view.blocks with author, cards denormalized
  fetchConversation: (conversatoinId: string) => void
  replyAction: replyActionType
  submitConversationCard: submitConversationCardType
  submitForm: submitFormType
}

interface State {
  lastActionGroupClicked: boolean
}

export class ConversationDisplay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      lastActionGroupClicked: false
    }
  }

  public componentDidMount() {
    this.props.fetchConversation(this.props.conversationId)
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.conversation.blocks.length !==
      this.props.conversation.blocks.length
    ) {
      this.setState({
        lastActionGroupClicked: false
      })
    }
  }

  public handleReplyActionClick = ({
    conversationAction
  }: {
    conversationAction: ConversationAction
  }) => {
    this.setState({ lastActionGroupClicked: true })
    this.props.replyAction({
      conversationId: this.props.conversationId,
      conversationAction
    })
  }

  public handleFormSubmit: submitFormType = ({
    conversationId,
    formId,
    cardId,
    conversionCode
  }) => {
    this.props.submitForm({ conversationId, formId, cardId, conversionCode })
  }

  public handleCardSubmit: submitConversationCardType = ({
    conversationId,
    cardId,
    data
  }) => {
    this.props.submitConversationCard({ conversationId, cardId, data })
  }

  public getCardProps = (card: CardInterface, isLast: boolean) => {
    const header = getCardHeaderFromCard(card, isLast)
    const defaultProps = {
      ...card.props,
      _id: card._id,
      topBar: true,
      _width: 100,
      mb: 6,
      padding: '16px',
      header,
      onSubmit: (data: any) =>
        this.handleCardSubmit({
          conversationId: this.props.conversationId,
          cardId: card._id,
          data
        })
    }

    switch (card.template) {
      case 'form': {
        const formId = `c_${this.props.conversationId}${FORM_CARD_ID_SEPARATOR}${card._id}`
        const { conversionCode, submitted } = card.props
        return {
          ...defaultProps,
          _id: formId,
          formId,
          collapsed: !isLast || submitted,
          header,
          onSubmit: () =>
            this.handleFormSubmit({
              conversationId: this.props.conversationId,
              formId,
              cardId: card._id,
              conversionCode
            })
        }
      }
      default:
        return defaultProps
    }
  }

  public getVideoMessageProps = (video: VideoInterface) => {
    const defaultProps = {
      ...video
    }
    switch (video.status) {
      case 'declined': {
        return {
          ...defaultProps,
          header: 'Call Declined',
          description: 'You declined this call'
        }
      }
      case 'ended': {
        const lastedTime = moment(video.endTime).diff(
          moment(video.startTime),
          'minutes'
        )
        return {
          ...defaultProps,
          header: 'Call Ended',
          description: `Ended at ${moment(video.endTime).format(
            'LT'
          )} - Lasted ${lastedTime || 'less than a'} ${
            lastedTime === 1 || lastedTime === 0 ? 'minute' : 'minutes'
          }`
        }
      }
      case 'active': {
        const lastedTime = moment().diff(moment(video.startTime), 'minutes')
        return {
          ...defaultProps,
          header: 'Call Started',
          description: `Started ${lastedTime || 'less than a'} ${
            lastedTime === 1 || lastedTime === 0 ? 'minute' : 'minutes'
          } ago`
        }
      }
      default:
        return defaultProps
    }
  }

  public mapElementsToComponents = (
    elements: ConversationElementInterface[],
    authorType: string,
    isLastBlock: boolean
  ) => {
    return elements.map((element, i) => {
      const isLastElement = i === elements.length - 1
      const isLast = isLastBlock && isLastElement
      if (element.type === 'message') {
        return (
          <Bubble key={i} type={authorType} fullWidth={!!element.attachments}>
            <MessageDisplay element={element} />
          </Bubble>
        )
      }
      if (element.type === 'system') {
        return <SystemMessage key={i} text={element.contents as string} />
      }
      if (element.type === 'video-call' && element.video) {
        const { video } = element
        const props = this.getVideoMessageProps(video)
        const VideoMessageComponent = videoMessageMap[video.status]
        return <VideoMessageComponent key={i} {...props} />
      }
      if (element.type === 'card' && element.card) {
        const { card } = element
        const props = this.getCardProps(card, isLast)
        const CardComponent = cardMap[card.template]
        return <CardComponent key={i} {...props} />
      }
      if (element.type === 'buttonGroup' && element.actions && isLast) {
        return (
          <ButtonGroup key={i}>
            {element.actions.map(
              (elementAction, elementActionIndex: number) => {
                return (
                  <Button
                    key={elementActionIndex}
                    outline
                    multiline
                    rounded
                    disabled={this.state.lastActionGroupClicked}
                    onClick={() =>
                      this.handleReplyActionClick({
                        conversationAction: elementAction
                      })
                    }
                  >
                    {elementAction.cta}
                  </Button>
                )
              }
            )}
          </ButtonGroup>
        )
      }
      if (element.type === 'CL-certificate') {
        return (
          <Container mb={10}>
            <FinancingCertificateContainer />
          </Container>
        )
      }

      return null
    })
  }

  public mapBlocksToComponents = (blocks: ConversationBlockInterface[]) => {
    return blocks.map((block, i) => {
      const authorType = block.author ? block.author.type : 'provider'
      const authorAvatar = (block.author && block.author.photo) || ''
      const isLastBlock = i === blocks.length - 1
      return (
        <BubbleCluster key={i} type={authorType} avatar={authorAvatar}>
          {this.mapElementsToComponents(
            block.elements,
            authorType,
            isLastBlock
          )}
        </BubbleCluster>
      )
    })
  }

  public render() {
    return (
      <div>{this.mapBlocksToComponents(this.props.conversation.blocks)}</div>
    )
  }
}
