import { takeEvery } from 'redux-saga/effects'
import iframeCommunication from 'services/iframe'

import { initializeDeskSuccess } from './_actions'

function saga(action: ReturnType<typeof initializeDeskSuccess>) {
  const { webdesk } = action.payload
  if (webdesk && webdesk.behavior && webdesk.behavior.disableTranslator) {
    return
  }

  iframeCommunication.initializeTranslator()
}

export function* initializeTranslatorSaga() {
  yield takeEvery(initializeDeskSuccess, saga)
}
