import { path } from 'ramda'
import { Action } from 'redux'
import { isType } from 'typescript-fsa'

import { NAVIGATION } from 'common/constants'
import settings from 'settings'
import { initializeDeskSuccess } from 'state/_sagas/_actions'
import { goBack, navigate } from '.'
import { rehydrateRouterState } from './router.actions'

interface Route {
  currentView: string
  params: { [x: string]: any }
}

export interface RouterState {
  location: Route
  history: Route[]
  rehydrated: boolean
}

export const DEFAULT_STATE: RouterState = {
  location: {
    currentView: '',
    params: {}
  },
  history: [],
  rehydrated: false
}

export const router = (state: RouterState = DEFAULT_STATE, action: Action) => {
  if (isType(action, navigate)) {
    const { viewId, params = {} } = action.payload

    if (viewId !== state.location.currentView) {
      const location = { currentView: viewId, params }
      return {
        location,
        history: [...state.history, { ...location }]
      }
    }

    return state
  }

  if (isType(action, goBack)) {
    return state.history.length > 1
      ? {
          location: {
            ...state.history[state.history.length - 2]
          },
          history: state.history.slice(0, -1)
        }
      : DEFAULT_STATE
  }

  if (isType(action, rehydrateRouterState)) {
    const hydratedState = action.payload.state
    return {
      ...hydratedState,
      rehydrated: true
    }
  }

  if (isType(action, initializeDeskSuccess)) {
    const { webdesk, views, lastConversationId } = action.payload
    const currentView = webdesk.behavior.toolbarMode
      ? path(['navigation', 'navButtons', 0, 'viewId'], webdesk)
      : webdesk.navigation.homeViewId
    const currentLocation = {
      currentView,
      params: {}
    }

    if (settings.bookingMode) {
      currentLocation.currentView = NAVIGATION.BOOKING
      let showAll = true
      let service
      if (settings.bookingModeAppointmentServiceId) {
        showAll = false
        service = { _id: settings.bookingModeAppointmentServiceId }
      }
      currentLocation.params = { showAll, service }

      return {
        ...state,
        location: currentLocation,
        history: [currentLocation]
      }
    }

    if (state.rehydrated) {
      const isInvalidConv =
        state.location.currentView === NAVIGATION.CONVERSATIONS &&
        (!lastConversationId ||
          state.location.params.conversationId !== lastConversationId)
      const isInvalidLocation =
        state.location.currentView.indexOf('__') === -1 &&
        !views.find((view: any) => view._id === state.location.currentView)
      const hasInvalidHistoryViews =
        state.history.filter(
          route =>
            route.currentView.indexOf('__') === -1 &&
            !views.find((view: any) => view._id === route.currentView)
        ).length > 0
      if (!hasInvalidHistoryViews && !isInvalidLocation && !isInvalidConv) {
        return state
      }
    }

    return {
      ...state,
      location: currentLocation,
      history: [currentLocation]
    }
  }

  return state
}
