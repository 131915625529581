import * as React from 'react'
import styled, { withTheme } from 'styledComponents'

import { Flex, Text } from 'components/_utility'
import { FaqInterface } from 'types'

export interface Props extends FaqInterface {
  className?: string
}

const Component = ({
  className,
  question,
  questionHtml,
  answer,
  answerHtml
}: Props) => (
  <Flex column className={className}>
    <Text
      isTitle
      dangerouslySetInnerHTML={{ __html: questionHtml || question }}
    />
    <Text isBody dangerouslySetInnerHTML={{ __html: answerHtml || answer }} />
  </Flex>
)

const styledComponent = styled(Component)`
  &:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: solid 1px ${p => p.theme.gray.lighter};
  }

  p {
    margin: 0;
  }
`

export const Faq = withTheme(styledComponent)
