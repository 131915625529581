import * as React from 'react'

import { DockContainer } from 'components'
import styled, { css } from 'styledComponents'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { cardMap } from 'components/_cards'
import { Button, Card, Flex } from 'components/_utility'
import { CardInterface, LauncherInterface, LauncherType } from 'types'
import { getCardHeaderFromCard } from 'utility'

type submitFormType = (formId: string) => void
export interface Props {
  className?: string
  launcher: LauncherInterface
  cards: CardInterface[]
  closeLauncher: () => void
  previewMode: string
  webdeskPosition: string
  horizontalPadding: string
  verticalPadding: string
  isDeskOpen: boolean
  submitForm: submitFormType
}

const mapCardsToComponents = (
  cards: CardInterface[],
  submitForm: submitFormType
) => {
  return cards.map((card: CardInterface, i: number) => {
    const header = getCardHeaderFromCard(card)
    const props: { [x: string]: any } = {
      _width: 100,
      _id: card._id,
      mb: i < cards.length - 1 ? 9 : 0,
      header,
      ...card.props
    }
    if (card.template === 'form') {
      props.onSubmit = (formId: string) => {
        submitForm(formId)
      }
    }
    const CardComponent = cardMap[card.template]
    return <CardComponent key={i} {...props} />
  })
}

const Component = ({
  className,
  launcher,
  cards,
  closeLauncher,
  previewMode,
  webdeskPosition,
  horizontalPadding,
  verticalPadding,
  isDeskOpen,
  submitForm
}: Props) => {
  const isLauncherOpen = launcher.open && !!launcher.cardIds.length

  if (previewMode === 'none') {
    React.useEffect(() => {
      const resizeHandler = () => {
        const el = document.getElementById('rp-launcher')
        const iframe = window.top.document.getElementById('chatpath-iframe')
        if (el instanceof HTMLElement) {
          el.style.maxHeight = `${window.top.innerHeight - 36}px`
        }
        if (iframe instanceof HTMLElement) {
          if (webdeskPosition === 'left') {
            iframe.style.left = `${horizontalPadding}px`
          } else {
            iframe.style.right = `${horizontalPadding}px`
          }
          iframe.style.bottom = `${verticalPadding}px`
        }
      }
      resizeHandler()
      window.top.addEventListener('resize', resizeHandler)
      return () => window.top.removeEventListener('resize', resizeHandler)
    }, [])
  }

  const isDockVisible =
    launcher.type !== LauncherType.HIDDEN || isDeskOpen || isLauncherOpen

  return (
    <Flex
      id="rp-launcher"
      className={className}
      column
      justifyContent="flex-end"
      alignItems={webdeskPosition === 'left' ? 'flex-start' : 'flex-end'}
    >
      {isLauncherOpen && (
        <React.Fragment>
          <CloseButton mb={4} onClick={closeLauncher}>
            Close
            <Icon icon={faTimes} />
          </CloseButton>
          <CardWrapper>{mapCardsToComponents(cards, submitForm)}</CardWrapper>
        </React.Fragment>
      )}
      {isDockVisible && <DockContainer />}
    </Flex>
  )
}

const CardWrapper = styled.div`
  width: calc(100% - 24px);
  margin-bottom: 9px;
  border-radius: 6px;
  overflow: auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);

  > ${Card} {
    box-shadow: 0 4px 72px rgba(0, 0, 0, 0.12);
  }
`

const CloseButton = styled(Button)`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 9px;
  border-radius: 6px;

  font-size: 11px;
  color: white;
  background-color: ${p => p.theme.gray.light};
  opacity: 0.4;

  &:hover {
    background-color: ${p => p.theme.gray.base};
  }

  &:focus {
    background-color: ${p => p.theme.gray.darkest};
  }

  svg {
    margin-left: 3px;
  }
`

export const LauncherDisplay = styled(Component)`
  width: 100%;

  ${p =>
    p.previewMode === 'none' &&
    css`
      max-height: ${window.top.innerHeight - 36}px;
    `}
  ${p =>
    p.previewMode !== 'none' &&
    css`
      max-height: ${window.innerHeight - 45}px;
    `}
`
