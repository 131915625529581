import { CardInterface, InventoryElement, ProductInterface } from 'types'
import { mapInventoryElement } from './mapInventoryElement'
import { getFormCardRows } from './getFormCardRows'

export const mapCardToState = (card: CardInterface) => {
  if (card.template === 'carousel') {
    return {
      ...card,
      props: {
        ...card.props,
        products: (card.props.products || []).map(
          (product: InventoryElement | ProductInterface) => {
            if (product.original) {
              return product
            }
            return mapInventoryElement(product)
          }
        )
      }
    }
  }
  if (card.template === 'form') {
    return {
      ...card,
      props: {
        ...card.props,
        formData: {
          ...card.props.formData,
          rows: getFormCardRows(card.props.formData.rows)
        }
      }
    }
  }

  return card
}
