import * as React from 'react'
import styled from 'styledComponents'

import { Button, Flex, Text } from 'components/_utility'
import { TranslateButton } from 'components/TranslateButton'
import settings from 'settings'
import { shadow } from 'style/mixins'

import { faChevronLeft, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { FlexProps, ViewHeaderInterface } from 'types'

export interface Props {
  header: ViewHeaderInterface
  previousViewId?: string
  goBack: () => void
  closeWindow: () => void
}

export const HeaderDisplay = ({
  header,
  previousViewId,
  goBack,
  closeWindow
}: Props) => {
  const keyPress = React.useCallback(
    e => {
      if (e.key === 'Escape') {
        const x = document.getElementById('__btnBack') as HTMLElement
        if (window.getComputedStyle(x).visibility === 'hidden') {
          closeWindow()
        } else {
          goBack()
        }
      }
    },
    [closeWindow, goBack]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', keyPress)
    return () => document.removeEventListener('keydown', keyPress)
  }, [keyPress])

  return (
    <React.Fragment>
      <FixedHeader
        row
        alignItems="center"
        _p={8}
        isConversations={header.navigation === 'Messenger'}
      >
        <Button
          id="__btnBack"
          header
          mr={18}
          onClick={goBack}
          hidden={!previousViewId}
          customAttributes={{ 'data-test': 'back-button' }}
        >
          <Icon icon={faChevronLeft} />
        </Button>
        <Flex row justifyContent="center" grow overflow="hidden" basis="0">
          <Text title={header.navigation} truncated color="white" shadow>
            {header.navigation}
          </Text>
        </Flex>
        <TranslateButton />
        <Button
          header
          onClick={closeWindow}
          hidden={settings.bookingMode || settings.inventoryMode}
          customAttributes={{ 'data-test': 'close-button' }}
        >
          <Icon icon={faTimes} />
        </Button>
      </FixedHeader>
      {(header.title || header.subtitle) && (
        <ScrollingHeader>
          <Text
            isHeader
            color="white"
            shadow
            mt={6}
            mb={9}
            breakWord
            data-test="view-header-title"
          >
            {header.title}
          </Text>
          <Text color="white" transparent thin breakWord>
            {header.subtitle}
          </Text>
        </ScrollingHeader>
      )}
    </React.Fragment>
  )
}

interface FixedHeaderProps extends FlexProps {
  _p: number
  isConversations: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FixedHeader = styled(({ isConversations, ...rest }) => (
  <Flex {...rest} />
))<FixedHeaderProps>`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 48px;
  ${p =>
    p.isConversations
      ? `background-color: ${p.theme.colors.original};
      ${shadow('light')};`
      : `background-color: 'transparent';`}
`

const ScrollingHeader = styled.div`
  padding: 0 36px 24px;
  img {
    width: 120px;
  }
`
