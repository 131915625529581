import * as React from 'react'
import styled from 'styledComponents'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { InjectedFieldProps } from 'types'

// import { Flex } from 'components/_utility'

interface Props extends InjectedFieldProps {
  className?: string
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
}

const Component = ({
  className,
  name,
  label,
  required,
  value,
  status,
  updateField,
  disabled
}: Props) => {
  const onChange = (e: React.SyntheticEvent) => {
    updateField({
      [name]: {
        value: (e.target as HTMLInputElement).checked ? 'true' : 'false'
      }
    })
  }

  return (
    <div className={className}>
      <div className="pretty p-icon p-pulse p-curve">
        <input
          type="checkbox"
          name={name}
          checked={value === 'true'}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="state p-primary">
          <Icon className="icon" icon={faCheck as IconProp} size="2x" />
          <label data-test={status}>
            {label}
            {required ? '*' : ''}
          </label>
        </div>
      </div>
    </div>
  )
}

export const Checkbox = styled(Component)<Props>`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 14px;

  .pretty input:checked ~ .state.p-primary label:after {
    background-color: ${p => p.theme.colors.original} !important;
  }

  .pretty input:focus ~ .state.p-primary label:before {
    border-color: ${p => p.theme.colors.original} !important;
  }

  .pretty .state > label {
    margin-top: 0.6px;
    color: ${p => p.theme.gray.dark};

    &:before {
      ${p => p.status === 'invalid' && `border-color: red`};
    }
  }
`
