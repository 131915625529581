import * as React from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon as Icon,
  Props as IconProps
} from '@fortawesome/react-fontawesome'
import { Flex } from 'components/_utility'
import { helpers } from 'style/mixins/'
import { colorShades } from 'style/variables'
import styled, { css } from 'styledComponents'
import { HelpersProps } from 'types'

export interface CirbuttProps {
  className?: string
  icon: IconDefinition
  onClick: () => void
  color: string
  size: number
  scale?: number
  tooltip?: string
  tooltipRight?: boolean
}

interface TooltipProps {
  size: number
  tooltipRight?: boolean
}

interface SpeIconProps {
  fsize: number
}

const Component = ({
  className,
  icon,
  tooltip,
  onClick,
  size,
  tooltipRight
}: CirbuttProps) => {
  return (
    <div className={className}>
      <Flex
        onClick={onClick}
        column
        justifyContent="center"
        alignItems="center"
      >
        <SpeIcon fsize={size} icon={icon} color="white" />
        {tooltip && (
          <Tooltip size={size} tooltipRight={tooltipRight}>
            {tooltip}
          </Tooltip>
        )}
      </Flex>
    </div>
  )
}

const SpeIcon = styled(Icon)<IconProps & SpeIconProps & HelpersProps>`
  ${helpers}
  width: ${p => p.fsize * 0.51}px !important;
  height: ${p => p.fsize}px !important;
`

const Tooltip = styled('div')<TooltipProps>`
  visibility: hidden;
  position: absolute;
  ${p =>
    p.tooltipRight &&
    css`
      left: ${p.size * 1.1}px;
    `}
  ${p =>
    !p.tooltipRight &&
    css`
      right: ${p.size * 1.1}px;
    `}
  font-size: ${p => p.size * 0.5}px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  text-align: right;
  border-radius: 3px;
  background-color: ${p => p.theme.gray.darker};
  color: white;
`

export const Cirbutt = styled(Component)`
  ${helpers}

  z-index: 11;
  position: relative;
  border-radius: 50%;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  background-color: ${p => p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.color};
  box-shadow: 0px 5px 5px 0 rgba(50, 50, 93, 0.1),
    0px -1px 3px 0 rgba(0, 0, 0, 0.07);
  transition-property: transform;
  transition-duration: 0.1s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${p => colorShades(p.color).darker};
  }

  &:hover ${Tooltip} {
    visibility: visible;
  }

  &:active {
    transform: scale(0.9);
  }
`
