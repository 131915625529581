import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { submitViewForm } from 'state/forms'
import { currentViewCardsSelector } from 'state/router'
import { isToolbarEnabledSelector } from 'state/ui'
import { RootState } from 'types'
import { CardStackDisplay, Props } from './CardStack.display'

type StateProps = Pick<Props, 'cards' | 'isToolbarMode'>

const mapStateToProps = (state: RootState): StateProps => ({
  cards: currentViewCardsSelector(state),
  isToolbarMode: isToolbarEnabledSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitForm: (formId: string) => {
    dispatch(submitViewForm({ cardId: formId }))
  }
})

export const CardStackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardStackDisplay)
