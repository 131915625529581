import { Middleware } from 'redux'
import { isType } from 'typescript-fsa'
import { monitoringService } from 'services/monitoring'
import { updateLead } from 'state/lead'
import { RootState } from 'state/reducer'

export const monitoringMiddleware: Middleware<
  {},
  RootState
> = store => next => action => {
  const prevState = store.getState()

  if (isType(action, updateLead)) {
    monitoringService.setLeadData(action.payload)
  }

  next(action)

  const newState = store.getState()
  if (
    prevState.router.location.currentView !==
    newState.router.location.currentView
  ) {
    const { location } = newState.router
    monitoringService.trackPageView({
      view: location.currentView,
      properties: location.params
    })
  }
}
