import { delay } from 'redux-saga'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import rpApiService from 'services/rp.api'
import socket from 'services/rp.socket'
import { showToast } from 'state/_sagas/_actions'
import { cardByIdSelector, updateCard } from 'state/entities/cards'
import { runConversionCode, setFormSubmitted } from 'state/forms'
import { leadSelector, updateLead } from 'state/lead'
import { init as initSocket } from 'state/socket'
import { getFormDataToSubmit } from 'utility/getFormDataToSubmit'
import { submitViewForm } from './forms.actions'
import { formSelector } from './forms.selectors'

function* submitViewFormSaga(action: Action<{ cardId: string }>) {
  const { cardId } = yield action.payload
  try {
    if (!socket.isInitialized()) {
      yield put(initSocket())
      while (!socket.isInitialized()) {
        yield delay(1000)
      }
    }
    const card = yield select(cardByIdSelector, cardId)
    const { conversionCode } = card.props
    yield put(setFormSubmitted({ formId: cardId, submitted: true }))
    const form = yield select(formSelector, cardId)
    const dataToSubmit = getFormDataToSubmit(form, card)
    const lead = yield select(leadSelector)
    if (!lead._id) {
      const { _id } = yield call(rpApiService.createLead, {
        deviceType: lead.deviceType,
        customData: lead.customData
      })
      yield put(updateLead({ _id }))
    }
    yield call(rpApiService.submitViewForm, dataToSubmit)
    yield put(
      updateCard({
        _id: cardId,
        data: {
          props: { submitted: true, collapsed: true }
        }
      })
    )
    yield put(runConversionCode({ conversionCode }))
  } catch (e) {
    console.error('Error:', e)
    let message = 'Something went wrong. Please try again later.'
    try {
      const { errors = [] } = JSON.parse(e.message)
      if (errors.length) {
        message = Object.values(errors[0])[0] as string
      }
    } catch (e) {
      console.error('Error:', e)
    }
    yield put(setFormSubmitted({ formId: cardId, submitted: false }))
    yield put(showToast({ message, variant: 'error' }))
  }
}

export default function* cardsSaga() {
  yield all([takeEvery(submitViewForm, submitViewFormSaga)])
}
