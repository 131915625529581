import { FieldOptionInterface } from 'types'

export interface FormFieldInterface {
  value?: string | number | string[]
  label?: string
  required?: boolean
  status?: string
  dirty?: boolean
  maxCharacters?: number
  consent?: boolean
  options?: FieldOptionInterface[]
  disabled?: boolean
  inquiryTypeId?: string
  companyId?: string
  type?: FormFieldType
}

export enum FormFieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SLIDER = 'slider'
}
