import * as React from 'react'
import styled from 'styledComponents'

import { withForm } from 'components/_hoc'
import {
  Card,
  ConnectedInput,
  ConnectedMaskedInput,
  Flex,
  Label,
  SubmitButton,
  Text
} from 'components/_utility'
import { helpers } from 'style/mixins/'
import { HelpersProps, InputMask } from 'types'

export interface Props extends HelpersProps {
  className?: string
  submit: () => void
  phoneMask?: InputMask
}

const Component = ({ className, submit, phoneMask }: Props) => (
  <Card className={className}>
    <Flex column mb={12}>
      <Text isTitle>We&apos;ve got your trade-in value!</Text>
      <Text isBody>Fill in the details below to see it.</Text>
    </Flex>
    {/* wrapped to form to handle ENTER key submission */}
    <form onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}>
      <Flex row mb={12}>
        <Flex column _width={50} pr={6}>
          <Label>First Name*</Label>
          <ConnectedInput required name="firstName" />
        </Flex>
        <Flex column _width={50} pl={6}>
          <Label>Last Name*</Label>
          <ConnectedInput required name="lastName" />
        </Flex>
      </Flex>
      <Flex column mb={12}>
        <Label>Email*</Label>
        <ConnectedInput required name="email" />
      </Flex>
      <Flex column mb={12}>
        <Label>Phone</Label>
        {phoneMask ? (
          <ConnectedMaskedInput name="phone" mask={phoneMask} />
        ) : (
          <ConnectedInput name="phone" />
        )}
      </Flex>
      <SubmitButton
        _width={100}
        primary
        failureMessage="Please let us know where we should send your estimate!"
        onClick={submit}
      >
        Next
      </SubmitButton>
    </form>
  </Card>
)

export const Step3 = styled(withForm(Component, 'trade_in_3'))`
  ${helpers}
`
