/* eslint-disable no-undef */
import * as React from 'react'
import { Action } from 'typescript-fsa'

import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import Sound from 'react-sound'
import { Dispatch } from 'redux'

import { LauncherContainer, WindowContainer } from 'components'
import { Flex } from 'components/_utility'
import iframeCommunication from 'services/iframe'
import settings from 'settings'
import { handleDashboardAction } from 'state/_sagas/_actions'
import { checkIfMarkRead } from 'state/entities/conversations'
import {
  checkExpandOnExit,
  checkExpandOnHover,
  hideLauncherOnMouseLeave,
  setTitleAnimation,
  stopSound
} from 'state/ui/ui.actions'
import {
  isWideSelector,
  mobileMoveUpSelector,
  playSoundSelector,
  rpPreviewModeSelector,
  tabFlashSelector,
  titleSelector,
  webdeskPositionSelector,
  webdeskHorizontalPaddingSelector,
  webdeskVerticalPaddingSelector,
  webMoveUpSelector,
  isToolbarEnabledSelector
} from 'state/ui/ui.selectors'
import { sizes } from 'style/variables'
import styled, { css } from 'styledComponents'
import { RootState } from 'types'

// @ts-ignore
soundManager.setup({ debugMode: false })

const mapStateToProps = (state: RootState) => {
  const { value, isAnimated } = titleSelector(state)
  return {
    isWide: isWideSelector(state),
    playSound: playSoundSelector(state),
    title: value,
    isTitleAnimated: isAnimated,
    tabFlash: tabFlashSelector(state),
    previewMode: rpPreviewModeSelector(state),
    webdeskPosition: webdeskPositionSelector(state),
    horizontalPadding: webdeskHorizontalPaddingSelector(state),
    verticalPadding: webdeskVerticalPaddingSelector(state),
    webMoveUp: webMoveUpSelector(state),
    mobileMoveUp: mobileMoveUpSelector(state),
    isToolbarEnabled: isToolbarEnabledSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
  stopSound: () => dispatch(stopSound()),
  setTitleAnimation: isAnimated => dispatch(setTitleAnimation({ isAnimated })),
  checkIfMarkRead: () => dispatch(checkIfMarkRead({})),
  checkExpandOnExit: () => dispatch(checkExpandOnExit()),
  checkExpandOnHover: () => dispatch(checkExpandOnHover()),
  hideLauncherOnMouseLeave: () => dispatch(hideLauncherOnMouseLeave()),
  handleDashboardMessage: (event: MessageEvent) => {
    if (event.data.type === 'DASHBOARD') {
      dispatch(
        handleDashboardAction({
          dashboardAction: event.data.action as Action<any>
        })
      )
    }
  }
})

interface Props {
  className?: string
  title: string
  isTitleAnimated: boolean
  playSound: boolean
  webdeskPosition: string
  horizontalPadding: string
  verticalPadding: string
  tabFlash: boolean
  isWide: boolean
  isToolbarEnabled: boolean
  previewMode: string
  webMoveUp: number
  mobileMoveUp: number
  stopSound: () => void
  setTitleAnimation: (isAnimated: boolean) => void
  checkIfMarkRead: () => void
  checkExpandOnExit: () => void
  checkExpandOnHover: () => void
  hideLauncherOnMouseLeave: () => void
  handleDashboardMessage: EventListener
}

class Frame extends React.Component<Props> {
  public componentDidMount() {
    window.addEventListener('message', this.postMessageHandler)
    window.addEventListener('focus', () => this.props.checkIfMarkRead())
    window.parent.addEventListener('message', this.props.handleDashboardMessage)
    if (this.props.previewMode === 'none') {
      const width = this.props.isWide
        ? sizes.isMobile
          ? '100%' // Mobile expanded
          : '396px' // Desktop expanded
        : '180px' // Collapsed to icon (with margin)
      iframeCommunication.changeDimensions({
        width
      })
      iframeCommunication.webdeskPosition({
        position: this.props.webdeskPosition,
        horizontalPadding: this.props.horizontalPadding,
        verticalPadding: this.props.verticalPadding
      })
    }
  }

  public componentDidUpdate(prevProps: Props) {
    window.top.document.title = this.props.title || window.top.document.title

    if (
      prevProps.isWide !== this.props.isWide &&
      this.props.previewMode === 'none'
    ) {
      const width = this.props.isWide
        ? sizes.isMobile
          ? '100%' // Mobile expanded
          : '396px' // Desktop expanded
        : '180px' // Collapsed to icon (with margin)
      iframeCommunication.changeDimensions({
        width
      })
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.postMessageHandler)
    window.top.removeEventListener('message', this.props.handleDashboardMessage)
  }

  public postMessageHandler = (e: MessageEvent) => {
    const {
      data: { type }
    } = e
    let timeoutId: any

    if (type === 'onFocus' || type === 'mouseEnter') {
      if (this.props.isTitleAnimated) {
        this.props.setTitleAnimation(false)
      }
      this.props.checkIfMarkRead()
    }

    if (type === 'iframeMouseEnter') {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(this.props.checkExpandOnHover, 40)
    }

    if (type === 'iframeMouseLeave') {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(this.props.hideLauncherOnMouseLeave, 40)
    }

    if (type === 'onBlur') {
      if (this.props.tabFlash) {
        this.props.setTitleAnimation(true)
      }
    }

    if (type === 'leaveCheck') {
      this.props.checkExpandOnExit()
    }
  }

  public onHeightResize = (width: number, height: number) => {
    if (this.props.previewMode === 'none') {
      iframeCommunication.changeDimensions({
        height,
        webMoveUp: this.props.webMoveUp,
        mobileMoveUp: this.props.mobileMoveUp
      })
    }
  }

  public render() {
    const { className, playSound, previewMode } = this.props

    return (
      <div className={className}>
        <Sound
          url={`${settings.rootUrl}/notification_water_drop.mp3`}
          // @ts-ignore
          playStatus={playSound ? Sound.status.PLAYING : Sound.status.STOPPED}
          autoLoad={true}
          onFinishedPlaying={this.props.stopSound}
        />
        <Flex
          id="rp-reference"
          column
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <WindowContainer />
          {previewMode !== 'cardmodal' && <LauncherContainer />}
        </Flex>
        <ReactResizeDetector
          resizableElementId="rp-reference"
          handleHeight
          onResize={this.onHeightResize}
        />
      </div>
    )
  }
}

const styledComponent = styled(Frame)`
  position: fixed;
  top: 0;
  bottom: 0;

  ${p =>
    p.previewMode !== 'none' &&
    css`
      #rp-reference {
        visibility: hidden;
      }
    `}

  ${p =>
    p.webdeskPosition === 'right' &&
    css`
      right: 0;
    `}

  ${p =>
    p.webdeskPosition === 'left' &&
    css`
      left: 0;
    `}

  > ${Flex} {
    position: fixed;
    bottom: 0;
    width: 360px;

    ${p =>
      p.webdeskPosition === 'right' &&
      css`
        right: 0;
      `}

    ${p =>
      p.webdeskPosition === 'left' &&
      css`
        left: 0;
      `}

    margin: 18px 18px ${p => p.webMoveUp + 18}px 18px;

    ${p =>
      sizes.isMobile &&
      css`
        left: 0;
        margin: 0px 0px ${p.mobileMoveUp + 0}px 0px;
        width: 100%;
      `}
  }
`

export const FrameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(styledComponent)
