import { put, select, takeEvery } from 'redux-saga/effects'
import { formFieldSelector, updateForm, formCardSelector } from 'state/forms'
import { leadSelector, pingLead, updateLead } from 'state/lead'

import { TrackedEventDestinations } from 'types'

import { logAction, trackEvent, updateField } from './_actions'

function* saga(action: ReturnType<typeof updateField>) {
  const {
    formId,
    data,
    required,
    maxCharacters,
    consent,
    sideEffect,
    type
  } = action.payload
  const fieldName = Object.keys(data)[0]

  try {
    yield put(
      updateForm({
        formId,
        data: {
          [fieldName]: {
            ...data[fieldName],
            required,
            maxCharacters,
            consent,
            type
          }
        }
      })
    )
    // Only run if field value has changed
    // eslint-disable-next-line no-prototype-builtins
    if (data[fieldName].hasOwnProperty('value')) {
      const { value, status, label } = yield select(
        formFieldSelector,
        formId,
        fieldName
      )

      const formIdSplit = formId.split('_')
      const cardId_only = formIdSplit[formIdSplit.length - 2]
      const cardDate_only = formIdSplit[formIdSplit.length - 1]
      const cards = yield select(formCardSelector)
      const currentCard = cards.byId[`${cardId_only}_${cardDate_only}`]

      let captureFormOnSubmitOnly = false
      if (currentCard) {
        captureFormOnSubmitOnly = currentCard.props.captureFormOnSubmitOnly
          ? currentCard.props.captureFormOnSubmitOnly
          : false
      }

      if (status === 'valid' && !captureFormOnSubmitOnly) {
        // update lead with field
        yield put(
          updateLead({
            [fieldName]: label ? { value, label } : value
          })
        )

        // get updated lead information before pinging lead
        const lead = yield select(leadSelector)
        if (lead.email || lead.phone) {
          yield put(pingLead({ performUpdate: true }))
          yield put(
            trackEvent({
              trackingName: 'LEAD_CAPTURED',
              properties: { field: fieldName },
              destinations: [TrackedEventDestinations.MIXPANEL]
            })
          )
        }

        if (sideEffect) {
          yield put({ ...sideEffect })
        }
      }
    }
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* updateFieldSaga() {
  yield takeEvery(updateField, saga)
}
