import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import {
  analyticsMiddleware,
  blockActionsForPreviewMiddleware,
  emitEP2ToDashboardMiddleware,
  monitoringMiddleware
} from 'middleware'
import settings from 'settings'
import { reducer, RootState } from 'state/reducer'
import rootSaga from './saga'

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  analyticsMiddleware,
  blockActionsForPreviewMiddleware,
  emitEP2ToDashboardMiddleware,
  sagaMiddleware,
  monitoringMiddleware,
  thunk
]

const defaultState = {}
const composeEnhancers =
  (window.top as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const persistConfig = {
  key: `root:${settings.webdeskId || settings.companyId}`,
  storage,
  whitelist: ['ui', 'router', 'entities'],
  debug: false,
  stateReconciler: (inboundState: RootState, originalState: RootState) =>
    originalState
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(
  persistedReducer,
  defaultState,
  composeEnhancers(applyMiddleware(...middleware))
)

export const persistor = persistStore(store)

export const getStore = () => store

sagaMiddleware.run(rootSaga)

if (module.hot) {
  module.hot.accept('state/reducer', function cb() {
    // This fetch the new state of the above reducers.
    const nextRootReducer = require('state/reducer').reducer
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer))
  })
}
