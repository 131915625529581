import * as React from 'react'
import styled, { css } from 'styledComponents'

import { ActionButtonActionInterface, ActionButtonGroup } from 'components'
import { shadow } from 'style/mixins/'
import { CardHeaderProps } from 'types'
import { CardHeader } from './'

export interface Props {
  className?: string
  children: React.ReactNode
  header?: CardHeaderProps
  padding?: string
  collapsed?: boolean
  topbar?: boolean
  gradientBorder?: boolean
  actionButtons?: ActionButtonActionInterface[]
  actionButtonsDisplay?: {
    padding?: string
    chevronRight?: boolean
  }
  customAttributes?: { [x: string]: string }
}

const Component = React.forwardRef(
  (
    {
      className,
      children,
      header,
      padding,
      gradientBorder,
      collapsed,
      actionButtons,
      actionButtonsDisplay,
      customAttributes = {}
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div ref={ref} className={className} id="ep2-card" {...customAttributes}>
      {header && (
        <CardHeader
          title={header.title}
          cardIndex={header.cardIndex}
          status={header.status}
          collapsed={collapsed}
          type={header.type}
          onClick={header.onClick}
          onPrevious={header.onPrevious}
          onNext={header.onNext}
        />
      )}
      {!collapsed && children && (
        <CardBody padding={padding} gradientBorder={gradientBorder}>
          {children}
          {actionButtons && !!actionButtons.length && (
            <ActionButtonGroup
              {...actionButtonsDisplay}
              primary
              fullWidth
              actions={actionButtons}
            />
          )}
        </CardBody>
      )}
    </div>
  )
)

Component.displayName = 'Card'

export const Card = styled(Component)`
  flex-shrink: 0;
  border-radius: 6px;
  overflow: hidden;
  ${shadow('medium')}
  background-color: white;

  ${p =>
    p.topbar !== false &&
    css`
      background: linear-gradient(
          to right,
          ${p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.colors.lighter},
          ${p.theme.isCadillacTheme?p.theme.cadilacBackGroundColor:p.theme.colors.dark}
        )
        white;
      background-repeat: no-repeat;
      background-size: 100% 3px;
    `}

  ${p =>
    p.gradientBorder &&
    css`
      background: linear-gradient(
        to right,
        ${p.theme.colors.lighter},
        ${p.theme.colors.dark}
      );
      padding: 5px;
    `}
`

const CardBody = styled('div')<{ padding?: string; gradientBorder?: boolean }>`
  padding: 18px 24px;
  ${p => p.padding === 'none' && `padding: 0`}
  ${p => p.padding && `padding: ${p.padding}`}
  ${p => p.gradientBorder && `background-color: white`};
`
