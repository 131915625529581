import * as mixpanel from 'mixpanel-browser'
import { call, put, select } from 'redux-saga/effects'
import { logAction } from 'state/_sagas/_actions'
import { rpApiSessionTokenSelector } from 'state/company'

// default value
const chatProfitCenter = 'sales'

function* handleChatImpression(
  sdWindow: any,
  properties: { [x: string]: any },
  token: string
) {
  yield call(() => {
    sdWindow.parent.sd('dataLayer', {
      chatProfitCenter,
      chatType: 'chat',
      chatSessionId: token,
      chatProvider: 'DIGITALAIRSTRIKE',
      events: 'chatImpression'
    })
    sdWindow.parent.sd('send')
    mixpanel.track('shift/chatImpression', properties)
  })
}

function* handleChatClick(
  sdWindow: any,
  properties: { [x: string]: any },
  token: string
) {
  yield call(() => {
    sdWindow.parent.sd('dataLayer', {
      chatProfitCenter,
      chatType: 'chat',
      chatSessionId: token,
      chatProvider: 'DIGITALAIRSTRIKE',
      events: 'chatClick'
    })
    sdWindow.parent.sd('send')
    mixpanel.track('shift/chatClick', properties)
  })
}

function* handleChatMessage(
  sdWindow: any,
  properties: { [x: string]: any },
  token: string
) {
  yield call(() => {
    sdWindow.parent.sd('dataLayer', {
      chatProfitCenter,
      chatType: 'chat',
      chatSessionId: token,
      chatProvider: 'DIGITALAIRSTRIKE',
      chatMessageType: properties.chatMessageType,
      chatMessageContent: properties.chatMessageContent,
      events: 'chatMessage'
    })
    sdWindow.parent.sd('send')
    if (properties.chatMessageType === 'agent') {
      mixpanel.track('shift/chatAgentMessage', properties)
    }
    mixpanel.track('shift/chatMessage', properties)
  })
}

function* handleChatLead(
  sdWindow: any,
  properties: { [x: string]: any },
  token: string
) {
  yield call(() => {
    sdWindow.parent.sd('dataLayer', {
      chatProfitCenter,
      chatType: 'chat',
      chatSessionId: token,
      chatProvider: 'DIGITALAIRSTRIKE',
      chatLeadId: properties.leadId,
      events: 'chatLead'
    })
    sdWindow.parent.sd('send')
    mixpanel.track('shift/chatLead', properties)
  })
}

function* handleChatStarted(properties: { [x: string]: any }) {
  yield call(() => {
    mixpanel.track('shift/chatStarted', properties)
  })
}

function* handleChatClosed(sdWindow: any, token: string) {
  yield call(() => {
    sdWindow.parent.sd('dataLayer', {
      chatProfitCenter,
      chatType: 'chat',
      chatSessionId: token,
      chatProvider: 'DIGITALAIRSTRIKE',
      events: 'chatClosed'
    })
    sdWindow.parent.sd('send')
  })
}

export default function* trackEventShiftSubgenerator(
  trackingName: string,
  properties: { [x: string]: any }
) {
  try {
    if (!(window as any).parent.sd) {
      return
    }

    const token = yield select(rpApiSessionTokenSelector)

    switch (trackingName) {
      case 'DESK_INITIALIZED':
        yield call(() => handleChatImpression(window as any, properties, token))
        break
      case 'CHAT_CLICK':
        yield call(() => handleChatClick(window as any, properties, token))
        break
      case 'CHAT_MESSAGE':
        yield call(() => handleChatMessage(window as any, properties, token))
        break
      case 'LEAD_CREATED':
        yield call(() => handleChatLead(window as any, properties, token))
        break
      case 'CHAT_STARTED':
        yield call(() => handleChatStarted(properties))
        break
      case 'CHAT_CLOSED':
        yield call(() => handleChatClosed(window as any, token))
        break
      default:
        break
    }
  } catch (error) {
    yield put(logAction({ error }))
  }
}
