import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'
import { Actions } from '@andyet/simplewebrtc'

import iframeCommunication from 'services/iframe'
import rpApiService from 'services/rp.api'
import { leadSelector } from 'state/lead'
import {
  declineVideoCall,
  leaveVideoCall,
  setRoomName,
  joinVideoCall,
  expandVideo,
  collapseVideo
} from './video.actions'
import { expandedModeSelector } from './video.selectors'

function* declineVideoCallSaga(action: Action<{ messageId: string }>) {
  const { messageId } = action.payload
  yield call(rpApiService.declineVideoCall, messageId)
}

function* leaveVideoCallSaga() {
  const { _id: leadId } = yield select(leadSelector)
  yield call(rpApiService.leaveVideoCall, leadId)
  yield put(Actions.removeAllMedia('audio') as any)
  yield put(Actions.removeAllMedia('video') as any)
  const isExpanded = yield select(expandedModeSelector)
  if (isExpanded) {
    yield put(collapseVideo())
  }
}

function* joinVideoCallSaga(action: Action<{ messageId: string }>) {
  const { _id: leadId, firstName = '', lastName = '' } = yield select(
    leadSelector
  )
  const { messageId } = action.payload
  yield put(setRoomName({ roomName: leadId }))
  yield call(rpApiService.joinVideoCall, {
    lead: { _id: leadId, firstName, lastName },
    messageId
  })
}

function* expandVideoSaga() {
  yield call(iframeCommunication.changeDimensions, {
    width: '100%',
    height: '100%',
    videoExpanded: true
  })
}

function* collapseVideoSaga() {
  yield call(iframeCommunication.collapseVideo)
}

export default function* videoSaga() {
  yield all([
    takeEvery(declineVideoCall, declineVideoCallSaga),
    takeEvery(leaveVideoCall, leaveVideoCallSaga),
    takeEvery(joinVideoCall, joinVideoCallSaga),
    takeEvery(expandVideo, expandVideoSaga),
    takeEvery(collapseVideo, collapseVideoSaga)
  ])
}
