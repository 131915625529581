import { put, takeEvery } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'

import settings from 'settings'
import { EntitiesState } from 'state/entities'
import { rehydrateCardsState } from 'state/entities/cards'
import { rehydrateRouterState, RouterState } from 'state/router'
import { rehydrateUIState, UIState } from 'state/ui'
import customWindow from 'utility/storage/customWindow'
import { logAction, rehydrateAction } from './_actions'

function* saga(
  action: Action<{ ui: UIState; router: RouterState; entities: EntitiesState }>
) {
  if (settings.previewMode !== 'none') return

  try {
    const expireTime = customWindow.localStorage.getItem(
      settings.persistExpireKey
    )
    const currentTime = new Date().getTime()
    const newExpireTime = currentTime + settings.storeExpirationTime
    customWindow.localStorage.setItem(
      settings.persistExpireKey,
      newExpireTime.toFixed(0)
    )
    if (!action.payload || !expireTime || +expireTime < currentTime) return

    yield put(rehydrateUIState({ state: action.payload.ui }))
    yield put(rehydrateRouterState({ state: action.payload.router }))
    yield put(
      rehydrateCardsState({ cardsState: action.payload.entities.cards })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
  }
}

export function* rehydrateSaga() {
  yield takeEvery(rehydrateAction, saga)
}
