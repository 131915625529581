import { datadogRum } from '@datadog/browser-rum'
import settings from 'settings'

class DatadogService {
  private appId: any

  private clientToken: any

  constructor() {
    this.appId = '535c974e-efb6-4b91-acc6-cc415a74ea06'
    this.clientToken = 'pub6aa958560479f4c392bc490729464c94'
  }

  start() {
    datadogRum.init({
      applicationId: this.appId,
      clientToken: this.clientToken,
      site: 'datadoghq.com',
      service: 'response-path---engagement-panel-',
      env: settings.env,
      version: '2.0.2',
      sampleRate: 10,
      trackInteractions: true
    })
  }
}

export const datadogService = new DatadogService()
