import { NAVIGATION, VIRTUAL_CARDS } from 'common/constants'
import { createSelector, OutputSelector } from 'reselect'
import settings from 'settings'
import { RootState } from 'state/reducer'

import { homeViewIdSelector, isToolbarEnabledSelector } from 'state/ui'
import { CardSequenceViewInterface, CardStackViewInterface } from 'types'
import { getMappedViewCards } from 'utility'

const viewsByIdSelector = createSelector(
  (state: RootState) => state.entities.views.byId,
  viewsById => viewsById
)

export const currentViewIdSelector = createSelector(
  (state: RootState) => state.router.location.currentView,
  currentView => currentView
)

export const currentViewParamsSelector = createSelector(
  (state: RootState) => state.router.location.params,
  params => params
)

// eslint-disable-next-line import/no-mutable-exports
export let currentViewSelector: OutputSelector<any, any, any>
if (settings.previewMode !== 'none') {
  currentViewSelector = createSelector(
    currentViewIdSelector,
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.template : undefined
    },
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.header.navigation : undefined
    },
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.header.title : undefined
    },
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.header.subtitle : undefined
    },
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.header.footer : undefined
    },
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.cardIds : undefined
    },
    (state: RootState) => {
      const view = state.entities.views.byId[state.router.location.currentView]
      return view ? view.wholeView : undefined
    },
    (
      _id,
      template,
      navigation,
      title,
      subtitle,
      footer,
      cardIds,
      wholeView
    ) => {
      switch (_id) {
        case NAVIGATION.CONVERSATIONS:
          return {
            _id: NAVIGATION.CONVERSATIONS,
            template: 'conversations',
            header: { navigation: 'Messenger' }
          }
        case NAVIGATION.CHATBOT:
          return {
            _id: NAVIGATION.CHATBOT,
            template: 'cardStack',
            header: {},
            cardIds: [VIRTUAL_CARDS.CHATBOT._id]
          }
        case NAVIGATION.FAQS:
          return {
            _id: NAVIGATION.FAQS,
            template: 'cardStack',
            header: {},
            cardIds: [VIRTUAL_CARDS.FAQS._id]
          }
        case NAVIGATION.BOOKING:
          return {
            _id: NAVIGATION.BOOKING,
            template: 'cardStack',
            header: { navigation: 'Book Consultation' },
            cardIds: [VIRTUAL_CARDS.BOOKING._id]
          }
        default:
          return {
            ...wholeView,
            _id,
            template,
            header: {
              navigation,
              title,
              subtitle,
              footer
            },
            cardIds
          }
      }
    }
  )
} else {
  currentViewSelector = createSelector(
    viewsByIdSelector,
    currentViewIdSelector,
    (viewsById, viewId) => {
      switch (viewId) {
        case NAVIGATION.CONVERSATIONS:
          return {
            _id: NAVIGATION.CONVERSATIONS,
            template: 'conversations',
            header: { navigation: 'Messenger' }
          }
        case NAVIGATION.CHATBOT:
          return {
            _id: NAVIGATION.CHATBOT,
            template: 'cardStack',
            header: {},
            cardIds: [VIRTUAL_CARDS.CHATBOT._id]
          }
        case NAVIGATION.FAQS:
          return {
            _id: NAVIGATION.FAQS,
            template: 'cardStack',
            header: {},
            cardIds: [VIRTUAL_CARDS.FAQS._id]
          }
        case NAVIGATION.BOOKING:
          return {
            _id: NAVIGATION.BOOKING,
            template: 'cardStack',
            header: { navigation: 'Book Consultation' },
            cardIds: [VIRTUAL_CARDS.BOOKING._id]
          }
        default:
          return viewsById[viewId]
      }
    }
  )
}

export const previousViewIdSelector = createSelector(
  (state: RootState) => state.router.history,
  history =>
    history[history.length - 2]
      ? history[history.length - 2].currentView
      : undefined
)

export const currentViewCardsSelector = createSelector(
  currentViewSelector,
  (state: RootState) => state.entities.cards,
  homeViewIdSelector,
  isToolbarEnabledSelector,
  (
    currentView: CardSequenceViewInterface | CardStackViewInterface,
    cards,
    homeViewId,
    isToolbarEnabled
  ) => {
    let currentViewCardsIds: string[] = currentView.cardIds
      ? [...currentView.cardIds]
      : []
    if (currentView._id === homeViewId) {
      currentViewCardsIds = [VIRTUAL_CARDS.MESSAGES._id, ...currentViewCardsIds]
    }
    if (isToolbarEnabled) {
      currentViewCardsIds = currentViewCardsIds.filter(
        cardId =>
          !['messages', 'chatbot', 'conversations'].includes(
            cards.byId[cardId].preset
          )
      )
    }

    return getMappedViewCards(currentViewCardsIds, cards)
  }
)
