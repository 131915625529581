import * as React from 'react'

import { CATCH_ALL_FORM_ID } from 'common/constants'

export const FormContext = React.createContext({ formId: CATCH_ALL_FORM_ID })

interface InjectedProps {
  formId?: string
}
export const withForm = <T extends object>(
  Component: React.ComponentType<T>,
  formId: string
): React.ComponentType<T & InjectedProps> => {
  return class WithFormComponent extends React.Component<T & InjectedProps> {
    public render() {
      return (
        <FormContext.Provider
          value={{ formId: `${this.props.formId || formId}` }}
        >
          <Component {...this.props} />
        </FormContext.Provider>
      )
    }
  }
}
