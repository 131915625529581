import * as moment from 'moment-timezone'

const zoneMap = {
  HT: {
    code: 'US/Hawaii',
    full: 'Hawaii',
    abbreviation: 'HT'
  },
  AKT: {
    code: 'US/Alaska',
    full: 'Alaska',
    abbreviation: 'AKT'
  },
  PT: {
    code: 'US/Pacific',
    full: 'Pacific',
    abbreviation: 'PT'
  },
  MT: {
    code: 'US/Mountain',
    full: 'Mountain',
    abbreviation: 'MT'
  },
  MST: {
    code: 'US/Arizona',
    full: 'Arizona',
    abbreviation: 'AZ'
  },
  CT: {
    code: 'US/Central',
    full: 'Central',
    abbreviation: 'CT'
  },
  ET: {
    code: 'US/Eastern',
    full: 'Eastern',
    abbreviation: 'ET'
  },
  AST: {
    name: 'Atlantic Time',
    code: 'America/Puerto_Rico',
    abbreviation: 'AST'
  },
  WET: {
    code: 'Europe/London',
    full: 'Western Europe',
    abbreviation: 'WET'
  },
  CET: {
    code: 'Europe/Paris',
    full: 'Central Europe',
    abbreviation: 'WET'
  },
  PHT: {
    code: 'Asia/Manila',
    full: 'Philippines',
    abbreviation: 'PHT'
  },
  EET: {
    code: 'Africa/Cairo',
    full: 'Egypt',
    abbreviation: 'EET'
  },
  NCT: {
    code: 'Asia/Kolkata',
    full: 'India',
    abbreviation: 'NCT'
  },
  AET: {
    code: 'Australia/Sydney',
    full: 'Australian Eastern',
    abbreviation: 'AET'
  }
}

export const getTimezone = ({
  type,
  timezoneId
}: {
  type: string
  timezoneId: string
}) => {
  const abbr = moment.tz.zone(timezoneId)!.abbr(moment().valueOf())
  const mountain = moment.tz.zone('US/Mountain')!.abbr(moment().valueOf())
  let abbreviation = abbr

  // detect Arizona
  if ((abbr !== 'MST' || mountain !== 'MDT') && abbr !== 'EET') {
    abbreviation = abbr.substr(0, abbr.length - 2) + 'T'
  }

  if (!zoneMap[abbreviation]) {
    return type === 'full'
      ? timezoneId
          .split('/')
          .pop()!
          .replace(/_/g, ' ')
      : abbr
  }

  return type === 'full'
    ? zoneMap[abbreviation].full
    : zoneMap[abbreviation].abbreviation
}
