// import { call, put, takeEvery } from 'redux-saga/effects'
import { put, select, takeEvery } from 'redux-saga/effects'

import { VIRTUAL_CARDS } from 'common/constants'
import { appointment } from 'state/_sagas/_subgenerators'
import { updateCard } from 'state/entities/cards'
import { appointmentServiceIdSelector, leadSelector } from 'state/lead'
import { getAppointmentDatetimes, logAction, showToast } from '../_actions'

function* saga() {
  try {
    // Retrieve datetimes from API (use appointment subgenerator)
    // Dispatch updateCard action to store datetimes on the AppointmentCard instance
    const serviceId = yield select(appointmentServiceIdSelector)
    const lead = yield select(leadSelector)
    const datetimeOptions = yield* appointment.getAppointmentDatetimeOptions(
      serviceId,
      lead
    )

    yield put(
      updateCard({
        _id: VIRTUAL_CARDS.BOOKING._id,
        data: { props: { datetimeOptions } }
      })
    )
  } catch (e) {
    yield put(logAction({ error: e }))
    console.error('Error:', e)
    yield put(
      showToast({ message: 'Failed to retrieve timetable', variant: 'error' })
    )
  }
}

export function* getAppointmentDatetimesSaga() {
  yield takeEvery(getAppointmentDatetimes, saga)
}
